import {
    COUNTRY_REGISTER, FIND_USER,
    LOADING_REGISTER,
    ROLE_REGISTER,
    SET_ALUMNOS_REGISTER,
    SET_FIND_REGISTER,
    SET_TUTOR_REGISTER,
    SET_USER_REGISTER,
    SET_DEFAULTS,
    STEP_REGISTER,
    SET_USER_SCHOOLS, SET_USER_STUDENTS
} from '../../actions/types';

const initialState = {
    loading: false,
    country: [],
    role: '',
    step: 0,
    alumnos: [{curp: ''}],
    tutor: [],
    find: false,
    user: []
};

export default function RegistroReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DEFAULTS:
            return {
                ...state,
                loading: false,
                country: [],
                role: '',
                step: 0,
                alumnos: [{curp: ''}],
                tutor: [],
                find: false,
                user: [],
                user_schools: [],
                user_students: []
            };
        case LOADING_REGISTER:
            return {
                ...state,
                loading: action.payload,
            };
        case ROLE_REGISTER:
            return {
                ...state,
                role: action.payload,
                loading: false,
            };
        case COUNTRY_REGISTER:
            return {
                ...state,
                country: action.payload,
            };
        case STEP_REGISTER:
            return {
                ...state,
                step: action.payload,
            };
        case SET_ALUMNOS_REGISTER:
            return {
                ...state,
                alumnos: action.payload,
            };
        case SET_TUTOR_REGISTER:
            return {
                ...state,
                tutor: action.payload,
            };
        case SET_FIND_REGISTER:
            return {
                ...state,
                find: action.payload,
            };
        case SET_USER_REGISTER:
            return {
                ...state,
                user: action.payload,
            };
        case FIND_USER:
            return {
                ...state,
                user: action.payload,
            };
        case SET_USER_SCHOOLS:
            return {
                ...state,
                user_schools: action.payload,
            };
        case SET_USER_STUDENTS:
            return {
                ...state,
                user_students: action.payload,
            };
        default:
            return {
                ...state,
                loading: false,
            };
    }
}
