import {combineReducers} from 'redux';
import AdmisionesReducer from './AdmisionesReducers/AdmisionesReducer';
import AsignarMaestrosReducer from "./Configuracion/AsignarMaestrosReducers/AsignarMaestrosReducer";
import AsistenciasReducers from "./AsistenciasReducers/AsistenciasReducers";
import CalificacionReducer from "./CalificacionesReducers/CalificacionReducer";
import CategoriasReducer from './Configuracion/MateriasReducer/CategoriasReducer';
import DatosDeContactoReducer from "./DatosDeContactoReducers/DatosDeContactoReducer";
import GestionReducer from './GestionReducers/GestionReducer';
import HorariosReducer from './Configuracion/HorariosReducers/HorariosReducer';
import JornadasReducer from './Configuracion/JornadasReducer/JornadasReducer';
import PagosReducer from "./PagosReducers/PagosReducer";
import ParametrosGeneralesReducer from './Configuracion/ParametrosGeneralesReducers/ParametrosGeneralesReducer'
import PlanEstudioReducer from "./PlanesEstudioReducers/PlanEstudioReducer";
import postReducer from './postReducer';
import RootReducer from './RootReducers/RootReducer';
import ZoomReducer from "./ZoomReducers/ZoomReducer";
import ReferencesReducer from "./PagosReducers/ReferencesReducer";
import RegistroReducer from "./Registro/RegistroReducer";
import AcademicReducer from "./AcademicReducers/AcademicReducer";
import AcumuladosReducer from "./PlanesEstudioReducers/AcumuladosReducer";
import VirtualClassReducers from "./VirtualClassReducers/VirtualClassReducers";
import KsmCloudReducers from "./KsmCloudReducers/KsmCloudReducers";

export default combineReducers({
    admisiones: AdmisionesReducer,
    asignarMaestros: AsignarMaestrosReducer,
    asistencias: AsistenciasReducers,
    calificaciones: CalificacionReducer,
    categorias_materias: CategoriasReducer,
    datos_contacto: DatosDeContactoReducer,
    gestion: GestionReducer,
    horarios: HorariosReducer,
    jornadas: JornadasReducer,
    parametros: ParametrosGeneralesReducer,
    pay: PagosReducer,
    plan_estudios: PlanEstudioReducer,
    posts: postReducer,
    rooot: RootReducer,
    zoom: ZoomReducer,
    references: ReferencesReducer,
    registro: RegistroReducer,
    academic: AcademicReducer,
    acumulados: AcumuladosReducer,
    virtual_class: VirtualClassReducers,
    ksm_cloud_files: KsmCloudReducers
});
