import {ADMISIONES, ALUMNOS_INGRESO, DOCUMENTOS_EXTRA} from '../../actions/types';

const initialState = {
    documentos_extra: [],
    documentos_inscritos: false,
    alumnos_ingreso: []
};

export default function AdmisionesReducer(state = initialState, action) {
    switch (action.type) {
        case ADMISIONES:
            return {
                ...state,
                documentos_inscritos: action.documentos_inscritos,
                loading: false,
            };
        case DOCUMENTOS_EXTRA:
            return {
                ...state,
                documentos_extra: action.payload,
                loading: false,
            };
        case ALUMNOS_INGRESO:
            return {
                ...state,
                alumnos_ingreso: action.payload,
                loading: false,
            };
        default:
            return {
                ...state,
                loading: false,
            };
    }
}
