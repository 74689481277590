import {Row} from "react-material-responsive-grid";
import {info, warn} from "../../Global-W/NewFunctions";
import ChatViewBeta from "../Maestro/VirtualClassBeta/ChatViewBeta";
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    myDataGuest,
    producerStated,
    setCurrentItemClass,
    setGuests,
    setLoading,
    setMessages,
    SetStudents
} from "../../../redux/actions/VirtualClassActions/VirtualClassActions";
import KolegiaBroadcastContext from "../../../Context/KolegiaBroadcast";
import MainVideo from "../Alumno/VirtualClassBeta/MainVideo";
import ExtrasVirtualClass from "../Generals/ExtrasVirtualClass";
import {Participants} from "../Generals/Participants";

export default function FullClass() {
    const {
        socketRef,
        sendRequest
    } = useContext(KolegiaBroadcastContext);

    const {
        userName,
        userId,
        bd,
        schoolData,
        userPuesto
    } = useSelector(state => state.rooot)
    const {loading, students, currentItemClass, main_stream} = useSelector(state => state.virtual_class)

    const [enterJoin, setEnterJoin] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        if (currentItemClass !== undefined) {
            dispatch(setLoading(true));
            // sendRequest('fetch users', {room_id: currentItemClass.room_id});
        }
        return () => {
            setEnterJoin(false);
            dispatch(setCurrentItemClass(null))
            dispatch(SetStudents([]));
            dispatch(producerStated(false));
            dispatch(setGuests([]));
            dispatch(myDataGuest(null));
        }
    }, [])

    useEffect(() => {
        info('-DATA CURRENT -', currentItemClass)
        sendRequest('get_updated_students', {
            user_id: currentItemClass.socket_id,
            me: socketRef.current.id
        });
    }, [])

    useEffect(() => {
        if (students.length !== 0 && !enterJoin) {
            warn('ENTRA REQUEST');
            sendRequest('join guest', {
                users_id: userId,
                user_name: userName,
                id_subject: currentItemClass.id_materia,
                school_id: schoolData.id,
                room_id: currentItemClass.room_id,
                bd_school: bd,
                puesto: userPuesto
            }).then((response) => {
                setEnterJoin(true);
                info('JOINED TO CLASS', response);
                sendRequest('fetch users', {room_id: currentItemClass.room_id});
                dispatch(setGuests(response.current_guests));
                dispatch(myDataGuest(response.data_guest));
                dispatch(setMessages(response.messages));
                dispatch(setLoading(false));
            })
        }
    }, [students, enterJoin])

    return (
        <Row className={'uk-width-1-1'} style={{height: '100%'}}>
            <Row className="uk-width-1-1 uk-width-1-2@m"
                 style={{padding: 3, height: '65%', borderBottomLeftRadius: 7, borderBottomRightRadius: 7}}>
                {loading && main_stream !== null
                    ? <Row
                        className="uk-width-1-1 normal_text animated-background"
                        style={{
                            margin: 0,
                            padding: 0,
                            height: '100%',
                            borderRadius: 6,
                            overflow: 'hidden',
                        }}/> : <MainVideo connectToClass={() => {
                    }}/>}
            </Row>
            <Row className="uk-width-1-1 uk-width-1-2@m"
                 style={{height: '65%', borderBottomLeftRadius: 7, borderBottomRightRadius: 7}}>
                <Row className="uk-width-1-2" style={{padding: 3, height: '100%'}}>
                    <ExtrasVirtualClass/>
                </Row>
                <Row className="uk-width-1-2" style={{padding: 3, height: '100%'}}>
                    <ChatViewBeta/>
                </Row>
            </Row>
            <Row className="uk-width-1-1@m" style={{padding: 3, height: '35%'}}>
                {/*<ParticipantsBeta/>*/}
                <Participants/>
            </Row>
        </Row>
    )
}
