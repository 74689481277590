import {
    ACTIVIDADES_ADMIN,
    ACTIVIDADES_ALUMNOS,
    ACTIVITIES_MAESTRO,
    ALUMNOS_REVISION,
    CLASES_CICLO,
    CLASES_CREADAS,
    CLASES_MAESTRO,
    CLASS_PLANNING,
    CLASSES_GROUP,
    DELETE_PLANES,
    DIAS_CLASES,
    GRUPOS_MAESTRO,
    INFO_ACTIVITIES_ADMIN,
    LOADING_PLANES,
    MATERIAS_MAESTRO,
    OPTIONAL_CONTENT,
    PLANEACIONES_MAESTRO,
    PLANES,
    PLANES_ADMIN,
    PLANES_IN_CALENDARIO,
    PLANES_MATERIAS_ADMIN,
    PLANES_MATERIAS_TEACHER,
    POST_LOADING_PLANES,
    SELECTED_PLAN,
    SESSIONS_SCHEDULE, STATUS_MODAL_CRITERIA,
    SUSPENCIONES_CLASES,
    TEMAS,
    TEMAS_ASIGNADOS_MATERIA,
    THEMES_SUBJECT,
    UPDATE_PLAN,
    VALIDATION_TO_CREATE,
    VARIABLE_EXTRA_ALL,
    VARIABLE_GRUPOS,
} from '../../actions/types';

const initialState = {
    loading: false,
    post_loading: false,
    plan: 0,
    planes: [],
    planes_admin: [],
    tema: 0,
    temas: [],
    materias_maestro: [],
    grupos_maestro: [],
    clases_maestro: [],
    clases_ciclo: [],
    dias_clases: [],
    clases_creadas: [],
    actividades_maestro: [],
    variables_grupos: [],
    variable_extra_all: [],
    validation_planes: true,
    suspenciones_clases: [],
    sesiones_clases: [],
    temas_asignados_materia: [],
    alumnos_revision: [],
    actividades_alumnos: [],
    planes_calendario: [],
    sesiones_materia: [],
    actividades_admin: [],
    planes_materias_admin: [],
    planes_materias_teacher: [],
    planeaciones_maestro: [],
    classes_group: [],
    optional_content: [],
    info_activities_admin: [],
    class_planning: [],
    themes_subject: [],
    status_modal_criteria: false,
};

export default function PlanEstudioReducer(state = initialState, action) {

    switch (action.type) {
        case TEMAS:
            return {
                ...state,
                temas: action.payload,
                loading: false
            };
        case PLANES:
            return {
                ...state,
                planes: action.payload,
                loading: false
            };
        case DELETE_PLANES:
            return {
                ...state,
                planes: action.payload,
                loading: false
            };
        case UPDATE_PLAN:
            return {
                ...state,
                planes: action.payload,
                loading: false
            };
        case SELECTED_PLAN:
            return {
                ...state,
                plan: action.payload
            };
        case LOADING_PLANES:
            return {
                ...state,
                loading: action.payload,
            };
        case POST_LOADING_PLANES:
            return {
                ...state,
                post_loading: action.payload,
                loading: false
            };
        case MATERIAS_MAESTRO:
            return {
                ...state,
                materias_maestro: action.payload,
                loading: false
            };
        case GRUPOS_MAESTRO:
            return {
                ...state,
                grupos_maestro: action.payload,
                loading: false
            };
        case CLASES_MAESTRO:
            return {
                ...state,
                clases_maestro: action.payload,
                loading: false
            };
        case CLASES_CICLO:
            return {
                ...state,
                clases_ciclo: action.payload,
                loading: false
            };
        case DIAS_CLASES:
            return {
                ...state,
                dias_clases: action.payload,
                loading: false
            };
        case CLASES_CREADAS:
            return {
                ...state,
                clases_creadas: action.payload,
                loading: false
            };
        case ACTIVITIES_MAESTRO:
            return {
                ...state,
                actividades_maestro: action.payload,
                loading: false
            };
        case PLANES_ADMIN:
            return {
                ...state,
                planes_admin: action.payload,
                loading: false
            };
        case VARIABLE_GRUPOS:
            return {
                ...state,
                variables_grupos: action.payload,
                loading: false
            };
        case VARIABLE_EXTRA_ALL:
            return {
                ...state,
                variable_extra_all: action.payload,
                loading: false
            };
        case VALIDATION_TO_CREATE:
            return {
                ...state,
                validation_planes: action.validation_planes,
                loading: false
            };
        case SUSPENCIONES_CLASES:
            return {
                ...state,
                suspenciones_clases: action.payload,
                loading: false
            };
        case TEMAS_ASIGNADOS_MATERIA:
            return {
                ...state,
                temas_asignados_materia: action.payload,
                loading: false
            };
        case ALUMNOS_REVISION:
            return {
                ...state,
                alumnos_revision: action.payload,
                loading: false
            };
        case ACTIVIDADES_ALUMNOS:
            return {
                ...state,
                actividades_alumnos: action.payload,
                loading: false
            };
        case PLANES_IN_CALENDARIO:
            return {
                ...state,
                planes_calendario: action.payload,
                loading: false
            };
        case SESSIONS_SCHEDULE:
            return {
                ...state,
                sesiones_materia: action.payload,
                loading: false
            };
        case ACTIVIDADES_ADMIN:
            return {
                ...state,
                actividades_admin: action.payload,
                loading: false
            };
        case PLANES_MATERIAS_ADMIN:
            return {
                ...state,
                planes_materias_admin: action.payload,
                loading: false
            };
        case PLANES_MATERIAS_TEACHER:
            return {
                ...state,
                planes_materias_teacher: action.payload,
                loading: false
            };
        case PLANEACIONES_MAESTRO:
            return {
                ...state,
                planeaciones_maestro: action.payload,
                loading: false
            };
        case CLASSES_GROUP:
            return {
                ...state,
                classes_group: action.payload,
                loading: false
            };
        case OPTIONAL_CONTENT:
            return {
                ...state,
                optional_content: action.payload,
                loading: false
            };
        case INFO_ACTIVITIES_ADMIN:
            return {
                ...state,
                info_activities_admin: action.payload,
                loading: false
            };
        case CLASS_PLANNING:
            return {
                ...state,
                class_planning: action.payload,
                loading: false
            };
        case THEMES_SUBJECT:
            return {
                ...state,
                themes_subject: action.payload,
                loading: false
            };
        case STATUS_MODAL_CRITERIA:
            return {
                ...state,
                status_modal_criteria: action.payload,
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
