class Resizer {
    static changeHeightWidth(
        height,
        maxHeight,
        width,
        maxWidth,
        minWidth,
        minHeight
    ) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
        if (minWidth && width < minWidth) {
            height = Math.round((height * minWidth) / width);
            width = minWidth;
        }
        if (minHeight && height < minHeight) {
            width = Math.round((width * minHeight) / height);
            height = minHeight;
        }
        return {height, width};
    }

    static resizeImage(
        image,
        maxWidth,
        maxHeight,
        minWidth,
        minHeight,
        compressFormat = "jpeg",
        quality = 100
    ) {
        let qualityDecimal = quality / 100;
        let canvas = document.createElement("canvas");

        let width = image.width;
        let height = image.height;

        let newHeightWidth = this.changeHeightWidth(
            height,
            maxHeight,
            width,
            maxWidth,
            minWidth,
            minHeight
        );

        canvas.width = newHeightWidth.width;
        canvas.height = newHeightWidth.height;


        width = newHeightWidth.width;
        height = newHeightWidth.height;

        let ctx = canvas.getContext("2d");

        ctx.drawImage(image, 0, 0, width, height);

        return canvas.toDataURL(`image/${compressFormat}`, qualityDecimal);
    }

    static b64toBlob(b64Data, contentType) {
        contentType = contentType || "image/jpeg";
        let sliceSize = 512;

        let byteCharacters = atob(
            b64Data.toString().replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
        );
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    static createResizedImage(
        file,
        maxWidth,
        maxHeight,
        compressFormat,
        quality,
        responseUriFunc,
        outputType = "base64",
        minWidth = null,
        minHeight = null,
    ) {
        let blob = null;
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                let image = new Image();
                image.src = reader.result;
                image.onload = function () {
                    let resizedDataUrl = Resizer.resizeImage(
                        image,
                        maxWidth,
                        maxHeight,
                        minWidth,
                        minHeight,
                        compressFormat,
                        quality,
                    );
                    blob = Resizer.b64toBlob(resizedDataUrl, `image/${compressFormat}`);
                    outputType === "blob"
                        ? responseUriFunc(blob)
                        : responseUriFunc(resizedDataUrl);
                };
            };
            reader.onerror = (error) => {
                responseUriFunc(error);
            };
        } else {
            responseUriFunc("File Not Found");
        }
    }
}

export default {
    imageFileResizer: (
        file,
        maxWidth,
        maxHeight,
        compressFormat,
        quality,
        responseUriFunc,
        outputType,
        minWidth,
        minHeight,
    ) => {
        return Resizer.createResizedImage(
            file,
            maxWidth,
            maxHeight,
            compressFormat,
            quality,
            responseUriFunc,
            outputType,
            minWidth,
            minHeight,
        );
    },
};