import React, {useEffect, useRef, useState} from 'react';
import {Col, Grid, Row} from 'react-material-responsive-grid';
import {
    fetchActivitiesAlumno,
    sendRespuestaActividad,
    setLoadPost
} from "../../../../../redux/actions/PlanEstudioActions/PlanEstudioActions";
import {useDispatch, useSelector} from "react-redux";
import CardActivity from "../../../../ActividadesAprendizaje-W/CardActivity";
import {alertSuccess, alertWarning, pulseLoader, sendReport} from "../../../../Global-W/NewFunctions";
import FaltaMensaje from "../../../../Global-W/Classes/FaltaMensaje";
import Modal from "../../../../Global-W/Hooks/Modal";
import SaveButtonV2 from "../../../../Global-W/Hooks/SaveButtonV2";
import {PreviewImages} from "../../../../Global-W/Hooks/PreviewImages";
import {AddLinks} from "../../../../Global-W/Hooks/AddLinks";
import axios from 'axios'
import {setModalActivityOnClass} from "../../../../../redux/actions";

const moment = require('moment/moment');
require('moment/locale/es');
moment.locale('es');

export default function AnswerActivityBeta() {
    const resetLinks = useRef(null);
    const resetFiles = useRef(null);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.plan_estudios.loading);
    const post_loading = useSelector(state => state.plan_estudios.post_loading);
    const actividades_alumnos = useSelector(state => state.plan_estudios.actividades_alumnos);
    const {classData, status_class} = useSelector(state => state.virtual_class);
    const [indicator, setIndicator] = useState(true);
    const [links, setLinks] = useState([]);
    const [ixActivity, setIxActivity] = useState(-1);
    const [inicio, setInicio] = useState(0)
    const [modalRespuesta, setModalRespuesta] = useState(false);
    const [comentario, setComentario] = useState('');
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [tipoActividad, setTipoActividad] = useState('todos');
    const [files, setFiles] = useState([]);
    const [prevFiles, setPrevFiles] = useState([]);
    const [modalFiles, setModalFiles] = useState(false)
    const [activityResponses, setActivityResponses] = useState([])
    const [numFiles, setNumFiles] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [isFinished, setIsFinished] = useState(false)
    const [responseId, setResponseId] = useState(null)
    const {
        bd,
        schoolData,
        token,
        userId,
        userName,
        userPuesto,
        userRole,
        api,
        secondColor,
        thirdColor,
        fourthColor,
        fifthColor,
        orangeColor,
        redColor,
        blueColor,
        grayColorOne,
        grayColorTwo,
        grayColorThree,
        modalActivityOnClass,
    } = useSelector(state => state.rooot);
    const [activities, setActivities] = useState([]);
    const mensaje1 = 'Se ha indicado que  la fecha límite de entrega de esta actividad es el día '
    const mensaje2 = '. Después de esta fecha y hora ya no será posible enviar su respuesta.'

    const optionFiltro = [
        {name: 'Todos', tipo: 'todos', color: thirdColor, icono: 'fas fa-th-large',},
        {name: 'Avisos', tipo: 'avisos', color: secondColor, icono: 'fas fa-exclamation'},
        {name: 'Pendientes', tipo: 'pendiente', color: redColor, icono: 'fas fa-clock'},
        {name: 'Enviados', tipo: 'enviado', color: '#d7a800', icono: 'fas fa-paper-plane'},
        {name: 'Mejorar', tipo: 'mejora', color: '#c9d700', icono: 'fas fa-chevron-circle-up'},
        {name: 'Evaluados', tipo: 'evaluado', color: '#1c7300', icono: 'fas fa-star'},
        {name: 'No entregado', tipo: 'entregado', color: orangeColor, icono: 'fas fa-times'},
    ];

    useEffect(() => {
        if (status_class === 1) {
            setIndicator(true);
            dispatch(fetchActivitiesAlumno(token, bd, userId, inicio, tipoActividad, classData.relacion.id_materia));
            setActivityResponses([])
            setResponseId(null)
            setIsFinished(false)
        }
    }, [classData, tipoActividad, inicio]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setActivities([])
    }, [tipoActividad])

    useEffect(() => {
        let index = 0;
        actividades_alumnos.forEach((item) => {
            setActivities(activities => [...activities, item]);
            index++;
        });
        if (index === actividades_alumnos.length) {
            setIndicator(false);
        }
    }, [actividades_alumnos]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (loading) {
            setIndicator(true);
        }
    }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

    const pushResponse = (response) => {
        activityResponses.push(response)
    }

    useEffect(() => {
        if (responseId !== null) {
            setPublished()
        }
    }, [isFinished]) // eslint-disable-line react-hooks/exhaustive-deps

    const setPublished = async () => {
        try {
            await axios.get(api + bd + '/activity_learn/published/update/' + responseId, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then(() => {
                setActivityResponses([])
                setResponseId(null)
                setIsFinished(false)
            })
            alertSuccess('Se ha enviado la respuesta de la actividad con éxito');
        } catch (error) {
            alertWarning('Hubo un error al publicar tu respuesta')
            sendReportData(error, '', 'ActividadesAprendizaje/Alumno/GestionarActividades', 'setPublished', {a: api + bd + '/activity_learn/published/update/' + responseId})
        }
    }

    const sendReportData = (error, description, component, func, params) => {
        const data = {
            "errorReport": {
                "error": error.toString(),
                "errorInfo": JSON.stringify(error),
                "database": bd,
                "userId": userId,
                "role": userRole,
                "jobPosition": userPuesto,
                "level": null,
                "group": null,
                "name": userName,
                // "active": 1,
                // "status": 2,
                "schoolId": schoolData.id,
                "description": description,
                "function": func,
                "component": component,
                "parameters": params
            }
        };
        sendReport(data);
        return "Ok";
    }

    const verifyUpload = () => {
        if (files.length === activityResponses.length) {
            setIsFinished(true)
        } else {
            setActivityResponses([]);
            setResponseId(null)
            setIsFinished(false)
        }
    }

    const tiposActividades = optionFiltro.map((item, i) => {
        return <Row key={i + "filtro"} className="uk-width-1-5" style={{margin: 0, padding: 3}}>
            <Row
                data-uk-tooltip={item.name}
                className="uk-width-1-1 centerAll"
                style={{
                    margin: 0,
                    padding: 5,
                    borderRadius: 6,
                    border: '1px solid',
                    borderColor: item.color,
                    backgroundColor: tipoActividad === item.tipo ? item.color : grayColorOne,
                }}
                onClick={() => {
                    if (item.tipo !== tipoActividad) {
                        setInicio(0);
                        setTipoActividad(item.tipo);
                        setIxActivity(-1);
                    }
                }}
            >
                <Row className="uk-width-1-1 centerAll">
                    <Row className="uk-width-1-5 centerAll">
                        <label className="uk-width-1-1 normal_text uk-text-truncate" style={{
                            color: tipoActividad === item.tipo ? fourthColor : item.color,
                        }}>
                            <i className={item.icono}/>
                        </label>
                    </Row>
                    <Row className="uk-width-4-5 centerAll">
                        <label className="uk-width-1-1 normal_text uk-text-truncate"
                               style={{
                                   textAlign: 'left',
                                   fontSize: '10px',
                                   textTransform: 'uppercase',
                                   color: tipoActividad === item.tipo ? fourthColor : item.color,
                               }}>
                            {item.name}
                        </label>
                    </Row>
                </Row>
            </Row>
        </Row>
    });

    const renderSelector = () => {
        return <Row className="uk-width-1-1">
            <Row className="uk-width-1-1 sub_title">
                Seleccione el tipo de actividades a consultar
            </Row>
            <Row className="uk-width-1-1" style={{marginTop: 5}}>
                {tiposActividades}
            </Row>
        </Row>
    }

    const activitiesList = activities.map((itm, i) => {
        const estatus_actividad = itm.respuesta_actividad === null ? '1' : itm.respuesta_actividad.estatus_actividad
        let isAdmin = userRole === 'Admin' || userRole === 'Maestro';
        return (<Col
            key={i + 'activity'}
            className="uk-width-1-2 centerAll"
            style={{margin: 0, padding: 6}}>
            <CardActivity
                key={i + 'card'}
                is_aviso={itm.id_materia === 'aviso general'}
                id_criterio={itm.id_criterio}
                estatus_actividad={itm.respuesta_actividad === null ? '1' : itm.respuesta_actividad.estatus_actividad}
                editable={itm.actividad === null}
                enviado={itm.respuesta_actividad !== null}
                published={itm.respuesta_actividad !== null
                    ? Number(itm.respuesta_actividad.published)
                    : null}
                fecha_enviado={itm.respuesta_actividad !== null ? itm.respuesta_actividad.created_at : ''}
                tipo_entrega={itm.tipo_entrega}
                autor={itm.id_materia === 'aviso general' ? itm.maestro.name : itm.materia.nombre}
                tema={itm.id_clase === 'ninguno' || itm.id_clase === '' ? 'NINGUNO' : itm.tema_sesion.tema !== null ? itm.tema_sesion.tema.indice + ' ' + itm.tema_sesion.tema.tema : 'NINGUNO'}
                retroalimentacion={itm.retroalimentacion}
                button3={() => null}
                buttons={[true, true, isAdmin]}
                deleted={() => null}
                fecha_prorroga={estatus_actividad === '4' || estatus_actividad === '2' ? itm.respuesta_actividad.prorroga : ''}
                updated={() => {
                    if ((estatus_actividad === '2') || (estatus_actividad === '4') || (estatus_actividad === '0')) {
                        if ((estatus_actividad === '4' || estatus_actividad === '2') && moment(itm.respuesta_actividad.prorroga) < moment()) {
                            let mensajeProrroga1 = 'Se le dio un tiempo de PRÓRROGA ADICIONAL para la entrega de esta actividad, el cual YA VENCIÓ y por lo tanto ya no puede entregar la actividad.'
                            if (estatus_actividad === '2') {
                                mensajeProrroga1 = 'Se le dio un tiempo límite para la mejora de esta actividad, el cual YA VENCIÓ y por lo tanto ya no puede entregar la actividad.'
                            }
                            alertWarning(mensajeProrroga1);
                        } else {
                            doNext(itm, i)
                        }
                    } else {
                        if (itm.validaciones.restriccion_fecha === '0' || moment() <= moment(itm.fecha_entrega + ' ' + itm.hora)) {
                            doNext(itm, i)
                        } else {
                            alertWarning(mensaje1 + moment(itm.fecha_entrega + ' ' + itm.hora).format('DD-MM-Y') + ' a las ' + moment(itm.fecha_entrega + ' ' + itm.hora).format('HH:mm') + mensaje2)
                        }
                    }
                }}
                files={itm.respuesta_actividad !== null ? itm.respuesta_actividad.all_media.length : 0}
                openFiles={() => {
                    if (itm.respuesta_actividad !== null) {
                        setIxActivity(i);
                        setComentario(itm.respuesta_actividad.comentario);
                        setPrevFiles(itm.respuesta_actividad.all_media);
                        setModalFiles(true)
                    }
                }}
                destinatarios={
                    itm.id_materia === 'aviso general' ?
                        ''
                        : itm.materia.club === '2' ? itm.grupo.name : itm.materia.id_grado.grado + '' + itm.grupo.grupo
                }
                download={() => console.info('download')}
                dueDate={itm.fecha_entrega}
                dueTime={itm.hora}
                enterado={itm.enterado !== null}
                fecha={itm.created_at}
                imagen1={itm.imagen}
                link={itm.link}
                logo={'https://galeriasce.s3-us-west-2.amazonaws.com/img-repos/' + bd + '/icono.png'}
                nivel={'Primaria'}
                pdf={itm.documento}
                resumen={itm.descripcion}
                requeridos={itm.requeridos}
                otros_requeridos={itm.otros_requeridos}
                transversalidad={itm.transversalidad}
                audio={itm.audio}
                video={itm.video}
                imagenes={itm.imagenes}
                documentos={itm.documentos}
                videos={itm.videos}
                audios={itm.audios}
                links={itm.links}
                role={userRole}
                titulo={itm.titulo.toUpperCase()}
                ponderacion={itm.actividad !== null && itm.actividad !== undefined ? itm.actividad : null}
            />
        </Col>);
    })

    const doNext = (itm, i) => {
        setIxActivity(i);
        setModalRespuesta(true);
        if (itm.respuesta_actividad !== null) {
            setComentario(itm.respuesta_actividad.comentario);
            setPrevFiles(itm.respuesta_actividad.all_media);
        } else {
            setComentario('');
            setPrevFiles([]);
        }
    }

    const sendRespuesta = (concats) => {
        const formData = new FormData();
        formData.append('new', JSON.stringify({
            id_alumno: userId,
            id_actividad: activities[ixActivity].id,
            comentario: comentario,
            imagen: "[]",
            documento: null,
            prev_files: prevFiles,
            estatus_actividad: activities[ixActivity].respuesta_actividad === null ? '1' : activities[ixActivity].respuesta_actividad.estatus_actividad,
            published: activities[ixActivity].respuesta_actividad === null
                ? 0
                : activities[ixActivity].respuesta_actividad.published === 2
                    ? 0
                    : activities[ixActivity].respuesta_actividad.published === 1
                        ? 1
                        : 0,
            // prorroga:activities[ixActivity].respuesta_actividad.prorroga
        }));
        dispatch(sendRespuestaActividad(token, bd, formData, userId, 0, tipoActividad)).then((response) => {
            setResponseId(response.data.id)
            if (concats.length !== 0) {
                saveFile(response.data.id, 'activity_response', 0, concats);
            } else {
                setInicio(0);
                setIxActivity(-1);
                setFiles([]);
                setActivities([]);
                setModalRespuesta(false);
                dispatch(setLoadPost(false));
                dispatch(fetchActivitiesAlumno(token, bd, userId, 0, tipoActividad));
            }
        });
    }

    const renderImages = prevFiles.map((item, i) => {
        if (item.status === '1') {
            if (item.file_type === 'jpeg' || item.file_type === 'png' || item.file_type === 'jpg') {
                return <Row key={i + 'preview'}
                            className='uk-width-1-3 centerAll'
                            style={{margin: 0, padding: 3}}>
                    <Row className='uk-width-1-1'
                         style={{
                             backgroundColor: grayColorThree,
                             borderRadius: 6,
                             overflow: 'hidden',
                             margin: 0,
                         }}
                    >
                        <Row className="uk-width-1-1"
                             style={{
                                 borderBottom: '1px solid',
                                 borderColor: grayColorOne,
                                 margin: 0,
                                 padding: 3,
                             }}>
                            <Row className="uk-width-5-6"
                            >
                                <label style={{textAlign: 'left'}}
                                       className=' uk-width-1-1 normal_text uk-text-truncate'>{item.name}</label>
                            </Row>
                            {!modalFiles
                                ? <Row className="uk-width-1-6 centerAll"
                                >
                                    <Row className="uk-width-1-1 centerAll"
                                    >
                                        <div onClick={() => {
                                            const old = prevFiles[i];
                                            const updated = {...old, 'status': '0'}
                                            const clone = [...prevFiles];
                                            clone[i] = updated;
                                            setPrevFiles(clone);
                                        }}>
                                            <i style={{color: redColor}}
                                               className="fas fa-minus-circle"/>
                                        </div>
                                    </Row>
                                </Row>
                                : null}
                        </Row>
                        <Row className="uk-width-1-1 centerAll"
                             style={{
                                 backgroundColor: grayColorOne,
                                 borderBottomLeftRadius: 6,
                                 borderBottomRightRadius: 6,
                                 margin: 0,
                                 padding: 0,
                             }}>
                            <img className="uk-width-1-1 centerAll"
                                 style={{
                                     borderBottomLeftRadius: 6,
                                     borderBottomRightRadius: 6,
                                     objectFit: 'cover',
                                     width: 150, height: 118
                                 }}
                                 src={item.file}
                                 alt=""/>
                        </Row>
                    </Row>
                </Row>
            } else {
                return <Row className="centerAll uk-width-1-1" style={{margin: 0, padding: 3}}>
                    <Row className="centerAll uk-width-1-1 normal_text"
                         style={{
                             margin: 0,
                             padding: 5,
                             borderRadius: 6,
                             backgroundColor: grayColorTwo
                         }}>
                        <Row className=" uk-width-5-6 normal_text uk-text-truncate">
                            {item.name}
                        </Row>
                        {!modalFiles
                            ? <Row
                                className="centerAll uk-width-1-6 normal_text"
                                onClick={() => {
                                    const old = prevFiles[i];
                                    const updated = {...old, 'status': '0'}
                                    const clone = [...prevFiles];
                                    clone[i] = updated;
                                    setPrevFiles(clone);
                                }}
                            >
                                <i className="fas fa-minus-circle" style={{color: redColor}}/>
                            </Row>
                            : null}
                    </Row>
                </Row>
            }

        } else {
            return null
        }
    });

    const saveRespuesta = () => {
        let concats = links.concat(files);
        setTotalFiles(concats.length);
        if (activities[ixActivity].validaciones.obligatorio_texto === '1' && comentario === '') {
            alertWarning('El docente ha indicado que es necesario que envíe una respuesta de texto, no puede enviar la respuesta de la actividad sin ello');
        } else if (activities[ixActivity].validaciones.obligatorio_imagen === '1' && concats.length === 0) {
            alertWarning('El docente ha indicado que es necesario que envíe una respuesta con archivos multimedia, no puede enviar la respuesta de la actividad sin ello');
        } else {
            sendRespuesta(concats);
        }
    }

    const selectFile = (files) => {
        setFiles(files);
    }

    const saveFile = async (id, type, i, concats) => {
        dispatch(setLoadPost(true));
        setNumFiles(i + 1);
        let formData = new FormData();
        let urlApi = '';
        if (concats[i].type === 'link') {
            urlApi = api + bd + '/v3/noticias/upload/link';
            formData.append('new', JSON.stringify({
                'activity_id': id,
                'activity_type': type,
                'file': concats[i].url,
                'file_type': concats[i].type,
                'status': '1',
            }));
        } else {
            urlApi = api + bd + '/v3/noticias/upload/file';
            formData.append('new', JSON.stringify({
                'id': id,
                'type': type,
                'name': concats[i].name,
            }));
            formData.append('file', concats[i].file);
        }
        await axios.post(urlApi, formData, {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor((loaded * 100) / total)

                if (percent < 100) {
                    setUploadPercentage(percent)
                }
            },
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + token
            }
        }).then(response => {
            pushResponse(true)
            setUploadPercentage(100);
            if (i + 1 < concats.length) {
                setUploadPercentage(0);
                saveFile(id, type, i + 1, concats)
            } else {
                verifyUpload()
                setTotalFiles(0);
                setNumFiles(0);
                setInicio(0);
                setIxActivity(-1);
                setFiles([]);
                setActivities([]);
                setModalRespuesta(false);
                dispatch(setLoadPost(false));
                dispatch(fetchActivitiesAlumno(token, bd, userId, 0, tipoActividad));
                // resetFiles.current.resetFiles();
                // resetLinks.current.resetLinks();
            }
        }).catch(error => {
            pushResponse(false)
            if (i + 1 < concats.length) {
                setUploadPercentage(0);
                saveFile(id, type, i + 1, concats)
            } else {
                setTotalFiles(0);
                setNumFiles(0);
                setInicio(0);
                setIxActivity(-1);
                setFiles([]);
                setActivities([]);
                setModalRespuesta(false);
                dispatch(setLoadPost(false));
                dispatch(fetchActivitiesAlumno(token, bd, userId, 0, tipoActividad));
                // resetFiles.current.resetFiles();
                // resetLinks.current.resetLinks();
                sendReportData(error, '', 'ActividadesAprendizaje/Alumno/GestionarActividades', 'saveFile', {a: urlApi})
            }
        });
    }

    const addLink = (links) => {
        setLinks(links)
    }

    const extras = () => {
        const item = activities[ixActivity];

        const estatus_actividad = item !== undefined
            ? item.respuesta_actividad === null ? '1'
                : item.respuesta_actividad.estatus_actividad : '1';

        const editable = item !== undefined
            ? item.actividad === null
            : true;

        const enviable = item !== undefined
            ? !editable && (estatus_actividad === '2' || estatus_actividad === '4')
                ? true
                : item.validaciones.restriccion_fecha === '0' || moment() <= moment(item.fecha_entrega + ' ' + item.hora)
            : false

        return <Row className="uk-width-1-1">
            <Modal open={modalRespuesta}
                   size={'medium-modal'}
                   closeOnOverlayClick={false}
                   onClose={() => {
                       setIxActivity(-1);
                       setModalRespuesta(false);
                   }}
                   child={ixActivity !== -1 ?
                       <Row className="uk-width-1-1 centerAll normal_text"
                            style={{alignItems: 'flex-start'}}>
                           <Row className="uk-width-1-1 module_title"
                                style={{fontSize: '20px', textAlign: 'left'}}>
                               {item.titulo}
                           </Row>
                           <Row className="uk-width-1-1 normal_text uk-text-truncate"
                                style={{marginTop: 3, fontSize: '12px', textAlign: 'left'}}>
                               {item.id_clase === 'ninguno'
                                   ? 'NINGUNO'
                                   : item.tema_sesion !== null
                                       ? item.tema_sesion.tema !== null
                                           ? item.tema_sesion.tema.indice + ' ' + item.tema_sesion.tema.tema
                                           : 'NINGUNO'
                                       : 'NINGUNO'}
                           </Row>
                           <Row className='uk-width-1-2' style={{margin: 0, padding: '0 5px 0 0'}}>
                               <Row
                                   className="uk-width-1-1 sub_title"
                                   style={{marginTop: 10}}
                               >
                                   Escriba un texto de respuesta
                               </Row>
                               <textarea
                                   className="uk-button-default uk-width-1-1 normal_text"
                                   style={{
                                       borderRadius: 6,
                                       backgroundColor: fourthColor,
                                       borderColor: grayColorTwo,
                                       borderWidth: 1,
                                       lineHeight: 1,
                                       padding: 5,
                                       textAlign: 'left',
                                   }}
                                   maxLength={1000}
                                   rows="7"
                                   onChange={e => setComentario(e.target.value)}
                                   value={comentario}/>
                               <AddLinks
                                   ref={resetLinks}
                                   addLink={(links) => addLink(links)}/>
                               {prevFiles.length === 0
                                   ? null : <Row className="centerAll uk-width-1-1"
                                                 style={{justifyContent: 'flex-start'}}>
                                       <Row style={{margin: '10px 0 5px 0', padding: 0,}}
                                            className="main_title uk-width-1-1">
                                           Archivos multimedia adjuntados previamente
                                       </Row>
                                       {renderImages}
                                   </Row>}
                               {activities[ixActivity].respuesta_actividad !== null
                                   ? <Row className="uk-width-1-1" style={{marginTop: 10}}>
                                       {activities[ixActivity].respuesta_actividad.comentario_maestro !== null && activities[ixActivity].respuesta_actividad.comentario_maestro !== ''
                                           ? <Row className="uk-width-1-1">
                                               <Row style={{marginTop: 10, marginBottom: 5}}
                                                    className="main_title uk-width-1-1">
                                                   Comentario del maestro
                                               </Row>
                                               <div
                                                   className="uk-button-default uk-width-1-1 normal_text"
                                                   style={{
                                                       borderRadius: 6,
                                                       backgroundColor: grayColorOne,
                                                       borderColor: grayColorTwo,
                                                       borderWidth: 1,
                                                       lineHeight: 1,
                                                       padding: 10,
                                                       height: '10vh',
                                                       textAlign: 'left'
                                                   }}
                                               >
                                                   {activities[ixActivity].respuesta_actividad.comentario_maestro}
                                               </div>
                                           </Row> : null}
                                       <div
                                           className="uk-width-1-1"
                                           style={{
                                               color: orangeColor,
                                               textAlign: 'left',
                                               marginTop: 10,
                                               fontSize: '1rem'
                                           }}
                                       >
                                           *Al realizar esta acción se actualizará la respuesta
                                       </div>
                                   </Row> : null}
                           </Row>

                           <Row className='uk-width-1-2' style={{margin: 0, padding: '0 0 0 5px'}}>
                               <Row className='uk-width-1-1 sub_title' style={{marginTop: 10}}>
                                   Adjuntar nuevos archivos multimedia
                               </Row>
                               <div className='uk-width-1-1' style={{
                                   margin: 0,
                                   padding: 0,
                                   marginTop: 3,
                                   marginBottom: 6,
                                   fontSize: '.75rem',
                                   color: redColor,
                                   textAlign: 'left'
                               }}>
                                   *Asegúrate de tener una conexión de red estable para poder subir varios archivos o
                                   archivos
                                   pesados no mayores a 10mb, de lo contrario no se podrá subir adecuadamente la
                                   respuesta de
                                   la actividad.
                               </div>
                               <PreviewImages
                                   ref={resetFiles}
                                   accepted={'.jpg,.png,.jpeg,.pdf,.ppt,.pptx,.xls,.docx,.doc,.xlsx,.mp4,.ovg,.webm,.mkv'}
                                   child={<Row className="uk-width-1-1 centerAll normal_text"
                                               style={{
                                                   border: 'px solid',
                                                   borderColor: grayColorOne,
                                                   borderStyle: 'dashed',
                                                   margin: 0,
                                                   padding: 15,
                                               }}>
                                       <Row className="uk-width-1-1 centerAll sub_title"
                                            style={{margin: '10px 0', padding: 0}}>
                                           Agregar archivos
                                           <i style={{fontSize: '2em', margin: '0 10px'}} className="fas fa-upload"/>
                                       </Row>
                                       <Row className="uk-width-1-1 centerAll section_title_two"
                                       >
                                           Extensiones de archivos aceptadas
                                       </Row>
                                       <Row className="uk-width-1-1 centerAll"
                                       >
                                           .jpg, .png, .jpeg, .pdf, .ppt, .pptx, .xls, .docx, .xlsx, .mkv, .webm, .ovg,
                                           .mp4
                                       </Row>
                                   </Row>}
                                   previewImages={'3'}
                                   imageSize={'uk-width-1-3'}
                                   select={(files) => selectFile(files)}/>
                           </Row>
                           {activities[ixActivity].respuesta_actividad !== null ? activities[ixActivity].respuesta_actividad.published === 1 || activities[ixActivity].respuesta_actividad.published === 0
                               ? <div
                                   className="uk-width-1-1"
                                   style={{
                                       color: orangeColor,
                                       textAlign: 'left',
                                       marginTop: 10,
                                       fontSize: '1rem'
                                   }}
                               >
                                   *Debes esperar a que la respuesta de la actividad se suba completamente o esta se
                                   cancelará
                               </div>
                               : null : null}
                           {post_loading && totalFiles > 0 ?
                               <Row style={{marginTop: 10}} className="uk-width-1-1 centerAll">
                                   <Row className="uk-width-1-2 centerAll">
                                       <Row
                                           className="uk-width-1-1 centerAll normal_text">
                                           Subiendo archivos: {numFiles} de {totalFiles}. No recargues ni cierres la
                                           ventana
                                       </Row>
                                       <progress className="uk-width-7-8 uk-progress"
                                                 value={uploadPercentage}
                                                 style={{margin: '5px 0'}}
                                                 max="100"/>
                                       <Row className="uk-width-1-1 normal_text"
                                            style={{textAlign: 'right'}}>
                                           <label className="uk-width-1-1 normal_text" style={{textAlign: 'center'}}>
                                               {uploadPercentage}%
                                           </label>
                                       </Row>
                                   </Row>
                               </Row> : null}

                           <Row className="uk-width-1-2 centerAll" style={{margin: 0, padding: 10, marginTop: 10}}>
                               <Row className="uk-width-1-2 centerAll" style={{margin: 0, padding: 5}}>
                                   {post_loading
                                       ? <Row data-uk-tooltip={"Cancelar"}
                                              className={'uk-button uk-button-default uk-width-1-1'}
                                              style={{
                                                  padding: 10,
                                                  lineHeight: 1,
                                                  borderRadius: 7,
                                                  border: '1px solid',
                                                  borderColor: secondColor,
                                                  backgroundColor: fourthColor,
                                              }}>
                                           <Row className='uk-width-1-1 centerAll'>
                                               <div><i className={"far fa-times-circle"}
                                                       style={{color: secondColor}}/>
                                               </div>
                                               <label className='normal_text uk-width-2-3 uk-text-truncate'
                                                      style={{
                                                          marginLeft: 10,
                                                          color: secondColor,
                                                          lineHeight: 1
                                                      }}>
                                                   Cancelar
                                               </label>
                                           </Row>
                                       </Row>
                                       : <SaveButtonV2
                                           icon="far fa-times-circle"
                                           size={"1"}
                                           title="Cancelar"
                                           text_color={secondColor}
                                           styles={{
                                               border: '1px solid',
                                               borderColor: secondColor,
                                               backgroundColor: fourthColor,
                                           }}
                                           onClick={() => {
                                               setModalRespuesta(false);
                                               setIxActivity(-1);
                                           }}
                                       />}
                               </Row>
                               <Row className="uk-width-1-2 centerAll" style={{margin: 0, padding: 5}}>
                                   <SaveButtonV2
                                       styles={{backgroundColor: enviable ? secondColor : grayColorOne}}
                                       text_color={enviable ? fourthColor : fifthColor}
                                       icon="fas fa-paper-plane"
                                       size={"1"}
                                       title={activities[ixActivity].respuesta_actividad !== null ? "Actualizar respuesta" : "Enviar respuesta"}
                                       onClick={() => enviable ? saveRespuesta() : alertWarning(mensaje1 + moment(activities[ixActivity].fecha_entrega + ' ' + activities[ixActivity].hora).format('DD-MM-Y HH:mm:ss')) + mensaje2}
                                   />
                               </Row>
                           </Row>
                       </Row> : null}
            />
            <Modal open={modalFiles}
                   size={'small-modal'}
                   closeOnOverlayClick={false}
                   onClose={() => {
                       setIxActivity(-1);
                       setModalFiles(false);
                   }}
                   child={ixActivity !== -1 ?
                       <Row className="uk-width-1-1 centerAll normal_text"
                            style={{alignItems: 'flex-start'}}>
                           <Row className="uk-width-1-1 module_title"
                                style={{fontSize: '20px', textAlign: 'left'}}>
                               {activities[ixActivity].titulo}
                           </Row>
                           <Row className="uk-width-1-1 normal_text uk-text-truncate"
                                style={{marginTop: 3, fontSize: '12px', textAlign: 'left'}}>
                               {activities[ixActivity].id_clase === 'ninguno'
                                   ? 'NINGUNO'
                                   : activities[ixActivity].tema_sesion !== null
                                       ? activities[ixActivity].tema_sesion.tema !== null
                                           ? activities[ixActivity].tema_sesion.tema.indice + ' ' + activities[ixActivity].tema_sesion.tema.tema
                                           : 'NINGUNO'
                                       : 'NINGUNO'}
                           </Row>
                           <Row className='uk-width-1-1' style={{margin: 0, padding: '0 5px 0 0'}}>
                               <Row
                                   className="uk-width-1-1 sub_title"
                                   style={{marginTop: 10}}
                               >
                                   Respuesta
                               </Row>
                               <textarea
                                   disabled={true}
                                   className="uk-button-default uk-width-1-1 normal_text"
                                   style={{
                                       borderRadius: 6,
                                       backgroundColor: fourthColor,
                                       borderColor: grayColorTwo,
                                       borderWidth: 1,
                                       lineHeight: 1,
                                       padding: 5,
                                       textAlign: 'left',
                                   }}
                                   maxLength={1000}
                                   rows="7"
                                   onChange={e => setComentario(e.target.value)}
                                   value={comentario}/>
                               {prevFiles.length === 0
                                   ? null : <Row className="centerAll uk-width-1-1"
                                                 style={{justifyContent: 'flex-start'}}>
                                       <Row style={{margin: '10px 0 5px 0', padding: 0,}}
                                            className="main_title uk-width-1-1">
                                           Archivos multimedia adjuntados
                                       </Row>
                                       {renderImages}
                                   </Row>}
                               {activities[ixActivity].respuesta_actividad !== null
                                   ? <Row className="uk-width-1-1" style={{marginTop: 10}}>
                                       {activities[ixActivity].respuesta_actividad.comentario_maestro !== null && activities[ixActivity].respuesta_actividad.comentario_maestro !== ''
                                           ? <Row className="uk-width-1-1">
                                               <Row style={{marginTop: 10, marginBottom: 5}}
                                                    className="main_title uk-width-1-1">
                                                   Comentario del maestro
                                               </Row>
                                               <div
                                                   className="uk-button-default uk-width-1-1 normal_text"
                                                   style={{
                                                       borderRadius: 6,
                                                       backgroundColor: grayColorOne,
                                                       borderColor: grayColorTwo,
                                                       borderWidth: 1,
                                                       lineHeight: 1,
                                                       padding: 10,
                                                       height: '10vh',
                                                       textAlign: 'left'
                                                   }}
                                               >
                                                   {activities[ixActivity].respuesta_actividad.comentario_maestro}
                                               </div>
                                           </Row> : null}
                                   </Row> : null}
                           </Row>

                           <Row className="uk-width-1-1 centerAll" style={{margin: 0, padding: 5}}>
                               <Row data-uk-tooltip={"Cancelar"}
                                    onClick={() => {
                                        setIxActivity(-1);
                                        setModalFiles(false)
                                    }}
                                    className={'uk-button uk-button-default uk-width-1-2'}
                                    style={{
                                        padding: 10,
                                        lineHeight: 1,
                                        borderRadius: 7,
                                        border: '1px solid',
                                        borderColor: secondColor,
                                        backgroundColor: fourthColor,
                                    }}>
                                   <Row className='uk-width-1-1 centerAll'>
                                       <div><i className={"far fa-times-circle"} style={{color: secondColor}}/>
                                       </div>
                                       <label className='normal_text uk-width-2-3 uk-text-truncate'
                                              style={{marginLeft: 10, color: secondColor, lineHeight: 1}}>
                                           Cerrar
                                       </label>
                                   </Row>
                               </Row>
                           </Row>

                       </Row> : null}
            />
        </Row>
    }

    return (<Row className="uk-width-1-10 centerAll hover-background-gray">
        <Row
            className="uk-width-1-1 centerAll"
            data-uk-tooltip="Mis actividades"
            onClick={() => dispatch(setModalActivityOnClass(true))}>
            <div>
                <i className="fas fa-arrow-circle-up" style={{fontSize: '1.2rem'}}/>
            </div>
        </Row>
        <Modal size={"medium-modal"} open={modalActivityOnClass} onClose={() => {
            dispatch(setModalActivityOnClass(false))
        }}>
            <Grid className="uk-width-1-1">
                <Row style={{margin: 0, padding: 15}}>
                    <div className="uk-width-1-2" style={{margin: 0, padding: '0 5px 0 0'}}>
                        {renderSelector()}
                    </div>
                    <Row className="uk-width-1-1 main_title" style={{marginTop: 10}}>
                        Lista de actividades
                    </Row>
                    <div className="uk-width-1-1 scroll centerAll"
                         style={{
                             margin: 0,
                             padding: 5,
                             backgroundColor: grayColorTwo,
                             borderRadius: 6,
                             maxHeight: '55vh'
                         }}>
                        {indicator && inicio === 0 ? pulseLoader()
                            : activities.length !== 0 ?
                                <Row
                                    className="uk-width-1-1"
                                    uk-grid="masonry: true"
                                    style={{alignItems: 'flex-start'}}
                                >
                                    {activitiesList}
                                </Row>
                                : <FaltaMensaje mensaje="Sin actividades para el alumno seleccionado"/>}
                        {actividades_alumnos.length >= 5 ?
                            <Row className="uk-width-1-1 centerAll" style={{margin: 0, padding: 10}}>
                                {indicator && inicio !== 0 ? pulseLoader()
                                    : <Row
                                        className="uk-width-1-3 centerAll"
                                        style={{
                                            margin: 0,
                                            padding: 10,
                                            borderRadius: 6,
                                            backgroundColor: grayColorOne,
                                            fontSize: '1rem'
                                        }}
                                        onClick={() => setInicio(inicio + 5)}
                                    >
                                        <div style={{textAlign: 'center'}}>
                                            <i className="fas fa-chevron-down" style={{color: blueColor}}/>
                                        </div>
                                        <div style={{textAlign: 'center', marginLeft: 10}}>
                                            Mostrar más
                                        </div>
                                    </Row>}
                            </Row>
                            : null}
                    </div>
                </Row>
                {extras()}
            </Grid>
        </Modal>
    </Row>);
}
