import {
    ADMIN_REQUEST,
    ADMIN_REQUEST_AUDIO,
    ALL_CLASSES_USER,
    CHANGE_ALL_MEDIA,
    CHANGE_MEDIA_TEACHER,
    CHANGE_ONE,
    CHAT_STATUS,
    CONNECTING_CLASS,
    CONNECTION_STATUS,
    CURRENT_CLASS,
    CURRENT_ITEM_CLASS,
    SET_GUESTS,
    LEAVE_USER,
    LOADING_VIRTUAL_CLASS,
    MAIN_AUDIO_STATUS,
    MAIN_STREAM,
    MAIN_VIDEO_STATUS,
    MY_DATA_GUEST,
    NEW_GUEST,
    NEW_TEACHER,
    NOTIFICATION,
    PRODUCER_STARTED,
    REFRESH_CLASS,
    ROOM_ID,
    SET_MESSAGES,
    SET_REMOTE_VIDEOS,
    SET_STATUS_CLASS,
    SET_STUDENTS,
    SET_STUDENTS_VIDEO,
    START_TRANSMISSION,
    STATUS_AUDIO_STUDENT,
    STATUS_VIDEO_STUDENT,
    STUDENTS,
    STUDENTS_IN_CLASS,
    STUDENTS_IN_STREAM,
    TEACHER_LEAVING,
    TEACHERS_LIST,
    UPDATE_MEDIA_TEACHER,
    MY_VIDEO_STATUS,
    MY_AUDIO_STATUS,
    UPDATE_MEDIA,
    NO_VIDEO,
    RESTART_STUDENT,
    STATUS_TEACHER,
    PRIMARY_CAM, SECOND_CAM, PRIMARY_MIC, SECOND_MIC,
} from "../../actions/types";

const initialState = {
    loading: true,
    status_video_student: false,
    status_audio_student: false,
    students_in_stream: [],
    students_in_class: [],
    students: [],
    room_id: '',
    classData: {},
    nextClass: {},
    status_class: 3,
    classExist: false,
    refresh_class: false,
    remoteVideos: {},
    messages: [],
    producer_started: false,
    main_stream: null,
    start_transmission: false,
    connecting_class: false,
    all_classes: [],
    connection_status: '',
    chatStatus: false,
    next_same: false,
    teachers_list: [],
    admin_request: true,
    admin_request_audio: true,
    currentItemClass: null,
    lastVersion: '10.13.01',
    current_guests: [],
    data_guest: null,
    notification : false,
    main_video_status: true,
    main_audio_status: true,
    my_video_status: true,
    my_audio_status: true,
    no_video: false,
    status_teacher:'0',
    primary_camera:'',
    primary_mic:'',
    second_camera:'',
    second_mic:''
};

export default function VirtualClassReducers(state = initialState, action) {
    switch (action.type) {
        case RESTART_STUDENT:
            return {
                ...state,
                refresh_class: initialState.refresh_class,
                producer_started: initialState.producer_started,//
                main_stream: initialState.main_stream,//
                start_transmission: initialState.start_transmission,//
                connecting_class: initialState.connecting_class,//
                connection_status: initialState.connection_status,//
                chatStatus: initialState.chatStatus,//
                main_video_status: true,//
                main_audio_status: true,//
                my_video_status: true,//
                my_audio_status: true,//
                no_video: false//
            }
        case STATUS_VIDEO_STUDENT:
            return {
                ...state,
                status_video_student: action.payload,
            };
        case STATUS_AUDIO_STUDENT:
            return {
                ...state,
                status_audio_student: action.payload,
            };
        case STUDENTS_IN_STREAM:
            return {
                ...state,
                students_in_stream: action.payload,
            };
        case STUDENTS_IN_CLASS:
            return {
                ...state,
                students_in_class: action.payload,
            };
        case STUDENTS:
            return {
                ...state,
                students: action.payload.filter(it => it.puesto === "Alumno")
            }
        case ROOM_ID:
            return {
                ...state,
                room_id: action.payload
            }
        case CURRENT_CLASS:
            return {
                ...state,
                classData: action.classData,
                students: action.students,
                classExist: action.classExist,
                room_id: action.roomId,
                nextClass: action.nextClass,
                status_class: action.status_class,
                is_refresh: false,
                next_same: false,
                // loading: false
            }
        case SET_MESSAGES:
            return {
                ...state,
                messages: action.payload === {} ? [] : action.payload
            }
        case SET_REMOTE_VIDEOS:
            return {
                ...state,
                remoteVideos: action.payload
            }
        case SET_STUDENTS:
            return {
                ...state,
                students: action.payload.filter(it => it.puesto === "Alumno")
            }
        case PRODUCER_STARTED:
            return {
                ...state,
                producer_started: action.payload
            }
        case MAIN_STREAM:
            return {
                ...state,
                main_stream: action.payload
            }
        case START_TRANSMISSION:
            return {
                ...state,
                start_transmission: action.payload,
                status_audio_student: action.payload,
                status_video_student: action.payload
            }
        case CONNECTING_CLASS:
            return {
                ...state,
                connecting_class: action.payload
            }
        case CONNECTION_STATUS:
            return {
                ...state,
                connection_status: action.payload
            }
        case SET_STUDENTS_VIDEO:
            let clone = [...state.students]
            let u_id = state.students_in_class.find(item => item.socket_id === action.id )
            if(u_id !== undefined){
                let ix = state.students.indexOf(state.students.find(it => it.id.toString() === u_id.user_id.toString()))
                if (ix !== -1) {
                    clone[ix][action.mode] = action.status
                }
                return {
                    ...state,
                    students: clone
                }
            }
            return {
                ...state
            }

        case LOADING_VIRTUAL_CLASS:
            return {
                ...state,
                loading: action.payload
            }
        case LEAVE_USER:
            let students_leave = [...state.students]
            let st_ix = state.students.indexOf(state.students.find(it => it.id.toString() === action.payload.toString()))
            if(st_ix !== -1){
                students_leave[st_ix]['audio_active'] = '1'
                students_leave[st_ix]['video_active'] = '1'
            }
            let in_class = [...state.students_in_class];
            let index = state.students_in_class.indexOf(state.students_in_class.find(it => Number(it.user_id) === Number(action.payload)));
            if (index !== -1) {
                in_class.splice(index, 1);
            }
            return {
                ...state,
                students_in_class: in_class,
                students: students_leave
            }
        case CHANGE_ALL_MEDIA:
            let cloneAll = state.students.map(item => {
                item[action.mode] = '0'
                return item;
            });
            return {
                ...state,
                students: cloneAll,
            }
        case CHANGE_ONE:
            let cloneOne = state.students.map(item => {
                if (item.id.toString() === action.mode.data.student_id.toString()) {
                    item[action.mode.type] = action.mode.value
                }
                return item;
            });
            return {
                ...state,
                students: cloneOne,
            }
        case ALL_CLASSES_USER:
            return {
                ...state,
                all_classes: action.payload
            }
        case REFRESH_CLASS:
            return {
                ...state,
                refresh_class: action.payload,
                next_same: action.next_same
            }
        case CHAT_STATUS:
            return {
                ...state,
                chatStatus: action.payload
            }
        case TEACHERS_LIST:
            return {
                ...state,
                teachers_list: action.payload
            }
        case ADMIN_REQUEST:
            return {
                ...state,
                admin_request: action.payload
            }
        case ADMIN_REQUEST_AUDIO:
            return {
                ...state,
                admin_request_audio: action.payload
            }
        case CHANGE_MEDIA_TEACHER:
            let teachers_video_clone = [...state.teachers_list]
            let i = teachers_video_clone.indexOf(teachers_video_clone.find(it => it.user_id.toString() === action.payload.id.toString()))
            if (i !== -1) {
                teachers_video_clone[i][action.payload.mode] = action.payload.value
            }
            return {
                ...state,
                teachers_list: teachers_video_clone
            }
        case TEACHER_LEAVING:
            let teacherLeaving = [...state.teachers_list]
            let end = teacherLeaving.filter(it => it.user_id.toString() !== action.payload.id.toString())
            return {
                ...state,
                teachers_list: end
            }
        case NEW_TEACHER:
            if (state.teachers_list.find(it => it.user_id === action.payload.user_id) === undefined) {
                action.payload.mic = true
                action.payload.camera = true
                action.payload.buttons = true
                if (state.teachers_list.length === 0) {
                    action.payload.audio = true
                }
                return {
                    ...state,
                    teachers_list: [...state.teachers_list, action.payload]
                }
            } else {
                return {
                    ...state
                }
            }
        case UPDATE_MEDIA_TEACHER:
            let cloneMedia = [...state.teachers_list]
            let indexMedia = state.teachers_list.indexOf(state.teachers_list.find(it => it.socket_id.toString() === action.payload.user_id.toString()))
            if(indexMedia !== -1 ){
                cloneMedia[indexMedia][action.payload.prop] = action.payload.value
                if(action.payload.response){
                    cloneMedia[indexMedia].buttons = true
                }
            }
            return {
                ...state,
                teachers_list: cloneMedia
            }
        case NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            }
        case SET_STATUS_CLASS:
            return {
                ...state,
                status_class: action.payload
            }
        case CURRENT_ITEM_CLASS:
            return {
                ...state,
                currentItemClass: action.payload
            }
        case NEW_GUEST:

        function findGuest(select) {
            return select.user_id.toString() === action.payload.user_id.toString();
        }

            action.payload.mic = true
            action.payload.camera = true
            action.payload.buttons = true
            return {
                ...state,
                current_guests: state.current_guests.find(findGuest) === undefined ? [...state.current_guests, action.payload] : state.current_guests
            }
        case SET_GUESTS:
            return {
                ...state,
                current_guests: action.payload
            }
        case MY_DATA_GUEST:
            return {
                ...state,
                data_guest: action.payload
            }
        case MAIN_VIDEO_STATUS:
            return {
                ...state,
                main_video_status: action.payload
            }
        case MAIN_AUDIO_STATUS:
            return {
                ...state,
                main_audio_status: action.payload
            }
        case MY_VIDEO_STATUS:
            return {
                ...state,
                my_video_status: action.payload
            }
        case MY_AUDIO_STATUS:
            return {
                ...state,
                my_audio_status: action.payload
            }
        case UPDATE_MEDIA:
            let find = state.students_in_class.find(it => it.socket_id === action.payload.socket_id && it.puesto === 'Alumno')
            if(find !== undefined){
                let clone = [...state.students]
                let ix = state.students.indexOf(state.students.find(it => it.id.toString() === find.user_id.toString()))
                if (ix !== -1){
                    clone[ix][action.payload.mode] = action.payload.value
                }

                return {
                    ...state,
                    students: clone
                }
            }
            find = state.current_guests.find(it => it.socket_id === action.payload.socket_id)
            if(find !== undefined){
                let clone = [...state.current_guests]
                let ix = state.current_guests.indexOf(it => it.id.toString() === find.user_id.toString())
                if (ix !== -1){
                    clone[ix][action.payload.mode] = action.payload.value
                }
                return {
                    ...state,
                    current_guests: clone
                }
            }
            return {
                ...state,
                main_video_status: action.payload.mode === 'video_active' ? (action.payload.value === '1' ): state.main_video_status,
                main_audio_status: action.payload.mode === 'audio_active' ? (action.payload.value === '1' ) : state.main_audio_status
            }
        case NO_VIDEO:
            return {
                ...state,
                no_video: action.payload
            }
        case STATUS_TEACHER:
            return {
                ...state,
                status_teacher: action.payload
            }
        case PRIMARY_CAM:
            return {
                ...state,
                primary_camera: action.payload
            }
        case SECOND_CAM:
            return {
                ...state,
                second_camera: action.payload
            }
        case PRIMARY_MIC:
            return {
                ...state,
                primary_mic: action.payload
            }
        case SECOND_MIC:
            return {
                ...state,
                second_mic: action.payload
            }
        default:
            return {
                ...state
            };
    }
}
