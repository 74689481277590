import {AppEnv} from "../../../../components/env";
import {
    GET_JORNADAS,
    GET_JORNADAS_COMMENTS,
    GET_JORNADAS_RELATION,
    ID_RELACION_GRUPO,
    JORNADAS_ADMIN_MAESTRO,
    JORNADAS_ALUMNO,
    JORNADAS_GRUPO,
    JORNADAS_MAESTRO,
    JORNADAS_PADRE,
    JORNADAS_PREFECTO,
    LOADING_JORNADAS,
    SEMANAS,
    SEMANAS_OCUPADAS
} from "../../types";
import {alertFail, alertSuccess} from "../../../../components/Global-W/NewFunctions";
import axios from "axios"

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_JORNADAS,
        payload: data
    };
}

export function fetchJornadas(bd, token, ciclo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/get/" + ciclo, {
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token
            },
        }).then((response) => {
            dispatch(fetchJornadasSuccess(response.data));
        }).catch((error) => {
            dispatch(fetchJornadasFailed());
        });
    };
}

export function fetchJornadasSuccess(response) {
    return {
        type: GET_JORNADAS,
        payload: response,
    };
}

export function fetchJornadasFailed() {
    alertFail();
    return {
        type: GET_JORNADAS,
        payload: [],
    };
}

export function fetchCreateJornadas(bd, token, ciclo, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/jornadas/create", fd, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            alertSuccess()
            dispatch(fetchJornadas(bd, token, ciclo));
        }).catch((error) => {
            alertFail()
        });
    };
}

export function fetchUpdateJornadas(bd, token, ciclo, id, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/jornadas/update/" + id, fd, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token
            },
        }).then((response) => {
            dispatch(fetchJornadas(bd, token, ciclo));
        }).catch((error) => {
            alertFail()
        });
    };
}

export function fetchUpdateNameJornada(bd, token, ciclo, id, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/jornadas/update/name/" + id, fd, {
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchJornadas(bd, token, ciclo));
        }).catch((error) => {
            alertFail()
        });
    };
}

export function fetchDeleteJornadas(bd, token, ciclo, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/delete/" + id, {
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            alertSuccess()
            dispatch(fetchJornadas(bd, token, ciclo));
        }).catch((error) => {
            alertFail()
        });
    };
}

export function fetchJornadasRelation(bd, token, ciclo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/relation/get/" + ciclo, {
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token
            },
        }).then((response) => {
            dispatch(fetchJornadasRelationSuccess(response.data));
        }).catch((error) => {
            dispatch(fetchJornadasRelationFailed());
        });
    };
}

export function fetchCreateJornadaRelation(bd, token, grado, grupo, jornada, ciclo, fd, fd2) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/jornadas/relation/create/" + grado + '/' + grupo + '/' + jornada + '/' + ciclo, fd, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + token
                },
            }
        ).then((response) => {
            alertSuccess()
            dispatch(fetchCreateJornadaComments(bd, token, grado, grupo, jornada, ciclo, fd2));
        }).catch((error) => {
            alertFail()
        });
    };
}

export function fetchJornadasRelationSuccess(response) {
    return {
        type: GET_JORNADAS_RELATION,
        payload: response,
    };
}

export function fetchJornadasRelationFailed() {
    alertFail();
    return {
        type: GET_JORNADAS_RELATION,
        payload: [],
    };
}

export function fetchCreateJornadaComments(bd, token, grado, grupo, jornada, ciclo, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/jornadas/comments/create/" + grado + '/' + grupo + '/' + jornada + '/' + ciclo, fd, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + token
                },
            }
        ).then((response) => {
            alertSuccess()
            dispatch(fetchJornadasRelation(bd, token, ciclo));
            dispatch(fetchJornadaCommentsSuccess(response.data));
        }).catch((error) => {
            dispatch(fetchJornadaCommentsFailed());
        });
    };
}

export function fetchJornadaComments(bd, token, grado, grupo, jornada, ciclo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/comments/get/" + grado + '/' + grupo + '/' + jornada + '/' + ciclo, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + token
                },
            }
        ).then((response) => {
            dispatch(fetchJornadaCommentsSuccess(response.data));
        }).catch((error) => {
            dispatch(fetchJornadaCommentsFailed());
        });
    };
}

export function fetchJornadaCommentsSuccess(response) {
    return {
        type: GET_JORNADAS_COMMENTS,
        payload: response,
    };
}

export function fetchJornadaCommentsFailed() {
    alertFail();
    return {
        type: GET_JORNADAS_COMMENTS,
        payload: [],
    };
}

export function fetchSemanas(bd, token, ciclo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/semanas/" + ciclo, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchSemanasSuccess(response.data));
        }).catch((error) => {
            alertFail()
            dispatch(fetchSemanasFailed());
        });
    };
}

export function fetchSemanasSuccess(response) {
    return {
        type: SEMANAS,
        payload: response,
    };
}

export function fetchSemanasFailed() {
    alertFail();
    return {
        type: SEMANAS,
        payload: [],
    };
}

export function fetchSemanasOcupadas(bd, token, ciclo, grado, grupo, jornada) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/semanas/ocupadas/" + ciclo + '/' + grado + '/' + grupo + '/' + jornada, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchSemanasOcupadasSuccess(response.data));
        }).catch((error) => {
            alertFail()
            dispatch(fetchSemanasOcupadasFailed());
        });
    };
}

export function fetchSemanasOcupadasSuccess(response) {
    return {
        type: SEMANAS_OCUPADAS,
        payload: response,
    };
}

export function fetchSemanasOcupadasFailed() {
    alertFail();
    return {
        type: SEMANAS_OCUPADAS,
        payload: [],
    };
}

export function fetchRelacionGrupo(bd, token, grado, grupo, ciclo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/relation/group/" + grado + '/' + grupo + '/' + ciclo, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchRelacionGrupoSuccess(response.data));
        }).catch((error) => {
            alertFail()
            dispatch(fetchRelacionGrupoFailed());
        });
    };
}

export function fetchRelacionGrupoSuccess(response) {
    return {
        type: ID_RELACION_GRUPO,
        payload: response.id,
    };
}

export function fetchRelacionGrupoFailed() {
    alertFail();
    return {
        type: ID_RELACION_GRUPO,
        payload: '',
    };
}

export function fetchJornadasGrupo(bd, token, grado, grupo, ciclo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/by/group/" + grado + '/' + grupo + '/' + ciclo, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchJornadasGrupoSuccess(response.data));
        }).catch((error) => {
            alertFail()
            dispatch(fetchJornadasGrupoFailed());
        });
    };
}

export function fetchJornadasGrupoSuccess(response) {
    return {
        type: JORNADAS_GRUPO,
        payload: response,
    };
}

export function fetchJornadasGrupoFailed() {
    alertFail();
    return {
        type: JORNADAS_GRUPO,
        payload: [],
    };
}


export function deletedJornada(bd, token, ciclo, id_jornada) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/delete/" + id_jornada, {
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token
            },
        }).then((response) => {
            alertSuccess('Se ha borrado la jornada con éxito');
            dispatch(fetchJornadas(bd, token, ciclo));
        }).catch((error) => {
            alertFail();
            dispatch(fetchJornadas(bd, token, ciclo));
        });
    };
}


export function fetchJornadasMaestro(bd, token, ciclo, user) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/maestro/" + ciclo + '/' + user, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchJornadasMaestroSuccess(response.data));
        }).catch((error) => {
            alertFail()
            dispatch(fetchJornadasMaestroFailed());
        });
    };
}

export function fetchJornadasMaestroSuccess(response) {
    return {
        type: JORNADAS_MAESTRO,
        payload: response,
    };
}

export function fetchJornadasMaestroFailed() {
    alertFail();
    return {
        type: JORNADAS_MAESTRO,
        payload: [],
    };
}


export function fetchJornadasAdminMaestro(bd, token, ciclo, user) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/admin/maestro/" + ciclo + '/' + user, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchJornadasAdminMaestroSuccess(response.data));
        }).catch((error) => {
            alertFail()
            dispatch(fetchJornadasAdminMaestroFailed());
        });
    };
}

export function fetchJornadasAdminMaestroSuccess(response) {
    return {
        type: JORNADAS_ADMIN_MAESTRO,
        payload: response,
    };
}

export function fetchJornadasAdminMaestroFailed() {
    alertFail();
    return {
        type: JORNADAS_ADMIN_MAESTRO,
        payload: [],
    };
}


export function fetchJornadasPadre(bd, token, ciclo, user) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/padre/" + ciclo + '/' + user, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchJornadasPadreSuccess(response.data));
        }).catch((error) => {
            alertFail()
            dispatch(fetchJornadasPadreFailed());
        });
    };
}

export function fetchJornadasPadreSuccess(response) {
    return {
        type: JORNADAS_PADRE,
        payload: response,
    };
}

export function fetchJornadasPadreFailed() {
    alertFail();
    return {
        type: JORNADAS_PADRE,
        payload: [],
    };
}


export function fetchJornadasAlumno(bd, token, ciclo, user) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/alumno/" + ciclo + '/' + user, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchJornadasAlumnoSuccess(response.data));
        }).catch((error) => {
            alertFail()
            dispatch(fetchJornadasAlumnoFailed());
        });
    };
}

export function fetchJornadasAlumnoSuccess(response) {
    return {
        type: JORNADAS_ALUMNO,
        payload: response,
    };
}

export function fetchJornadasAlumnoFailed() {
    alertFail();
    return {
        type: JORNADAS_ALUMNO,
        payload: [],
    };
}


export function fetchJornadasPrefecto(bd, token, grado, grupo, ciclo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/jornadas/prefecto/" + grado + '/' + grupo + '/' + ciclo, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchJornadasPrefectoSuccess(response.data));
        }).catch((error) => {
            alertFail()
            dispatch(fetchJornadasPrefectoFailed());
        });
    };
}

export function fetchJornadasPrefectoSuccess(response) {
    return {
        type: JORNADAS_PREFECTO,
        payload: response,
    };
}

export function fetchJornadasPrefectoFailed() {
    alertFail();
    return {
        type: JORNADAS_PREFECTO,
        payload: [],
    };
}
