import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Row} from 'react-material-responsive-grid';
import {useSelector} from "react-redux";
import {SubTitle} from "../Styles";

export const AddLinks = forwardRef(({
                                         addLink = {},
                                         contraste = '1'
                                     }, ref) => {
    // const = props;
    const [links, setLinks] = useState([]);
    const [link, setLink] = useState('');
    const {
        grayColorOne,
        fourthColor,
        redColor,
        greenColor,
        betaTheme
    } = useSelector(state => state.rooot)

    useImperativeHandle(ref, () => ({
        resetLinks() {
            setLinks([])
        }
    }));

    useEffect(() => {
        addLink(links)
    }, [links]) // eslint-disable-line react-hooks/exhaustive-deps

    const renderLinks = links.map((it, i) => {
        return <Row key={i + 'preview'} className="uk-width-1-1 centerAll"
                    style={{margin: 0, padding: 3,}}>
            <Row className="uk-width-1-1 centerAll"
                 style={{
                     backgroundColor: contraste === '1' ? grayColorOne : fourthColor,
                     borderRadius: 6,
                     margin: 0,
                 }}>
                <Row className="uk-width-1-1"
                     style={{margin: 0, padding: 3,}}>
                    <Row className="uk-width-5-6"
                         style={{margin: 0, padding: 3}}>
                        <label style={{textAlign: 'left'}}
                               className=' uk-width-1-1 normal_text uk-text-truncate'>{it.name}</label>
                    </Row>
                    <Row className="uk-width-1-6 centerAll">
                        <Row className="uk-width-1-2 centerAll">
                            <a href={it.url} target={'blank'}>
                                <i className="fas fa-external-link-alt"/>
                            </a>
                        </Row>
                        <Row onClick={() => {
                            const temp = [...links];
                            temp.splice(i, 1);
                            setLinks(temp);
                        }} className="uk-width-1-2 centerAll">
                            <i style={{color: redColor}}
                               className="fas fa-minus-circle"/>
                        </Row>
                    </Row>
                </Row>
            </Row>
        </Row>
    })


    return <Row className="uk-width-1-1 centerAll">
        <SubTitle theme={betaTheme}  className="uk-width-1-1">
            Agregar enlaces
        </SubTitle>
        <Row className="uk-width-1-1 centerAll">
            {renderLinks}
        </Row>
        <Row className="uk-width-1-1 centerAll">
            <Row className="uk-width-5-6 centerAll"
                 style={{margin: 0, padding: 2}}>
                <input
                    className="uk-button-default uk-width-1-1 normal_text"
                    maxLength={100}
                    onChange={text => setLink(text.target.value)}
                    value={link}
                    style={{
                        borderRadius: 6,
                        backgroundColor: grayColorOne,
                        borderWidth: 0,
                        lineHeight: 1,
                        padding: 9,
                        textAlign: 'left',
                    }}
                />
            </Row>
            <Row className="uk-width-1-6 centerAll"
                 style={{margin: 0, padding: 2, cursor: 'pointer'}}
                 onClick={() => {
                     let elLink = link;
                     if (link.search('https://') === -1 && link.search('http://') === -1) {
                         elLink = "http://".concat(link);
                     }
                     setLinks([...links, {
                         url: elLink,
                         type: 'link',
                         name: elLink
                     }])
                     setLink('')
                 }}>
                <Row className="uk-width-1-1 centerAll" style={{
                    margin: 0,
                    borderRadius: 6,
                    backgroundColor: grayColorOne,
                    padding: 6,
                    textAlign: 'center'
                }}>
                    <i className="fas fa-plus-circle"
                       style={{color: greenColor, fontSize: '1.3rem'}}/>
                </Row>
            </Row>
        </Row>
    </Row>
});
