import {AppEnv} from "../../../../components/env";
import {
    ALL_MATERIAS_MAESTRO,
    ALL_SUBJECTS,
    LOADING_ASIGNAR_MAESTRO,
    MAESTROS,
    MATERIAS_FALTANTES,
    RELACION_MATERIAS,
    RELATION,
    RELATION_INFO
} from "../../types";
import {alertFail, alertSuccess} from "../../../../components/Global-W/NewFunctions";
import axios from "axios"

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_ASIGNAR_MAESTRO,
        payload: data
    };
}

export function checkMaterias(bd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/subjects/check/relation", {
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                dispatch(fetchCheckMateriasSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchCheckMateriasFailed());
            });
    };
}

export function fetchCheckMateriasSuccess(response) {
    const faltantes = response.map((it) => {
        if (it.faltantes !== 0) {
            return it;
        } else {
            return null
        }
    });

    return {
        type: MATERIAS_FALTANTES,
        payload: faltantes,
    };
}

export function fetchCheckMateriasFailed() {
    alertFail();
    return {
        type: MATERIAS_FALTANTES,
        payload: [],
    };
}

export function fetchRelacionMaterias(bd, ciclo, status) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/subjects/get/maestros/relacion/materia/" + ciclo + "/" + status, {
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            dispatch(fetchRelacionMateriasSuccess(response.data));
        }).catch((error) => {
            dispatch(fetchRelacionMateriasFailed());
        });
    };
}

export function fetchRelacionMateriasSuccess(response) {
    return {
        type: RELACION_MATERIAS,
        payload: response,
    };
}

export function fetchRelacionMateriasFailed() {
    alertFail();
    return {
        type: RELACION_MATERIAS,
        payload: [],
    };
}

export function fetchMaterias(bd, ciclo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/subjects/por/asignar/" + ciclo, {
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            dispatch(fetchMateriasSuccess(response.data));
        }).catch((error) => {
            dispatch(fetchMateriasFailed());
        });
    };
}

export function fetchMateriasSuccess(response) {
    return {
        type: ALL_SUBJECTS,
        payload: response,
    };
}

export function fetchMateriasFailed() {
    alertFail();
    return {
        type: ALL_SUBJECTS,
        payload: [],
    };
}

export function fetchMaestros(bd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios
            .get(apiUrl + bd + "/get/usuarios/puestos/Maestro", {
                method: "get",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                dispatch(fetchMaestrosSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchMaestrosFailed());
            });
    };
}

export function fetchMaestrosSuccess(response) {
    return {
        type: MAESTROS,
        payload: response,
    };
}

export function fetchMaestrosFailed() {
    alertFail();
    return {
        type: MAESTROS,
        payload: [],
    };
}

export function fetchRelation(bd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios
            .get(apiUrl + bd + "/subjects/relation", {
                method: "get",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                dispatch(fetchRelationSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchRelationFailed());
            });
    };
}

export function fetchRelationSuccess(response) {
    return {
        type: RELATION,
        payload: response,
    };
}

export function fetchRelationFailed() {
    alertFail();
    return {
        type: RELATION,
        payload: [],
    };
}

export function fetchSaveRelation(bd, token, ciclo, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios
            .post(apiUrl + bd + "/subjects/save/relation", formData, {
                method: 'POST', headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            })
            .then((response) => {
                // dispatch(fetchRelacionMaterias(bd, ciclo, "1"));
                dispatch(fetchMaterias(bd, ciclo));
                dispatch(fetchRelation(bd));
                dispatch(checkMaterias(bd));
            })
            .catch((error) => {
                alertFail();
            });
    };
}


export function editCharge(bd, token, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/subjects/change/relation", formData, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(changeActividades(bd, token, formData));
        }).catch((error) => {
            dispatch(editChargeFailed(1));
        });
    };
}

export function changeActividades(bd, token, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/subjects/change/relation/actividades", formData, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(changePlanesEstudio(bd, token, formData));
        }).catch((error) => {
            dispatch(editChargeFailed());
        });
    };
}

export function changePlanesEstudio(bd, token, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/subjects/change/relation/planes/estdio", formData, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(changeDesempenos(bd, token, formData));
        }).catch((error) => {
            dispatch(editChargeFailed());
        });
    };
}

export function changeDesempenos(bd, token, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/subjects/change/relation/desempenos", formData, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(changeEncuadres(bd, token, formData));
        }).catch((error) => {
            dispatch(editChargeFailed());
        });
    };
}

export function changeEncuadres(bd, token, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/subjects/change/relation/encuadres", formData, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(changeAsistencias(bd, token, formData));
        }).catch((error) => {
            dispatch(editChargeFailed());
        });
    };
}

export function changeAsistencias(bd, token, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/subjects/change/relation/asistencias", formData, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(changePlanesClases(bd, token, formData))
        }).catch((error) => {
            dispatch(editChargeFailed());
        });
    };
}

export function changePlanesClases(bd, token, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/subjects/change/relation/planes_clases", formData, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(changeContenidosClases(bd, token, formData))
        }).catch((error) => {
            dispatch(editChargeFailed());
        });
    };
}

export function changeContenidosClases(bd, token, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/subjects/change/relation/contenidos_clases", formData, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchRelation(bd))
            alertSuccess()
        }).catch((error) => {
            dispatch(editChargeFailed());
        });
    };
}

export function checkRelationTeacher(bd, token, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/subjects/change/relation/check", formData, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(checkRelationTeacherSuccess(response.data))
        }).catch((error) => {
            alertFail()
        });
    };
}

export function checkRelationTeacherSuccess(response) {
    return {
        type: RELATION_INFO,
        payload: response,
    };
}

export function deleteRelationTeacher(bd, token, formData) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + "/subjects/change/relation/delete", formData, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: 'Bearer ' + token
            },
        }).then((response) => {
            dispatch(fetchRelation(bd))
            alertSuccess()
        }).catch((error) => {
            dispatch(editChargeFailed());
        });
    };
}


export function editChargeFailed() {
    alertFail();
    return {
        type: RELATION,
        payload: [],
    };
}

export function fetchAllMateriasMaestro(bd, token) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + "/subjects/teachers", {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchAllMateriasMaestroSuccess(response.data));
        }).catch(error => {
            dispatch(fetchAllMateriasMaestroFailed())
        })
    }
}

export function fetchAllMateriasMaestroSuccess(response) {
    return {
        type: ALL_MATERIAS_MAESTRO,
        payload: response,
    };
}

export function fetchAllMateriasMaestroFailed() {
    alertFail();
    return {
        type: ALL_MATERIAS_MAESTRO,
        payload: [],
    };
}
