import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {AppEnv} from '../../env';
import {Row} from 'react-material-responsive-grid';

class FaltaMensaje extends Component {
    static propTypes = {
        mensaje: PropTypes.string,
        subMensaje: PropTypes.string,
        margin: PropTypes.number,
        fontSizeMessage: PropTypes.number,
        fontSizeSubMessage: PropTypes.number,
    };
    static defaultProps = {
        mensaje: 'Seleccione grado y grupo',
        subMensaje: '',
        margin: 40,
        fontSizeMessage: 15,
        fontSizeSubMessage: 12,
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {margin, fontSizeMessage, fontSizeSubMessage} = this.props;
        return (
            <Row className="uk-width-1-1 centerAll normal_text"
                 style={{
                     marginTop: margin,
                     marginBottom: margin,
                 }}>
                <Row className="uk-width-1-1 centerAll normal_text"
                     style={{
                         fontSize: fontSizeMessage,
                         color: AppEnv.grayColorFive,
                         textAlign: 'center',
                         textTransform: 'uppercase'
                     }}>
                    {this.props.mensaje}
                </Row>
                {this.props.subMensaje !== ''
                    ? <Row className="uk-width-1-1 centerAll normal_text"
                           style={{
                               padding: '0 30px',
                               color: AppEnv.grayColorFive,
                               fontSize: fontSizeSubMessage,
                               textAlign: 'center',
                               marginTop: 10,
                           }}>
                        {this.props.subMensaje}
                    </Row>
                    : null}
            </Row>
        );


    }
}

export default FaltaMensaje;
