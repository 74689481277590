import {TEACHER_ACTIVITIES, TEACHER_CRITERIA} from '../../actions/types';

const initialState = {
    teacher_criteria: [],
    teacher_activities: [],
    loading: false
};

export default function AcademicReducer(state = initialState, action) {
    switch (action.type) {
        case TEACHER_CRITERIA:
            return {
                ...state,
                teacher_criteria: action.payload,
                loading: false,
            };
        case TEACHER_ACTIVITIES:
            return {
                ...state,
                teacher_activities: action.payload,
                loading: false,
            };
        default:
            return {
                ...state,
                loading: false,
            };
    }
}
