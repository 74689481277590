import {
    ADMIN_REQUEST,
    ADMIN_REQUEST_AUDIO,
    ALL_CLASSES_USER,
    CHANGE_ALL_MEDIA,
    CHANGE_MEDIA_TEACHER,
    CHANGE_ONE,
    CHAT_STATUS,
    CONNECTING_CLASS,
    CONNECTION_STATUS,
    CURRENT_CLASS,
    CURRENT_ITEM_CLASS,
    LEAVE_USER,
    LOADING_VIRTUAL_CLASS,
    MAIN_STREAM,
    NEW_TEACHER,
    PRODUCER_STARTED,
    REFRESH_CLASS,
    ROOM_ID,
    SET_MESSAGES,
    SET_REMOTE_VIDEOS,
    SET_STATUS_CLASS,
    SET_STUDENTS,
    SET_STUDENTS_VIDEO,
    START_TRANSMISSION,
    STATUS_AUDIO_STUDENT,
    STATUS_VIDEO_STUDENT,
    STUDENTS,
    STUDENTS_IN_CLASS,
    STUDENTS_IN_STREAM,
    TEACHER_LEAVING,
    TEACHERS_LIST,
    NEW_GUEST,
    MY_DATA_GUEST,
    UPDATE_MEDIA_TEACHER,
    NOTIFICATION,
    SET_GUESTS,
    MAIN_VIDEO_STATUS,
    MAIN_AUDIO_STATUS,
    MY_AUDIO_STATUS,
    MY_VIDEO_STATUS,
    UPDATE_MEDIA,
    NO_VIDEO,
    RESTART_STUDENT,
    STATUS_TEACHER,
    PRIMARY_CAM, SECOND_CAM, PRIMARY_MIC, SECOND_MIC
} from "../types";

const moment = require('moment/moment');
require('moment/locale/es');
moment.locale('es');

export function setLoading(data) {
    return {
        type: LOADING_VIRTUAL_CLASS,
        payload: data
    };
}

export function SetAdminRequest(data) {
    return {
        type: ADMIN_REQUEST,
        payload: data
    }
}

export function SetAdminRequestAudio(data) {
    return {
        type: ADMIN_REQUEST_AUDIO,
        payload: data
    }
}

export function SetStudents(data) {
    return {
        type: STUDENTS,
        payload: data
    };
}

export function RoomId(data) {
    return {
        type: ROOM_ID,
        payload: data
    };
}

export function changeStatusVideo(data) {
    return {
        type: STATUS_VIDEO_STUDENT,
        payload: data
    };
}

export function changeStatusAudio(data) {
    return {
        type: STATUS_AUDIO_STUDENT,
        payload: data
    };
}

export function studentsInStream(data) {
    return {
        type: STUDENTS_IN_STREAM,
        payload: data
    }
}

export function studentsInClass(data) {
    return {
        type: STUDENTS_IN_CLASS,
        payload: data
    }
}

export function setStudents(data) {
    return {
        type: SET_STUDENTS,
        payload: data
    }
}

export function setMessages(data) {
    return {
        type: SET_MESSAGES,
        payload: data
    }
}

export function setChangeVideo(data) {
    return {
        type: SET_STUDENTS_VIDEO,
        id: data.id,
        status: data.status,
        mode: data.mode
    }
}

export function allClasesUser(data) {
    return {
        type: ALL_CLASSES_USER,
        payload: data
    }
}


export function fetchCurrentClass(classes, schoolData, puesto) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            //+-+-+-+-+ CURRENT CLASS
            function findCurrentClass(select) {
                let currentHr = moment().format('HH:mm:ss');
                let classHrStart = moment(select.horarios.inicio, 'HH:mm:ss').format('HH:mm:ss');
                let classHrEnd = moment(select.horarios.fin, 'HH:mm:ss').format('HH:mm:ss');
                if (schoolData.id === 8 || schoolData.id === 42) {
                    return select.status_class === 1;
                } else {
                    return classHrStart <= currentHr && classHrEnd >= currentHr;
                }
            }

            //+-+-+-+-+ NEXT CLASS IF EXISTS
            function findNextClass(select) {
                let currentHr = moment().format('HH:mm:ss');
                let classHrStart = moment(select.horarios.inicio, 'HH:mm:ss').format('HH:mm:ss');
                if (schoolData.id === 8 || schoolData.id === 42) {
                    return select.status_class === 1;
                } else {
                    return classHrStart > currentHr && select.status_class === 1;
                }
            }

            try {
                let ix_class = classes.indexOf(classes.find(findCurrentClass));
                let info = {}
                if (ix_class !== -1) {
                    info.nextClass = {}
                    info.classData = classes[ix_class]
                    info.students = classes[ix_class].alumnos
                    info.status_class = classes[ix_class].status_class
                    info.tipo_clase = classes[ix_class].tipo_clase
                    info.classExist = classes[ix_class].alumnos.length !== 0
                    info.roomId = classes[ix_class].room_id;
                    info.same_next = classes[ix_class].same_next;
                    if (classes[ix_class].status_class !== 1) {
                        let ix_next = classes.indexOf(classes.find(findNextClass));
                        if (ix_next !== -1) {
                            info.nextClass = classes[ix_next];
                        } else {
                            info = {
                                nextClass: {},
                                classData: {},
                                students: [],
                                status_class: 3,
                                tipo_clase: 'no hay clases',
                                classExist: false,
                                same_next: '0',
                                roomId: 'sin_clase'
                            }
                        }
                    }
                } else {
                    info = {
                        nextClass: {},
                        classData: {},
                        students: [],
                        status_class: 3,
                        tipo_clase: 'no hay clases',
                        classExist: false,
                        same_next: '0',
                        roomId: 'sin_clase'
                    }
                }
                dispatch(fetchCurrentClassSuccess(info))
                resolve(info);
            } catch (error) {
                reject(error);
            }
        });
    };
}

export function fetchCurrentClassSuccess(data) {
    return {
        type: CURRENT_CLASS,
        classData: data.classData,
        nextClass: data.nextClass,
        status_class: data.status_class,
        students: data.students,
        classExist: data.classExist,
        roomId: data.roomId,
    }
}

export function setRemoteVideos(data) {
    return {
        type: SET_REMOTE_VIDEOS,
        payload: data
    }
}

export function SetTeachers(data) {
    return {
        type: TEACHERS_LIST,
        payload: data
    }
}

export function producerStated(data) {
    return {
        type: PRODUCER_STARTED,
        payload: data
    }
}

export function changeMainStream(data) {
    return {
        type: MAIN_STREAM,
        payload: data
    }
}

export function startTransmission(data) {
    return {
        type: START_TRANSMISSION,
        payload: data
    }
}

export function connectingToClass(data) {
    return {
        type: CONNECTING_CLASS,
        payload: data
    }
}

export function changeConnectionStatus(data) {
    return {
        type: CONNECTION_STATUS,
        payload: data
    }
}

export function leaveUserToRoom(data) {
    return {
        type: LEAVE_USER,
        payload: data
    }
}

export function changeAllMedia(data) {
    return {
        type: CHANGE_ALL_MEDIA,
        mode: data
    }
}

export function changeOne(data) {
    return {
        type: CHANGE_ONE,
        mode: data
    }
}

export function changeChatStatus(data) {
    return {
        type: CHAT_STATUS,
        payload: data
    }
}

export function refreshClass(data) {
    return {
        type: REFRESH_CLASS,
        payload: data.is_refresh,
        next_same: data.next_same,
    }
}

export function changeMediaTeacher(data) {
    return {
        type: CHANGE_MEDIA_TEACHER,
        payload: data
    }
}

export function setTeacher(data) {
    return {
        type: NEW_TEACHER,
        payload: data
    }
}

export function updateMediaTeacher(data){
    return {
        type: UPDATE_MEDIA_TEACHER,
        payload:data
    }
}

export function setNotification(data){
    return{
        type: NOTIFICATION,
        payload:data
    }
}

export function dropTeacher(data) {
    return {
        type: TEACHER_LEAVING,
        payload: data
    }
}

export function setStatusClass(data) {
    return {
        type: SET_STATUS_CLASS,
        payload: data
    }
}

export function setCurrentItemClass(data) {
    return {
        type: CURRENT_ITEM_CLASS,
        payload: data
    }
}

export function setNewGuest(data){
    return {
        type: NEW_GUEST,
        payload:data
    }
}

export function setGuests(data){
    return {
        type: SET_GUESTS,
        payload:data
    }
}

export function myDataGuest(data){
    return {
        type: MY_DATA_GUEST,
        payload:data
    }
}

export function setMainAudioStatus(data){
    return {
        type: MAIN_AUDIO_STATUS,
        payload:data
    }
}

export function setMyAudioStatus(data){
    return{
        type: MY_AUDIO_STATUS,
        payload:data
    }
}
export function setMyVideoStatus(data){
    return{
        type: MY_VIDEO_STATUS,
        payload:data
    }
}

export function setMainVideoStatus(data){
    return {
        type: MAIN_VIDEO_STATUS,
        payload:data
    }
}

export function updateMedia(data){
    return{
        type:UPDATE_MEDIA,
        payload:data
    }
}

export function noVideoStream(data){
    return{
        type: NO_VIDEO,
        payload: data
    }
}

export function restartStudent(){
    return{
        type: RESTART_STUDENT
    }
}

export function setStatusTeacher(data){
    return{
        type: STATUS_TEACHER,
        payload: data
    }
}

export function setCamera(data){
    if(data.kind === '1'){
        return{
            type:PRIMARY_CAM,
            payload:data.value
        }
    }else{
        return {
            type:SECOND_CAM,
            payload: data.value
        }
    }
}
export function setMic(data){
    if(data.kind === '1'){
        return{
            type:PRIMARY_MIC,
            payload:data.value
        }
    }else{
        return {
            type:SECOND_MIC,
            payload: data.value
        }
    }
}