import {
    ALIAS,
    ALUMNOS_EGRESADOS,
    BROWSER,
    CICLO_ACTUAL,
    IMAGES,
    INIT,
    LISTA_ALUMNOS,
    LOADING_COUNTRIES,
    MODAL,
    MODE,
    MY_ROLES,
    PERIODOS,
    ROOT_LOAD,
    SCHOOL_CONFIGURATION,
    SCHOOL_DATA,
    SCHOOLS_NIVELES,
    SET_BETA,
    SET_KOLEGIA_THEME,
    SET_MENU,
    SET_MODAL_ACTIVITY_CLASS,
    SET_SUB_MENU,
    THEME,
    UPDATE_SCHOOL_CONFIGURATION,
    USER_PICTURE
} from '../../actions/types';
import {AppEnv} from "../../../components/env";

const initialState = {
        apiPos: "https://stock.kolegia.com/pos/",//http://127.0.0.1:9100/pos/" ,
        bd: sessionStorage.getItem('bd') || '',
        browserIntent: sessionStorage.getItem('browserIntent') || '0',
        cicloActual: '',
        country: sessionStorage.getItem('id_pais') || '1',
        images: [],
        loading: false,
        roles: sessionStorage.getItem('roles') || '',
        schoolData: '',
        schoolId: sessionStorage.getItem('schoolId') || '',
        token: sessionStorage.getItem('token') || '',
        userEmail: sessionStorage.getItem('email') || '',
        userGrado: sessionStorage.getItem('grado') || '',
        userGrupo: sessionStorage.getItem('grupo') || '',
        terminosCondiciones: sessionStorage.getItem('terminos_condiciones') || '',
        userId: sessionStorage.getItem('id') || '',
        userName: sessionStorage.getItem('name') || '',
        userPicture: sessionStorage.getItem('userPicture') || 'https://img.icons8.com/fluent/120/000000/user-male-circle.png/',
        userPuesto: sessionStorage.getItem('puesto') || '',
        userPuestoAlias: sessionStorage.getItem('userRoleAlias') || '',
        userRole: sessionStorage.getItem('role') || '',
        activo: sessionStorage.getItem('activo') || '',
        estatus: sessionStorage.getItem('estatus') || '',
        mode: sessionStorage.getItem('mode') || '',
        beta: sessionStorage.getItem('beta') || 'false',
        kolegia_theme: sessionStorage.getItem('kolegia_theme') || 'light',
        theme: {
            kolegiaBlue: AppEnv.kolegiaBlue,
            backGround: AppEnv.backGround,
            mainColor: AppEnv.mainColor,
            secondColor: AppEnv.secondColor,
            thirdColor: AppEnv.thirdColor,
            fourthColor: AppEnv.fourthColor,
            fifthColor: AppEnv.fifthColor,

            greenColor: AppEnv.greenColor,
            greenColorTwo: AppEnv.greenColorTwo,
            yellowColor: AppEnv.yellowColor,
            orangeColor: AppEnv.orangeColor,
            orangeColorTwo: AppEnv.orangeColorTwo,
            redColor: AppEnv.redColor,
            blueColor: AppEnv.blueColor,

            grayBackground: AppEnv.grayBackground,
            grayColorOne: AppEnv.grayColorOne,
            grayColorTwo: AppEnv.grayColorTwo,
            grayColorThree: AppEnv.grayColorThree,
            grayColorFour: AppEnv.grayColorFour,
            grayColorFive: AppEnv.grayColorFive,
        },
        periodos: [],
        alias: [],
        my_roles: [],
        alumnos: [],
        alumnos_egresados: [],
        schools_niveles: [],
        modal: false,
        modalType: 'registro',
        iMenu: 0,
        iSubMenu: -2,
        menu: '',
        subMenu: '',
        event: sessionStorage.getItem('event') || AppEnv.event,
        url: sessionStorage.getItem('url') || AppEnv.url,
        api: sessionStorage.getItem('api') || AppEnv.api,
        apiGes: sessionStorage.getItem('apiGes') || AppEnv.apiGes,
        Helvetica: sessionStorage.getItem('Helvetica') || AppEnv.Helvetica,
        kolegiaBlue: sessionStorage.getItem('kolegiaBlue') || AppEnv.kolegiaBlue,
        backGround: sessionStorage.getItem('backGround') || AppEnv.backGround,
        mainColor: sessionStorage.getItem('mainColor') || AppEnv.mainColor,
        secondColor: sessionStorage.getItem('secondColor') || AppEnv.secondColor,
        thirdColor: sessionStorage.getItem('thirdColor') || AppEnv.thirdColor,
        fourthColor: sessionStorage.getItem('fourthColor') || AppEnv.fourthColor,
        fifthColor: sessionStorage.getItem('fifthColor') || AppEnv.fifthColor,
        sixthColor: sessionStorage.getItem('sixthColor') || AppEnv.sixthColor,
        greenColor: sessionStorage.getItem('greenColor') || AppEnv.greenColor,
        greenColorTwo: sessionStorage.getItem('greenColorTwo') || AppEnv.greenColorTwo,
        greenColorThree: sessionStorage.getItem('greenColorThree') || AppEnv.greenColorThree,
        yellowColor: sessionStorage.getItem('yellowColor') || AppEnv.yellowColor,
        orangeColor: sessionStorage.getItem('orangeColor') || AppEnv.orangeColor,
        orangeColorTwo: sessionStorage.getItem('orangeColorTwo') || AppEnv.orangeColorTwo,
        redColor: sessionStorage.getItem('redColor') || AppEnv.redColor,
        blueColor: sessionStorage.getItem('blueColor') || AppEnv.blueColor,
        oxford1Light: sessionStorage.getItem('oxford1Light') || AppEnv.oxford1Light,
        oxfordDark: sessionStorage.getItem('oxfordDark') || AppEnv.oxfordDark,
        ligthBlue: sessionStorage.getItem('ligthBlue') || AppEnv.ligthBlue,
        grayBackground: sessionStorage.getItem('grayBackground') || AppEnv.grayBackground,
        grayColorZero: sessionStorage.getItem('grayColorZero') || AppEnv.grayColorZero,
        grayColorOne: sessionStorage.getItem('grayColorOne') || AppEnv.grayColorOne,
        grayColorTwo: sessionStorage.getItem('grayColorTwo') || AppEnv.grayColorTwo,
        grayColorThree: sessionStorage.getItem('grayColorThree') || AppEnv.grayColorThree,
        grayColorFour: sessionStorage.getItem('grayColorFour') || AppEnv.grayColorFour,
        grayColorFive: sessionStorage.getItem('grayColorFive') || AppEnv.grayColorFive,
        iOS: sessionStorage.getItem('iOS') || AppEnv.iOS,
        Android: sessionStorage.getItem('Android') || AppEnv.Android,
        AppGallery: sessionStorage.getItem('AppGallery') || AppEnv.AppGallery,
        zoom: sessionStorage.getItem('zoom') || AppEnv.zoom,
        app: sessionStorage.getItem('app') || AppEnv.app,
        appName: sessionStorage.getItem('appName') || AppEnv.appName,
        name: sessionStorage.getItem('name') || AppEnv.name,
        correo_soporte: sessionStorage.getItem('correo_soporte') || AppEnv.correo_soporte,
        Landing: sessionStorage.getItem('Landing') || AppEnv.Landing,
        NombreCorto: sessionStorage.getItem('NombreCorto') || AppEnv.NombreCorto,
        SchoolId: sessionStorage.getItem('SchoolId') || AppEnv.SchoolId,
        SitioWeb: sessionStorage.getItem('SitioWeb') || AppEnv.SitioWeb,
        tipoApp: sessionStorage.getItem('tipoApp') || AppEnv.tipoApp,
        celular_soporte: sessionStorage.getItem('celular_soporte') || AppEnv.celular_soporte,
        OneSignal: sessionStorage.getItem('OneSignal') || AppEnv.OneSignal,
        OpenPay: sessionStorage.getItem('OpenPay') || AppEnv.OpenPay,
        OpenPayPK: sessionStorage.getItem('OpenPayPK') || AppEnv.OpenPayPK,
        Production: sessionStorage.getItem('Production') || AppEnv.Production,
        websocket: AppEnv.websocket,
        testsApi: AppEnv.testsApi,
        paymentsApi: AppEnv.paymentsApi,
        emailsApi: AppEnv.emailsApi,
        configurationApi: AppEnv.configurationApi,
        multimediaApi: AppEnv.multimediaApi,
        userRegistryApi: AppEnv.userRegistryApi,
        modalActivityOnClass: false,
        betaThemes: {
            light: {
                id: "T_001",
                name: "Light",
                colors: {
                    body: "#FFFFFF",
                    text: "#000000",
                    border: "#F1F1F1",
                    background: "#FFFFFF",
                    subtitle: "#4D4D4D",
                    focus: "#7893dc",
                },
                button: {
                    select: {
                        text: "#000",
                        background: "#F1F1F1",
                        hover_text: "#FFF",
                        hover_background: "#969696",
                        selected_text: "#FFF",
                        selected_background: "#4D4D4D"
                    },
                }
            },
            seaWave: {
                id: "T_007",
                name: "Sea Wave",
                colors: {
                    body: "#9be7ff",
                    text: "#0d47a1",
                    border: "#E5E5E5",
                    background: "#fff",
                    subtitle: "#4D4D4D",
                    focus: "#7893dc",

                },
                button: {
                    select: {
                        text: "#000",
                        background: "#F1F1F1",
                        hover_text: "#FFF",
                        hover_background: "#969696",
                        selected_text: "#FFF",
                        selected_background: "#4D4D4D"
                    },
                }
            }
        },
        betaTheme: {
            id: "T_001",
            name: "Light",
            colors: {
                body: "#FFF",
                text: "#000",
                disabled_text: "#C1C1C1",
                border: "#F1F1F1",
                background: "#FFFFFF",
                background_gray: "#F9F9F9",
                subtitle: "#4D4D4D",
                focus: "#7893dc",
                icon_color: "#000",
                green: "#50b650",
            },
            select: {
                text: "#000",
                disabled_text: "#AAA",
                border: "#F1F1F1",
                background: "#F9F9F9",
                disabled_background: "#F9F9F9",
            },
            input: {
                normal: {
                    text: "#000",
                    disabled_text: "#AAA",
                    border: "#F1F1F1",
                    background: "#F9F9F9",
                    disabled_background: "#BFBFBF",
                },
                white: {
                    text: "#000",
                    disabled_text: "#AAA",
                    border: "#F1F1F1",
                    background: "#FFF",
                    disabled_background: "#F9F9F9",
                },
            },
            button: {
                select: {
                    text: "#000",
                    background: "#F9F9F9",
                    hover_text: "#FFF",
                    hover_background: "#969696",
                    selected_text: "#FFF",
                    selected_background: "#4D4D4D",
                    disabled_background: "#F1F1F1",
                    disabled_text: "#C1C1C1",
                },
                add: {
                    text: "#4D4D4D",
                    background: "#FFF",
                    border: "#50b650",
                    hover_text: "#F3F3F3",
                    hover_background: "#50b650",
                    hover_border: "#F1F1F1",
                    icon_background: "#50b650",
                    icon_color: "#FFF",
                    disabled_background: "#F1F1F1",
                    disabled_text: "#C1C1C1",
                    disabled_icon_background: "#6F6F6F",
                    disabled_border: "#6F6F6F",
                },
                delete: {
                    text: "#4D4D4D",
                    background: "#FFF",
                    border: "#FF3333",
                    hover_text: "#F3F3F3",
                    hover_background: "#FF3333",
                    hover_border: "#F1F1F1",
                    icon_background: "#FF3333",
                    icon_color: "#FFF",
                    disabled_background: "#F1F1F1",
                    disabled_text: "#C1C1C1",
                    disabled_icon_background: "#6F6F6F",
                },
                save: {
                    text: "#4D4D4D",
                    background: "#FFF",
                    border: AppEnv.secondColor,
                    hover_text: "#F3F3F3",
                    hover_background: AppEnv.secondColor,
                    hover_border: "#F1F1F1",
                    icon_background: AppEnv.secondColor,
                    icon_color: "#FFF",
                    disabled_background: "#F1F1F1",
                    disabled_text: "#C1C1C1",
                    disabled_icon_background: "#6F6F6F",
                    disabled_border: "#6F6F6F",
                },
                secondary: {
                    text: "#4D4D4D",
                    background: "#FFF",
                    border: "#4D4D4D",
                    hover_text: "#F3F3F3",
                    hover_background: "#4D4D4D",
                    hover_border: "#F1F1F1",
                    icon_background: "#4D4D4D",
                    icon_color: "#FFF",
                    disabled_background: "#F1F1F1",
                    disabled_text: "#C1C1C1",
                    disabled_icon_background: "#6F6F6F",
                    disabled_border: "#6F6F6F",
                },
                check: {
                    text: "#4D4D4D",
                    background: "#FFF",
                    border: "#F1F1F1",
                    hover_text: "#F3F3F3",
                    hover_background: "#50b650",
                    hover_border: "#F1F1F1",
                    icon_background: "#50b650",
                    icon_color: "#FFF",
                    disabled_background: "#F1F1F1",
                    disabled_text: "#C1C1C1",
                    disabled_icon_background: "#6F6F6F",
                    disabled_border: "#6F6F6F",
                },
                tab: {
                    text: "#000",
                    background: "#F1F1F1",
                    border_color: "#F1F1F1",
                    hover_text: "#FFF",
                    hover_background: "#969696",
                    hover_border_color: "#969696",
                    selected_text: "#000",
                    selected_background: "#FFF",
                    selected_border_color: "#DDDDDD"
                },
            },
            spinner: {
                normal: {color: AppEnv.secondColor},
                disabled: {color: "#6F6F6F"}
            },
            card: {
                background: "#FFF",
                border: "#F1F1F1"
            }
        },
        school_configuration: {}
    }
;

export default function RootReducer(state = initialState, action) {

    switch (action.type) {
        case ROOT_LOAD:
            return {
                ...state,
                loading: action.payload
            };
        case CICLO_ACTUAL:
            return {
                ...state,
                cicloActual: action.payload,
                loading: false
            };
        case MODAL:
            return {
                ...state,
                modal: action.modal,
                modalType: action.modalType,
                loading: false
            };
        case SCHOOL_DATA:
            return {
                ...state,
                schoolData: action.payload,
                loading: false
            };
        case PERIODOS:
            return {
                ...state,
                periodos: action.payload,
                loading: false
            };
        case ALIAS:
            return {
                ...state,
                alias: action.payload,
                loading: false
            };
        case LOADING_COUNTRIES:
            return {
                ...state,
                loading: action.payload
            };
        case BROWSER:
            return {
                ...state,
                browserIntent: action.payload
            };
        case INIT:
            return {
                ...state,
                bd: action.bd,
                country: action.country,
                roles: action.roles,
                schoolId: action.schoolId,
                token: action.token,
                userEmail: action.userEmail,
                userGrado: action.userGrado,
                userGrupo: action.userGrupo,
                userId: action.userId,
                userName: action.userName,
                userPicture: action.userPicture,
                userPuesto: action.userPuesto,
                userPuestoAlias: action.userPuestoAlias,
                userRole: action.userRole,
                activo: action.activo,
                estatus: action.estatus,
                loading: false,
            };
        case USER_PICTURE:
            return {
                ...state,
                userPicture: action.payload
            }
        case THEME:
            return {
                ...state,
                theme: action.payload,
                kolegiaBlue: action.payload.kolegiaBlue,
                backGround: action.payload.backGround,
                mainColor: action.payload.mainColor,
                secondColor: action.payload.secondColor,
                thirdColor: action.payload.thirdColor,
                fourthColor: action.payload.fourthColor,
                fifthColor: action.payload.fifthColor,

                greenColor: action.payload.greenColor,
                greenColorTwo: action.payload.greenColorTwo,
                yellowColor: action.payload.yellowColor,
                orangeColor: action.payload.orangeColor,
                orangeColorTwo: action.payload.orangeColorTwo,
                redColor: action.payload.redColor,
                blueColor: action.payload.blueColor,

                grayBackground: action.payload.grayBackground,
                grayColorOne: action.payload.grayColorOne,
                grayColorTwo: action.payload.grayColorTwo,
                grayColorThree: action.payload.grayColorThree,
                grayColorFour: action.payload.grayColorFour,
                grayColorFive: action.payload.grayColorFive,
            };
        case MY_ROLES:
            return {
                ...state,
                my_roles: action.payload
            };
        case LISTA_ALUMNOS:
            return {
                ...state,
                alumnos: action.payload,
                loading: false
            };
        case ALUMNOS_EGRESADOS:
            return {
                ...state,
                alumnos_egresados: action.payload,
                loading: false
            };
        case MODE:
            return {
                ...state,
                mode: action.payload,
            };
        case SCHOOLS_NIVELES:
            return {
                ...state,
                schools_niveles: action.payload,
            };
        case IMAGES:
            return {
                ...state,
                images: action.payload,
            };
        case SET_MENU:
            return {
                ...state,
                iMenu: action.index,
                menu: action.name,
            };
        case SET_BETA:
            return {
                ...state,
                beta: action.payload,
            };
        case SET_KOLEGIA_THEME:
            return {
                ...state,
                kolegia_theme: action.payload,
            };
        case SET_SUB_MENU:
            return {
                ...state,
                iSubMenu: action.index,
                subMenu: action.name,
            };
        case SET_MODAL_ACTIVITY_CLASS:
            return {
                ...state,
                modalActivityOnClass: action.payload,
            };
        case SCHOOL_CONFIGURATION:
            return {
                ...state,
                school_configuration: action.payload,
            };
        case UPDATE_SCHOOL_CONFIGURATION:
            const updated = {...state.school_configuration, [action.prop]: action.value}
            return {
                ...state,
                school_configuration: updated,
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
