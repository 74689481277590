import React, {memo, useContext, useEffect, useState} from "react";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {Row} from "react-material-responsive-grid";
import KolegiaBroadcastContext from "../../../../../Context/KolegiaBroadcast";
import axios from 'axios';
import {AppEnv} from "../../../../env";
import {info} from "../../../../Global-W/NewFunctions";

function Timer() {
    const {sendRequest} = useContext(KolegiaBroadcastContext)
    const dispatch = useDispatch();
    const {
        classData,
        status_class,
        next_same,
        refresh_class,
        lastVersion,
        grayColorThree
    } = useSelector(state => state.virtual_class);
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [second, setSecond] = useState('');
    const [classFinished, setClassFinished] = useState(false);
    const [isCurrentVersion, setIsCurrentVersion] = useState('');
    const {
        fourthColor,
        redColor,
        fifthColor,
        grayColorFour,
        bd,
        schoolData,
        userRole,
        apiGes
    } = useSelector(state => state.rooot);

    useEffect(() => {
        fetchVersion();
    }, [])

    const fetchVersion = async () => {
        try {
            const {data} = await axios.get(AppEnv.apiGes + 'configuration/versiones/get/VC');
            let versionBd = data.android_major + '.' + data.android_minor + '.' + data.android_patch;
            info('is last version', versionBd === lastVersion)
            setIsCurrentVersion(versionBd === lastVersion ? 'ÚLTIMA VERSIÓN' : '');
        } catch (e) {

        }
    }

    useEffect(() => {
        if (status_class === 1 && userRole !== 'Admin') {
            const interval = setInterval(() => {
                const end = moment(classData.horarios.fin, 'HH:mm:ss');
                const duration = moment.duration(end.diff(moment()));
                setHour((duration.hours() < 10) ? '0' + duration.hours().toString() : duration.hours().toString());
                setMinute((duration.minutes() < 10) ? '0' + duration.minutes().toString() : duration.minutes().toString());
                setSecond((duration.seconds() < 10) ? '0' + duration.seconds().toString() : duration.seconds().toString());
                setClassFinished(duration.hours() <= 0 && duration.minutes() <= 0 && duration.seconds() <= 0);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [classData]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (classFinished && classData.same_next === '1') {
            // dispatch(refreshClass({is_refresh: true, next_same: true}));
        }
    }, [classFinished]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (refresh_class && status_class === 1 && next_same) {
            // let usersId = classData.alumnos.map(item => item.id);
            // usersId.push(Number(classData.relacion.id_maestro));
            // let objectChat = {
            //     room_id:classData.room_id,
            //     id_subject: classData.relacion.id_materia,
            //     users_id:usersId,
            //     bd_school: bd
            // };
            // sendRequest('change_message_room', objectChat);
        }
    }, [classData, refresh_class]); // eslint-disable-line react-hooks/exhaustive-deps

    return <Row className="uk-width-1-1 centerAll"
                style={{
                    padding: 5,
                    height: userRole === 'Admin' ? '10%' : '8%',
                    justifyContent: 'flex-start',
                    backgroundColor: grayColorThree
                }}>
        {status_class === 1
            ? <Row className="centerAll"
                   style={{width: '7%', padding: '0', justifyContent: 'flex-start'}}>
                <Row
                    className="txtnegritasMiniBlack centerAll uk-width-1-1"
                    style={{
                        padding: '3px 4px',
                        borderRadius: 3,
                        backgroundColor: status_class === 1 ? redColor : grayColorFour,
                        fontSize: '.7rem',
                        color: fourthColor
                    }}>
                    EN VIVO
                </Row>
            </Row> : null}
        {status_class === 3 ? <Row
            className="txtnegritasMiniBlack centerAll"
            style={{
                padding: '3px 4px',
                borderRadius: 3,
                backgroundColor: status_class === 1 ? redColor : grayColorFour,
                fontSize: '.7rem',
                color: fourthColor
            }}>
            TRANSMISIÓN FINALIZADA
        </Row> : null}
        <Row className="centerAll" style={{width: '3%'}}>
            <i data-uk-tooltip={lastVersion + ' - ÚLTIMA VERSIÓN'} className="fas fa-info-circle"
               style={{color: fifthColor, fontSize: '1rem'}}/>
        </Row>
        {schoolData.id !== 8 ? status_class === 1
            ? <Row className=" section_title_two uk-text-truncate"
                   style={{width: '85%'}}>
                <label
                    className="uk-width-3-4 section_title_two uk-text-truncate"
                    style={{
                        textAlign: 'left',
                        textTransform: 'uppercase'
                    }}
                >
                    {classData.relacion.nombre.nombre} — {classData.horarios.horas}
                </label>
                <label
                    className="uk-width-1-4"
                    data-uk-tooltip="Tiempo restante"
                >{Number(minute) <= 0 ? '00:00' : minute + ':' + second}</label>
            </Row>
            : null : null}
        {schoolData.id === 8
            ? <Row className=" section_title_two uk-text-truncate"
                   style={{width: '85%'}}>
                <label
                    className="uk-width-1-1 section_title_two uk-text-truncate"
                    style={{
                        textAlign: 'left',
                        textTransform: 'uppercase'
                    }}
                >
                    CLASE DEMO
                </label>
            </Row> : null}
        {classFinished && classData.same_next === '0'
            ? <Row style={{width: '5%', padding: 0}}>
                {/*<Row*/}
                {/*    className="uk-width-1-1 centerAll"*/}
                {/*    data-uk-tooltip="Ir a siguiente clase"*/}
                {/*    style={{*/}
                {/*        borderRadius: 6,*/}
                {/*        padding: 3,*/}
                {/*        backgroundColor: fifthColor,*/}
                {/*        color: fourthColor,*/}
                {/*        cursor: 'pointer'*/}
                {/*    }}*/}
                {/*    onClick={() => {*/}
                {/*        setClassFinished(false);*/}
                {/*        dispatch(setLoading(true));*/}
                {/*        sendRequest('change_class', {is_same: classData.same_next}).then(() => {*/}
                {/*            dispatch(refreshClass({is_refresh: true, next_same: classData.same_next === '1'}));*/}
                {/*        });*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <i className="fas fa-arrow-right" style={{fontSize: '1rem'}}/>*/}
                {/*</Row>*/}
            </Row> : null}
    </Row>
}

export default memo(Timer)
