import {
    LOADING_ZOOM,
    MEETINGS_GROUP,
    MODULES,
    MODULES_TEACHER,
    SKIP_ZOOM,
    ZOOM_PROPS,
    ZOOM_STATUS,
    ZOOM_SUSCRIBE,
} from '../../actions/types';

const initialState = {
    loading: false,
    meetings_group: [],
    modules: [],
    modules_teacher: [],
    skip: 0,
    zoom_basic: sessionStorage.getItem('zoom_basic') || '',
    zoom_refresh_token: sessionStorage.getItem('zoom_refresh_token') || '',
    zoom_status: '',
    zoom_token: sessionStorage.getItem('zoom_token') || '',
    zoom_token_time: sessionStorage.getItem('zoom_token_time') || '',
    zoom_uri: sessionStorage.getItem('zoom_uri') || '',
    zoom_user_id: sessionStorage.getItem('zoom_user_id') || '',
};

export default function ZoomReducer(state = initialState, action) {

    switch (action.type) {
        case LOADING_ZOOM:
            return {
                ...state,
                loading: false
            };
        case MODULES:
            return {
                ...state,
                modules: action.payload,
                loading: false
            };
        case MODULES_TEACHER:
            return {
                ...state,
                modules_teacher: action.payload,
                loading: false
            };
        case MEETINGS_GROUP:
            return {
                ...state,
                meetings_group: action.payload,
                loading: false
            };
        case SKIP_ZOOM:
            return {
                ...state,
                skip: action.payload,
                loading: false
            };
        case ZOOM_STATUS:
            return {
                ...state,
                zoom_status: action.payload,
                loading: false
            };
        case ZOOM_SUSCRIBE:
            return {
                ...state,
                zoom_token: action.zoom_token,
                zoom_refresh_token: action.zoom_refresh_token,
                zoom_user_id: action.zoom_user_id,
                zoom_token_time: action.zoom_token_time,
                loading: false
            };
        case ZOOM_PROPS:
            return {
                ...state,
                zoom_basic: action.zoom_basic,
                zoom_uri: action.zoom_uri,
                loading: false
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
