import React from 'react'

export default function Modal({
                                  child = undefined,
                                  open = false,
                                  onClose = {},
                                  size = "small-modal",
                                  showCloseIcon = true,
                                  styleContainer = {},
                                  styleModal = {},
                                  children = {}
                              }) {
    const clase = 'own-modal-content ' + size
    if (open) {
        return <div className='own-modal' style={styleContainer}>
            <div
                className={clase} style={styleModal}>
                {showCloseIcon
                    ? <div className='close-modal' style={{padding: 8}} onClick={() => onClose()}>
                        <i className="fas fa-times"/>
                    </div>
                    : null}

                {child ? child : children}
            </div>
        </div>
    } else return null
}
