import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Row} from 'react-material-responsive-grid';
import Dropzone from "react-dropzone";
import {AppEnv} from "../../env";
import Resizer from "./Resizer";
import {alertWarning} from "../NewFunctions";

export const PreviewImages = forwardRef((props, ref) => {
    const {
        accepted = '.jpg,.gif,.png,.jpeg,.pdf,.xlsx,.pptx,.docx,.xls,.ppt,.doc,.webm,.ovg,.mp4,.mp3,.midi,.wav,.wma,.m4a,.heic,.heif',
        child = {},
        contraste = '1',
        maxSize = 31457280,
        multiSelect = true,
        previewImages = '1',
        resizeOptions = {maxWidth: 700, maxHeight: 700, quality: 100},
        select,
        imageSize = 'uk-width-1-2',
        skip = false,
        centerPreview = false
    } = props;
    const [selectedFiles, setSelectedFiles] = useState([]);

    useImperativeHandle(ref, () => ({
        resetFiles() {
            setSelectedFiles([])
        }
    }));

    useEffect(() => {
        select(selectedFiles);
    }, [selectedFiles]) // eslint-disable-line react-hooks/exhaustive-deps

    const renderPreviewImages = selectedFiles.map((it, i) => {
        let tipo = it.file.type.split('/');
        if (previewImages === '1') {
            return <Row key={i + 'preview'}
                        className={"centerAll"}
                        style={{margin: 0, padding: 3, width: 150, height: 150}}>
                <Row className='uk-width-1-1'
                     style={{
                         backgroundColor: contraste === '1' ? AppEnv.grayColorOne : AppEnv.fourthColor,
                         borderRadius: 6,
                         margin: 0,
                     }}>
                    <Row className="uk-width-1-1"
                         style={{
                             borderBottom: '1px solid',
                             borderColor: contraste === '1' ? AppEnv.fourthColor : AppEnv.grayColorOne,
                             margin: 0,
                             padding: 3,
                         }}>
                        <Row className="centerAll"
                             style={{margin: 0, padding: '0 5px'}}>
                            <a href={it.url} target={'blank'} download>
                                <i className="fas fa-download"/>
                            </a>
                        </Row>
                        <Row className="centerAll"
                             style={{margin: 0, padding: '0 5px'}}>
                            <a onClick={() => {
                                const temp = [...selectedFiles];
                                temp.splice(i, 1);
                                setSelectedFiles(temp);
                            }}>
                                <i style={{color: AppEnv.redColor}}
                                   className="fas fa-minus-circle"/>
                            </a>
                        </Row>
                    </Row>
                    <Row className="uk-width-1-1 centerAll"
                         style={{
                             backgroundColor: contraste === '1' ? AppEnv.grayColorOne : AppEnv.fourthColor,
                             borderBottomLeftRadius: 6,
                             borderBottomRightRadius: 6,
                             margin: 0,
                             padding: 0,
                         }}>
                        <img className="uk-width-1-1 centerAll"
                             style={{
                                 borderBottomLeftRadius: 6,
                                 borderBottomRightRadius: 6,
                                 objectFit: 'cover',
                                 width: 150, height: 118
                             }}
                             src={it.url}
                             alt=""/>
                    </Row>
                </Row>
            </Row>
        } else if (previewImages === '3') {
            return <Row key={i + 'preview'}
                        className={imageSize + ' centerAll'}
                        style={{margin: 0, padding: 3}}>
                <Row className='uk-width-1-1'
                     style={{
                         backgroundColor: AppEnv.grayColorThree,
                         borderRadius: 6,
                         overflow: 'hidden',
                         margin: 0,
                     }}>
                    <Row className="uk-width-1-1"
                         style={{
                             borderBottom: '1px solid',
                             borderColor: contraste === '1' ? AppEnv.fourthColor : AppEnv.grayColorOne,
                             margin: 0,
                             padding: 3,
                         }}>
                        <Row className="uk-width-5-6"
                        >
                            <label style={{textAlign: 'left'}}
                                   className=' uk-width-1-1 normal_text uk-text-truncate'>{it.name}</label>
                        </Row>
                        <Row className="uk-width-1-6 centerAll"
                        >
                            <Row className="uk-width-1-2 centerAll"
                            >
                                <a href={it.url} target={'blank'} download>
                                    <i className="fas fa-download"/>
                                </a>
                            </Row>
                            <Row className="uk-width-1-2 centerAll"
                            >
                                <a onClick={() => {
                                    const temp = [...selectedFiles];
                                    temp.splice(i, 1);
                                    setSelectedFiles(temp);
                                }}>
                                    <i style={{color: AppEnv.redColor}}
                                       className="fas fa-minus-circle"/>
                                </a>
                            </Row>
                        </Row>
                    </Row>
                    <Row className="uk-width-1-1 centerAll"
                         style={{
                             backgroundColor: contraste === '1' ? AppEnv.grayColorOne : AppEnv.fourthColor,
                             borderBottomLeftRadius: 6,
                             borderBottomRightRadius: 6,
                             margin: 0,
                             padding: 0,
                         }}>
                        {tipo[0] === 'image'
                            ? <img className="uk-width-1-1 centerAll"
                                   style={{
                                       borderBottomLeftRadius: 6,
                                       borderBottomRightRadius: 6,
                                       objectFit: 'cover',
                                       width: 150, height: 118
                                   }}
                                   src={it.url}
                                   alt=""/>
                            : null}
                        {tipo[0] !== 'image'
                            ? <Row className="uk-width-1-1 centerAll"
                                   style={{
                                       width: 150,
                                       height: 118
                                   }}>
                                <div>
                                    <i className="fas fa-file-alt"
                                       style={{fontSize: '3rem', color: AppEnv.fifthColor}}/>
                                </div>
                            </Row>
                            : null}

                    </Row>
                </Row>
            </Row>
        } else {
            return <Row key={i + 'preview'} className="uk-width-1-1 centerAll"
                        style={{margin: 0, padding: 3,}}>
                <Row className="uk-width-1-1 centerAll"
                     style={{
                         backgroundColor: contraste === '1' ? AppEnv.grayColorOne : AppEnv.fourthColor,
                         borderRadius: 6,
                         margin: 0,
                     }}>
                    <Row className="uk-width-1-1"
                         style={{margin: 0, padding: 3}}>
                        <Row className="uk-width-5-6 centerAll"
                             style={{margin: 0, padding: '0 5px'}}>
                            <label style={{textAlign: 'left'}}
                                   className=' uk-width-1-1 normal_text uk-text-truncate'>{it.name}</label>
                        </Row>
                        <Row className="uk-width-1-6 centerAll"
                        >
                            <Row className="uk-width-1-2 centerAll"
                            >
                                <a href={it.url} target={'blank'} download>
                                    <i className="fas fa-download"/>
                                </a>
                            </Row>
                            <Row className="uk-width-1-2 centerAll"
                            >
                                <a onClick={() => {
                                    const temp = [...selectedFiles];
                                    temp.splice(i, 1);
                                    setSelectedFiles(temp);
                                }}>
                                    <i style={{color: AppEnv.redColor}}
                                       className="fas fa-minus-circle"/>
                                </a>
                            </Row>
                        </Row>
                    </Row>
                </Row>
            </Row>
        }
    })

    const resizeImage = (file) => new Promise(resolve => {
        let tipo = file.type.split('/')
        Resizer.imageFileResizer(file, resizeOptions.maxWidth, resizeOptions.maxHeight, tipo[1], resizeOptions.quality,
            uri => {
                resolve(uri);
            },
            'blob'
        );
    });

    const getFiles = async (files) => {
        for (let i = 0; i < files.length; i++) {
            if (files[i].size <= maxSize) {
                let tipo = files[i].type.split('/')
                if (!skip) {
                    if (tipo[0] === 'image') {
                        await resizeImage(files[i])
                            .then(async (image) => {
                                let file = await fetch(URL.createObjectURL(image))
                                    .then(r => r.blob())
                                    .then(blobFile => new File([blobFile], files[i].name, {type: image.type}));
                                await setSelectedFiles(selectedFiles => [...selectedFiles, {
                                    url: URL.createObjectURL(image),
                                    file: file,
                                    name: files[i].name
                                }])
                            });
                    } else {
                        await setSelectedFiles(selectedFiles => [...selectedFiles, {
                            url: URL.createObjectURL(files[i]),
                            file: files[i],
                            name: files[i].name
                        }])
                    }
                } else {
                    await setSelectedFiles(selectedFiles => [...selectedFiles, {
                        url: URL.createObjectURL(files[i]),
                        file: files[i],
                        name: files[i].name
                    }])
                }
            } else {
                alertWarning('El archivo ' + files[i].name + ' pesa mas de ' + ((maxSize / 1024) / 1024).toFixed(2) + 'mb')
            }
        }
    }

    return <Row className="uk-width-1-1 centerAll"
    >
        <Dropzone
            multiple={multiSelect}
            maxSize={maxSize}
            accept={accepted}
            onDrop={(files) => getFiles(files)}>
            {({getRootProps, getInputProps}) => (
                <div style={{marginBottom: 10}} className='uk-width-1-1'
                     {...getRootProps()}>
                    <input {...getInputProps()} />
                    {child}
                </div>
            )}
        </Dropzone>
        <Row className="uk-width-1-1 centerAll"
             style={{justifyContent: centerPreview ? 'center' : 'flex-start'}}>
            {previewImages !== '0'
                ? renderPreviewImages
                : null}
        </Row>
    </Row>
});
