import { AppEnv } from "../env";
import { Col, Grid, Row } from "react-material-responsive-grid";
import React, { Suspense } from "react";
import UIkit from "uikit";
import axios from 'axios'
import MenuAulaVirtual from "../AulaVirtual-W/Admin/MenuAulaVirtual";
import { useSelector } from "react-redux";

const moment = require('moment/moment');
require('moment/locale/es');
moment.locale('es');

export function retry(fn, retriesLeft = 3, interval = 1000) {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}

export function setWithExpiry(key, value, ttl) {
    const item = {
        value: value,
        expiry: new Date().getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}


const CovidSurvey = React.lazy(() => retry(() => import('../Global-W/CovidSurvey/CovidSurvey')));
const MenuCovidAdmin = React.lazy(() => retry(() => import('../Global-W/CovidSurvey/Admin/MenuCovidAdmin')));
const Conversations = React.lazy(() => retry(() => import('../Organizacion-W/Conversations')));
const AdmisionesConfig = React.lazy(() => import('../Preinscripciones-W/Admin/AdmisionesConfig'));
const AdmisionesPadre = React.lazy(() => import('../Preinscripciones-W/Tutor/AdmisionesPadre'));
const AgendarCitaAdmin = React.lazy(() => import('../Citas-W/AgendarCitaAdmin'));
const AgendarCitaMaestro = React.lazy(() => import('../Citas-W/AgendarCitaMaestro'));
const AgendarCitasPadre = React.lazy(() => import('../Citas-W/AgendarCitasPadre'));
const AgendarCitasPrefecto = React.lazy(() => import('../Citas-W/AgendarCitasPrefecto'));
const AsistenciasAdmin = React.lazy(() => import('../Asistencias-W/Admin/AsistenciasAdmin'));
const AsistenciasMaestro = React.lazy(() => import('../Asistencias-W/Maestro/AsistenciasMaestro'));
const AsistenciasPadre = React.lazy(() => import('../Asistencias-W/AsistenciasPadre'));
const AssistsTutor = React.lazy(() => import('../Asistencias-W/AssistsTutor'));
const AsistenciasPrefecto = React.lazy(() => import('../Asistencias-W/AsistenciasPrefecto'));
const CalendarioDeActividadesGlobal = React.lazy(() => import('../CalendarioDeActividades-W/CalendarioDeActividadesGlobal'));
const CalificacionesPadre = React.lazy(() => import('../Calificaciones-W/QualifsTutor'));
const CalificacionesPrefecto = React.lazy(() => import('../Calificaciones-W/Prefecto/CalificacionesPrefecto'));
const ControlTutores2 = React.lazy(() => import('../DatosDeContacto-W/ControlTutores2'));
const DatosContacto = React.lazy(() => import('../DatosDeContacto-W/DatosContacto'));
const DatosContactoPadre = React.lazy(() => import('../DatosDeContacto-W/Tutor/DatosContactoPadre'));
const DatosContactoDocente = React.lazy(() => import('../DatosDeContacto-W/DatosContactoDocente'));
const ControlDeAlumnos = React.lazy(() => import('../DatosDeContacto-W/Tutor/ControlDeAlumnos'));
const MenuPerfilPadre = React.lazy(() => import('../DatosDeContacto-W/Tutor/MenuPerfilPadre'));
const DirectorioPrefecto = React.lazy(() => import('../DatosDeContacto-W/DirectorioPrefecto2'));
// const Documentos = React.lazy(() => import('../Documentos-W/Admin/DocumentManagement'));
const Documentos = React.lazy(() => import('../Documentos-W/Documentos2'));
const DocumentosPadre = React.lazy(() => import('../Documentos-W/DocumentosPadre2'));
const Encuadre = React.lazy(() => import('../Encuadres-W/Encuadre'));
const EncuadreAlumno = React.lazy(() => import('../Encuadres-W/EncuadreAlumno'));
const EncuadreMaestro = React.lazy(() => import('../Encuadres-W/EncuadreMaestro'));
const EncuadrePadre = React.lazy(() => import('../Encuadres-W/EncuadrePadre'));
const FaltaMensaje = React.lazy(() => import('./Classes/FaltaMensaje'));
const Galeria = React.lazy(() => import('../Galeria-W/Galeria2'));
const GaleriaPadre = React.lazy(() => import('../Galeria-W/GaleriaPadre2'));
const GestMenuEsc = React.lazy(() => import('../MenuDeAlimentos-W/GestMenuEsc'));
const HorarioAlumno = React.lazy(() => import('../Horario-W/HorarioAlumno'));
const HorariosMaestro = React.lazy(() => import('../Horario-W/HorarioMaestro'));
const HorariosPadre = React.lazy(() => import('../Horario-W/HorarioPadre'));
const HorariosPrefecto = React.lazy(() => import('../Horario-W/HorarioPrefecto'));
const InscripcionesAdmin = React.lazy(() => import('../Preinscripciones-W/Admin/AsignarAltas/InscripcionesAdmin'));
const MenuActividadesMaestro = React.lazy(() => import('../ActividadesAprendizaje-W/Maestro/MenuActividadesMaestro'));
const MenuAulaVirtualMaestro = React.lazy(() => import('../AulaVirtual-W/Maestro/MenuAulaVirtualMaestro'));
const MenuActividadesTutor = React.lazy(() => import('../ActividadesAprendizaje-W/Tutor/MenuActividadesTutor'));
const MenuActividadesAlumno = React.lazy(() => import('../ActividadesAprendizaje-W/Alumno/MenuActividadesAlumno'));
const MenuAulaVirtualAlumno = React.lazy(() => import('../AulaVirtual-W/Alumno/MenuAulaVirtualAlumno'));
const MenuActividadesAdmin = React.lazy(() => import('../ActividadesAprendizaje-W/Admin/MenuActividadesAdmin'));
const MenuCalendario = React.lazy(() => import('../CalendarioDeActividades-W/MenuCalendario'));
const MenuCalificacionesAdmin = React.lazy(() => import('../Calificaciones-W/Admin/MenuCalificaciones'));
const MenuCalificacionesMaestro = React.lazy(() => import('../Calificaciones-W/Maestro/MenuCalificaciones'));
const MenuCalificacionesPrefecto = React.lazy(() => import('../Calificaciones-W/Prefecto/MenuCalificacionesPrefecto'));
const CalificacionesAlumno = React.lazy(() => import('../Calificaciones-W/Alumno/QualifsStudent'));
const MenuCursos = React.lazy(() => import('../Cursos-W/MenuCursos'));
const MenuEscPadre = React.lazy(() => import('../MenuDeAlimentos-W/MenuDeAlimentosPadre'));
const MenuPagosDir = React.lazy(() => import('../Pagos-W/Administrador/MenuPagos'));
const MenuPagosPadre = React.lazy(() => import('../Pagos-W/Padre/MenuPagos'));
const MiOrganizacion = React.lazy(() => import('../Organizacion-W/MiOrganizacion'));
const MovimientoDeAlumnos = React.lazy(() => import('../Movimiento-W/MovimientoDeAlumnos'));
const NoticiasAlumno = React.lazy(() => import('../Noticias-W/NoticiasAlumno'));
const NoticiasMaestro = React.lazy(() => import('../Noticias-W/NoticiasMaestro'));
const NoticiasPadre = React.lazy(() => import('../Noticias-W/NoticiasPadre'));
const Notificaciones = React.lazy(() => import('../Actividad-W/Notificaciones'));
const ApplicantsMenu = React.lazy(() => import('../Preinscripciones-W/New/Admin/Applicants/ApplicantsMenu'));
const Parametros = React.lazy(() => import('../Configuracion-W/Parametros'));
const MenuNoticias = React.lazy(() => import('../Noticias-W/Admin/MenuNoticias'));
const ZoomPadre = React.lazy(() => import('../Zoom-W/Padre/MenuZoom'));
const ZoomMaestro = React.lazy(() => import('../Zoom-W/Maestro/MenuZoom'));
const ZoomAlumno = React.lazy(() => import('../Zoom-W/Alumno/MenuZoom'));
const Store = React.lazy(() => import('../Store-W/Store'));
const Inventory = React.lazy(() => import('../Inventory-W/Inventory'));
const SubMenus = React.lazy(() => import('../Global-W/Hooks/SubMenus'));

export function info(title, data = undefined) {
    console.log('\x1b[35m%s\x1b[0m', title, "\n");
    // eslint-disable-next-line no-unused-expressions
    data !== undefined ? log(data) : null
}

export function warn(title, data) {
    console.log('\x1b[33m%s\x1b[0m', title, "\n");
    // eslint-disable-next-line no-unused-expressions
    data !== undefined ? log(data) : null
}

export function error(title, data) {
    console.log('\x1b[31m%s\x1b[0m', title, "\n");
    // eslint-disable-next-line no-unused-expressions
    data !== undefined ? log(data) : null
}

export function debug(title, data) {
    console.log('\x1b[36m%s\x1b[0m', title, "\n");
    // eslint-disable-next-line no-unused-expressions
    data !== undefined ? log(data) : null
}

function log(data) {
    console.log(data)
    console.log("\n")
}

export function getSeason() {
    if (AppEnv.app === 'kolegia') {
        let today = moment()
        let year = moment().format('Y');
        if (today.isBetween(year + '-11-26', (year + 1) + '-01-10')) {
            return 'navidad'
        } else if (today.isBetween((year - 1) + '-11-26', year + '-01-10')) {
            return 'navidad'
        } else if (today.isBetween(year + '-02-01', year + '-02-28')) {
            return 'valentine'
        } else {
            return 'dashboard'
        }
    } else {
        return 'dashboard'
    }
}

export function sortList(arr) {
    return arr.sort(function (a, b) {
        if (a.name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return -1;
        if (a.name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return 1;
        return 0;
    });
}


export function sortOnline(arr) {
    return arr.sort(function (a, b) {
        if (a.online === true && b.online === false) return -1;
        if (a.online === false && b.online === true) return 1;
        return 0;
    });
}

export function sortListGroups(arr) {
    return arr.sort(function (a, b) {
        if (a.grado !== null) {
            if (a.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + a.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + b.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return -1;
            if (a.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + a.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + b.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return 1;
        } else {
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return -1;
            if (a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return 1;
        }
        return 0;
    });
}

export function sortGroups(arr) {
    return arr.sort(function (a, b) {
        if (a.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + a.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + b.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return -1;
        if (a.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + a.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + b.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return 1;
        return 0;
    });
}

export function sortListGroupsTwo(arr) {
    return arr.sort(function (a, b) {
        if (a.grados.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + a.grupos.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.grados.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + b.grupos.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return -1;
        if (a.grados.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + a.grupos.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.grados.grado.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + b.grupos.grupo.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return 1;
        return 0;
    });
}

export function sortListNombre(arr) {
    return arr.sort(function (a, b) {
        if (a.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return -1;
        if (a.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return 1;
        return 0;
    });
}

export function sortListNombreName(arr) {
    return arr.sort(function (a, b) {
        if (a.nombre.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.nombre.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return -1;
        if (a.nombre.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.nombre.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return 1;
        return 0;
    });
}

export function sortListAlumnoName(arr) {
    return arr.sort(function (a, b) {
        if (a.alumno.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.alumno.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return -1;
        if (a.alumno.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.alumno.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) return 1;
        return 0;
    });
}

export function sortArray(response, key) {
    return response.sort(function (a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
    });
}

export function suspenseFallback() {
    return <Row className='centerAll' style={{
        margin: 0,
        padding: 60
    }}>
        <Row className='uk-width-1-1 centerAll'>
            {pulseLoader()}
        </Row>
        <label style={{ textAlign: 'center' }} className='encabezadoDB uk-width-1-1'>
            Cargando
        </label>
        <label className='subtituloDB'>
            Por favor espere ...
        </label>
    </Row>;
}

export function dateFormat(date, recive, send) {
    return moment(date, recive).format(send);
}

export function splitEvery(str) {
    let string;
    if (str.length % 4 === 0 || str.length % 4 === 2) {
        string = str.replace(/(.{4})/gi, '$1 - ').trim()
    } else {
        string = str.replace(/(.{3})/g, '$1 - ').trim()
    }
    if ((str.length % 4 === 0 && str.length !== 18) || (str.length % 3 === 0 && str.length !== 18)) {
        string = string.slice(0, -2);
    }
    return string;
}

export function getCicloActual(bd) {
    return fetch(AppEnv.api + bd + '/get/ciclo/actual', {
        method: 'GET', headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => response.json())
        .then(responseJson => {
            if (responseJson.error === null) {
                alertFail();
            } else {
                return responseJson.id;
            }
        });
}

export function getDatos(responseJson) {
    if (responseJson[0].orden === '1') {
        return {
            op: AppEnv.OpenPay,
            opk: AppEnv.OpenPayPK,
            production: AppEnv.Production
        };
    } else if (responseJson[0].orden === '2') {
        return {
            op: AppEnv.OpenPayI,
            opk: AppEnv.OpenPayPKI,
            production: AppEnv.ProductionI
        };
    } else if (responseJson[0].orden === '3') {
        return {
            op: AppEnv.OpenPayII,
            opk: AppEnv.OpenPayPKII,
            production: AppEnv.ProductionII
        };
    } else if (responseJson[0].orden === '4') {
        return {
            op: AppEnv.OpenPayIII,
            opk: AppEnv.OpenPayPKIII,
            production: AppEnv.ProductionIII
        };
    } else if (responseJson[0].orden === '5') {
        return {
            op: AppEnv.OpenPayIV,
            opk: AppEnv.OpenPayPKIV,
            production: AppEnv.ProductionIV
        };
    } else if (responseJson[0].orden === '6') {
        return {
            op: AppEnv.OpenPayV,
            opk: AppEnv.OpenPayPKV,
            production: AppEnv.ProductionV
        };
    } else if (responseJson[0].orden === '7') {
        return {
            op: AppEnv.OpenPayVI,
            opk: AppEnv.OpenPayPKVI,
            production: AppEnv.ProductionVI
        };
    } else if (responseJson[0].orden === '8') {
        return {
            op: AppEnv.OpenPayVII,
            opk: AppEnv.OpenPayPKVII,
            production: AppEnv.ProductionVII
        };
    } else if (responseJson[0].orden === '9') {
        return {
            op: AppEnv.OpenPayVIII,
            opk: AppEnv.OpenPayPKVIII,
            production: AppEnv.ProductionVIII
        };
    } else if (responseJson[0].orden === '10') {
        return {
            op: AppEnv.OpenPayIX,
            opk: AppEnv.OpenPayPKIX,
            production: AppEnv.ProductionIX
        };
    } else if (responseJson[0].orden === '11') {
        return {
            op: AppEnv.OpenPayX,
            opk: AppEnv.OpenPayPKX,
            production: AppEnv.ProductionX
        };
    } else if (responseJson[0].orden === '12') {
        return {
            op: AppEnv.OpenPayXI,
            opk: AppEnv.OpenPayPKXI,
            production: AppEnv.ProductionXI
        };
    } else if (responseJson[0].orden === '13') {
        return {
            op: AppEnv.OpenPayXII,
            opk: AppEnv.OpenPayPKXII,
            production: AppEnv.ProductionXII
        };
    } else if (responseJson[0].orden === '14') {
        return {
            op: AppEnv.OpenPayXIII,
            opk: AppEnv.OpenPayPKXIII,
            production: AppEnv.ProductionXIII
        };
    } else if (responseJson[0].orden === '15') {
        return {
            op: AppEnv.OpenPayXIV,
            opk: AppEnv.OpenPayPKXIV,
            production: AppEnv.ProductionXIV
        };
    } else if (responseJson[0].orden === '16') {
        return {
            op: AppEnv.OpenPayXV,
            opk: AppEnv.OpenPayPKXV,
            production: AppEnv.ProductionXV
        };
    } else if (responseJson[0].orden === '17') {
        return {
            op: AppEnv.OpenPayXVI,
            opk: AppEnv.OpenPayPKXVI,
            production: AppEnv.ProductionXVI
        };
    } else if (responseJson[0].orden === '18') {
        return {
            op: AppEnv.OpenPayXVII,
            opk: AppEnv.OpenPayPKXVII,
            production: AppEnv.ProductionXVII
        };
    } else if (responseJson[0].orden === '19') {
        return {
            op: AppEnv.OpenPayXVIII,
            opk: AppEnv.OpenPayPKXVIII,
            production: AppEnv.ProductionXVIII
        };
    } else if (responseJson[0].orden === '20') {
        return {
            op: AppEnv.OpenPayXIX,
            opk: AppEnv.OpenPayPKXIX,
            production: AppEnv.ProductionXIX
        };
    } else {
        return {
            op: '',
            opk: '',
            production: false
        };
    }
}

export function listaAlumnos(data) {
    let views = [];
    if (data.length !== 0) {
        data.forEach((itm, ix) => {
            views.push(<Row key={ix + 'lista'} className="uk-width-1-1 normal_text" style={{
                margin: 0,
                padding: 10,
                marginBottom: 3,
                borderRadius: 6,
                backgroundColor: AppEnv.grayColorOne
            }}>
                <Col className="uk-width-2-3">
                    <div className="uk-width-1-1 uk-text-truncate" style={{
                        color: AppEnv.fifthColor,
                        textAlign: 'left'
                    }}>{itm.nombre !== undefined ? itm.nombre.toUpperCase() : null}</div>
                </Col>
                <div className='uk-width-1-6 normal_text' style={{
                    padding: 0,
                    margin: 0,
                    textAlign: 'center'
                }}>{itm.faltas_dia}</div>
                <div className='uk-width-1-6 normal_text' style={{
                    padding: 0,
                    margin: 0,
                    textAlign: 'center'
                }}>{itm.faltas_clase}</div>
            </Row>);
        });
    }
    return views;
}

export function listaMaestro(data) {
    let views = [];
    if (data.length !== 0) {
        data.forEach((itm, ix) => {
            views.push(<Row key={ix + 'lista'} className="uk-width-1-1 normal_text" style={{
                margin: 0,
                padding: 10,
                marginBottom: 3,
                borderRadius: 6,
                backgroundColor: AppEnv.grayColorOne
            }}>
                <Col className="uk-width-2-3">
                    <div className="uk-width-1-1 uk-text-truncate" style={{
                        color: AppEnv.fifthColor,
                        textAlign: 'left'
                    }}>{itm.maestro !== undefined ? itm.maestro.toUpperCase() : null}</div>
                </Col>
                <div className='uk-width-1-6 normal_text' style={{
                    padding: 0,
                    margin: 0,
                    textAlign: 'center'
                }}>{itm.faltas_dia}</div>
                <div className='uk-width-1-6 normal_text' style={{
                    padding: 0,
                    margin: 0,
                    textAlign: 'center'
                }}>{itm.faltas_clase}</div>
            </Row>);
        });
    }
    return views;
}

export function shortNumber(num, digits) {
    let units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
        decimal;
    for (let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);

        if (num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i];
        }
    }
    return num;
}

export function clabeBanco(clabe) {
    return clabe[0] + clabe[1] + clabe[2] + clabe[3] + ' ' + clabe[4] + clabe[5] + clabe[6] + clabe[7] + '  ' + clabe[8] + clabe[9] + clabe[10] + clabe[11] + '  ' + clabe[12] + clabe[13] + clabe[14] + clabe[15] + '  ' + clabe[16] + clabe[17];
}

export function checkDniLength(country, type) {
    if (country == '1') {
        if (type === 'maxLength') {
            return 18;
        } else {
            return 18;
        }
    } else if (country == '2') {
        if (type === 'maxLength') {
            return 12;
        } else {
            return 6;
        }
    } else if (country == '3') {
        if (type === 'maxLength') {
            return 8;
        } else {
            return 8;
        }
    }
}

export function validateDni(country, dni, alias = 'DNI') {
    if (country == '1') {
        return validationMexico(dni, alias);
    } else if (country == '2') {
        return validationColombia(dni, alias);
    } else if (country == '3') {
        return validationArgentina(dni, alias);
    }
}

export function validationMexico(dni, alias) {
    if (dni === undefined || dni === '' || dni === null) {
        alertWarning('El campo ' + alias + ', no esta capturado');
        return false;
    } else if (dni.length === 16) {
        return true;
    } else {
        if (validarInputCurp(dni.toUpperCase()) === false) {
            alertWarning('El campo ' + alias + ', debe ser de 18 caracteres con el formato correcto o bien de 16 caracteres en caso de ser extranjero');
            return false;
        }
    }
    return true;
}

export function validationArgentina(dni, alias) {
    let regex = /^\d{8}(?:[-\s]\d{4})?$/;
    if (dni === undefined || dni === '') {
        alertWarning('El campo ' + alias + ', no está capturado');
        return false;
    } else {
        if (regex.test(dni)) {
            return true;
        } else {
            alertWarning('El campo ' + alias + ', debe ser de 8 números');
            return false;
        }
    }
}

export function validationColombia(dni, alias) {
    let regex = /^\d{6,12}(?:[-\s]\d{4})?$/;
    if (dni === undefined || dni === '') {
        alertWarning('El campo ' + alias + ', no está capturado');
        return false;
    } else {
        if (regex.test(dni)) {
            return true;
        } else {
            alertWarning('El campo ' + alias + ', debe ser de 6 a 12 números');
            return false;
        }
    }
}

export function validationCurp(curp) {
    if (curp === undefined || curp === '') {
        return false;
    } else if (curp.length === 16) {
        return true;
    } else {
        return validarInputCurp(curp)
    }
}

export function validarInputCurp(input) {
    let curp = input.toUpperCase();
    return curpValida(curp);
}

export function curpValida(curp) {
    let re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado = curp.match(re);
    if (!validado) {
        return false;
    }

    function digitoVerificador(curp17) {
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        let diccionario = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ',
            lngSuma = 0.0,
            lngDigito;
        for (let i = 0; i < 17; i++)
            lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        lngDigito = 10 - lngSuma % 10;
        if (lngDigito == 10) return 0;
        return lngDigito;
    }

    return validado[2] == digitoVerificador(validado[1]);
}

export function getDireccion(country, cp) {
    let l = cp.length;
    if (l === getCpLength(country)) {
        let data = [];
        let found = false;
        fetch(AppEnv.apiGes + 'get/direccion/' + country + '/' + cp, {
            method: 'GET', headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(responseJson => {
                if (responseJson.error !== undefined) {
                    alertFail();
                    found = false;
                } else {
                    if (responseJson.length !== 0) {
                        data = responseJson;
                        found = true;
                    }
                }
            });
        return { data: data, found: found };
    } else {
        return { data: [], found: false };
    }

}

export function getCpLength(country) {
    if (country == '1') {
        return 5;
    } else if (country == '2') {
        return 6;
    } else if (country == '3') {
        return 4;
    }
}

export function validarCp(country = '0', cp = '0', cpActive = '0') {
    if (cpActive == '1') {
        if (country == '1') {
            return validateCpMexico(cp);
        } else if (country == '2') {
            return validateCpColombia(cp);
        } else if (country == '3') {
            return validateCpArgentina(cp);
        }
    } else {
        return true;
    }
}

export function validateCpMexico(cp) {
    if (checkCpMexico(cp)) {
        alertWarning('Revise el campo: código postal');
        return false;
    }
    return true;
}

export function validateCpColombia(cp) {
    if (checkCpColombia(cp)) {
        alertWarning('Revise el campo: código postal');
        return false;
    }
    return true;
}

export function validateCpArgentina(cp) {
    if (checkCpArgentina(cp)) {
        alertWarning('Revise el campo: código postal');
        return false;
    }
    return true;
}

export function checkCpMexico(value) {
    let regex = new RegExp('^[0-9]{5}$');
    return !regex.test(value);
}

export function checkCpColombia(value) {
    let regex = new RegExp('^[0-9]{6}$');
    return !regex.test(value);
}

export function checkCpArgentina(value) {
    let regex = new RegExp('^[0-9]{4}$');
    return !regex.test(value);
}

export function diasRestantes(fechaini, fechafin) {
    return moment(fechafin).diff(fechaini, 'days');
}

export function horasRestantes(fechaini, fechafin) {
    return moment(fechafin).diff(fechaini, 'hours');
}

export function mensajeModalidades() {
    let info = 'Defina las modalidades de pago en la opción Configuración de cobros';
    let mgs = (
        <Row className={'uk-width-1-1@s centerAll normal_text'} style={{
            backgroundColor: AppEnv.grayColorOne,
            borderRadius: 10,
            margin: 0,
            padding: 15,
            marginBottom: 5
        }}>
            <Col className={'uk-width-5-6@s centerAll'} style={{ paddingVertical: 5 }}>
                <Row className={'uk-width-1-1@s centerAll'}>
                    <div style={{
                        color: AppEnv.redColor,
                        fontWeight: '700'
                    }}>¡Importante!{'  '}
                    </div>
                </Row>
                <Row className={'uk-width-1-1@s centerAll normal_text'}>
                    {info}
                </Row>
            </Col>
            <Col className={'uk-width-1-6@s'} style={{
                borderColor: AppEnv.redColor,
                borderWidth: 1,
                marginBottom: 0,
                padding: 0
            }}>
                <div>
                    <a>
                        <i className="fas fa-info-circle fa-2x" style={{ color: AppEnv.redColor }} />
                    </a>
                </div>
            </Col>
        </Row>);
    return mgs;
}

export function customAlertResp(title, message, buttons = 1, msjBtn1 = '', msjBtn2 = '', msjBtn3 = '', btn = null, btn2 = null, btn3 = null) {
    return (
        <Grid className={'centerAll'} style={{
            height: '100vh',
            width: '100vw',
            backgroundColor: 'rgba(255,255,255,.95)',
            position: 'absolute',
            top: 0,
            zIndex: 10000,
            margin: 0,
            padding: 0
        }}>
            <Row className="centerAll" style={{
                height: '100vh',
                width: '100vw',
                margin: 0,
                padding: 0
            }}>
                <Row style={{
                    backgroundColor: AppEnv.fourthColor,
                    borderRadius: 10,
                    width: '50%',
                    margin: 0,
                    padding: 15
                }} className='customalertWidth centerAll uk-box-shadow-medium'>
                    <Row className='dd uk-width-1-1 centerAll' style={{
                        margin: 0,
                        padding: 0,
                        marginBottom: 5,
                        textAlign: 'center'
                    }}>{title}
                    </Row>
                    <Row className='uk-width-1-1 centerAll normal_text' style={{
                        margin: 0,
                        padding: 0,
                        marginBottom: 15,
                        fontSize: '1.5rem',
                        fontWeight: '400'
                    }}>{message}
                    </Row>
                    <Row className='uk-width-1-1 centerAll' style={{ marginBottom: 5 }}>
                        {buttons >= 1
                            ? <button onClick={btn} style={{
                                borderRadius: 10,
                                marginLeft: 10,
                                marginTop: 3,
                                border: '1px solid',
                                borderColor: AppEnv.secondColor,
                                backgroundColor: buttons === 1 ? AppEnv.secondColor : AppEnv.fourthColor,
                                color: buttons === 1 ? AppEnv.fourthColor : AppEnv.secondColor,
                                maxWidth: '33%',
                                height: 30,
                            }}
                                className='uk-button uk-button-small customalertFont'>
                                {msjBtn1}
                            </button>
                            : null}
                        {buttons >= 2
                            ? <button style={{
                                borderRadius: 10,
                                marginLeft: 10,
                                marginTop: 3,
                                border: '1px solid',
                                borderColor: AppEnv.secondColor,
                                backgroundColor: AppEnv.secondColor,
                                color: AppEnv.fourthColor,
                                maxWidth: '33%',
                                height: 30
                            }} onClick={btn2} className='uk-button uk-button-small customalertFont'>
                                {msjBtn2}
                            </button>
                            : null}
                        {buttons >= 3
                            ? <button style={{
                                borderRadius: 10,
                                marginLeft: 10,
                                marginTop: 3,
                                border: '1px solid',
                                borderColor: AppEnv.secondColor,
                                backgroundColor: AppEnv.secondColor,
                                color: AppEnv.fourthColor,
                                maxWidth: '33%',
                                height: 30
                            }} onClick={btn3} className='uk-button uk-button-small customalertFont'>
                                {msjBtn3}
                            </button>
                            : null}
                    </Row>
                </Row>
            </Row>
        </Grid>
    );
}

export function user_data_validation1(data) {
    if (data === '1' || data === 1 || data === null || data === undefined || data === '' || data === ' ') {
        return 'S/D';
    } else {
        return data;
    }
}

export function fondoAleatorio() {
    let season = getSeason()
    let r = Math.floor(Math.random() * 5) + 1;
    let pic = '';
    switch (r) {
        case 0:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/' + season + '/1.jpg';
            break;
        case 1:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/' + season + '/2.jpg';
            break;
        case 2:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/' + season + '/3.jpg';
            break;
        case 3:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/' + season + '/4.jpg';
            break;
        case 4:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/' + season + '/5.jpg';
            break;
        case 5:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/' + season + '/6.jpg';
    }
    return pic;
}

export function getBancos(country = '1') {
    if (country == '1') {
        return [
            { nombre: 'ABC CAPITAL' },
            { nombre: 'AFIRME' },
            { nombre: 'AZTECA' },
            { nombre: 'BAJIO' },
            { nombre: 'BANAMEX' },
            { nombre: 'BANCO BASE' },
            { nombre: 'BANCO FAMSA' },
            { nombre: 'BANCOPPEL' },
            { nombre: 'BANJÉRCITO' },
            { nombre: 'BANORTE' },
            { nombre: 'BANREGIO' },
            { nombre: 'BANSI' },
            { nombre: 'BBVA' },
            { nombre: 'CIBANCO' },
            { nombre: 'HSBC' },
            { nombre: 'INBURSA' },
            { nombre: 'INTERACCIONES' },
            { nombre: 'INTERBANCO' },
            { nombre: 'INVEX' },
            { nombre: 'IXE' },
            { nombre: 'JP MORGAN' },
            { nombre: 'MI BANCO' },
            { nombre: 'MONEX' },
            { nombre: 'MULTIVA' },
            { nombre: 'SANTANDER' },
            { nombre: 'SCOTIABANK' },
            { nombre: 'THE ROYAL BANK' },
            { nombre: 'VE POR MÁS' },
            { nombre: 'VOLKSWAGEN' }
        ];
    } else if (country == '2') {
        return [
            { nombre: 'Argentaria Colombia S.A. (BBVA )' },
            { nombre: 'Banco Caja Social BCSC' },
            { nombre: 'Banco de Bogotá' },
            { nombre: 'Banco de Credito' },
            { nombre: 'Banco de la República de Colombia' },
            { nombre: 'Banco de Occidente' },
            { nombre: 'Banco GNB Sudameris' },
            { nombre: 'Banco Granahorrar' },
            { nombre: 'Banco Popular' },
            { nombre: 'Banco Popular Colombia' },
            { nombre: 'Banco Santander Colombia' },
            { nombre: 'Bancoldex' },
            { nombre: 'Bancolombia' },
            { nombre: 'BBVA Banco Ganadero' },
            { nombre: 'Citi Bank' },
            { nombre: 'Colmena BCSC' },
            { nombre: 'Colpatria' },
            { nombre: 'Conavi' },
            { nombre: 'Credit Suisse Representaciõn para Colombia' },
            { nombre: 'Davivienda' },
            { nombre: 'Deutsche Bank' },
            { nombre: 'Helm Financial Services' },
            { nombre: 'Megabanco' },
            { nombre: 'UBS AG in Bogotá' }
        ];
    } else if (country == '3') {
        return [
            { nombre: 'ABN AMRO Asset Management' },
            { nombre: 'ABN AMRO Bank' },
            { nombre: 'American Express Bank Ltd S.A.' },
            { nombre: 'Banco B.i Creditanstalt S.A.' },
            { nombre: 'Banco Bisel' },
            { nombre: 'Banco Bradesco Argentina S.A.' },
            { nombre: 'Banco Central de la República Argentina' },
            { nombre: 'Banco Cetelem Argentina S.A.' },
            { nombre: 'Banco Ciudad de Buenos Aires' },
            { nombre: 'Banco Cmf S.A.' },
            { nombre: 'Banco Cofidis S.A.' },
            { nombre: 'Banco Columbia' },
            { nombre: 'Banco Comafi' },
            { nombre: 'Banco Credicoop' },
            { nombre: 'Banco Credicoop Cooperativo Limitado' },
            { nombre: 'Banco de Balcarce S.A.' },
            { nombre: 'Banco de Entre Rios S.A.' },
            { nombre: 'Banco de Inversion y Comercio Exterior S. A.' },
            { nombre: 'Banco de la Nacion Argentina' },
            { nombre: 'BANCO DE LA PAMPA' },
            { nombre: 'Banco de La Pampa' },
            { nombre: 'Banco de la Provincia de Buenos Aires' },
            { nombre: 'Banco de la Provincia de Buenos Aires' },
            { nombre: 'Banco de Tierra de Fuego' },
            { nombre: 'Banco de Valores SA' },
            { nombre: 'Banco del Buen Ayre' },
            { nombre: 'Banco Francés' },
            { nombre: 'Banco Galicia y Buenos Aires S.A.' },
            { nombre: 'Banco General de Negocios S.A.' },
            { nombre: 'Banco Hipotecario' },
            { nombre: 'Banco Hipotecario S.A.' },
            { nombre: 'Banco Itaú' },
            { nombre: 'Banco Julio S.A.' },
            { nombre: 'Banco Macro S.A.' },
            { nombre: 'Banco Mariva' },
            { nombre: 'Banco Mercurio S.A.' },
            { nombre: 'BANCO MUNICIPAL DE ROSARIO' },
            { nombre: 'Banco Patagonia S.A.' },
            { nombre: 'Banco Piano' },
            { nombre: 'Banco Privado de Inversiones S.A.' },
            { nombre: 'Banco Regional de Cuyo S.A.' },
            { nombre: 'Banco Río' },
            { nombre: 'Banco Saenz S.A.' },
            { nombre: 'Banco Sudameris Argentina S.A.' },
            { nombre: 'Banco Supervielle' },
            { nombre: 'Banco Suquia S.A.' },
            { nombre: 'Bank Leumi' },
            { nombre: 'Bank of America, NA' },
            { nombre: 'Bank of Tokyo-mitsubishi Ufj' },
            { nombre: 'BankBoston' },
            { nombre: 'Banque Nationale de Paris Argentina' },
            { nombre: 'Banque Safdie' },
            { nombre: 'Bansud' },
            { nombre: 'Barclays Bank Plc' },
            { nombre: 'Barclays Wealth' },
            { nombre: 'Bica Cooperativa de Emprendimientos Múltiples Limitada' },
            { nombre: 'BNP Paribas' },
            { nombre: 'C.C.C. La Capital de Plata' },
            { nombre: 'Citibank (Argentina)' },
            { nombre: 'Citibank Argentina' },
            { nombre: 'Clariden Bank SA' },
            { nombre: 'Corp Banca S.A.' },
            { nombre: 'Credilogros Compania Financiera SA' },
            { nombre: 'Credit Suisse (Investment Banking)' },
            { nombre: 'DaimlerChrysler Financiera' },
            { nombre: 'Deutsche Bank Argentina S.A.' },
            { nombre: 'E-Galicia.com' },
            { nombre: 'Fiat Credito Compania Financiera' },
            { nombre: 'Ford Credit Compania Financiera' },
            { nombre: 'GE Money Bank GmbH' },
            { nombre: 'Goldman Sachs Argentina L.L.C.' },
            { nombre: 'HSBC' },
            { nombre: 'ING Bank N.V. -Branch' },
            { nombre: 'Israel Discount Bank Ltd.' },
            { nombre: 'JOHN DEERE CREDIT COMPAÑIA FINANCIERA' },
            { nombre: 'JPMorgan Chase Bank, National Association' },
            { nombre: 'Lloyds Bank' },
            { nombre: 'Masventas S.A Compania Financiera' },
            { nombre: 'MBA Banco de Investitiones' },
            { nombre: 'Nuevo Banco Bisel S.A.' },
            { nombre: 'Nuevo Banco de Chaco S.A.' },
            { nombre: 'Nuevo Banco de la Rioja S.A.' },
            { nombre: 'Nuevo Banco de Santa Fe' },
            { nombre: 'Nuevo Banco Industrial de Azul' },
            { nombre: 'RCI Banque' },
            { nombre: 'Schroder Mildesa Investment Management S.A.' },
            { nombre: 'Standard Bank' },
            { nombre: 'Standard Chartered Bank' },
            { nombre: 'Toyota Compania Financiera de Argentina S.A' },
            { nombre: 'Tutelar Compania Financiera S.A.' },
            { nombre: 'UBS in Buenos Aires' },
            { nombre: 'Unicredito Italiano' },
            { nombre: 'Volkswagen Argentina' }];
    }
}

export function whatsAppMessage(message, country, number) {
    const ext = extensionPhone(country);
    const url = 'https://wa.me/' + ext + number + '?text=' + encodeURIComponent(message);
    window.open(url, 'blank');
}

export function extensionPhone(country) {
    if (country === '1') {
        return 52;
    }
    if (country === '2') {
        return 57;
    }
    if (country === '3') {
        return 54;
    }
}

export function alertFail(text = 'Ha ocurrido un error. Si el error continúa, pónganse en contacto con: ' + AppEnv.correo_soporte) {
    UIkit.notification({
        message: '<span data-uk-icon=\'icon: warning\'></span> ' + text,
        status: 'danger',
        timeout: 3500
    });
}

export function alertSuccess(text = 'Felicidades, la acción fue completada con éxito') {
    UIkit.notification({
        message: '<span data-uk-icon=\'icon: check\'></span> ' + text,
        status: 'success',
        timeout: 3500
    });
}

export function alertWarning(text = 'Ups, por favor revise la información') {
    UIkit.notification({
        message: '<span data-uk-icon=\'icon: info\'></span> ' + text,
        status: 'warning',
        timeout: 3500
    });
}

export function currency(num, dec = false) {
    if (dec) {
        return Number(num).toLocaleString('es-MX', {
            currency: 'MXN',
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
        });
    } else {
        return Number(num).toLocaleString('es-MX', {
            currency: 'MXN',
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    }
}

export function checkNumber(value) {
    let regex = new RegExp('^[0-9]{10}$');
    return !regex.test(value);
}

export function getDia(value) {
    if (value === '1') {
        return 'Lunes'
    } else if (value === '2') {
        return 'Martes'
    } else if (value === '3') {
        return 'Miércoles'
    } else if (value === '4') {
        return 'Jueves'
    } else if (value === '5') {
        return 'Viernes'
    }
}

export function getDiaZoom(value) {
    if (value === '2') {
        return 'Lunes'
    } else if (value === '3') {
        return 'Martes'
    } else if (value === '4') {
        return 'Miércoles'
    } else if (value === '5') {
        return 'Jueves'
    } else if (value === '6') {
        return 'Viernes'
    }
}

export function checkCp(value) {
    let regex = new RegExp('^[0-9]{4,5}$');
    return !regex.test(value);
}

export function checkEmail(value) {
    let regex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    return !regex.test(value);
}

export function validateCamposVacios(campos) {
    for (let i = 0; i < campos.length; i++) {
        let it = campos[i];
        if (it.value === undefined || it.value === '' || it.value === '1' || it.value === ' ') {
            alertWarning('Revise el campo: ' + it.name);
            return false;
        } else if (it.type === '2') {
            if (checkNumber(it.value)) {
                alertWarning('Revise el campo: ' + it.name);
                return false;
            }
        } else if (it.type === '3') {
            if (checkEmail(it.value)) {
                alertWarning('Revise el campo: ' + it.name);
                return false;
            }
        }
    }

    return true
}

export function MaysPrimera(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function validationUrl(url) {
    let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i');
    if (!pattern.test(url)) {
        alertWarning('Escriba una dirección web válida');
    }
    return pattern.test(url)
}

export function loading(visible, state, func, mensaje, altura = '35vh') {
    return <Row className='uk-width-1-1'> {visible === true ?
        <Row className='uk-width-1-1 centerAll'>
            {pulseLoader()}</Row>
        : state === 0 ? <FaltaMensaje mensaje={mensaje} />
            : <Row style={{ margin: '0 0 10px 0', padding: 0, maxHeight: altura }} className='uk-width-1-1 scroll'>
                {func}
            </Row>}</Row>;
}

export function pulseLoader(color = AppEnv.secondColor) {

    return <Row className=" uk-width-1-1 centerAll">
        <Row className="uk-width-1-1 centerAll"
        >
            <Row className="spinner centerAll"
                style={{ margin: 0, padding: 3 }}>
                <div style={{ backgroundColor: color }} className="bounce1" />
            </Row>
            <Row className="spinner  centerAll"
                style={{ margin: 0, padding: 3 }}>
                <div style={{ backgroundColor: color }} className="bounce2" />
            </Row>
            <Row className="spinner  centerAll"
                style={{ margin: 0, padding: 3 }}>
                <div style={{ backgroundColor: color }} className="bounce3" />
            </Row>
        </Row>
    </Row>
}

export function puestoOrAlias(data) {
    let type;
    if (data.alias !== undefined && data.alias !== '' && data.alias !== null && data.alias !== ' ' && data.alias !== 'null') {
        type = data.alias;
    } else {
        type = data.puesto === 'Secretaria' ? 'Coordinador administrativo' :
            data.puesto === 'Director' ? 'Director' :
                data.puesto === 'Alumno' ? 'Alumno' :
                    data.puesto === 'Maestro' ? 'Maestro' :
                        data.puesto === 'Coordinador Academico' ? 'Coordinador académico' :
                            data.puesto === 'Coordisi' ? 'Coordinador disciplinario' :
                                data.puesto === 'SubDirector' ? 'Subdirector' :
                                    data.puesto === 'Medico' ? 'Médico' :
                                        data.puesto === 'Padre' ? 'Padre' :
                                            data.puesto === 'Prefecto' ? 'Prefecto' : null;
    }
    return type;
}

export function copyToClipboard(text) {
    // text.select();
    navigator.clipboard.writeText(text)
    // document.execCommand('copy');
    alertSuccess('Elemento copiado');
}

function switchNavidad(r) {
    let pic = '';
    switch (r) {
        case 0:
            pic = 'https://galeriasce.s3-us-west-2.amazonaws.com/IMG-Escuelas/kolegia/navidad/1.gif';
            break;
        case 1:
            pic = 'https://galeriasce.s3-us-west-2.amazonaws.com/IMG-Escuelas/kolegia/navidad/2.gif';
            break;
        case 2:
            pic = 'https://galeriasce.s3-us-west-2.amazonaws.com/IMG-Escuelas/kolegia/navidad/3.gif';
            break;
        case 3:
            pic = 'https://galeriasce.s3-us-west-2.amazonaws.com/IMG-Escuelas/kolegia/navidad/4.gif';
            break;
        case 4:
            pic = 'https://galeriasce.s3-us-west-2.amazonaws.com/IMG-Escuelas/kolegia/navidad/2.gif';
            break;
        case 5:
            pic = 'https://galeriasce.s3-us-west-2.amazonaws.com/IMG-Escuelas/kolegia/navidad/6.gif';
    }
    return pic;
}

function switchNormal(r) {
    let pic = '';
    switch (r) {
        case 0:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/dashboard/1.jpg';
            break;
        case 1:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/dashboard/2.jpg';
            break;
        case 2:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/dashboard/3.jpg';
            break;
        case 3:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/dashboard/4.jpg';
            break;
        case 4:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/dashboard/5.jpg';
            break;
        case 5:
            pic = 'https://s3-us-west-2.amazonaws.com/galeriasce/IMG-Escuelas/dashboard/6.jpg';
    }
    return pic;
}

export function randomBackground() {
    let r = Math.floor(Math.random() * 6);
    let season = getSeason()
    let ext = 'jpg'
    let cinco = '5'
    if (season === 'navidad') {
        ext = 'gif'
        cinco = '4'
    }
    let pic = '';
    switch (r) {
        case 0:
            pic = 'https://ksm-s3-globalbucket.s3.amazonaws.com/IMG-Escuelas/kolegia/' + season + '/1.' + ext;
            break;
        case 1:
            pic = 'https://ksm-s3-globalbucket.s3.amazonaws.com/IMG-Escuelas/kolegia/' + season + '/2.' + ext;
            break;
        case 2:
            pic = 'https://ksm-s3-globalbucket.s3.amazonaws.com/IMG-Escuelas/kolegia/' + season + '/3.' + ext;
            break;
        case 3:
            pic = 'https://ksm-s3-globalbucket.s3.amazonaws.com/IMG-Escuelas/kolegia/' + season + '/4.' + ext;
            break;
        case 4:
            pic = 'https://ksm-s3-globalbucket.s3.amazonaws.com/IMG-Escuelas/kolegia/' + season + '/' + cinco + '.' + ext;
            break;
        case 5:
            pic = 'https://ksm-s3-globalbucket.s3.amazonaws.com/IMG-Escuelas/kolegia/' + season + '/6.' + ext;
    }
    return pic;
}

export function getLogo() {
    let season = getSeason()
    if (season !== 'dashboard') {
                 return  "https://ksm-s3-globalbucket.s3.amazonaws.com/IMG-Escuelas/" +
                     AppEnv.app +
                     "/" +
                     season +
                     "/logo.gif"

    } else {
        return "../images/" + AppEnv.app + "/logo.png";
    }
}

export function customAlert(title, message, buttons = 1, msjBtn1 = '', msjBtn2 = '', msjBtn3 = '', btn = {}, btn2 = {}, btn3 = {}) {
    let width = 'uk-width-1-' + buttons;
    return (
        <Grid className={'centerAll'} style={{
            height: '100vh',
            width: '100vw',
            backgroundColor: 'rgba(255,255,255,.95)',
            position: 'absolute',
            top: 0,
            zIndex: 10000,
            margin: 0,
            padding: 0
        }}>
            <Row className="centerAll" style={{
                height: '100vh',
                width: '100vw',
                margin: 0,
                padding: 0
            }}>
                <Row style={{
                    margin: 0,
                    padding: 40,
                    backgroundColor: AppEnv.fourthColor,
                    borderRadius: 10,
                }}
                    className='uk-width-1-2@s uk-width-1-3@m uk-width-1-4@l  centerAll uk-box-shadow-medium'>
                    <Row className='dd uk-width-1-1 centerAll' style={{
                        margin: 0,
                        padding: 0,
                        marginBottom: 8,
                        textAlign: 'center'
                    }}>{title}
                    </Row>
                    <Row className='uk-width-1-1 centerAll normal_text' style={{
                        margin: 0,
                        padding: 0,
                        marginBottom: 15,
                        fontSize: '1.5rem',
                        fontWeight: '400'
                    }}>{message}
                    </Row>
                    <Row className='uk-width-1-1 centerAll' style={{ marginBottom: 5 }}>
                        {buttons >= 1
                            ? <Row className={width + " centerAll"}
                                style={{ margin: 0, padding: 5 }}>
                                <Row
                                    style={{
                                        borderRadius: 10,
                                        border: '1px solid',
                                        borderColor: AppEnv.secondColor,
                                        backgroundColor: buttons === 1 ? AppEnv.secondColor : AppEnv.fourthColor,
                                        color: buttons === 1 ? AppEnv.fourthColor : AppEnv.secondColor,
                                        cursor: 'pointer',
                                        fontSize:'.7rem'
                                    }}
                                    onClick={btn}
                                    className='centerAll uk-width-1-1 uk-button uk-button-small'>
                                    {msjBtn1}
                                </Row>
                            </Row>
                            : null}
                        {buttons >= 2
                            ? <Row className={width + " centerAll"}
                                style={{ margin: 0, padding: 5 }}>
                                <Row
                                    style={{
                                        borderRadius: 10,
                                        border: '1px solid',
                                        borderColor: AppEnv.secondColor,
                                        backgroundColor: AppEnv.secondColor,
                                        color: AppEnv.fourthColor,
                                        textAlign:'center',
                                        cursor: 'pointer',
                                        fontSize:'.7rem'
                                    }}
                                    onClick={btn2}
                                    className='centerAll uk-width-1-1 uk-button uk-button-small'>
                                    {msjBtn2}
                                </Row>
                            </Row>
                            : null}
                        {buttons >= 3
                            ? <Row className={width + " centerAll"}
                                style={{ margin: 0, padding: 5 }}>
                                <Row
                                    style={{
                                        borderRadius: 10,
                                        marginLeft: 15,
                                        border: '1px solid',
                                        borderColor: AppEnv.secondColor,
                                        backgroundColor: AppEnv.secondColor,
                                        color: AppEnv.fourthColor,
                                        textAlign:'center',
                                        cursor: 'pointer',
                                        fontSize:'.7rem'
                                    }}
                                    onClick={btn3}
                                    className='centerAll uk-width-1-1 uk-button uk-button-small'>
                                    {msjBtn3}
                                </Row>
                            </Row>
                            : null}
                    </Row>
                </Row>
            </Row>
        </Grid>
    );
}

export function roles(role) {
    return role === 'Admin'
        ? 'Administradores'
        : role === 'Medico'
            ? 'Médico'
            : role
}

export function switch1(menus, selected, name) {
    let inicio = <Row className='centerAll' style={{
        margin: 0,
        padding: 30
    }}>
        <img alt='' className='lazyload' data-src='https://img.icons8.com/ios/96/000000/shooting-stars.png' />
        <label style={{ textAlign: 'center' }} className='encabezadoDB uk-width-1-1'>Bienvenido,</label><br />
        <label style={{ textAlign: 'center' }} className='encabezadoLigeroDB uk-width-1-1'>
            {name} </label><br />
        <label className='subtituloDB'>
            Para continuar, selecciona una opción del menú que está a la izquierda
        </label>
    </Row>;

    let noDisponible = <Row className='centerAll' style={{
        margin: 0,
        padding: 30
    }}>
        <img alt='' className='lazyload' data-src='https://img.icons8.com/ios/96/000000/maintenance.png' />
        <label style={{ textAlign: 'center' }} className='encabezadoDB uk-width-1-1'>Bienvenido,</label><br />
        <label style={{ textAlign: 'center' }} className='encabezadoLigeroDB uk-width-1-1'>
            {name} </label><br />
        <label className='subtituloDB'>
            Funcionalidad en proceso de desarrollo.
        </label>
    </Row>;

    let loading = <Row className='centerAll' style={{
        margin: 0,
        padding: 60
    }}>
        <Row className='uk-width-1-1 centerAll'>
            {pulseLoader()}
        </Row>
        <label style={{ textAlign: 'center' }} className='encabezadoDB uk-width-1-1'>
            Cargando
        </label>
        <label className='subtituloDB'>
            Por favor espere ...
        </label>
    </Row>;
    return (<Suspense fallback={loading}>
        {menus[selected] !== undefined
            ? {
                'AdmisionesConfig': <AdmisionesConfig subMenu={menus[selected].sub_menu} />,
                'AgendarCitaAdmin': <AgendarCitaAdmin />,
                'AgendarCitaMaestro': <AgendarCitaMaestro />,
                'AgendarCitasPadre': <AgendarCitasPadre />,
                'AgendarCitasPrefecto': <AgendarCitasPrefecto />,
                'AsistenciasAdmin': <AsistenciasAdmin subMenu={menus[selected].sub_menu} />,
                'AsistenciasMaestro': <AsistenciasMaestro subMenu={menus[selected].sub_menu} />,
                'AsistenciasPadre': <AsistenciasPadre />,
                'AsistenciasPrefecto': <AsistenciasPrefecto />,
                'AspirantePendiente': <AdmisionesPadre subMenu={menus[selected].sub_menu} />,
                'CalendarioDeActividadesGlobal': <CalendarioDeActividadesGlobal />,
                'CalificacionesAdmin': <MenuCalificacionesAdmin subMenu={menus[selected].sub_menu} />,
                'CalificacionesMaestro': <MenuCalificacionesMaestro subMenu={menus[selected].sub_menu} />,
                'CalificacionesPadre': <CalificacionesPadre />,
                'CalificacionesPrefecto': <CalificacionesPrefecto />,
                'CalificacionesAlumno': <CalificacionesAlumno />,
                'ControlTutores': <ControlTutores2 />,
                'CrearActividad': <MenuCalendario subMenu={menus[selected].sub_menu} />,
                'DatosContacto': <DatosContacto />,
                'DatosContactoPadre': <DatosContactoPadre />,
                'DatosContactoDocente': <DatosContactoDocente />,
                'ControlDeAlumnos': <ControlDeAlumnos />,
                'MenuPerfilPadre': <MenuPerfilPadre subMenu={menus[selected].sub_menu} />,
                'DirectorioPrefecto': <DirectorioPrefecto />,
                'Encuadre': <Encuadre />,
                'EncuadreAlumno': <EncuadreAlumno />,
                'EncuadreMaestro': <EncuadreMaestro />,
                'EncuadrePadre': <EncuadrePadre />,
                'Galeria': <Galeria />,
                'GaleriaPadre': <GaleriaPadre />,
                'GestDocumentos': <Documentos />,
                'GestDocumentosPadre': <DocumentosPadre />,
                'GestMenuEsc': <GestMenuEsc />,
                'HorariosAlumno': <HorarioAlumno />,
                'HorariosMaestro': <HorariosMaestro />,
                'HorariosPadre': <HorariosPadre />,
                'HorariosPrefecto': <HorariosPrefecto />,
                'InscripcionesAdmin': <InscripcionesAdmin />,
                'MenuActividadesMaestro': <MenuActividadesMaestro subMenu={menus[selected].sub_menu} />,
                'MenuAulaVirtualMaestro': <MenuAulaVirtualMaestro subMenu={menus[selected].sub_menu} />,
                'MenuActividadesTutor': <MenuActividadesTutor subMenu={menus[selected].sub_menu} />,
                'MenuActividadesAlumno': <MenuActividadesAlumno subMenu={menus[selected].sub_menu} />,
                'MenuAulaVirtualAlumno': <MenuAulaVirtualAlumno subMenu={menus[selected].sub_menu} />,
                'MenuActividadesAdmin': <MenuActividadesAdmin subMenu={menus[selected].sub_menu} />,
                'MenuCalificacionesPrefecto': <MenuCalificacionesPrefecto subMenu={menus[selected].sub_menu} />,
                // 'CalificacionesAlumno': <CalificacionesAlumno />,
                'MenuCursos': <MenuCursos />,
                'MenuEscPadre': <MenuEscPadre />,
                'MenuPagos': <MenuPagosPadre />,
                'MenuPagosDir': <MenuPagosDir subMenu={menus[selected].sub_menu} />,
                'MiOrganizacion': <MiOrganizacion subMenu={menus[selected].sub_menu} />,
                'MovimientoDeAlumnos': <MovimientoDeAlumnos subMenu={menus[selected].sub_menu} />,
                'NoticiasAlumno': <NoticiasAlumno />,
                'NoticiasMaestro': <NoticiasMaestro />,
                'NoticiasPadre': <NoticiasPadre />,
                'Notificaciones': <Notificaciones />,
                'NuevosAspirantesAdmin': <ApplicantsMenu />,
                'Parametros': <Parametros subMenu={menus[selected].sub_menu} />,
                'Publicar': <MenuNoticias subMenu={menus[selected].sub_menu} />,
                'ZoomAdmin': <MenuAulaVirtual subMenu={menus[selected].sub_menu} />,
                'ZoomPadre': <ZoomPadre subMenu={menus[selected].sub_menu} />,
                'ZoomMaestro': <ZoomMaestro subMenu={menus[selected].sub_menu} />,
                'ZoomAlumno': <ZoomAlumno subMenu={menus[selected].sub_menu} />,
                'Store': <Store subMenu={menus[selected].sub_menu} />,
                'Inventory': <Inventory subMenu={menus[selected].sub_menu} />,
                'GestionarMensajes': <Conversations />,
                'Tests': <SubMenus subMenu={menus[selected].sub_menu} />,
                'Quizzes': <SubMenus subMenu={menus[selected].sub_menu} />,
                'MenuTramitesTutor': <SubMenus subMenu={menus[selected].sub_menu} />,
                'MenuTramites': <SubMenus subMenu={menus[selected].sub_menu} />,
                'CovidSurvey': <CovidSurvey />,
                'MenuCovid': <MenuCovidAdmin subMenu={menus[selected].sub_menu} />,

            }[menus[selected].web] || noDisponible
            : inicio
        }
    </Suspense>);
}

function rgbToYIQ({ r, g, b }) {
    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
}

function hexToRgb(hex) {
    if (!hex || hex === '') {
        return undefined;
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : undefined;
}

export function contrast(colorHex, threshold = 128) {
    if (colorHex === undefined) {
        return '#000';
    }

    const rgb = hexToRgb(colorHex);

    if (rgb === undefined) {
        return '#000';
    }

    return rgbToYIQ(rgb) >= threshold ? '#000' : '#fff';
}

export function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function getFirstLetters(str) {
    if (str !== "" && str !== undefined) {
        let matches = str.match(/\b(\w)/g);
        return matches.join('');
    } else {
        return "UR"
    }
}


export async function sendReport(data) {
    try {
        await axios.post('https://api.kolegia.com/error/report', data)
    } catch (e) {
        console.error('error => ', e)
    }
    return data;
}

export async function SendReport(data) {
    const {
        bd,
        userId,
        userName,
        userPuesto,
        userRole,
        schoolData,
    } = useSelector(state => state.rooot);
    try {
        const data1 = {
            "errorReport": {
                "error": error,
                "errorInfo": JSON.stringify(error),
                "database": bd,
                "userId": userId,
                "role": userRole,
                "jobPosition": userPuesto,
                "level": null,
                "group": null,
                "name": userName,
                "schoolId": schoolData.id,
                "description": data.description,
                "function": data.func,
                "component": data.component,
                "parameters": data.params
            }
        };
        await axios.post(AppEnv.url + '/error/report', data1)
    } catch (e) {
        console.error('error => ', e)
    }
    return data;
}


