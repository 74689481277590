import {
    GET_HORARIO_ALUMNO,
    GET_HORARIO_MAESTRO,
    GET_HORARIO_PADRE,
    GET_HORARIO_PREFECTO,
    LOADING_HORARIO,
} from '../../../actions/types';

const initialState = {
        horario_alumno: [],
        horario_maestro: [],
        horario_padre: [],
        horario_prefecto: [],
        loading: false,
    }
;

export default function HorariosReducer(state = initialState, action) {

    switch (action.type) {
        case GET_HORARIO_MAESTRO:
            return {
                ...state,
                horario_maestro: action.payload,
                loading: false
            };
        case GET_HORARIO_PADRE:
            return {
                ...state,
                horario_padre: action.payload,
                loading: false
            };
        case GET_HORARIO_ALUMNO:
            return {
                ...state,
                horario_alumno: action.payload,
                loading: false
            };
        case GET_HORARIO_PREFECTO:
            return {
                ...state,
                horario_prefecto: action.payload,
                loading: false
            };
        case LOADING_HORARIO:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
