import {
   ACUMULADOS_ALUMNOS,
   LOADING_ACUMULADOS
} from '../../actions/types';

const initialState = {
   loading: false,
   acumulados_alumnos: []
};

export default function AcumuladosReducer(state = initialState, action) {
   switch (action.type) {
      case LOADING_ACUMULADOS:
         return {
            ...state,
            loading: action.payload,
         };
      case ACUMULADOS_ALUMNOS:
         return {
            ...state,
            acumulados_alumnos: action.payload,
            loading: false
         };
      default:
         return {
            ...state,
            loading: false,
         };
   }
}
