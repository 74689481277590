import React, {useContext, useEffect, useState} from "react";
import KolegiaBroadcastContext from "../../../Context/KolegiaBroadcast";
import {useDispatch, useSelector} from "react-redux";
import {Row} from "react-material-responsive-grid";
import {
    changeMainStream,
    RoomId,
    setCurrentItemClass,
    SetTeachers,
    studentsInClass,
    updateMediaTeacher
} from "../../../redux/actions/VirtualClassActions/VirtualClassActions";
import {alertWarning, suspenseFallback} from "../../Global-W/NewFunctions";
import VideoBox from "./VideoBox";

export default function VirtualClassAdmin() {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)

        }, 1000)

    }, [])
    return <Row className={'uk-width-1-1 centerAll'}> {loading ? suspenseFallback() : <VirtualClassAdminTime/>} </Row>
}

const VirtualClassAdminTime = () => {
    const {
        socketRef,
        subscribe,
        handleDisconnectConsumer
    } = useContext(KolegiaBroadcastContext);
    const {
        userId
    } = useSelector(state => state.rooot)
    const {
        remoteVideos,
    } = useSelector(state => state.virtual_class)
    const dispatch = useDispatch()


    useEffect(() => {
        if (socketRef.current !== undefined) {
            socketRef.current.on('notification_exists', (data) => {
                if (data.requesting.toString() === userId.toString()) {
                    dispatch(updateMediaTeacher({prop: 'buttons', value: true, response: true, user_id: data.user_id}))
                    alertWarning(data.name + ' ya tiene una solicitud de ' + data.admin + ' para ' + (data.type === 'camera' ? 'encender su cámara' : 'encender su micrófono') + ' y aún no la ha respondido. Por favor espere a que la responda')
                }
            })

        }
        return () => {
            dispatch(changeMainStream(null))
            dispatch(RoomId(''))
            dispatch(SetTeachers([]))
            dispatch(setCurrentItemClass(null))
            dispatch(studentsInClass([]))
            handleDisconnectConsumer()
        }
    }, [socketRef]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Row className="uk-width-1-1 scroll">
            <Row className="uk-width-1-1@m">
                <VideoBox connectToClass={(state) => {
                    if (state) {
                        subscribe('AdminDashBoard');
                    }
                }}
                          sendDisconnect={() => handleDisconnectConsumer()}
                />
            </Row>
        </Row>
    )
}
