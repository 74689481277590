import {Col, Row} from "react-material-responsive-grid";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Dropzone from "react-dropzone";
import {alertWarning, pulseLoader} from "../../../Global-W/NewFunctions";
import Modal from "../../../Global-W/Hooks/Modal";
import ImageZoom from "../../../Global-W/Hooks/ImageZoom";
import ReactPlayer from "react-player";
import {setModalActivityOnClass} from "../../../../redux/actions";
import FaltaMensaje from "../../../Global-W/Classes/FaltaMensaje";
import KolegiaBroadcastContext from "../../../../Context/KolegiaBroadcast";
import Switch from "react-switch";

const moment = require('moment');
require('moment/locale/es');
moment.locale('es');

export default function ChatViewBeta() {
    const {socketRef, newMessageRecive, sendNewMessage} = useContext(KolegiaBroadcastContext)
    const bottomRef = useRef(null);
    const [newMessage, setNewMessage] = useState('');
    const [messagesRoom, setMessagesRoom] = useState([]);
    const [file, setFile] = useState([]);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [openModalFile, setOpenModalFile] = useState(false);
    const [fileMessage, setFileMessage] = useState({});
    const dispatch = useDispatch()
    const {
        userId,
        userRole,
        grayColorOne,
        kolegiaBlue,
        grayColorTwo,
        fourthColor,
        fifthColor,
        bd,
        app,
        token,
        websocket,
        userName,
        grayColorThree
    } = useSelector(state => state.rooot);
    const {
        room_id,
        messages,
        students,
        classExist,
        classData,
        chatStatus,
        start_transmission,
        currentItemClass
    } = useSelector(state => state.virtual_class);

    useEffect(() => {
        setMessagesRoom(messages.filter((it => it.type_message !== 2)));
    }, [messages])

    useEffect(() => {
        if (newMessageRecive !== undefined) {
            if (newMessageRecive.type_message !== 2) {
                setMessagesRoom(messagesRoom => [...messagesRoom, newMessageRecive]);
            }
        }
    }, [newMessageRecive]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (messagesRoom.length !== 0) {
            scrollToBottom()
        }
    }, [messagesRoom]) // eslint-disable-line react-hooks/exhaustive-deps

    const sendMessage = (messageBody) => {
        sendNewMessage(messageBody);
        setNewMessage("");
        setFile([])
        setSendingMessage(false)
    }

    const uploadFile = async (id, messageBody) => {
        const fd = new FormData();
        fd.append('app', app)
        fd.append('bd', bd)
        fd.append('activity_type', 'messages')
        fd.append('id', id)
        fd.append('file', file[0])
        const {data} = await axios.post(websocket + '/post/multimedia', fd, {
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        });

        await sendMessage({
            ...messageBody,
            file: data.file,
            type: data.file_type,
            file_name: data.name

        })
    }

    const handleSendMessage = () => {
        try {
            setSendingMessage(true);
            const bodyMessage = {
                body: newMessage,
                name: userName,
                room_id: room_id,
                user_id: userId,
                senderId: socketRef.current.id,
                bd_school: bd,
                id_subject: userRole === 'Admin' ? currentItemClass.id_materia : classData.relacion.id_materia,
                type_message: 1,
                date: moment().format('YYYY-MM-DD HH:mm:ss')
            }
            if (file.length === 0 && newMessage !== "") {
                sendMessage({...bodyMessage, file: null});
            } else if (file.length !== 0) {
                postMessage(bodyMessage)
            } else {
                setSendingMessage(false);
            }
        } catch (e) {

        } finally {
            setNewMessage('');
        }
    };

    const postMessage = async (messageBody) => {
        const {data} = await axios.post(websocket + '/post/message', messageBody, {
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        })
        await uploadFile(data.insertId, messageBody)
    }

    const renderMessages = messagesRoom.filter((it => it.type_message !== 2)).map((message, i) => {
        function findStudentColor(select) {
            return Number(select.id) === Number(message.user_id);
        }

        let ixStudent = students.indexOf(students.find(findStudentColor));

        let dateMessage = moment(message.date).local().format('HH:mm')
        let isSameUser = Number(message.user_id) === Number(userId);
        let isHttps = /(https?:\/\/[^\s]+)/g;
        let isHttp = /(https?:\/\/[^\s]+)/g;
        let isActividad = /(Se ha creado una actividad para la clase con el titulo: .*)/g;
        let isUrl = false;
        let isActivity = false;
        if ((isHttps.test(message.body) || isHttp.test(message.body)) && (message.body.indexOf('http') === 0 || message.body.indexOf('https') === 0)) {
            isUrl = true;
        }
        if (isActividad.test(message.body)) {
            isActivity = true;
        }
        return <Row key={i} className="uk-width-1-1" style={{margin: 0, padding: 2}}>
            <Row className="normal_text"
                 style={{
                     margin: 0,
                     borderRadius: 6,
                     padding: 4,
                     backgroundColor: isSameUser ? kolegiaBlue : fourthColor,
                     marginLeft: isSameUser ? 'auto' : 0,
                     marginRight: !isSameUser ? 'auto' : 0,
                     textAlign: "left",
                 }}
            >
                <Col>
                    {!isSameUser ?
                        <Row>
                            <label
                                className="uk-width-1-1 uk-text-truncate"
                                style={{
                                    textAlign: 'left',
                                    fontWeight: '700',
                                    fontSize: '.7rem',
                                    color: messagesRoom.length !== 0 && ixStudent !== -1 && students.length !== 0
                                        ? students[ixStudent].back_color
                                        : kolegiaBlue,
                                    marginBottom: 2
                                }}>
                                {message.name}
                            </label> </Row> : null}
                    <Row>
                        <label
                            className="uk-width-1-1"
                            onClick={() => isActivity ? dispatch(setModalActivityOnClass(true)) : null}
                            style={{
                                textAlign: 'left',
                                lineHeight: 1,
                                fontSize: '.8rem',
                                color: isSameUser ? fourthColor : fifthColor,
                                wordBreak: 'break-word',
                                cursor: isActivity ? "pointer" : null
                            }}>
                            {isUrl
                                ? <a class="uk-link-reset" href={message.body} target={'blank'}>
                                    {message.body}
                                </a>
                                : message.body}
                        </label>
                    </Row>
                    {message.file !== null
                        ? message.type === 'jpg' || message.type === 'png' || message.type === 'jpeg' || message.type === 'heic' || message.type === 'gif'
                            ? <Row style={{maxHeight: 200, maxWidth: 200, margin: '5px 0'}}>
                                <div onClick={() => {
                                    setOpenModalFile(true);
                                    setFileMessage({
                                        file: message.file,
                                        type: message.type,
                                        file_name: message.file_name
                                    });
                                }}>
                                    <img style={{
                                        objectFit: 'cover',
                                        maxHeight: 200,
                                        maxWidth: 200,
                                        borderRadius: 10
                                    }}
                                         loading={"lazy"}
                                         src={message.file} alt=""/>
                                </div>
                            </Row>
                            : message.type === 'docx'
                            || message.type === 'pdf'
                            || message.type === 'pptx'
                            || message.type === 'xlsx'
                            || message.type === "vnd.openxmlformats-officedocument.wordprocessingml.document"
                            || message.type === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            || message.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                ? <Row data-uk-tooltip={message.file_name}>
                                    <div onClick={() => {
                                        setOpenModalFile(true);
                                        setFileMessage({
                                            file: message.file,
                                            type: message.type,
                                            file_name: message.file_name
                                        });
                                    }}>
                                        <Row className="uk-width-1-1">
                                            {message.type === "docx" || message.type === "vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                ? <img alt=""
                                                       loading={"lazy"}
                                                       src="https://img.icons8.com/color/48/000000/microsoft-word-2019--v2.png"/>
                                                : message.type === "pptx" || message.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                                    ? <img alt=""
                                                           loading={"lazy"}
                                                           src="https://img.icons8.com/color/48/000000/microsoft-powerpoint-2019.png"/>
                                                    : message.type === 'xlsx' || message.type === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                        ? <img alt=""
                                                               loading={"lazy"}
                                                               src="https://img.icons8.com/color/48/000000/microsoft-excel-2019--v1.png"/>
                                                        : message.type === 'pdf'
                                                            ?
                                                            <img alt=""
                                                                 loading={"lazy"}
                                                                 src="https://img.icons8.com/color/48/000000/pdf.png"/>
                                                            : 'Kha?'}
                                        </Row>
                                        <Row className='uk-width-1-1'>
                                            <label className='uk-text-truncate' style={{
                                                textAlign: 'right',
                                                color: isSameUser ? fourthColor : fifthColor,
                                                fontSize: '.65rem',
                                                marginTop: 2,
                                                width: 80
                                            }}>
                                                {message.file_name}
                                            </label>
                                        </Row>
                                    </div>
                                </Row>
                                : message.type === 'mp4'
                                    ? <Row style={{maxHeight: 200, maxWidth: 200, margin: '5px 0'}}>
                                        <div onClick={() => {
                                            setOpenModalFile(true);
                                            setFileMessage({
                                                file: message.file,
                                                type: message.type,
                                                file_name: message.file_name
                                            });
                                        }}>
                                            <video preload={false} autoPlay={false} controls={true}
                                                   className='uk-width-1-1'
                                                   src={message.file}/>
                                        </div>
                                    </Row>
                                    : null
                        : null}
                    <Row>
                        <label
                            className="uk-width-1-1"
                            style={{
                                textAlign: 'right',
                                color: isSameUser ? fourthColor : fifthColor,
                                fontSize: '.65rem',
                                marginTop: 2
                            }}>
                            {dateMessage}
                        </label>
                    </Row>
                </Col>
            </Row>
        </Row>
    });


    const scrollToBottom = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
    };

    const getFiles = async (files) => {
        if (files.length !== 0) {
            setFile(files)
        } else {
            alertWarning('Solo se permiten archivos con un peso maximo de 15mb');
        }
    }

    const _handleKeyDown = (e) => {
        e.preventDefault();
        handleSendMessage()
        e.target.reset();
    }

    const disabledChat = () => {
        return <Row className="uk-width-1-1 centerAll"
                    data-uk-tooltip={start_transmission ? "Chat desactivado por el docente" : userRole === 'Admin' ? "Chat desactivado" : "Chat desactivado únase a la clase"}
                    style={{
                        height: '8%',
                        backgroundColor: grayColorThree,
                        borderRadius: '0 0 15px 0'
                    }}>
            <Row className="uk-width-1-8 centerAll "
                 style={{
                     cursor: 'not-allowed',
                 }}>
                <i className={'fas fa-paperclip'}/>
            </Row>

            <Row className="uk-width-3-4 centerAll">
                <form className="uk-width-1-1 centerAll">
                    <input
                        // maxLength={500}
                        disabled={true}
                        placeholder="Chat desactivado..."
                        className="uk-width-1-1 normal_text centerAll uk-textarea"
                        style={{
                            textAlign: 'left',
                            backgroundColor: fourthColor,
                            lineHeight: 1,
                            padding: 5,
                            borderRadius: 5,
                            minHeight: 25,
                            maxHeight: 30,
                            color: fifthColor,
                            resize: 'none',
                            cursor: 'not-allowed'
                        }} value={newMessage}/>
                </form>
            </Row>
            <Row className="uk-width-1-8 centerAll "
                 style={{
                     padding: 5,
                     cursor: 'not-allowed',
                 }}>
                <img alt="" src={"https://img.icons8.com/material-rounded/20/303030/filled-sent.png"}
                     style={{transform: 'rotate(45deg)'}}/>
            </Row>
        </Row>
    }

    const enabledChat = () => {
        return <Row className="uk-width-1-1 centerAll"
                    style={{
                        height: '8%',
                        backgroundColor: grayColorThree,
                    }}>
            <Row className="uk-width-1-8 centerAll "
                 style={{margin: 0, padding: 5, cursor: 'pointer'}}
                 data-uk-tooltip={"Adjuntar"}>
                <Dropzone
                    multiple={false}
                    maxSize={30000000}
                    accept={'.jpg,.gif,.png,.jpeg,.pdf,.xlsx,.pptx,.docx,.xls,.ppt,.doc,.webm,.mp3,.midi,.wma,.heic,.heif'}
                    onDrop={(files) => getFiles(files)}>
                    {({getRootProps, getInputProps}) => (
                        <Row className='centerAll uk-width-1-1'
                             {...getRootProps()}>
                            <input {...getInputProps()} />
                            <i className={'fas fa-paperclip'}/>
                        </Row>
                    )}
                </Dropzone>
            </Row>

            <Row className="uk-width-3-4 centerAll"
            >
                <form className="uk-width-1-1 centerAll" onSubmit={_handleKeyDown}>
                    <input
                        maxLength={500}
                        placeholder="Escriba su mensaje aquí"
                        className="uk-width-1-1 normal_text centerAll uk-textarea"
                        onChange={(e) => setNewMessage(e.target.value)}
                        style={{
                            textAlign: 'left',
                            backgroundColor: fourthColor,
                            lineHeight: 1,
                            padding: 5,
                            borderRadius: 5,
                            minHeight: 25,
                            maxHeight: 30,
                            color: fifthColor,
                            resize: 'none',
                        }} value={newMessage}/>
                </form>
            </Row>
            <Modal
                size={'medium-modal'}
                open={openModalFile}
                onClose={() => setOpenModalFile(false)}
            >
                {fileMessage.file !== undefined
                    ? <Row className="uk-width-1-1 centerAll uk-height-1-1"
                           style={{
                               margin: 0,
                               padding: 0,
                               backgroundColor: grayColorOne,
                           }}>

                        {fileMessage !== undefined && fileMessage.type === 'jpg' || fileMessage.type === 'png' || fileMessage.type === 'jpeg' || fileMessage.type === 'heic'
                            ? <ImageZoom
                                src={fileMessage.file}
                            /> : null}
                        {fileMessage !== undefined && fileMessage.type === 'docx' || fileMessage.type === 'pdf' || fileMessage.type === 'pptx' || fileMessage.type === 'xlsx' || fileMessage.type === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            ? fileMessage.type !== undefined && fileMessage.type !== 'pdf'
                                ? <iframe title="video 1" style={{height: '70vh'}}
                                          className='uk-width-1-1 uk-height-1-1'
                                          src={'https://view.officeapps.live.com/op/embed.aspx?src=' + fileMessage.file}
                                />
                                :
                                <div className='uk-width-1-1 uk-height-1-1'>
                                    <iframe title="video 1" style={{height: '70vh', width: '100%'}}
                                            className='uk-width-1-1 uk-height-1-1'
                                            src={"https://docs.google.com/viewer?url=" + fileMessage.file + "&embedded=true"}
                                    />
                                </div>
                            : null}
                        {fileMessage !== undefined && fileMessage.type === 'mp4'
                            ? <ReactPlayer
                                height={250}
                                style={{backgroundColor: fourthColor}}
                                controls
                                url={fileMessage.file}/> : null}
                    </Row> : null}
            </Modal>
            <Row className="uk-width-1-8 centerAll "
                 style={{
                     margin: 0,
                     padding: 5,
                     cursor: 'pointer',
                     pointerEvents: sendingMessage ? 'none' : null
                 }}
                 data-uk-tooltip={"Enviar"}
                 onClick={() => {
                     handleSendMessage()
                 }}>
                {sendingMessage
                    ? pulseLoader()
                    : <img alt="" src={"https://img.icons8.com/material-rounded/20/303030/filled-sent.png"}
                           style={{transform: 'rotate(45deg)'}}/>}
            </Row>
        </Row>
    }

    return classExist || userRole === 'Admin'
        ? <Row className="uk-width-1-1 centerAll"
               style={{
                   height: '100%',
                   borderRadius: 6,
                   overflow: 'hidden'
               }}
        >
            <Row className="uk-width-1-1 centerAll"
                 style={{padding: '2px 2px 2px 10px', height: '8%', backgroundColor: grayColorThree}}>
                <label className={userRole === "Maestro"
                    ? "uk-width-5-6 section_title_two"
                    : "uk-width-1-1 section_title_two"} style={{textAlign: 'left'}}>
                    CHAT DE LA CLASE
                </label>
                {userRole === "Maestro"
                    ? <Row data-uk-tooltip={chatStatus ? 'Desactivar chat a los alumno' : "Activar chat a los alumnos"}
                           className="uk-width-1-6 centerAll">
                        <Switch
                            checked={chatStatus}
                            onChange={async () => {
                                const bodyMessage = {
                                    body: !chatStatus ? 'El docente habilitó el chat' : 'El docente deshabilitó el chat',
                                    name: userName,
                                    room_id: room_id,
                                    user_id: userId,
                                    senderId: socketRef.current.id,
                                    bd_school: bd,
                                    id_subject: classData.relacion.id_materia,
                                    type_message: 2,
                                    date: moment().format('YYYY-MM-DD HH:mm:ss')
                                }
                                sendNewMessage(bodyMessage)
                                socketRef.current.emit("chat_change", {
                                    room_id: room_id,
                                    status: !chatStatus
                                })
                            }}
                            className="centerAll" offColor="#606060" onColor="#00dd00" id="normal-switch"
                            uncheckedIcon={false} checkedIcon={false} height={18} width={35}/>
                    </Row>
                    : null}
            </Row>
            <Col className="uk-width-1-1  scroll-chat"
                 style={{
                     margin: 0,
                     padding: 5,
                     backgroundColor: grayColorOne,
                     height: file.length !== 0 ? 'calc(99% - 90px - 5%)' : 'calc(99% - 45px - 5%)'
                 }}>
                {messagesRoom.length !== 0 ? renderMessages : null}
                <div ref={bottomRef} style={{height: 10}}/>
            </Col>

            {file.length !== 0
                ? <Row className="uk-width-1-1 centerAll normal_text"
                       style={{
                           height: 45,
                           backgroundColor: grayColorTwo,
                           padding: '0 10px'
                       }}
                >

                    {file[0].type === 'image/jpg' || file[0].type === 'image/jpeg' || file[0].type === 'image/png'
                        ? <Row className='uk-width-1-1'
                               style={{borderRadius: 6, backgroundColor: fourthColor, padding: 5}}>
                            <Row className='uk-width-1-6 centerAll'>
                                <img style={{height: 35, width: 35, borderRadius: 6}}
                                     src={URL.createObjectURL(file[0])}
                                     alt=""/>
                            </Row>
                            <Row className='uk-width-2-3 centerAll'>
                                <label className='uk-text-truncate normal_text'>
                                    {file[0].name}
                                </label>
                            </Row>
                            <Row style={{cursor: 'pointer'}} className='uk-width-1-6 centerAll'
                                 onClick={() => setFile([])}>
                                X
                            </Row>
                        </Row>
                        : <Row className='uk-width-1-1'
                               style={{borderRadius: 6, backgroundColor: fourthColor, padding: 5}}>
                            <Row className='uk-width-5-6 centerAll'>
                                <label className='uk-text-truncate normal_text'>
                                    {file[0].name}
                                </label>
                            </Row>
                            <Row style={{cursor: 'pointer'}} className='uk-width-1-6 centerAll'
                                 onClick={() => setFile([])}>
                                X
                            </Row>
                        </Row>}
                </Row>
                : null}

            {userRole === "Maestro" || userRole === 'Admin'
                ? enabledChat()
                : start_transmission
                    ? chatStatus
                        ? enabledChat()
                        : null
                    : null}

            {userRole === "Maestro" || userRole === 'Admin'
                ? null
                : start_transmission
                    ? chatStatus
                        ? null
                        : disabledChat()
                    : disabledChat()}

        </Row>
        : <Row className="uk-width-1-1 centerAll"
               style={{
                   height: '100%',
                   borderRadius: 6,
                   overflow: 'hidden'
               }}
        >
            <Row className="uk-width-1-1 centerAll" style={{padding: 5, height: '8%', backgroundColor: grayColorOne}}>
                <label className="uk-width-1-1 section_title_two" style={{textAlign: 'left'}}>
                    CHAT DE LA CLASE
                </label>
            </Row>
            <Col className="uk-width-1-1 centerAll scroll-chat"
                 style={{
                     margin: 0,
                     padding: 5,
                     backgroundColor: grayColorTwo,
                     height: 'calc(100% - 5%)'
                 }}>
                <FaltaMensaje mensaje={"Sin clases"}/>
            </Col>


            {file.length !== 0
                ? <Row className="uk-width-1-1 centerAll normal_text"
                       style={{
                           height: 45,
                           backgroundColor: grayColorTwo,
                           padding: '0 10px'
                       }}>

                    {file[0].type === 'image/jpg' || file[0].type === 'image/jpeg' || file[0].type === 'image/png'
                        ? <Row className='uk-width-1-1'
                               style={{borderRadius: 6, backgroundColor: fourthColor, padding: 5}}>
                            <Row className='uk-width-1-6 centerAll'>
                                <img style={{height: 35, width: 35, borderRadius: 6}}
                                     src={URL.createObjectURL(file[0])}
                                     alt=""/>
                            </Row>
                            <Row className='uk-width-2-3 centerAll'>
                                <label className='uk-text-truncate normal_text'>
                                    {file[0].name}
                                </label>
                            </Row>
                            <Row style={{cursor: 'pointer'}} className='uk-width-1-6 centerAll'
                                 onClick={() => setFile([])}>
                                X
                            </Row>
                        </Row>
                        : <Row className='uk-width-1-1'
                               style={{borderRadius: 6, backgroundColor: fourthColor, padding: 5}}>
                            <Row className='uk-width-5-6 centerAll'>
                                <label className='uk-text-truncate normal_text'>
                                    {file[0].name}
                                </label>
                            </Row>
                            <Row style={{cursor: 'pointer'}} className='uk-width-1-6 centerAll'
                                 onClick={() => setFile([])}>
                                X
                            </Row>
                        </Row>}
                </Row>
                : null}
        </Row>

}
