import {
    ALUMNOS_GG,
    CALIFICACIONES,
    COMENTARIOS_PREFECTO,
    CONCENTRADO_CALIFS,
    CONVERTIONS,
    CONVERTIONS_MAESTRO,
    CONVERTIONS_MATERIA,
    LOADING_CALIFICACIONES,
    MATERIAS,
    PROMEDIO_ANUAL,
    RANKINGS_ALUMNOS,
    SEARCH_CALIF_SCHEDULE,
    THE_CALIFICACIONES
} from '../../actions/types';

const initialState = {
    alfabetico: [],
    alias_boleta: 'Boleta',
    alumnos: [],
    calificaciones: [],
    colegiatura_calificaciones: true,
    comentarios_prefecto: [],
    comments_prefecto: true,
    conversiones_maestro: [],
    conversiones_materia: [],
    dos_decimales: false,
    download_colegiatura_calificaciones: false,
    fecha_calificaciones: '2080-01-01',
    firmas: JSON.stringify([]),
    firmas_planes: JSON.stringify([]),
    indicadores: false,
    indicadores_generales: false,
    loading: false,
    materias: [],
    minima_aprobatoria: 6,
    minima_boleta: 5,
    mostrar_calificaciones: true,
    mostrar_fecha_calificaciones: true,
    mostrar_promedio_area: false,
    ninguno: true,
    niveles: [],
    numerico: [],
    promedio_anual: 0.00,
    tabla_indicadores: false,
    tabla_observaciones: false,
    tipo: 'numerico',
    titulo_boleta: 'Boleta de calificaciones',
    un_decimal: false,
    conversiones: [
        {numerico: '10', alfabetico: 'A', nivel: 'IV'},
        {numerico: '9', alfabetico: 'B', nivel: 'III'},
        {numerico: '8', alfabetico: 'B', nivel: 'III'},
        {numerico: '7', alfabetico: 'C', nivel: 'II'},
        {numerico: '6', alfabetico: 'C', nivel: 'II'},
        {numerico: '5', alfabetico: 'D', nivel: 'I'},
    ],
    tipos_selected: [
        {nombre: 'Numérico', tipo: 'numerico'},
        {nombre: 'Alfabético', tipo: 'alfabetico'},
        {nombre: 'Nivel', tipo: 'nivel'},
    ],
    mostrar_faltas: false,
    tipo_faltas: 'calculadas',
    alias_falta: 'inasistencias',
    cantidad_deudas_posibles: '1',
    mostrar_ranking: true,
    ranking_por_materia: true,
    ranking_por_grado: true,
    ranking_por_grupo: true,
    rankings_alumnos: [],
    periodos_ranking: [],
    periodos_ranking_extra: [],
    alias_ranking: '',
    search_calif_schedule: [],
    mostrar_resultados: true,
    mostrar_resultados_area: true,
    mostrar_promedio_general: false,
    mostrar_firma_tutor: false,
    mostrar_firma_prefecto: false,
    mostrar_firma_maestro: false,
    concentrado_califs: [],
    restriction_quizzes: false,
    sello: '',
    paper_size: 'letter',
    paper_width: 21.6,
    paper_height: 27.9,
}

export default function CalificacionReducer(state = initialState, action) {

    switch (action.type) {
        case CALIFICACIONES:
            return {
                ...state,
                alias_boleta: action.alias_boleta,
                colegiatura_calificaciones: action.colegiatura_calificaciones,
                comments_prefecto: action.comments_prefecto,
                comments_maestro: action.comments_maestro,
                mostrar_faltas: action.mostrar_faltas,
                mostrar_ranking: action.mostrar_ranking,
                tipo_faltas: action.tipo_faltas,
                alias_falta: action.alias_falta,
                dos_decimales: action.dos_decimales,
                download_colegiatura_calificaciones: action.download_colegiatura_calificaciones,
                fecha_calificaciones: action.fecha_calificaciones,
                firmas: action.firmas,
                firmas_planes: action.firmas_planes,
                indicadores: action.indicadores,
                indicadores_generales: action.indicadores_generales,
                loading: false,
                minima_aprobatoria: action.minima_aprobatoria,
                minima_boleta: action.minima_boleta,
                mostrar_calificaciones: action.mostrar_calificaciones,
                mostrar_fecha_calificaciones: action.mostrar_fecha_calificaciones,
                ninguno: action.ninguno,
                periodos_fechas: action.periodos_fechas,
                tabla_indicadores: action.tabla_indicadores,
                tabla_observaciones: action.tabla_observaciones,
                titulo_boleta: action.titulo_boleta,
                un_decimal: action.un_decimal,
                cantidad_deudas_posibles: action.cantidad_deudas_posibles,
                periodos_ranking: action.periodos_ranking,
                periodos_ranking_extra: action.periodos_ranking_extra,
                ranking_por_materia: action.ranking_por_materia,
                ranking_por_grado: action.ranking_por_grado,
                ranking_por_grupo: action.ranking_por_grupo,
                alias_ranking: action.alias_ranking,
                mostrar_promedio_area: action.mostrar_promedio_area,
                mostrar_resultados: action.mostrar_resultados,
                mostrar_resultados_area: action.mostrar_resultados_area,
                mostrar_firma_tutor: action.mostrar_firma_tutor,
                mostrar_firma_prefecto: action.mostrar_firma_prefecto,
                mostrar_firma_maestro: action.mostrar_firma_maestro,
                mostrar_logo_oficial: action.mostrar_logo_oficial,
                mostrar_promedio_general: action.mostrar_promedio_general,
                sello: action.sello,
                restriction_quizzes: action.restriction_quizzes,
                paper_size: action.paper_size,
                paper_width: action.paper_width,
                paper_height: action.paper_height,
            };
        case CONVERTIONS:
            return {
                ...state,
                alfabetico: action.alfabetico,
                conversiones: action.conversiones,
                loading: false,
                niveles: action.niveles,
                numerico: action.numerico,
                tipo: action.tipo,
                tipos_selected: action.tipos_selected,
            };
        case ALUMNOS_GG:
            return {
                ...state,
                alumnos: action.payload,
                loading: false,
            };
        case CONVERTIONS_MAESTRO:
            return {
                ...state,
                conversiones_maestro: action.payload,
                loading: false,
            };
        case CONVERTIONS_MATERIA:
            return {
                ...state,
                alfabetico: action.alfabetico,
                conversiones: action.conversiones,
                loading: false,
                niveles: action.niveles,
                numerico: action.numerico,
                tipo: action.tipo,
                tipos_selected: action.tipos_selected,
            };
        case LOADING_CALIFICACIONES:
            return {
                ...state,
                loading: action.payload,
            };
        case PROMEDIO_ANUAL:
            return {
                ...state,
                promedio_anual: action.payload,
                loading: false,
            };
        case THE_CALIFICACIONES:
            return {
                ...state,
                calificaciones: action.payload,
                loading: false,
            };
        case MATERIAS:
            return {
                ...state,
                materias: action.payload,
                loading: false,
            };
        case COMENTARIOS_PREFECTO:
            return {
                ...state,
                comentarios_prefecto: action.payload,
                loading: false,
            };
        case RANKINGS_ALUMNOS:
            return {
                ...state,
                rankings_alumnos: action.payload,
                loading: false,
            };
        case SEARCH_CALIF_SCHEDULE:
            return {
                ...state,
                search_calif_schedule: action.payload,
                loading: false,
            };
        case CONCENTRADO_CALIFS:
            return {
                ...state,
                concentrado_califs: action.payload,
                loading: false,
            };
        default:
            return {
                ...state,
                loading: false,
            };
    }
}
