export const AppEnv = {
  // <=<●>=><=<●>=><=<●>=> Websockets event <=<●>=><=<●>=><=<●>=>

  // <=<●>=><=<●>=><=<●>=> Urls <=<●>=><=<●>=><=<●>=>
  url: process.env.REACT_APP_URL,
  api: process.env.REACT_APP_API,
  apiGes: process.env.REACT_APP_APIGES,
  websocket: process.env.REACT_APP_WEBSOCKET,
  testsApi: process.env.REACT_APP_TESTS_API,
  paymentsApi: process.env.REACT_APP_PAYMENTS_API,
  emailsApi: process.env.REACT_APP_EMAILS_API,
  configurationApi: process.env.REACT_APP_CONFIGURATION_API,
  errosApi: process.env.REACT_APP_ERROS_API,
  multimediaApi: process.env.REACT_APP_MULTIMEDIA_API,
  userRegistryApi: process.env.REACT_APP_USERREGISTRY_API,
  Helvetica: process.env.REACT_APP_HELVETICA,

  // <=<●>=><=<●>=><=<●>=> Colores <=<●>=><=<●>=><=<●>=>
  kolegiaBlue: process.env.REACT_APP_KOLEGIA_BLUE,
  backGround: process.env.REACT_APP_BACKGROUND,
  mainColor: process.env.REACT_APP_MAIN_COLOR,
  secondColor: process.env.REACT_APP_SECOND_COLOR,
  thirdColor: process.env.REACT_APP_THIRD_COLOR,
  fourthColor: process.env.REACT_APP_FOURTH_COLOR,
  fifthColor: process.env.REACT_APP_FIFTH_COLOR,
  sixthColor: process.env.REACT_APP_SIXTH_COLOR,

  greenColor: process.env.REACT_APP_GREEN_COLOR,
  greenColorTwo: process.env.REACT_APP_GREEN_COLOR_TWO,
  greenColorThree: process.env.REACT_APP_GREEN_COLOR_THREE,
  yellowColor: process.env.REACT_APP_YELLOW_COLOR,
  orangeColor: process.env.REACT_APP_ORANGE_COLOR,
  orangeColorTwo: process.env.REACT_APP_ORANGE_COLOR_TWO,
  redColor: process.env.REACT_APP_RED_COLOR,
  blueColor: process.env.REACT_APP_BLUE_COLOR,
  oxford1Light: process.env.REACT_APP_OXFORD1_LIGHT,
  oxfordDark: process.env.REACT_APP_OXFORD_DARK,
  ligthBlue: process.env.REACT_APP_LIGTH_BLUE,

  grayBackground: process.env.REACT_APP_GRAY_BACKGROUND,
  grayColorZero: process.env.REACT_APP_GRAY_COLOR_ZERO,
  grayColorOne: process.env.REACT_APP_GRAY_COLOR_ONE,
  grayColorTwo: process.env.REACT_APP_GRAY_COLOR_TWO,
  grayColorThree: process.env.REACT_APP_GRAY_COLOR_THREE,
  grayColorFour: process.env.REACT_APP_GRAY_COLOR_FOUR,
  grayColorFive: process.env.REACT_APP_GRAY_COLOR_FIVE,

  // <=<●>=><=<●>=><=<●>=> App Links <=<●>=><=<●>=><=<●>=>
  iOS: process.env.REACT_APP_IOS,
  Android: process.env.REACT_APP_ANDROID,
  AppGallery: process.env.REACT_APP_APPGALLERY,
  zoom: process.env.REACT_APP_ZOOM,

  // <=<●>=><=<●>=><=<●>=> App Globals <=<●>=><=<●>=><=<●>=>
  app: process.env.REACT_APP_APP,
  appName: process.env.REACT_APP_APPNAME,
  name: process.env.REACT_APP_NAME,
  correo_soporte: process.env.REACT_APP_CORREO_SOPORTE,
  Landing: process.env.REACT_APP_LANDING,
  NombreCorto: process.env.REACT_APP_NOMBRECORTO,
  SchoolId: process.env.REACT_APP_SCHOOLID,
  SitioWeb: process.env.REACT_APP_SITIOWEB,
  tipoApp: process.env.REACT_APP_TIPOAPP,
  celular_soporte: process.env.REACT_APP_CELULAR_SOPORTE,

  // <=<●>=><=<●>=><=<●>=> Api keys <=<●>=><=<●>=><=<●>=>
  OneSignal: process.env.REACT_APP_ONESIGNAL,

  // <=<●>=><=<●>=><=<●>=> Open Pay <=<●>=><=<●>=><=<●>=>
  OpenPay: process.env.REACT_APP_OPENPAY,
  OpenPayPK: process.env.REACT_APP_OPENPAYPK,
  Production: process.env.REACT_APP_PRODUCTION,

  OpenPayI: "",
  OpenPayPKI: "",
  ProductionI: false,

  OpenPayII: "",
  OpenPayPKII: "",
  ProductionII: false,

  OpenPayIII: "",
  OpenPayPKIII: "",
  ProductionIII: false,

  OpenPayIV: "",
  OpenPayPKIV: "",
  ProductionIV: false,

  OpenPayV: "",
  OpenPayPKV: "",
  ProductionV: false,

  OpenPayVI: "",
  OpenPayPKVI: "",
  ProductionVI: false,

  OpenPayVII: "",
  OpenPayPKVII: "",
  ProductionVII: false,

  OpenPayVIII: "",
  OpenPayPKVIII: "",
  ProductionVIII: false,

  OpenPayIX: "",
  OpenPayPKIX: "",
  ProductionIX: false,

  OpenPayX: "",
  OpenPayPKX: "",
  ProductionX: false,

  OpenPayXI: "",
  OpenPayPKXI: "",
  ProductionXI: false,

  OpenPayXII: "",
  OpenPayPKXII: "",
  ProductionXII: false,

  OpenPayXIII: "",
  OpenPayPKXIII: "",
  ProductionXIII: false,

  OpenPayXIV: "",
  OpenPayPKXIV: "",
  ProductionXIV: false,

  OpenPayXV: "",
  OpenPayPKXV: "",
  ProductionXV: false,

  OpenPayXVI: "",
  OpenPayPKXVI: "",
  ProductionXVI: false,

  OpenPayXVII: "",
  OpenPayPKXVII: "",
  ProductionXVII: false,

  OpenPayXVIII: "",
  OpenPayPKXVIII: "",
  ProductionXVIII: false,

  OpenPayXIX: "",
  OpenPayPKXIX: "",
  ProductionXIX: false,

  OpenPayXX: "",
  OpenPayPKXX: "",
  ProductionXX: false,

  OpenPayXXI: "",
  OpenPayPKXXI: "",
  ProductionXXI: false,

  OpenPayXXII: "",
  OpenPayPKXXII: "",
  ProductionXXII: false,

  OpenPayXXIII: "",
  OpenPayPKXXIII: "",
  ProductionXXIII: false,
};
