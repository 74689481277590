import React, {useContext, useEffect, useRef, useState} from "react";
import {Col, Row} from 'react-material-responsive-grid';
import KolegiaBroadcastContext from "../../../Context/KolegiaBroadcast";
import {useSelector} from "react-redux";

const moment = require('moment');
require('moment/locale/es');
moment.locale('es');

export default function LogsRoom() {
    const bottomLogsRef = useRef(null);
    const {newMessageRecive} = useContext(KolegiaBroadcastContext);
    const {messages, loading, students} = useSelector(state => state.virtual_class);
    const [messagesLogs, setMessagesLogs] = useState([]);
    const {fourthColor, fifthColor, kolegiaBlue} = useSelector(state => state.rooot);

    useEffect(() => {
        setMessagesLogs(messages);
    }, [messages])

    useEffect(() => {
        if (newMessageRecive !== undefined) {
            if (newMessageRecive.type_message === 2) {
                setMessagesLogs(messagesRoom => [...messagesRoom, newMessageRecive]);
            }
        }
    }, [newMessageRecive]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (messagesLogs.length !== 0) {
            scrollToBottom()
        }
    }, [messagesLogs]) // eslint-disable-line react-hooks/exhaustive-deps

    const scrollToBottom = () => {
        if (bottomLogsRef.current) {
            bottomLogsRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
            });
        }
    };

    const renderMessages = messagesLogs.filter((it => it.type_message === 2)).map((message, i) => {
        function findStudent(select) {
            return Number(select.id) === Number(message.user_id);
        }

        let ixStudent = students.indexOf(students.find(findStudent));
        let dateMessage = moment(message.date).local().format('HH:mm')
        return <Row
            key={i + 'log_message'}
            className="uk-width-1-1 centerAll"
            style={{
                marginBottom: 3,
                borderRadius: 6,
                backgroundColor: fourthColor,
                padding: 4
            }}
        >
            <Row className="uk-width-1-1">
                <label
                    className="uk-width-5-6 uk-text-truncate"
                    style={{
                        textAlign: 'left',
                        fontWeight: '700',
                        fontSize: '.65rem',
                        color: messagesLogs.length !== 0 && ixStudent !== -1 && students.length !== 0
                            ? students[ixStudent].back_color
                            : kolegiaBlue,
                        marginBottom: 2
                    }}>
                    {message.name}
                </label>
                <label
                    className="uk-width-1-6"
                    style={{
                        textAlign: 'right',
                        color: fifthColor,
                        fontSize: '.65rem',
                        marginTop: 2
                    }}>
                    {dateMessage}
                </label>
            </Row>
            <label className="uk-width-1-1 normal_text" style={{
                textAlign: 'left',
                color: fifthColor,
                fontSize: '.6rem',
                textTransform: 'uppercase'
            }}>
                {message.body}
            </label>
        </Row>
    })

    return loading ? null :
        <Col className="uk-width-1-1 scroll-chat" style={{margin: 0, padding: 2, height: 'calc(100% - 5%)'}}>
            {messagesLogs.length !== 0 ? renderMessages : null}
            <div ref={bottomLogsRef} style={{height: 10}}/>
        </Col>
}
