import React from 'react'
import {useSelector} from 'react-redux'
import axios from "axios"

const headers = {headers: {'Content-Type': 'application/json'}}

const GlobalConfigurationContext = React.createContext()

const {Provider, Consumer} = GlobalConfigurationContext

function GlobalConfigurationProvider(props) {
    const {
        bd,
        api
    } = useSelector(state => state.rooot);
    const [globalConfiguration, setGlobalConfiguration] = React.useState(null)

    React.useEffect(() => {
        if (bd !== '') fetchGlobalConfiguration(bd)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchGlobalConfiguration = async ({db}) => {
        try {
            const {data} = await axios.get(api + db + '/config/all', headers)
            setGlobalConfiguration(data)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Provider value={{globalConfiguration, fetchGlobalConfiguration}}>
            {props.children}
        </Provider>
    )
}

export default GlobalConfigurationContext
export {GlobalConfigurationProvider, Consumer as GlobalConfigurationContext}
