import React, {memo, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {Row} from "react-material-responsive-grid";
import {useDispatch, useSelector} from "react-redux";
import {
    restartStudent,
    setStatusTeacher,
    setStudents,
    studentsInStream
} from "../../../redux/actions/VirtualClassActions/VirtualClassActions";
import Pagination from "../../Global-W/Hooks/Pagination";
import KolegiaBroadcastContext from "../../../Context/KolegiaBroadcast";
import FaltaMensaje from "../../Global-W/Classes/FaltaMensaje";
import VideoBoxNew from "./VideoBox";
import {alertWarning} from "../../Global-W/NewFunctions";

const moment = require('moment');
require('moment/locale/es');
moment.locale('es');


const VideoBox2 = ({item = null, stream = null}) => {
    const streamUser = useRef();

    useEffect(() => {
        if (stream !== null && item !== null) {
            streamUser.current.srcObject = stream;
        }
    }, [item, stream]) // eslint-disable-line react-hooks/exhaustive-deps

    return <video ref={streamUser} playsInline autoPlay
                  style={{display: 'none'}}/>
};

export function Participants() {
    const {
        subscribe,
        handleDisconnectConsumer,
        socketRef,
        handleDisconnectProducer,
        sendRequest
    } = useContext(KolegiaBroadcastContext);
    const currentGuestRef = useRef();
    const chatReference = useRef()
    const cardContainer = useRef(null);
    const dispatch = useDispatch();
    const {
        grayColorOne,
        userRole,
        grayColorThree,
        userId
    } = useSelector(state => state.rooot);
    const {
        students_in_class,
        remoteVideos,
        producer_started,
        students,
        current_guests,
        chatStatus,
    } = useSelector(state => state.virtual_class);
    const [PageSize, setPageSize] = useState(userRole === 'Admin' ? 10 : 20);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (socketRef.current !== undefined) {
            socketRef.current.on("close class", async () => {
                if (userRole !== 'Maestro') {
                    alertWarning("El maestro ha salido de la clase");
                    // await dispatch(setMyAudioStatus(true))
                    // await dispatch(setMyVideoStatus(true))
                    // await dispatch(changeMainStream(null))
                    dispatch(setStatusTeacher('0'))
                    await dispatch(restartStudent())
                    await dispatch(setStudents(students.map(it => {
                        it.online = false;
                        it.has_video = false;
                        it.video_active = "1";
                        it.audio_active = "1";
                        return it
                    })))
                    await handleDisconnectConsumer();
                    await handleDisconnectProducer();
                    await sendRequest("close stream", {});
                }
            })
        }
    }, [socketRef])

    useEffect(() => {
        chatReference.current = chatStatus
    }, [chatStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        currentGuestRef.current = current_guests
    }, [current_guests]) // eslint-disable-line react-hooks/exhaustive-deps

    useLayoutEffect(() => {
        function updateSize() {
            setPageSize(window.innerWidth >= 1600
                ? 20
                : window.innerWidth >= 960
                    ? 12
                    : window.innerWidth >= 750
                        ? 10
                        : 8)
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(studentsInStream(remoteVideos));
    }, [remoteVideos]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (producer_started && userRole === 'Maestro') {
            subscribe()
        }
    }, [producer_started]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userRole === 'Admin' && students_in_class.length !== 0) {
            subscribe();
        }
    }, [students_in_class])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            handleDisconnectConsumer();
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const alumnos = useMemo(() => {
        function order(a, b) {
            try {
                if (a.id.toString() === userId.toString()) {
                    return -1
                } else {
                    if (a.online === true && b.online === false) return -1;
                    if (a.online === false && b.online === true) return 1;
                    return 0;
                }
            } catch (e) {
                console.error(e)
            }
        }

        const maped = students.map((item, i) => {

            function setData(data) {
                return Number(data.user_id) === Number(item.id);
            }

            item['socket_id'] = students_in_class.find(setData) !== undefined ? students_in_class.find(setData).socket_id : ''
            item['online'] = students !== undefined
                ? students_in_class.find(setData) !== undefined
                : false;
            item['has_video'] = students_in_class.find(setData) !== undefined
                ? remoteVideos[students_in_class.find(setData)['socket_id']] !== undefined
                : false;
            item['video_box_stream'] = item['online']
                ? students_in_class.find(setData) !== undefined
                    ? remoteVideos[students_in_class.find(setData)['socket_id']] !== undefined
                        ? remoteVideos[students_in_class.find(setData)['socket_id']]['stream']['stream']
                        : null
                    : null
                : null
            return item
        })

        return maped.sort(order)
    }, [students_in_class, students, remoteVideos, PageSize]);

    const showVideo = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return alumnos.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, students_in_class, alumnos, remoteVideos, PageSize]);

    const audioVideo = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        const fslice = alumnos.slice(lastPageIndex, students.length);
        const lslice = alumnos.slice(0, firstPageIndex);

        return lslice.concat(fslice).filter(item => item.has_video);

    }, [currentPage, students_in_class, alumnos, remoteVideos, PageSize]);

    const studentsBox = showVideo.filter(it => it.puesto !== "Maestro").map((item, i) => {
        return <Row
            key={"Student - " + item.id}
            className={PageSize === 20 ? "uk-width-1-8" : PageSize === 12 ? "uk-width-1-6" : PageSize === 10 ? "uk-width-1-5" : "uk-width-1-4"}
            style={{height: '50%'}}>
            <VideoBoxNew itsMe={Number(item.id) === Number(userId)} item={item} str={item.video_box_stream}/>
        </Row>
    });

    const audioStudentsBox = audioVideo.filter(it => it.puesto !== "Maestro").map((item, i) => {
        function setData(data) {
            return Number(data.user_id) === Number(item.id);
        }

        return <VideoBox2
            item={item}
            stream={remoteVideos[students_in_class.find(setData)['socket_id']]['stream']['stream']}/>
    });

    return students.length !== 0
        ? <Row
            className="uk-width-1-1 centerAll normal_text"
            style={{
                padding: 0,
                height: '100%',
                borderRadius: 6,
                overflow: 'hidden',
                backgroundColor: grayColorOne,
                justifyContent: 'flex-start'
            }}>
            <Row className="uk-width-1-1" style={{height: '15%', backgroundColor: grayColorThree, padding: 5}}>
                <Row className="uk-width-1-6" style={{alignItems: 'center'}}>
                    <label className="uk-width-5-6 section_title_two" style={{textAlign: 'left', marginLeft: 5}}>
                        {students_in_class.length} PARTICIPANTES
                    </label>
                </Row>
                <Row className="uk-width-2-3" style={{justifyContent: 'flex-start'}}>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={students.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </Row>
            </Row>
            <Row className="uk-width-1-1" style={{height: '85%'}}>
                <Row className="centerAll" style={{height: '100%', width: '100%'}}>
                    {students !== undefined
                        ? <div
                            ref={cardContainer}
                            style={{
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                alignContent: 'flex-start',
                                width: '100%',
                                flexDirection: 'column',
                                flexWrap: 'wrap',
                                display: 'flex',
                                overflowX: 'scroll',
                                overflowY: 'hidden',
                                height: '100%',
                                whiteSpace: 'nowrap'
                            }}>
                            {studentsBox}
                            {audioStudentsBox}
                        </div>
                        : null}
                </Row>
            </Row>
        </Row>
        : <Row
            className="uk-width-1-1 centerAll normal_text"
            style={{
                padding: 5,
                height: '100%',
                borderRadius: 6,
                backgroundColor: grayColorOne,
                justifyContent: 'flex-start'
            }}>
            <FaltaMensaje mensaje={"Sin clases"}/>
        </Row>
}

export default memo(Participants)
