import {ACCOUNTS, BALANCES, LOADING_PAGOS, MODALIDADES_PAGO, PAGOS, REFERENCE} from "../../actions/types";

const initialState = {
    activo_local: true,
    activo_normal: true,
    activo_open_pay: false,
    activo_pronto_pago: true,
    activo_referenciado: false,
    activo_vencido: true,
    banco1: '',
    banco2: '',
    banco3: '',
    banco4: '',
    banco5: '',
    beca: '',
    beca_aplicada_a: false,
    beca_normal: false,
    beca_pronto_pago: false,
    card_commission1: '0',
    card_commission: '0',
    cards: false,
    checked1: false,
    checked2: false,
    checked3: false,
    checked5: false,
    checked6: false,
    checked7: false,
    porcentaje: false,
    porcentaje_pronto_pago: false,
    checked: false,
    clabe1: '',
    clabe2: '',
    clabe3: '',
    clabe4: '',
    clabe5: '',
    colegiatura: '',
    commission_pasarela: 0,
    convenio: '',
    cuentas: JSON.stringify([]),
    descuento: '',
    descuento_monto: 0,
    dias: '',
    dias_normal: 3,
    dias_pronto_pago: 3,
    dias_vencido: 3,
    fecha_limite_colegiatura: 'mes',
    limite: 10,
    mail_normal: false,
    mail_pronto_pago: false,
    mail_vencido: false,
    maxBecaMonto: 300,
    modalidad_principal: '',
    modalidades: [],
    payNet: false,
    per_escuela: '',
    per_padres: '',
    precio_completo: '4500',
    precio_hora: '100',
    pronto_pago: '',
    pronto_pago_becados: false,
    recargo: '',
    ref_commission: '0',
    spei: false,
    text_mail_normal: '¡No lo olvide! Tiene pendiente un pago. Pague a tiempo para no generar recargo',
    text_mail_pronto_pago: 'Aprovecha el descuento de pronto pago',
    text_mail_vencido: 'Tiene vencido el pago, le invitamos a pagar lo antes posible',
    text_notif_normal: '¡No lo olvide! Tiene pendiente un pago. Pague a tiempo para no generar recargo',
    text_notif_pronto_pago: 'Aprovecha el descuento de pronto pago',
    text_notif_vencido: 'Tiene vencido el pago, le invitamos a pagar lo antes posible',
    tipo_pagos_maestros: 'quincenal',
    tipo_pronto_pago: 'unico',
    tipo_recargo: 'unico',
    bbva: false,
    santander: false,
    banamex_static: false,
    balance: 0,
    balances: [],
    reference: '',
    accounts: []
}

export default function PagosReducer(state = initialState, action) {
    switch (action.type) {
        case PAGOS:
            return {
                ...state,
                activo_local: action.activo_local,
                activo_normal: action.activo_normal,
                activo_open_pay: action.activo_open_pay,
                activo_pronto_pago: action.activo_pronto_pago,
                activo_referenciado: action.activo_referenciado,
                activo_vencido: action.activo_vencido,
                banco1: action.banco1,
                banco2: action.banco2,
                banco3: action.banco3,
                banco4: action.banco4,
                banco5: action.banco5,
                beca: action.beca,
                beca_aplicada_a: action.beca_aplicada_a,
                beca_normal: action.beca_normal,
                beca_pronto_pago: action.beca_pronto_pago,
                card_commission1: action.card_commission1,
                card_commission: action.card_commission,
                cards: action.cards,
                checked1: action.recargo,
                checked2: action.beca,
                checked3: action.dias,
                checked5: action.per_escuela,
                checked6: action.per_padres,
                checked7: action.limite,
                porcentaje: action.porcentaje,
                porcentaje_pronto_pago: action.porcentaje_pronto_pago,
                checked: action.descuento,
                clabe1: action.clabe1,
                clabe2: action.clabe2,
                clabe3: action.clabe3,
                clabe4: action.clabe4,
                clabe5: action.clabe5,
                colegiatura: action.colegiatura,
                convenio: action.convenio,
                commission_pasarela: action.commission_pasarela,
                cuentas: action.cuentas,
                descuento: action.descuento,
                descuento_monto: action.descuento_monto,
                dias: action.dias,
                dias_normal: action.dias_normal,
                dias_pronto_pago: action.dias_pronto_pago,
                dias_vencido: action.dias_vencido,
                fecha_limite_colegiatura: action.fecha_limite_colegiatura,
                limite: action.limite,
                loading: false,
                mail_normal: action.mail_normal,
                mail_pronto_pago: action.mail_pronto_pago,
                mail_vencido: action.mail_vencido,
                maxBecaMonto: action.maxBecaMonto,
                modalidad_principal: action.modalidad_principal,
                payNet: action.payNet,
                per_escuela: action.per_escuela,
                per_padres: action.per_padres,
                precio_completo: action.precio_completo,
                precio_hora: action.precio_hora,
                pronto_pago: action.pronto_pago,
                pronto_pago_becados: action.pronto_pago_becados,
                recargo: action.recargo,
                ref_commission: action.ref_commission,
                spei: action.spei,
                text_mail_normal: action.text_mail_normal,
                text_mail_pronto_pago: action.text_mail_pronto_pago,
                text_mail_vencido: action.text_mail_vencido,
                text_notif_normal: action.text_notif_normal,
                text_notif_pronto_pago: action.text_notif_pronto_pago,
                text_notif_vencido: action.text_notif_vencido,
                tipo_pagos_maestros: action.tipo_pagos_maestros,
                tipo_pronto_pago: action.tipo_pronto_pago,
                tipo_recargo: action.tipo_recargo,
                bbva: action.bbva,
                santander: action.santander,
                banamex_static: action.banamex_static,
            };
        case MODALIDADES_PAGO:
            return {
                ...state,
                modalidades: action.payload
            };
        case BALANCES:
            return {
                ...state,
                balance: action.balance,
                balances: action.balances
            };
        case LOADING_PAGOS:
            return {
                ...state,
                loading: action.payload
            };
        case REFERENCE:
            return {
                ...state,
                reference: action.payload
            };
        case ACCOUNTS:
            return {
                ...state,
                accounts: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
