export const DELETE_POST = 'DELETE_POST';
export const FETCH_POSTS = 'FETCH_POSTS';
export const LOADING_POSTS = 'LOADING_POSTS';
export const THEME = 'THEME';
export const MODE = 'MODE';
export const IMAGES = 'IMAGES';
export const SET_MENU = 'SET_MENU';
export const SET_SUB_MENU = 'SET_SUB_MENU';
export const ROOT_LOAD = 'ROOT_LOAD';

export const COUNTRIES = 'COUNTRIES';
export const LOADING_COUNTRIES = 'LOADING_COUNTRIES';
export const SELECTED_COUNTRY = 'SELECTED_COUNTRY';

export const CICLO_ACTUAL = 'CICLO_ACTUAL';
export const SCHOOL_DATA = 'SCHOOL_DATA';
export const SCHOOLS_NIVELES = 'SCHOOLS_NIVELES';
export const TIPOS_MATERIAS = 'TIPOS_MATERIAS';
export const AVISOS = 'AVISOS';
export const REINICIO = 'REINICIO';
export const MODAL = 'MODAL';

export const ALIAS = 'ALIAS';
export const BROWSER = 'BROWSER';

export const CALIFICACIONES = 'CALIFICACIONES';
export const CONVERTIONS = 'CONVERTIONS';
export const CONVERTIONS_MAESTRO = 'CONVERTIONS_MAESTRO';
export const CONVERTIONS_MATERIA = 'CONVERTIONS_MATERIA';
export const INIT = 'INIT';
export const LOADING_CALIFICACIONES = 'LOADING_CALIFICACIONES';
export const MATERIAS = 'MATERIAS';
export const PROMEDIO_ANUAL = 'PROMEDIO_ANUAL';
export const RANKINGS_ALUMNOS = 'RANKINGS_ALUMNOS';
export const THE_CALIFICACIONES = 'THE_CALIFICACIONES';
export const SEARCH_CALIF_SCHEDULE = 'SEARCH_CALIF_SCHEDULE';
export const CONCENTRADO_CALIFS = 'CONCENTRADO_CALIFS';

export const ALUMNOS_GG = 'ALUMNOS_GG';
export const PERIODOS = 'PERIODOS';

export const LOADING_PAGOS = 'LOADING_PAGOS';
export const MODALIDADES_PAGO = 'MODALIDADES_PAGO';
export const PAGOS = 'PAGOS';
export const BALANCES = 'BALANCES';
export const REFERENCE = 'REFERENCE';
export const ACCOUNTS = 'ACCOUNTS';

export const DELETE_PLANES = 'DELETE_PLANES';
export const LOADING_PLANES = 'LOADING_PLANES';
export const POST_LOADING_PLANES = 'POST_LOADING_PLANES';
export const PLANES = 'PLANES';
export const PLANES_ADMIN = 'PLANES_ADMIN';
export const PLANES_GESTION = 'PLANES_GESTION';
export const SELECTED_PLAN = 'SELECTED_PLAN';
export const TEMAS = 'TEMAS';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const VALIDATION_TO_CREATE = 'VALIDATION_TO_CREATE';
export const SUSPENCIONES_CLASES = 'SUSPENCIONES_CLASES';
export const TEMAS_ASIGNADOS_MATERIA = 'TEMAS_ASIGNADOS_MATERIA';
export const ALUMNOS_REVISION = 'ALUMNOS_REVISION';
export const ACTIVIDADES_ALUMNOS = 'ACTIVIDADES_ALUMNOS';
export const PLANES_IN_CALENDARIO = 'PLANES_IN_CALENDARIO';
export const SESSIONS_SCHEDULE = 'SESSIONS_SCHEDULE';
export const ACTIVIDADES_ADMIN = 'ACTIVIDADES_ADMIN';
export const PLANES_MATERIAS_ADMIN = 'PLANES_MATERIAS_ADMIN';
export const PLANES_MATERIAS_TEACHER = 'PLANES_MATERIAS_TEACHER';
export const PLANEACIONES_MAESTRO = 'PLANEACIONES_MAESTRO';
export const CLASSES_GROUP = 'CLASSES_GROUP';
export const OPTIONAL_CONTENT = 'OPTIONAL_CONTENT';
export const INFO_ACTIVITIES_ADMIN = 'INFO_ACTIVITIES_ADMIN';

export const ACUMULADOS_ALUMNOS = 'ACUMULADOS_ALUMNOS';
export const LOADING_ACUMULADOS = 'LOADING_ACUMULADOS';

export const MATERIAS_MAESTRO = 'MATERIAS_MAESTRO';

export const GRUPOS_MAESTRO = 'GRUPOS_MAESTRO';

export const CLASES_CICLO = 'CLASES_CICLO';
export const CLASES_CREADAS = 'CLASES_CREADAS';
export const CLASES_MAESTRO = 'CLASES_MAESTRO';
export const DIAS_CLASES = 'DIAS_CLASES';
export const VARIABLE_EXTRA_ALL = 'VARIABLE_EXTRA_ALL';
export const VARIABLE_GRUPOS = 'VARIABLE_GRUPOS';
export const CLASS_PLANNING = 'CLASS_PLANNING';
export const THEMES_SUBJECT = 'THEMES_SUBJECT';

export const ACTIVITIES_MAESTRO = 'ACTIVITIES_MAESTRO';

export const ALL_SUBJECTS = 'ALL_SUBJECTS';
export const LOADING_ASIGNAR_MAESTRO = 'LOADING_ASIGNAR_MAESTRO';
export const MAESTROS = 'MAESTROS';
export const ALL_MATERIAS_MAESTRO = 'ALL_MATERIAS_MAESTRO';
export const RELATION_INFO = 'RELATION_INFO';
export const MATERIAS_FALTANTES = 'MATERIAS_FALTANTES';
export const RELACION_MATERIAS = 'RELACION_MATERIAS';
export const RELATION = 'RELATION';
export const SAVE_RELATION = 'SAVE_RELATION';

export const COMENTARIOS_PREFECTO = 'COMENTARIOS_PREFECTO';

export const ALUMNOS_EGRESADOS = 'ALUMNOS_EGRESADOS';
export const LISTA_ALUMNOS = 'LISTA_ALUMNOS';
export const MY_ROLES = 'MY_ROLES';

export const ACADEMY_CONFIG = 'ACADEMY_CONFIG';
export const INICIO_FIN_CICLO1 = 'INICIO_FIN_CICLO1';
export const INICIO_FIN_CICLO2 = 'INICIO_FIN_CICLO2';
export const LOADING_PARAMETROS = 'LOADING_PARAMETROS';

export const ADMISIONES = 'ADMISIONES';
export const ALUMNOS_INGRESO = 'ALUMNOS_INGRESO';
export const DOCUMENTOS_EXTRA = 'DOCUMENTOS_EXTRA';
export const LOADING_ADMISIONES = 'LOADING_ADMISIONES';

export const CATEGORIAS = 'CATEGORIAS';
export const LOADING_CATEGORIAS = 'LOADING_CATEGORIAS';

export const CREATE_JORNADAS = 'CREATE_JORNADAS';
export const DELETE_JORNADAS = 'DELETE_JORNADAS';
export const GET_JORNADAS = 'GET_JORNADAS';
export const GET_JORNADAS_RELATION = 'GET_JORNADAS_RELATION';
export const ID_RELACION_GRUPO = 'ID_RELACION_GRUPO';
export const JORNADAS_ADMIN_MAESTRO = 'JORNADAS_ADMIN_MAESTRO';
export const JORNADAS_GRUPO = 'JORNADAS_GRUPO';
export const JORNADAS_MAESTRO = 'JORNADAS_MAESTRO';
export const JORNADAS_PADRE = 'JORNADAS_PADRE';
export const JORNADAS_ALUMNO = 'JORNADAS_ALUMNO';
export const JORNADAS_PREFECTO = 'JORNADAS_PREFECTO';
export const LOADING_JORNADAS = 'LOADING_JORNADAS';
export const SEMANAS = 'SEMANAS';
export const SEMANAS_OCUPADAS = 'SEMANAS_OCUPADAS';
export const UPDATE_JORNADAS = 'UPDATE_JORNADAS';
export const GET_JORNADAS_COMMENTS = 'GET_JORNADAS_COMMENTS';

export const GET_HORARIO_MAESTRO = 'GET_HORARIO_MAESTRO';
export const GET_HORARIO_PADRE = 'GET_HORARIO_PADRE';
export const GET_HORARIO_ALUMNO = 'GET_HORARIO_ALUMNO';
export const GET_HORARIO_PREFECTO = 'GET_HORARIO_PREFECTO';
export const LOADING_HORARIO = 'LOADING_HORARIO';

//<=<●>=><=<●>=><=<●>=> DATOS DE CONTACTO <=<●>=><=<●>=><=<●>=>
export const LOADING_DATOS = 'LOADING_DATOS';
export const DATOS_USER_ID = 'DATOS_USER_ID';
export const DATOS_USER_ALUMNO = 'DATOS_USER_ALUMNO';
export const LOADING_POST_DATOS = 'LOADING_POST_DATOS';
export const USER_PICTURE = 'USER_PICTURE'

//<=<●>=><=<●>=><=<●>=> ZOOM <=<●>=><=<●>=><=<●>=>
export const LOADING_ZOOM = 'LOADING_ZOOM';
export const MODULES = 'MODULES';
export const MODULES_TEACHER = 'MODULES_TEACHER';
export const MEETINGS_GROUP = 'MEETINGS_GROUP';
export const SKIP_ZOOM = 'SKIP_ZOOM';
export const ZOOM_STATUS = 'ZOOM_STATUS';

export const ZOOM_SUSCRIBE = 'ZOOM_SUSCRIBE';
export const ZOOM_PROPS = 'ZOOM_PROPS';

//<=<●>=><=<●>=><=<●>=> ASISTENCIAS <=<●>=><=<●>=><=<●>=>
export const EVENT_CHECK = 'EVENT_CHECK';
export const FALTAS_TOTALES_ALUMNO = 'FALTAS_TOTALES_ALUMNO';
export const FALTAS_TOTALES_MATERIA_ALUMNO = 'FALTAS_TOTALES_MATERIA_ALUMNO';
export const LOADING_ASISTENCIAS = 'LOADING_ASISTENCIAS';
export const SCHEDULES_FROM_DATE = 'SCHEDULES_FROM_DATE';
export const ASSISTANCE_BY_GROUP = 'ASSISTANCE_BY_GROUP';
export const ASSISTANCE_TODAY_ADMIN = 'ASSISTANCE_TODAY_ADMIN';
export const ASSISTANCE_PER_DAY = 'ASSISTANCE_PER_DAY';
export const ASSISTANCE_PER_MODULE = 'ASSISTANCE_PER_MODULE';
export const MODULES_CLASS_TEACHER = 'MODULES_CLASS_TEACHER';
export const CHECK_ASSISTANCE_TEACHER = 'CHECK_ASSISTANCE_TEACHER';
export const ASSISTANCE_TEACHERS_DATE = 'ASSISTANCE_TEACHERS_DATE';

//<=<●>=><=<●>=><=<●>=> REFERENCES <=<●>=><=<●>=><=<●>=>
export const GET_REFERENCES = 'GET_REFERENCES';
export const GET_LAST_REFERENCES = 'GET_LAST_REFERENCES';
export const LOADING_REFERENCES = 'LOADING_REFERENCES';

//<=<●>=><=<●>=><=<●>=> REGISTER <=<●>=><=<●>=><=<●>=>
export const LOADING_REGISTER = 'LOADING_REGISTER';
export const ROLE_REGISTER = 'ROLE_REGISTER';
export const COUNTRY_REGISTER = 'COUNTRY_REGISTER';
export const STEP_REGISTER = 'STEP_REGISTER';
export const SET_ALUMNOS_REGISTER = 'SET_ALUMNOS_REGISTER';
export const SET_TUTOR_REGISTER = 'SET_TUTOR_REGISTER';
export const SET_FIND_REGISTER = 'SET_FIND_REGISTER';
export const SET_USER_REGISTER = 'SET_USER_REGISTER';
export const FIND_USER = 'FIND_USER';
export const SET_DEFAULTS = 'SET_DEFAULTS';
export const SET_USER_SCHOOLS = 'SET_USER_SCHOOLS';
export const SET_USER_STUDENTS = 'SET_USER_STUDENTS';


//<=<●>=><=<●>=><=<●>=> ACADEMIC <=<●>=><=<●>=><=<●>=>
export const TEACHER_CRITERIA = 'TEACHER_CRITERIA';
export const TEACHER_ACTIVITIES = 'TEACHER_ACTIVITIES';
export const STATUS_MODAL_CRITERIA = 'STATUS_MODAL_CRITERIA';

//<=<●>=><=<●>=><=<●>=> ROOT <=<●>=><=<●>=><=<●>=>
export const SET_BETA = 'SET_BETA';
export const SET_KOLEGIA_THEME = 'SET_KOLEGIA_THEME';

//<=<●>=><=<●>=><=<●>=> VIRTUALCLASS <=<●>=><=<●>=><=<●>=>
export const STATUS_VIDEO_STUDENT = 'STATUS_VIDEO_STUDENT';
export const STATUS_AUDIO_STUDENT = 'STATUS_AUDIO_STUDENT';
export const SET_MODAL_ACTIVITY_CLASS = 'SET_MODAL_ACTIVITY_CLASS';
export const STUDENTS_IN_STREAM = 'STUDENTS_IN_STREAM';
export const STUDENTS_IN_CLASS = 'STUDENTS_IN_CLASS';
export const STUDENTS = 'STUDENTS';
export const ROOM_ID = 'ROOM_ID';
export const PRODUCER_STARTED = 'PRODUCER_STARTED';


export const CURRENT_CLASS = 'CURRENT_CLASS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_REMOTE_VIDEOS = 'SET_REMOTE_VIDEOS';
export const MAIN_STREAM = 'MAIN_STREAM';
export const SET_STUDENTS = 'SET_STUDENTS';
export const START_TRANSMISSION = 'START_TRANSMISSION';
export const LOADING_VIRTUAL_CLASS = 'LOADING_VIRTUAL_CLASS';
export const CONNECTING_CLASS = 'CONNECTING_CLASS';
export const SET_STUDENTS_VIDEO = 'SET_STUDENTS_VIDEO';
export const LEAVE_USER = 'LEAVE_USER';
export const CHANGE_ALL_MEDIA = 'CHANGE_ALL_MEDIA';
export const CHANGE_ONE = 'CHANGE_ONE';
export const ALL_CLASSES_USER = 'ALL_CLASSES_USER';
export const REFRESH_CLASS = 'REFRESH_CLASS';
export const CONNECTION_STATUS = 'CONNECTION_STATUS';
export const CHAT_STATUS = 'CHAT_STATUS';
export const TEACHERS_LIST = 'TEACHERS_LIST';
export const ADMIN_REQUEST = 'ADMIN_REQUEST';
export const ADMIN_REQUEST_AUDIO = 'ADMIN_REQUEST_AUDIO';
export const CHANGE_MEDIA_TEACHER = 'CHANGE_MEDIA_TEACHER';
export const TEACHER_LEAVING = 'TEACHER_LEAVING';
export const NEW_TEACHER = 'NEW_TEACHER';
export const SET_STATUS_CLASS = 'SET_STATUS_CLASS';
export const CURRENT_ITEM_CLASS = 'CURRENT_ITEM_CLASS';
export const NEW_GUEST = 'NEW_GUEST';
export const MY_DATA_GUEST = 'MY_DATA_GUEST';
export const UPDATE_MEDIA_TEACHER = 'UPDATE_MEDIA_TEACHER';
export const NOTIFICATION = 'NOTIFICATION';
export const SET_GUESTS = 'SET_GUESTS';
export const MAIN_VIDEO_STATUS = 'MAIN_VIDEO_STATUS';
export const MAIN_AUDIO_STATUS = 'MAIN_AUDIO_STATUS';
export const MY_VIDEO_STATUS = 'MY_VIDEO_STATUS'
export const MY_AUDIO_STATUS = 'MY_AUDIO_STATUS'
export const UPDATE_MEDIA = 'UPDATE_MEDIA'
export const NO_VIDEO = 'NO_VIDEO'
export const RESTART_STUDENT = 'RESTART_STUDENT'
export const STATUS_TEACHER = 'STATUS_TEACHER'
export const PRIMARY_CAM = 'PRIMARY_CAM'
export const SECOND_CAM = 'SECOND_CAM'
export const PRIMARY_MIC = 'PRIMARY_MIC'
export const SECOND_MIC = 'SECOND_MIC'

export const SCHOOL_CONFIGURATION = 'SCHOOL_CONFIGURATION'
export const UPDATE_SCHOOL_CONFIGURATION = 'UPDATE_SCHOOL_CONFIGURATION'

//TODO:===> KSM_CLOUD ACTIONS

export const TOTAL_UPLOADED = 'TOTAL_UPLOADED';
export const ROOT_FILES = 'ROOT_FILES';
