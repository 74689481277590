import {Col, Row} from "react-material-responsive-grid";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchConfCalificaciones,
    getConversiones
} from "../../../redux/actions/CalificacionesActions/CalificacionActions";
import {SuspenseImg} from "./SuspenseImage";

export const RenderMenus = forwardRef((props, ref) => {
    const {onPress, menu, principal = false, validation = false} = props;
    const [menuIndex, setMenuIndex] = useState(-1);
    const mostrar_ranking = useSelector(state => state.calificaciones.mostrar_ranking);
    const indicadoresGenerales = useSelector((state) => state.calificaciones.indicadores_generales);
    const indicadores = useSelector((state) => state.calificaciones.indicadores);
    const comments_maestro = useSelector(state => state.calificaciones.comments_maestro);
    const comments_prefecto = useSelector(state => state.calificaciones.comments_prefecto);
    const {
        bd,
        token,
        userRole,
        kolegiaBlue,
        secondColor,
        fourthColor,
        fifthColor,
        grayColorOne
    } = useSelector(state => state.rooot);
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        setIndexHome() {
            setMenuIndex(-1)
        },
        onPressed(it, i) {
            setMenuIndex(i)
        }
    }));

    useEffect(() => {
        if (validation) {
            dispatch(fetchConfCalificaciones(bd, token));
            dispatch(getConversiones(bd, token));
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setMenuIndex(-1)
    }, [menu]) // eslint-disable-line react-hooks/exhaustive-deps

    const onPressed = (it, i) => {
        setMenuIndex(i)
        onPress(it, i)
    }

    const renderMenus = menu.map((it, i) => {
        const color = {
            backgroundColor: menuIndex === i ? secondColor : grayColorOne,
            height: '5.5vMax',
            borderRadius: 14,
            lineHeight: 1,
            padding: '.8vh',
            borderWidth: 0,
            position: 'relative',
        };
        // let mostrarRanking = !mostrar_ranking && it.nombre.toString() === 'Calcular ranking';
        // let mostrarDesempenos = !indicadores && it.nombre.toString() === 'Niveles de desempeño' ? !indicadoresGenerales : false;
        let comentariosMaestro = userRole === 'Maestro' && !comments_maestro && it.nombre.toString() === 'Observaciones generales';
        let comentariosPrefecto = userRole === 'Prefecto' && !comments_prefecto && it.nombre.toString() === 'Observaciones generales';

        // let bg =menuIndex === i ? "uk-button uk-button-default uk-width-1-1 btn-grad" :  "uk-button uk-button-default uk-width-1-1 btn-normal"
        // let text =menuIndex === i ? "i uk-width-1-1 uk-text-truncate btn-text-grad" :  "i uk-width-1-1 uk-text-truncate btn-text-normal"

        let bg = menuIndex === i ? "uk-button uk-button-default uk-width-1-1" : "uk-button uk-button-default uk-width-1-1"
        let text = menuIndex === i ? "i uk-width-1-1 uk-text-truncate" : "i uk-width-1-1 uk-text-truncate"
        if (comentariosMaestro || comentariosPrefecto) {
            return null
        } else {
            const width = principal ? "principalButtonWidth" : "secondaryButtonWidth"
            return (<Row
                key={'menu' + i}
                className={"centerAll " + width}
                style={{margin: 0, padding: 5,}}>
                <button
                    key={i + 'MenuParametros'} style={color}
                    data-uk-tooltip={'title:' + it.nombre + ';pos: bottom'}
                    className={bg}
                    onClick={() => onPressed(it, i)}>
                    {it.beta === 1 || it.beta === "1"
                        ? <div className='ribbon-wrapper-red'>
                            <div className='ribbon-red'>
                                Beta
                            </div>
                        </div>
                        : it.beta === 2 || it.beta === "2"
                            ? <div className='ribbon-wrapper-red'>
                                <div className='ribbon-red' style={{backgroundColor: kolegiaBlue}}>
                                    Nuevo
                                </div>
                            </div>
                            : null}
                    <SuspenseImg alt="" src={it.url} style={{height: '2.5vMax', width: '2.5vMax'}}/>
                    <br/>
                    <Col hiddenDown={'lg'} className="i uk-width-1-1 uk-text-truncate" style={{
                        margin: 0,
                        padding: 0,
                        marginTop: 5,
                        maxHeight: '3vMax',
                        color: menuIndex === i ? fourthColor : fifthColor,
                        textTransform: 'capitalize'
                    }}>{it.nombre}</Col>
                    <Col hidden={['xs4', 'xs8', 'sm8', 'sm', 'md', 'xl']} className={text}
                         style={{
                             margin: 0,
                             padding: 0,
                             marginTop: 5,
                             maxHeight: '3vMax',
                             color: menuIndex === i ? fourthColor : fifthColor,
                             textTransform: 'capitalize'
                         }}>{it.nombre}</Col>
                </button>
            </Row>);
        }
    });

    return (
        <Row className="uk-width-1-1">
            {renderMenus}
            <br/>
        </Row>
    );
});
