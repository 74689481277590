import {
    ALL_MATERIAS_MAESTRO,
    ALL_SUBJECTS,
    LOADING_ASIGNAR_MAESTRO,
    MAESTROS,
    MATERIAS_FALTANTES,
    RELACION_MATERIAS,
    RELATION,
    RELATION_INFO
} from '../../../actions/types';

const initialState = {
        loading: false,
        materias_faltantes: [],
        relacion_materias: [],
        materias: [],
        maestros: [],
        relation: [],
        all_materias_maestro: [],
        relation_info: [],
    }
;

export default function AsignarMaestrosReducer(state = initialState, action) {

    switch (action.type) {
        case MATERIAS_FALTANTES:
            return {
                ...state,
                materias_faltantes: action.payload,
                loading: false
            };
        case RELACION_MATERIAS:
            return {
                ...state,
                relacion_materias: action.payload,
                loading: false
            };
        case ALL_SUBJECTS:
            return {
                ...state,
                materias: action.payload,
                loading: false
            };
        case RELATION_INFO:
            return {
                ...state,
                relation_info: action.payload,
                loading: false
            };
        case MAESTROS:
            return {
                ...state,
                maestros: action.payload,
                loading: false
            };
        case RELATION:
            return {
                ...state,
                relation: action.payload,
                loading: false
            };
        case ALL_MATERIAS_MAESTRO:
            return {
                ...state,
                all_materias_maestro: action.payload,
                loading: false
            };
        case LOADING_ASIGNAR_MAESTRO:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
