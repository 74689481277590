import {AppEnv} from "../../../../components/env";
import {
    GET_HORARIO_ALUMNO,
    GET_HORARIO_MAESTRO,
    GET_HORARIO_PADRE,
    GET_HORARIO_PREFECTO,
    LOADING_HORARIO,
} from "../../types";
import {alertFail} from "../../../../components/Global-W/NewFunctions";

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_HORARIO,
        payload: data
    };
}

export function fetchHorarioMaestro(bd, token, ciclo, user, jornada, i = 1, horario = []) {
    return (dispatch) => {
        dispatch(setLoading(true));
        fetch(apiUrl + bd + '/schedules/v3/get/by/teacher/' +
            ciclo + '/' + user + '/' + i + '/' + jornada, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then(res => res.json())
            .then(responseJson => {
                if (responseJson.error !== undefined) {
                    alertFail();
                } else {
                    let object_horario = [];
                    responseJson.forEach((itm, i) => {
                        if (itm.id_maestro === null) {
                            if (itm.horario.dia === this.state.elDia[i].id) {
                                object_horario.push({
                                    inicio: itm.inicio,
                                    fin: itm.fin,
                                    tipo_hora: itm.tipo_hora,
                                    id_maestro: '',
                                    id_materia: '',
                                    materia: ''
                                });
                            }
                        } else {
                            object_horario.push(itm);
                        }
                    });
                    if (i === 1) {
                        horario.push(object_horario)
                    }
                    horario.push(object_horario)

                    if (i >= 5) {
                        if (object_horario.length !== 0) {
                            dispatch(fetchHorarioMaestroSuccess(horario));
                        } else {
                            dispatch(fetchHorarioMaestroSuccess(horario));
                        }
                    } else {
                        dispatch(fetchHorarioMaestro(bd, token, ciclo, user, jornada, i + 1, horario))
                    }
                }
            }).catch((error) => {
            dispatch(fetchHorarioMaestroFailed());
        });
    }
}

export function fetchHorarioMaestroSuccess(response) {
    return {
        type: GET_HORARIO_MAESTRO,
        payload: response,
    };
}

export function fetchHorarioMaestroFailed() {
    alertFail();
    return {
        type: GET_HORARIO_MAESTRO,
        payload: [],
    };
}


export function fetchHorarioAlumno(bd, token, ciclo, i = 1, jornada, grado, grupo, horario = []) {
    return (dispatch) => {
        dispatch(setLoading(true));
        fetch(apiUrl + bd + '/schedules/v3/get/' +
            ciclo + '/' + i + '/' + jornada + '/' + grado + '/' + grupo, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then(res => res.json())
            .then(responseJson => {
                if (responseJson.error !== undefined) {
                    alertFail();
                } else {
                    let object_horario = [];
                    responseJson.forEach((itm, i) => {
                        if (itm.id_maestro === null) {
                            if (itm.horario.dia === this.state.elDia[i].id) {
                                object_horario.push({
                                    inicio: itm.inicio,
                                    fin: itm.fin,
                                    tipo_hora: itm.tipo_hora,
                                    id_maestro: '',
                                    id_materia: '',
                                    materia: ''
                                });
                            }
                        } else {
                            object_horario.push(itm);
                        }
                    });
                    if (i === 1) {
                        horario.push(object_horario)
                    }
                    horario.push(object_horario)
                    if (i === 5 && object_horario.length !== 0) {
                        dispatch(fetchHorarioAlumnoSuccess(horario));
                    } else {
                        dispatch(fetchHorarioAlumno(bd, token, ciclo, i + 1, jornada, grado, grupo, horario))
                    }
                }
            }).catch((error) => {
            dispatch(fetchHorarioAlumnoFailed());
        });
    }
}

export function fetchHorarioAlumnoSuccess(response) {
    return {
        type: GET_HORARIO_ALUMNO,
        payload: response,
    };
}

export function fetchHorarioAlumnoFailed() {
    alertFail();
    return {
        type: GET_HORARIO_ALUMNO,
        payload: [],
    };
}


export function fetchHorarioPadre(bd, token, ciclo, i = 1, jornada, grado, grupo, alumno, horario = []) {
    return (dispatch) => {
        dispatch(setLoading(true));
        fetch(apiUrl + bd + '/schedules/v3/by/alumno/' +
            ciclo + '/' + i + '/' + jornada + '/' + grado + '/' + grupo + '/' + alumno, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then(res => res.json())
            .then(responseJson => {
                if (responseJson.error !== undefined) {
                    alertFail();
                } else {
                    let object_horario = [];
                    responseJson.forEach((itm, i) => {
                        if (itm.id_maestro === null) {
                            if (itm.horario.dia === this.state.elDia[i].id) {
                                object_horario.push({
                                    inicio: itm.inicio,
                                    fin: itm.fin,
                                    tipo_hora: itm.tipo_hora,
                                    id_maestro: '',
                                    id_materia: '',
                                    materia: ''
                                });
                            }
                        } else {
                            object_horario.push(itm);
                        }
                    });
                    if (i === 1) {
                        horario.push(object_horario)
                    }
                    horario.push(object_horario)
                    if (i === 5 && object_horario.length !== 0) {
                        dispatch(fetchHorarioPadreSuccess(horario));
                    } else {
                        dispatch(fetchHorarioPadre(bd, token, ciclo, i + 1, jornada, grado, grupo, alumno, horario))
                    }
                }
            }).catch((error) => {
            dispatch(fetchHorarioPadreFailed());
        });
    }
}

export function fetchHorarioPadreSuccess(response) {
    return {
        type: GET_HORARIO_PADRE,
        payload: response,
    };
}

export function fetchHorarioPadreFailed() {
    alertFail();
    return {
        type: GET_HORARIO_PADRE,
        payload: [],
    };
}


export function fetchHorarioPrefecto(bd, token, ciclo, i = 1, jornada, grado, grupo, horario = []) {
    return (dispatch) => {
        dispatch(setLoading(true));
        fetch(apiUrl + bd + '/schedules/v3/get/' +
            ciclo + '/' + i + '/' + jornada + '/' + grado + '/' + grupo, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then(res => res.json())
            .then(responseJson => {
                if (responseJson.error !== undefined) {
                    alertFail();
                } else {
                    let object_horario = [];
                    responseJson.forEach((itm, i) => {
                        if (itm.id_maestro === null) {
                            if (itm.horario.dia === this.state.elDia[i].id) {
                                object_horario.push({
                                    inicio: itm.inicio,
                                    fin: itm.fin,
                                    tipo_hora: itm.tipo_hora,
                                    id_maestro: '',
                                    id_materia: '',
                                    materia: ''
                                });
                            }
                        } else {
                            object_horario.push(itm);
                        }
                    });
                    if (i === 1) {
                        horario.push(object_horario)
                    }
                    horario.push(object_horario)
                    if (i <= 5 && object_horario.length !== 0) {
                        dispatch(fetchHorarioPrefectoSuccess(horario));

                    } else {
                        dispatch(fetchHorarioPrefecto(bd, token, ciclo, i + 1, jornada, grado, grupo, horario))
                    }
                }
            }).catch((error) => {
            dispatch(fetchHorarioPrefectoFailed());
        });
    }
}

export function fetchHorarioPrefectoSuccess(response) {
    return {
        type: GET_HORARIO_PREFECTO,
        payload: response,
    };
}

export function fetchHorarioPrefectoFailed() {
    alertFail();
    return {
        type: GET_HORARIO_PREFECTO,
        payload: [],
    };
}

