import React, {useEffect, useRef, useState} from 'react';
import {Row} from 'react-material-responsive-grid';
import {AppEnv} from "../../env";

const ImageZoom = (props) => {
    const {
        src,
        download = true
    } = props;
    const [isPanning, setPanning] = useState(false);
    const [image, setImage] = useState();
    const [scales, setScales] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [position, setPosition] = useState({
        oldX: 0,
        oldY: 0,
        x: 0,
        y: 0,
        z: 1,
    });

    const containerRef = useRef();

    const onLoad = (e) => {
        setImage({
            width: e.target.naturalWidth,
            height: e.target.naturalHeight,
        });
    };

    const onMouseDown = (e) => {
        e.preventDefault();
        setPanning(true);
        setPosition({
            ...position,
            oldX: e.clientX,
            oldY: e.clientY
        });
    };

    const onWheel = () => {
        const sign = -0.1;
        const scale = 1 - sign;
        const rect = containerRef.current.getBoundingClientRect();

        setPosition({
            ...position,
            x: position.x * scale - (rect.width / 2) * sign,
            y: position.y * scale - (((image.height * rect.width) / image.width) / 2) * sign,
            z: position.z * scale,
        });
    };

    const onWheelMinus = (e) => {
        const sign = 0.1;
        const scale = 1 - sign;
        const rect = containerRef.current.getBoundingClientRect();

        setPosition({
            ...position,
            x: position.x * scale - (rect.width / 2) * sign,
            y: position.y * scale - (((image.height * rect.width) / image.width) / 2) * sign,
            z: position.z * scale,
        });
    };

    useEffect(() => {
        const mouseup = () => {
            setPanning(false);
        };

        const mousemove = (event) => {
            if (isPanning) {
                setPosition({
                    ...position,
                    x: position.x + event.clientX - position.oldX,
                    y: position.y + event.clientY - position.oldY,
                    oldX: event.clientX,
                    oldY: event.clientY,
                });
            }
        };

        window.addEventListener('mouseup', mouseup);
        window.addEventListener('mousemove', mousemove);

        return () => {
            window.removeEventListener('mouseup', mouseup);
            window.removeEventListener('mousemove', mousemove);
        };
    }); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            ref={containerRef}
            className="Image-container"
            style={{width: '80vw', height: '75vh', alignItems: 'center', justifyContent: 'center'}}
            onMouseDown={onMouseDown}
        >
            <div
                style={{transform: `translate(${position.x}px, ${position.y}px) scale(${position.z})`}}
            >
                <img
                    className="Image-image"
                    alt=""
                    style={{transform: `rotate(${rotate}deg)`}}
                    src={src}
                    onLoad={onLoad}
                />
            </div>
            <Row
                className="uk-width-1-1 centerAll"
                style={{
                    bottom: 5,
                    margin: 0,
                    padding: 0,
                    marginTop: 10,
                    marginBottom: 20,
                    position: 'absolute'
                }}
            >
                <div
                    data-uk-tooltip="Rotar a la izquierda"
                    style={{
                        width: '8%',
                        borderRadius: 6,
                        padding: 10,
                        backgroundColor: '#242424',
                        textAlign: 'center',
                        color: AppEnv.fourthColor
                    }}
                    onClick={() => setRotate(rotate - 90)}
                >
                    <i className="fas fa-undo-alt" style={{fontSize: '2rem'}}/>
                </div>
                <div
                    style={{
                        width: '8%',
                        borderRadius: 6,
                        marginLeft: 5,
                        padding: 10,
                        backgroundColor: '#242424',
                        textAlign: 'center',
                        color: AppEnv.fourthColor
                    }}
                    onClick={(e) => onWheelMinus(e)}
                >
                    <i className="fas fa-search-minus" style={{fontSize: '2rem'}}/>
                </div>
                <div
                    style={{
                        width: '8%',
                        marginLeft: 5,
                        borderRadius: 6,
                        padding: 10,
                        backgroundColor: '#242424',
                        textAlign: 'center',
                        color: AppEnv.fourthColor
                    }}
                    onClick={(e) => onWheel(e)}
                >
                    <i className="fas fa-search-plus" style={{fontSize: '2rem'}}/>
                </div>
                <div
                    data-uk-tooltip="Rotar a la derecha"
                    style={{
                        width: '8%',
                        marginLeft: 5,
                        borderRadius: 6,
                        padding: 10,
                        backgroundColor: '#242424',
                        textAlign: 'center',
                        color: AppEnv.fourthColor
                    }}
                    onClick={() => setRotate(rotate + 90)}

                >
                    <i className="fas fa-redo-alt" style={{fontSize: '2rem'}}/>
                </div>

                {download ?
                    <a href={src} style={{width: '8%'}} target={'blank'} download>
                        <div style={{
                            width: '100%',
                            marginLeft: 5,
                            borderRadius: 6,
                            padding: 10,
                            backgroundColor: '#242424',
                            textAlign: 'center',
                            color: AppEnv.fourthColor
                        }}>

                            <i className="fas fa-download" style={{fontSize: '2rem', color: AppEnv.fourthColor}}/>

                        </div>
                    </a> : null}
            </Row>
        </div>
    );
};

export default ImageZoom;
