import React, {Component} from 'react';
import {Grid, Row} from 'react-material-responsive-grid';
import PropTypes from 'prop-types';
import {getLogo} from "../NewFunctions";

class Loading extends Component {
    static propTypes = {
        imagen: PropTypes.string
    };

    static defaultProps = {
        imagen: getLogo()
    };

    constructor(props) {
        super(props);
        this.state = {
            imagen: getLogo()
        };
    }

    render() {
        return (
            <Grid className={'centerAll'} style={{
                height: '100vh',
                width: '100vw',
                backgroundColor: 'rgba(255,255,255,.95)',
                position: 'fixed',
                zIndex: 1,
            }}>
                <Row className="centerAll" style={{
                    height: '100vh',
                    width: '100vw',
                }}>
                    <img style={{width: '60vw'}} alt='' className='lazyload' data-src={this.props.imagen}/>
                </Row>
            </Grid>
        );
    }
}

export default Loading;
