import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    fetchHorarioPadre,
    fetchHorarioPadreSuccess
} from "../../redux/actions/Configuracion/HorariosActions/HorariosActions";
import {fetchJornadaComments} from "../../redux/actions/Configuracion/JornadasActions/JornadasActions";
import {Row} from "react-material-responsive-grid";
import FaltaMensaje from "../Global-W/Classes/FaltaMensaje";
import {alertFail, suspenseFallback} from "../Global-W/NewFunctions";
import Modal from "../Global-W/Hooks/Modal";
import axios from "axios";

const moment = require('moment/moment');
require('moment/locale/es');
moment.locale('es');

export default function HorarioAlumno() {
    const dispatch = useDispatch();
    const horarioPadre = useSelector(state => state.horarios.horario_padre);
    const jornadasComments = useSelector(state => state.jornadas.jornadas_comments);
    const load = useSelector(state => state.horarios.loading);
    const [comentarios, setComentarios] = useState([])
    const [hijo, setHijo] = useState('')
    const [jornada, setJornada] = useState('')
    const [modalComment, setModalComment] = useState(false);
    const [jornadasPadre, setJornadasPadre] = useState([]);
    const {
        bd,
        cicloActual,
        token,
        api,
        thirdColor,
        fourthColor,
        fifthColor,
        grayColorOne,
    } = useSelector(state => state.rooot);

    useEffect(() => {
        setComentarios(jornadasComments)
        return () => {
            dispatch(fetchHorarioPadreSuccess([]));
        };
    }, [jornadasComments]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchJornadas = async () => {
        try {
            const {data} = await axios.get(api + bd + "/jornadas/padre/" + cicloActual.id + '/' + hijo.id, {
                method: "GET", headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + token
                },
            });
            setJornadasPadre(data);
            if (data.length === 1) {
                setJornada(data[0])
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        fetch(api + bd + '/users/get/alumno', {
            method: 'GET', headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then(response => response.json())
            .then(responseJson => {
                if (responseJson.error === null) {
                    alertFail();
                } else {
                    setHijo(responseJson)
                }
            });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (jornada !== '' && hijo.id_grupo !== undefined) {
            dispatch(fetchHorarioPadre(bd, token, cicloActual.id, 1, jornada.id, hijo.id_grado, hijo.id_grupo, hijo.id))
            dispatch(fetchJornadaComments(bd, token, hijo.id_grado, hijo.id_grupo, jornada.id, cicloActual.id))
        }
    }, [jornada]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (hijo !== '') {
            fetchJornadas()
        }
    }, [hijo]) // eslint-disable-line react-hooks/exhaustive-deps


    const renderjornadasPadre = jornadasPadre.map((it, i) => {
        return <Row key={i + 'j'} className="uk-width-1-3" style={{margin: 0, padding: 5}}>
            <Row data-uk-tooltip={it.alias.toUpperCase()} className='normal_text uk-width-1-1 centerAll'
                 style={{
                     margin: 0,
                     borderRadius: 6,
                     padding: 8,
                     backgroundColor: jornada.id === it.id ? thirdColor : grayColorOne,
                     color: jornada.id === it.id ? fourthColor : fifthColor,
                     textAlign: 'center'
                 }}
                 onClick={() => setJornada(it)}>
                <label className="uk-width-1-1" style={{fontSize: 11, textAlign: 'center'}}>
                    {it.alias.toUpperCase()}
                </label>
            </Row>
        </Row>
    })

    const comment = comentarios.map((item, im) => {
        return <Row key={im + 'comment'} className="uk-width-1-1"
                    style={{margin: 0, padding: 3, marginBottom: 5}}>
            <Row className="uk-width-1-1"
                 style={{
                     margin: 0,
                     padding: 10,
                     borderRadius: 6,
                     backgroundColor: grayColorOne
                 }}>
                <Row className="uk-width-1-1" style={{marginTop: 5, marginBottom: 10}}>
                    <Row className="uk-width-1-1 main_title">
                        Comentario {im + 1}
                    </Row>
                </Row>
                <Row className="uk-width-1-1 section_title_two"
                >
                    Titulo
                </Row>
                <Row className="uk-width-1-1 normal_text"
                     style={{margin: 0, padding: 5, backgroundColor: fourthColor, borderRadius: 6}}>
                    {item.titulo}
                </Row>
                <Row className="uk-width-1-1 section_title_two"
                     style={{
                         margin: 0,
                         padding: 0,
                         marginTop: 10,
                     }}>
                    Comentario
                </Row>
                <Row className="uk-width-1-1 normal_text"
                     style={{
                         margin: 0,
                         padding: 5,
                         backgroundColor: fourthColor,
                         borderRadius: 6
                     }}>
                    {item.comentario}
                </Row>
            </Row>
        </Row>
    });

    const hora = (itemHora, i) => {
        return (
            <Row className="uk-width-1-1 centerAll" key={i + 'rndHor'}
                 style={{margin: 0, padding: 3}}>
                <Row className='uk-width-1-1 centerAll' style={{
                    backgroundColor: grayColorOne,
                    borderRadius: 6,
                    margin: 0,
                    padding: 5,
                    height: 40
                }}>
                    <Row style={{
                        margin: 0,
                        padding: 0,
                        height: 30,
                        borderColor: grayColorOne,
                        backgroundColor: itemHora.horario === null
                            ? grayColorOne
                            : itemHora.horario.relacion === null
                                ? grayColorOne
                                : fourthColor,
                        borderRadius: 6
                    }} className='uk-width-1-1 centerAll'>
                        <label className='uk-width-1-1 centerAll normal_text uk-text-truncate' style={{
                            margin: 0,
                            padding: 0,
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            verticalAlign: 'middle'
                        }}>
                            {itemHora.horario === null
                                ? 'Sin asignar'
                                : itemHora.horario.relacion === null
                                    ? 'Sin asignar'
                                    : itemHora.horario.relacion.nombre.nombre
                            }
                        </label>
                    </Row>
                </Row>
            </Row>);
    }

    const receso = (itemHora, i) => {
        return (
            <Row className="uk-width-1-1 centerAll" key={i + 'rndHor'}
                 style={{margin: 0, padding: 3}}>
                <Row className='uk-width-1-1 centerAll' style={{
                    backgroundColor: thirdColor,
                    borderRadius: 6,
                    margin: 0,
                    padding: 0,
                    height: 20
                }}>
                    <Row style={{
                        margin: 0,
                        padding: 0,
                        borderRadius: 6
                    }} className='uk-width-1-1 centerAll'>
                        <Row className='uk-width-1-1 txtnegritasMini centerAll'
                             style={{color: fourthColor, height: '100%'}}>
                            RECESO
                        </Row>
                    </Row>
                </Row>
            </Row>
        );
    }

    const horas = (itm, i, ix) => {
        let start = moment(itm.inicio, 'HH:mm').format('HH:mm');
        let end = moment(itm.fin, 'HH:mm').format('HH:mm');
        return <Row className="uk-width-1-1 centerAll" key={i + ix + 'r'}
                    style={{margin: 0, padding: 3}}>
            <Row className='uk-width-1-1 centerAll' style={{
                backgroundColor: itm.tipo_hora === '2' ? thirdColor : grayColorOne,
                borderRadius: 6,
                margin: 0,
                padding: 0,
                height: itm.tipo_hora === '2' ? 20 : 40
            }}>
                <Row style={{
                    margin: 0,
                    padding: 0,
                    borderRadius: 6
                }} className='uk-width-1-1 centerAll'>
                    <Row className='uk-width-1-1 section_title_two centerAll'
                         style={{
                             margin: 0,
                             padding: 0,
                             color: itm.tipo_hora === '2' ? fourthColor : null,
                             height: '100%'
                         }}>
                        {start}{' - '}{end}
                    </Row>
                </Row>
            </Row>
        </Row>
    }

    const renderHorario = horarioPadre.map((it, i) => {
        const renderDia = it.map((itm, ix) => {
            if (i === 0) {
                return horas(itm, i, ix)
            } else {
                if (itm.tipo_hora === '2') {
                    return receso(itm, ix)
                } else {
                    return hora(itm, ix)
                }
            }
        });
        let dia = i === 0
            ? 'Horas'
            : i === 1
                ? 'Lunes'
                : i === 2
                    ? 'Martes'
                    : i === 3
                        ? 'Miércoles'
                        : i === 4
                            ? 'Jueves'
                            : 'Viernes';
        return <Row key={i + 'horario'} className="uk-width-1-6">
            <Row className='uk-width-1-1 section_title_two centerAll' style={{marginBottom: 3}}>
                {dia.toUpperCase()}
            </Row>
            {renderDia}
        </Row>
    })

    const columnaIzquierda = () => {
        return <Row style={{margin: 0, padding: 15}} className='uk-width-1-1 centerH'>
            <Row className='module_title uk-width-1-1'>
                Horario escolar
            </Row>
            <Row className="uk-width-1-1"
            >
                <Row className="uk-width-1-2@m"
                     style={{margin: 0, padding: 3}}>
                    <Row className='main_title uk-width-1-1 '>
                        Tutor del grupo
                    </Row>
                    <Row style={{height: '100%'}}
                         className='uk-width-1-1 normal_text'>
                        {hijo.prefecto !== null && hijo.prefecto !== undefined ? hijo.prefecto.name : 'Sin tutor asignado'}
                    </Row>
                </Row>
            </Row>
            {jornadasPadre.length !== 1
                ? <Row className="uk-width-1-1 centerAll"
                >
                    <Row className='main_title uk-width-1-1 ' style={{marginTop: 10}}>
                        Seleccione una jornada
                    </Row>
                    <Row className='uk-width-1-1'>
                        {jornadasPadre.length !== 0
                            ? renderjornadasPadre
                            : <FaltaMensaje mensaje={'Sin jornadas disponibles'}/>}
                    </Row>
                </Row>
                : null}
            {comentarios.length !== 0
                ? <Row className="uk-width-1-1 "
                       style={{margin: '10px 0', padding: 0}}>
                    <Row className='main_title uk-width-1-1 '>
                        Comentarios
                    </Row>
                    <Row className='uk-width-1-2 '>
                        <Row className='normal_text uk-width-2-3 centerAll'
                             style={{
                                 margin: 0,
                                 borderRadius: 6,
                                 padding: 8,
                                 backgroundColor: grayColorOne,
                                 color: fifthColor,
                                 textAlign: 'center'
                             }}
                             onClick={() => setModalComment(true)}>
                            <label className="uk-width-1-1 normal_text">
                                Ver comentarios
                            </label>
                        </Row>
                    </Row>
                </Row>
                : null}
            {jornadasPadre.length !== 1
                ? <Row className='main_title uk-width-1-1 '
                       style={{marginTop: 10, marginBottom: 10}}>
                    Horario
                </Row> : null}
            {load ? <Row className="uk-width-1-1 centerAll" style={{maxHeight: '70vh'}}>
                {suspenseFallback()}
            </Row> : horarioPadre.length !== 0 ?
                <Row className="uk-width-1-1 centerAll" style={{maxHeight: '70vh'}}>
                    {renderHorario}
                </Row>
                : <FaltaMensaje mensaje={jornada === '' ? 'Seleccione una jornada' : 'Sin Horarios Disponibles'}/>}
            <Modal size={'small-modal'}
                   open={modalComment}
                   onClose={() => {
                       setModalComment(false)
                   }}
                   child={
                       <Row className="uk-width-1-1 centerAll"
                       >
                           {comment}
                       </Row>
                   }/>
        </Row>
    }

    return columnaIzquierda()

}
