import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";

import {Provider} from 'react-redux';
import {GlobalConfigurationProvider} from "./Context/GlobalConfigurationContext";
import AppRoutes from "./routes";
import Store from "./redux/store"

ReactDOM.render(
    <React.StrictMode>
        <Provider store={Store}>
            <GlobalConfigurationProvider>
                <Router>
                    <AppRoutes/>
                </Router>
            </GlobalConfigurationProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
