import {CATEGORIAS, LOADING_CATEGORIAS} from '../../../actions/types';

const initialState = {
        loading: false,
        categorias: []
    }
;

export default function CategoriasReducer(state = initialState, action) {

    switch (action.type) {
        case CATEGORIAS:
            return {
                ...state,
                categorias: action.payload,
                loading: false
            };
        case LOADING_CATEGORIAS:
            return {
                ...state,
                loading: action.payload,
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
