import React from 'react';
import {Row} from "react-material-responsive-grid";
import {AppEnv} from "../../env";
import {getWithExpiry, randomBackground, setWithExpiry, whatsAppMessage} from "../NewFunctions";
import axios from "axios";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
            modal: false,
            bd: sessionStorage.getItem('bd'),
            id: sessionStorage.getItem('id'),
            role: sessionStorage.getItem('role'),
            puesto: sessionStorage.getItem('puesto'),
            grado: sessionStorage.getItem('grado'),
            grupo: sessionStorage.getItem('grupo'),
            name: sessionStorage.getItem('name'),
            activo: sessionStorage.getItem('activo'),
            estatus: sessionStorage.getItem('estatus'),
            schoolId: sessionStorage.getItem('schoolId'),
            descripcion: ''
        }
    }

    componentDidCatch(error, errorInfo) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (error?.message && chunkFailedMessage.test(error.message)) {
            if (!getWithExpiry("chunk_failed")) {
                setWithExpiry("chunk_failed", "true", 10000);
                caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
                caches.keys().then((names) => {
                    names.forEach(name => {
                        caches.delete(name);
                    })
                }).then(() => window.location.reload(true));
            }
        }
        const now = new Date()
        const errorToString = String(error)
        const msg = {
            error: errorToString,
            errorInfo: errorInfo,
            bd: this.state.bd,
            id: this.state.id,
            role: this.state.role,
            puesto: this.state.puesto,
            grado: this.state.grado,
            grupo: this.state.grupo,
            name: this.state.name,
            activo: this.state.activo,
            estatus: this.state.estatus,
            schoolId: this.state.schoolId,
            date: now
        }
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    async sendReport() {
        let formData = new FormData();
        formData.append('message', this.state.descripcion
            + "\n\nError -> " + this.state.error
            + "\n\nError Info -> " + JSON.stringify(this.state.errorInfo));
        await axios.post(AppEnv.api + this.state.bd + '/zoom/make/error', formData, {
            method: 'post', headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }).then(res => res.data)
            .then(responseJson => {
                if (responseJson.error !== undefined) {
                    window.location.reload(true)
                } else {
                    window.location.reload(true)
                }
            }).catch(error => {
                window.location.reload(true)
            });
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <Row
                    style={{
                        margin: 0,
                        padding: 0,
                    }}
                    className="full centerAll">
                    <img style={{
                        objectFit: 'cover', position: 'absolute', zIndex: '-1000',
                        height: '100vh',
                    }} className='lazyload centerAll uk-width-1-1' data-src={randomBackground()} alt="Fondo"/>
                    <Row className='uk-width-1-3@l uk-width-3-4@m uk-width-1-1@s centerAll'
                         style={{
                             margin: 0,
                             padding: 30,
                             backgroundColor: AppEnv.fourthColor,
                             borderRadius: 15
                         }}>
                        <Row className="uk-width-1-1"
                        >
                            <img alt="" src="https://img.icons8.com/dusk/80/000000/online-support--v1.png"/>
                        </Row>
                        <div className="uk-width-1-1 module_title"
                             style={{margin: '20px 0 0 0', padding: 0, textAlign: 'left', lineHeight: 1}}>
                            Contacte a <label style={{color: AppEnv.redColor}}>soporte</label>
                        </div>
                        <Row className="uk-width-1-1 normal_text "
                             style={{margin: '5px 0 0 0', padding: 0, textAlign: 'justify', lineHeight: 1}}>
                            Al parecer, sucedió algo inesperado. Vamos a investigar la situación. Es muy importante que
                            nos apoyes enviándonos la mayor cantidad posible de detalles acerca de lo que estabas
                            haciendo antes de ver esta pantalla. Si requieres apoyo de manera urgente, haz clic en el
                            botón de soporte técnico.
                        </Row>

                        <Row className="uk-width-1-1 sub_title "
                             style={{
                                 margin: '15px 0 0 0',
                                 color: AppEnv.fifthColor,
                                 padding: 0,
                                 textAlign: 'justify',
                                 lineHeight: 1
                             }}>
                            Cuéntanos la situación
                        </Row>

                        <Row className="uk-width-1-1 txtnegritasMiniBlack"
                             style={{
                                 margin: '3px 0 0 0',
                                 color: AppEnv.redColor,
                                 padding: 0,
                                 textAlign: 'justify',
                                 lineHeight: 1
                             }}>
                            ¿Qué estaba realizando antes de que apareciera esta ventana?
                        </Row>

                        <textarea
                            maxLength={256}
                            placeholder="Escriba aquí su respuesta"
                            rows={8}
                            className="uk-textarea uk-width-1-1 normal_text"
                            onChange={(e) => this.setState({'descripcion': e.target.value})}
                            style={{
                                lineHeight: 1, padding: 10, margin: '5px 0 0 0', borderRadius: 7, textAlign: 'left'
                            }}
                            value={this.state.descripcion}/>

                        <Row className="uk-width-1-1"
                             style={{margin: '15px 0 0 0', justifyContent: 'flex-end', padding: 0}}>


                            <Row className="uk-width-1-5"
                                 onClick={() => {
                                     this.sendReport()
                                     whatsAppMessage(this.state.descripcion, '1', '2282205275')
                                 }}
                                 style={{margin: 0, padding: 5}}>
                                <Row className="uk-width-1-1 normal_text centerAll" style={{
                                    margin: 0,
                                    padding: 5,
                                    borderRadius: 6,
                                    backgroundColor: AppEnv.greenColor,
                                    color: AppEnv.fourthColor,
                                }}>
                                    <i style={{color: AppEnv.fourthColor, fontSize: '20px', padding: '0 5px 0 0'}}
                                       className="fab fa-whatsapp"/> <span>Soporte</span>
                                </Row>
                            </Row>

                            <Row className="uk-width-1-5"
                                 onClick={() => this.sendReport()}
                                 style={{margin: 0, padding: 5}}>
                                <Row className="uk-width-1-1 normal_text centerAll" style={{
                                    margin: 0,
                                    padding: 5,
                                    borderRadius: 6,
                                    backgroundColor: AppEnv.grayColorTwo,
                                    color: AppEnv.fifthColor,
                                }}>
                                    <i style={{color: AppEnv.fifthColor, fontSize: '20px', padding: '0 5px 0 0'}}
                                       className="fas fa-redo"/><span>Recargar</span>
                                </Row>
                            </Row>

                            <Row className="uk-width-1-5"
                                 onClick={() => this.sendReport()}
                                 style={{margin: 0, padding: 5}}>
                                <Row className="uk-width-1-1 normal_text centerAll" style={{
                                    margin: 0,
                                    padding: 5,
                                    borderRadius: 6,
                                    backgroundColor: AppEnv.secondColor,
                                    color: AppEnv.fourthColor,
                                }}>
                                    <i style={{color: AppEnv.fourthColor, fontSize: '20px', padding: '0 5px 0 0'}}
                                       className="fas fa-paper-plane"/><span>Enviar</span>
                                </Row>
                            </Row>

                        </Row>
                    </Row>
                </Row>
            );
        }
        return this.props.children;
    }
}