import React, {Suspense} from 'react';
import ErrorBoundary from './components/Global-W/Classes/ErrorBoundary'
import {useSelector} from "react-redux";
import Loading from "./components/Global-W/Classes/Loading";
import KepComponent from "./components/Global-W/Content/kep";
import KsmComponent from "./components/Global-W/Content/ksm";
import LiceoComponent from "./components/Global-W/Content/liceo";

const DarkModeContent = React.lazy(() => import("./components/Global-W/DarkModeContent"));
const Content = React.lazy(() => import("./components/Global-W/Content"));

export default function App(props) {
    const {NombreCorto} = useSelector(state => state.rooot)
    const {children} = props;

    return (
        <div className="App">
                <ErrorBoundary>
                    <Suspense fallback={<Loading/>}>
                        {NombreCorto !== ''
                            ? {
                            'kep': <KepComponent body={children}/>,
                            'kolegia': <KsmComponent body={children}/>,
                            'liceoanimas': <LiceoComponent body={children}/>,
                        }[NombreCorto] ||  <KsmComponent body={children}/>
                            : null
                        }
                    </Suspense>
                </ErrorBoundary>
        </div>
    );
}
