import React, {useContext, useEffect, useRef, useState} from "react";
import {Row} from "react-material-responsive-grid";
import Modal from "../../../Global-W/Hooks/Modal";
import {AppEnv} from "../../../env";
import HorarioAlumno from "../../../Horario-W/HorarioAlumno";
import {alertWarning, pulseLoader, warn} from "../../../Global-W/NewFunctions";
import {useDispatch, useSelector} from "react-redux";
import {
    setCurrentItemClass,
    setMyAudioStatus,
    setMyVideoStatus,
    SetTeachers,
    startTransmission
} from "../../../../redux/actions/VirtualClassActions/VirtualClassActions";
import Timer from "../../Maestro/VirtualClassBeta/Components/Timer";
import AnswerActivityBeta from "./Components/AnswerActivityBeta";
import KolegiaBroadcastContext from "../../../../Context/KolegiaBroadcast";
import {setModalActivityOnClass} from "../../../../redux/actions";

const moment = require('moment');
require('moment/locale/es');
moment.locale('es');

export default function MainVideo({connectToClass}) {
    const dispatch = useDispatch();
    const [buttonConnect, setButtonConnect] = React.useState(true);
    const [available, setAvailable] = useState(true)
    const {
        classData,
        room_id,
        main_stream,
        loading,
        connecting_class,
        status_class,
        nextClass,
        teachers_list, currentItemClass,
        lastVersion,
        status_teacher
    } = useSelector(state => state.virtual_class);
    const {
        socketRef,
        sendRequest,
        sendNewMessage
    } = useContext(KolegiaBroadcastContext);
    const {
        secondColor,
        userRole,
        grayColorFive,
        grayColorThree,
        grayColorOne,
        schoolData,
        fifthColor,
        grayColorFour,
        redColor,
        fourthColor,
        userName,
        userId,
        bd
    } = useSelector(state => state.rooot);
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [second, setSecond] = useState('');
    const [timeFinished, setTimeFinished] = useState(false);
    const userMedia = useRef()

    const bodyMessage = {
        name: userName,
        room_id: room_id,
        user_id: userId,
        bd_school: bd,
        type_message: 2,
        date: moment().format('YYYY-MM-DD HH:mm:ss')
    }

    // info("------ RENDER MAIN_VIDEO-----");

    useEffect(() => {
        if (main_stream !== null) {
            setButtonConnect(false);
        } else {
            setButtonConnect(true);
        }
    }, [main_stream]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let x = teachers_list.find(item => item.user_id = room_id.split("/")[1])
        if (x !== undefined) {
            setAvailable(x.buttons)
        }
    }, [teachers_list])

    useEffect(() => {
        if (Object.keys(nextClass).length !== 0) {
            const interval = setInterval(() => {
                const end = moment(nextClass.horarios.inicio, 'HH:mm:ss');
                const duration = moment.duration(end.diff(moment()));
                setHour((duration.hours() < 10) ? '0' + duration.hours().toString() : duration.hours().toString());
                setMinute((duration.minutes() < 10) ? '0' + duration.minutes().toString() : duration.minutes().toString());
                setSecond((duration.seconds() < 10) ? '0' + duration.seconds().toString() : duration.seconds().toString());
                setTimeFinished(duration.hours() <= 0 && duration.minutes() <= 0 && duration.seconds() <= 0);
            }, 1000);
            if (userRole !== 'Admin') {
                return () => clearInterval(interval);
            }
        }
        if (status_class === 2) {
            const interval = setInterval(() => {
                const end = moment(classData.horarios.inicio, 'HH:mm:ss');
                const duration = moment.duration(end.diff(moment()));
                setHour((duration.hours() < 10) ? '0' + duration.hours().toString() : duration.hours().toString());
                setMinute((duration.minutes() < 10) ? '0' + duration.minutes().toString() : duration.minutes().toString());
                setSecond((duration.seconds() < 10) ? '0' + duration.seconds().toString() : duration.seconds().toString());
                setTimeFinished(duration.hours() <= 0 && duration.minutes() <= 0 && duration.seconds() <= 0);
            }, 1000);
            if (userRole !== 'Admin') {
                return () => clearInterval(interval);
            }
        }
    }, [nextClass]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (timeFinished) {
            // dispatch(refreshClass({is_refresh:true, next_same:false}));
        }
    }, [timeFinished]) // eslint-disable-line react-hooks/exhaustive-deps

    if (socketRef.current !== undefined) {
        socketRef.current.on("close class", async () => {
            setButtonConnect(true)
            dispatch(setModalActivityOnClass(false))
            // dispatch(connectingToClass(false))
        })
    }

    const handleLeaveClass = async () => {
        await sendRequest('admin_leave_room', {room_id}).then(async (res) => {
            warn('-data leave-', res);
            await dispatch(setCurrentItemClass(null))
        });

    }

    const handleMuteTeacher = () => {
        let clone = [...teachers_list]
        let find = teachers_list.indexOf(teachers_list.find(it => it.user_id.toString() === currentItemClass.user_id.toString()))
        let body = ''
        if (currentItemClass.mic) {
            body = 'desactivó el audio del docente'
            socketRef.current.emit('close_audio_to_teacher', {
                socket_teacher: currentItemClass.socket_id,
                teacher_id: currentItemClass.user_id,
                user_id: currentItemClass.user_id,
                requesting: userId,
                admin_room: schoolData.id.toString() + "/ADMIN"
            })
            if (find !== -1) {
                clone[find].mic = false
                dispatch(SetTeachers(clone))
            }
        } else {
            body = 'solicitó encender el micrófono del docente'
            clone[find].buttons = false
            dispatch(SetTeachers(clone))
            socketRef.current.emit('open_audio_to_teacher', {
                socket_teacher: currentItemClass.socket_id,
                teacher_id: currentItemClass.user_id,
                user_id: currentItemClass.user_id,
                requesting: userId,
                admin_room: schoolData.id.toString() + "/ADMIN",
                admin: userName,
            })
        }
        let messageContent = {
            ...bodyMessage,
            body: body,
            id_subject: currentItemClass.id_materia,
            senderId: socketRef.current.id
        };
        sendNewMessage(messageContent);
    }
    const handleClickVideo = () => {
        let body = ''
        let clone = [...teachers_list]
        let find = teachers_list.indexOf(teachers_list.find(it => it.user_id.toString() === currentItemClass.user_id.toString()))
        if (currentItemClass.camera) {
            body = 'desactivó la cámara del docente'
            socketRef.current.emit('close_video_to_teacher', {
                socket_teacher: currentItemClass.socket_id,
                teacher_id: currentItemClass.user_id,
                user_id: currentItemClass.user_id,
                requesting: userId,
                admin_room: schoolData.id.toString() + "/ADMIN"
            })
            if (find !== -1) {
                clone[find].camera = false
                dispatch(SetTeachers(clone))
            }
        } else {
            body = 'solicitó encender la cámara del docente'
            clone[find].buttons = false
            dispatch(SetTeachers(clone))
            socketRef.current.emit('open_video_to_teacher', {
                socket_teacher: currentItemClass.socket_id,
                teacher_id: currentItemClass.user_id,
                user_id: currentItemClass.user_id,
                requesting: userId,
                admin_room: schoolData.id.toString() + "/ADMIN",
                admin: userName,
            })
        }
        let messageContent = {
            ...bodyMessage,
            body: body,
            id_subject: currentItemClass.id_materia,
            senderId: socketRef.current.id
        };
        sendNewMessage(messageContent);
    }

    const getMicStatus = () => {
        let find = teachers_list.find(it => it.user_id.toString() === currentItemClass.user_id.toString())
        return find !== undefined ? find.mic : false
    }

    const getCameraStatus = () => {
        let find = teachers_list.find(it => it.user_id.toString() === currentItemClass.user_id.toString())
        return find !== undefined ? find.camera : false
    }

    const capitalizeString = (letter) => {
        const arr = letter.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].toLowerCase().slice(1);

        }
        return arr.join(" ");
    }
    const headerAdmin = () => {
        return (
            <Row className="uk-width-1-1 centerAll"
                 style={{padding: 5, height: '8%', justifyContent: 'flex-start', backgroundColor: grayColorThree}}>
                {status_class === 1
                    ? <Row className="centerAll"
                           style={{width: '10%', padding: '0', justifyContent: 'flex-start'}}>
                        <Row
                            className="txtnegritasMiniBlack centerAll uk-width-1-1"
                            style={{
                                padding: '3px 4px',
                                borderRadius: 3,
                                backgroundColor: status_class === 1 ? redColor : grayColorFour,
                                fontSize: '.7rem',
                                color: fourthColor
                            }}>
                            EN VIVO
                        </Row>
                    </Row> : null}
                <Row className="centerAll" style={{width: '5%'}}>
                    <i data-uk-tooltip={lastVersion} className="fas fa-info-circle"
                       style={{color: fifthColor, fontSize: '1rem'}}/>
                </Row>
                {status_class === 1
                    ? <Row className=" section_title_two uk-text-truncate centerAll"
                           style={{width: '85%', padding: 0, alignItems: 'center', height: '100%'}}>
                        <label
                            className="uk-width-1-2 section_title_two uk-text-truncate"
                            style={{
                                textAlign: 'left',
                                textTransform: 'uppercase'
                            }}>
                            {currentItemClass.data.split(":")[0]}
                        </label>
                        <label
                            className="uk-width-1-4"
                            data-uk-tooltip="Tiempo restante"
                        >
                        </label>
                        <Row className={'uk-width-1-4'} style={{justifyContent: 'flex-end'}}>
                            <Row className={'uk-width-1-2'}
                                 style={{
                                     backgroundColor: redColor,
                                     borderRadius: 3,
                                     padding: '3px 4px',
                                     color: fourthColor,
                                     justifyContent: 'center',
                                     alignItems: 'center',
                                     cursor: 'pointer'
                                 }}
                                 onClick={() => {
                                     handleLeaveClass()
                                 }}
                            >
                                <Row className={'uk-width-1-3'} style={{
                                    justifyContent: 'flex-end',
                                    padding: 2,
                                    alignItems: 'center',
                                    height: '100%'
                                }}>
                                    <div><i className="fas fa-arrow-circle-left"/></div>
                                </Row>
                                <Row className={'uk-width-2-3'} style={{justifyContent: 'center'}}>
                                    <label className={'uk-text-truncate'}>
                                        Salir
                                    </label>
                                </Row>
                            </Row>
                        </Row>
                    </Row>
                    : null}
            </Row>
        )
    }

    const toggleFullScreen = () => {
        userMedia.current.removeAttribute("controls");
        if (userMedia.current.requestFullscreen) {
            userMedia.current.requestFullscreen();
        } else if (userMedia.current.msRequestFullscreen) {
            userMedia.current.msRequestFullscreen();
        } else if (userMedia.current.mozRequestFullScreen) {
            userMedia.current.mozRequestFullScreen();
        } else if (userMedia.current.webkitRequestFullscreen) {
            userMedia.current.webkitRequestFullscreen();
        }
    }

    return loading
        ? <Row
            className="uk-width-1-1 normal_text animated-background"
            style={{
                margin: 0,
                padding: 0,
                height: '100%',
                borderRadius: 6,
                overflow: 'hidden',
            }}/>
        : <Row
            className="uk-width-1-1 normal_text"
            style={{
                margin: 0,
                padding: 0,
                height: '100%',
                borderRadius: 6,
                backgroundColor: grayColorThree,
                overflow: 'hidden',
            }}>
            {
                userRole === 'Admin' ? headerAdmin() : <Timer/>
            }
            <div className="uk-width-1-1"
                 style={{height: '84%', backgroundColor: grayColorOne}}>
                {buttonConnect ?
                    connecting_class ?
                        <Row className="uk-width-1-1 centerAll" style={{height: '100%'}}>
                            <div className="uk-width-1-1 centerAll alertPulse-css-1" style={{fontSize: '1rem'}}>
                                Conectando a la clase...
                            </div>
                            {pulseLoader(secondColor)}
                        </Row>
                        : <Row className="uk-width-1-1 centerAll" style={{height: '100%'}}>
                            <div>
                                {status_class === 1
                                    ? <div data-uk-tooltip="Conectarse a la clase" onClick={() => {
                                        if (loading === true) {
                                            connectToClass(false);
                                            alertWarning('Espere a que termine de cargar los datos de la clase');
                                        } else if (status_class === 1) {
                                            connectToClass(true);
                                        }
                                    }} style={{cursor: 'pointer'}}>
                                        <img alt=""
                                             className="alertPulse-css-1"
                                             src={"https://img.icons8.com/color/100/play-button-circled--v3.png"}
                                             style={{height: '45%'}}/>
                                        <div className="uk-width-1-1 centerAll" style={{fontSize: '1rem', marginTop: 15}}>
                                            ¡Hola, bienvenido!
                                        </div>
                                        <div className="uk-width-1-1 centerAll" style={{fontSize: '1rem'}}>
                                            {userRole === 'Alumno' ? (status_teacher === undefined || status_teacher === '0' ? 'El maestro aún no está en línea en el aula virtual.' : status_teacher === '1'
                                                ? 'El maestro ya se encuentra conectado al aula virtual, pero aún no inicia la clase. Por favor espere.' :
                                                'El maestro ya ha iniciado la clase, por favor únete haciendo click en el botón rojo de arriba.') : null}
                                        </div>
                                    </div> : null}
                                {status_class === 0 ?
                                    <div data-uk-tooltip="Esperando..." onClick={() => {
                                        if (status_class === 0 || status_class === 1) {
                                            alertWarning('En espera de la siguiente clase');
                                        }
                                    }} style={{cursor: 'pointer'}}>
                                        <img alt=""
                                             src={"https://img.icons8.com/color/100/000000/curriculum.png"}
                                             style={{height: '40%'}}/>
                                        <div className="uk-width-1-1 centerAll" style={{fontSize: '1rem', marginTop: 15}}>
                                            ¡Se paciente, en breve comenzamos!
                                        </div>
                                        <div className="uk-width-1-1 centerAll"
                                             style={{fontSize: '1rem'}}>
                                            La clase de {capitalizeString(nextClass.relacion.nombre.nombre)}
                                        </div>
                                        <div className="uk-width-1-1 centerAll"
                                             style={{fontSize: '1rem', marginBottom: 10}}>
                                            iniciará en {hour}:{minute}:{second} {hour > 0 ? 'horas' : 'minutos'}.
                                        </div>
                                    </div> : null}
                                {status_class === 2 ?
                                    <div data-uk-tooltip="Receso" onClick={() => {
                                        if (status_class === 2) {
                                            alertWarning('Es tiempo del receso');
                                        }
                                    }} style={{cursor: 'pointer'}}>
                                        <img alt=""
                                             src={"https://img.icons8.com/external-justicon-lineal-color-justicon/100/000000/external-coffee-time-coffee-shop-justicon-lineal-color-justicon-1.png"}
                                             style={{height: '45%'}}/>
                                        <div className="uk-width-1-1 centerAll" style={{fontSize: '1rem', marginTop: 15}}>
                                            ¡Es tiempo de descanso, tienes un receso!
                                        </div>
                                        <div className="uk-width-1-1 centerAll" style={{fontSize: '1rem'}}>
                                            Las clases reanudarán en {minute}:{second} minutos.
                                        </div>
                                    </div> : null}
                                {status_class === 3 ?
                                    <div data-uk-tooltip="Sin clases" onClick={() => {
                                        if (status_class === 3) {
                                            alertWarning('Las clases del día han concluido');
                                        }
                                    }} style={{cursor: 'pointer'}}>
                                        <img alt=""
                                             src={"https://img.icons8.com/color/100/000000/realtime-protection.png"}
                                             style={{height: '45%'}}/>
                                        <div className="uk-width-1-1 centerAll" style={{fontSize: '1rem', marginTop: 15}}>
                                            ¡Felicidades, lo lograste!
                                        </div>
                                        <div className="uk-width-1-1 centerAll" style={{fontSize: '1rem', marginTop: 0}}>
                                            Las clases del día de hoy han finalizado.
                                        </div>
                                    </div> : null}
                            </div>
                        </Row>
                    : <MediaStreaming userMedia={userMedia} streaming={main_stream}/>}
            </div>
            <Row className="uk-width-1-1"
                 style={{height: '8%', backgroundColor: AppEnv.grayColorThree}}>

                {userRole === 'Admin' ?
                    <Row className="uk-width-2-3">
                        <Row className={'uk-width-2-3'} style={{justifyContent: 'flex-start'}}>
                            <Row className={'uk-width-1-2'}>
                                <Row title={'Controles de cámara del maestro'}
                                     className={'uk-width-1-3'}
                                     onClick={() => {
                                         available ?
                                             handleClickVideo() : alertWarning('Esperando la respuesta del maestro, no puede utilizar los botones nuevamente')
                                     }}
                                     style={{
                                         cursor: 'pointer',
                                         alignItems: 'center',
                                         fontWeight: 'bold',
                                         fontSize: '0.5rem',
                                         justifyContent: 'center',
                                         height: '100%'
                                     }}>

                                    {!currentItemClass.camera ? <div>
                                            <img src="https://img.icons8.com/material-rounded/20/FF0000/no-video--v1.png"
                                                 alt={''}/>
                                        </div> :
                                        <div>
                                            <img src="https://img.icons8.com/ios-glyphs/20/000000/video-call.png"
                                                 alt={''}/>
                                        </div>}
                                </Row>
                                <Row title={'Controles de micrófono del maestro'}
                                     className={'uk-width-1-3'}
                                     onClick={() => {
                                         available ?
                                             handleMuteTeacher() : alertWarning('Esperando la respuesta del maestro, no puede utilizar los botones nuevamente')
                                     }}
                                     style={{
                                         cursor: 'pointer',
                                         alignItems: 'center',
                                         fontWeight: 'bold',
                                         fontSize: '0.5rem',
                                         justifyContent: 'center'
                                     }}>
                                    {!currentItemClass.mic ? <div>
                                            <img src="https://img.icons8.com/ios-glyphs/20/FF0000/no-microphone.png"
                                                 alt={''}/>
                                        </div> :
                                        <div>
                                            <img src="https://img.icons8.com/ios-glyphs/20/000000/microphone.png"
                                                 alt={''}/>
                                        </div>}
                                </Row>
                                <Row title={'Pantalla completa'}
                                     className={'uk-width-1-3'}
                                     onClick={() => {
                                         toggleFullScreen()
                                     }}
                                     style={{
                                         cursor: 'pointer',
                                         alignItems: 'center',
                                         fontWeight: 'bold',
                                         fontSize: '0.5rem',
                                         justifyContent: 'center',
                                         height: '100%'
                                     }}>
                                    <div
                                        style={{
                                            margin: '0 0 0 10px',
                                        }}>
                                        <img alt=""
                                             data-uk-tooltip="Pantalla completa"
                                             src="https://img.icons8.com/material/20/0000000/full-screen--v1.png"/>
                                    </div>
                                </Row>
                            </Row>
                        </Row>
                    </Row> : <ControlsStudent userMedia={userMedia}/>}
            </Row>
        </Row>
};

const MediaStreaming = ({userMedia, streaming}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [stream, setStream] = useState(null)
    const [child, setChild] = useState(null)
    const [message, setMessage] = useState('')

    useEffect(() => {
        if (streaming !== null) {
            openMediaDevice()
        }
    }, [streaming]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isLoading) {
            if (!stream) {
                setChild(<Message message={message}/>);
            } else {
                setChild(<VideoMedia userMedia={userMedia} stream={stream}/>);
            }
        }
    }, [isLoading, stream, message]) // eslint-disable-line react-hooks/exhaustive-deps

    const openMediaDevice = () => {
        try {
            setChild(null);
            setStream(streaming);
        } catch (e) {
            setMessage("Can't connect stream");
            setStream(null)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Row className='uk-width-1-1' style={{height: '100%'}}>
            {isLoading ? pulseLoader(AppEnv.secondColor) : child}
        </Row>
    )
}

const VideoMedia = ({userMedia, stream = null}) => {
    const {fourthColor} = useSelector(state => state.rooot)
    const {main_video_status, main_audio_status} = useSelector(state => state.virtual_class)
    useEffect(() => {
        if (stream !== null) {
            userMedia.current.srcObject = stream;
        }
    }, [stream]); // eslint-disable-line react-hooks/exhaustive-deps

    return <Row className="uk-width-1-1" style={{height: '100%', position: 'relative'}}>
        {!main_video_status ? <Row className="uk-width-1-1 centerAll" style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(73,73,73,0.99)',
            position: 'absolute'
        }}>
            <div className={"normal_text"} style={{fontSize: '1rem', color: fourthColor}}>
                Video desactivado
            </div>
        </Row> : null}
        {!main_audio_status ? <Row className="uk-width-1-1 centerAll" style={{
            width: '100%',
            height: '5%',
            backgroundColor: 'rgba(73,73,73,0.99)',
            position: 'absolute',
            bottom: 0
        }}>
            <div className={"normal_text"} style={{fontSize: '1rem', color: fourthColor}}>
                Audio desactivado
            </div>
        </Row> : null}

        <video className="uk-width-1-1"
               playsInline
               ref={userMedia}
               autoPlay
               style={{
                   backgroundColor: "black",
                   height: '100%',
                   objectFit: 'contain',
                   pointerEvents: "none"
               }}/>
    </Row>

}

const Message = ({message}) => {
    return <div className="uk-width-1-1" style={{color: AppEnv.fourthColor}}>
        {message}
    </div>
}

const ControlsStudent = ({userMedia}) => {
    const {sendRequest} = useContext(KolegiaBroadcastContext)
    const dispatch = useDispatch();
    const {
        room_id,
        main_stream,
        connecting_class,
        start_transmission,
        status_class,
        my_video_status,
        my_audio_status,
        no_video
    } = useSelector(state => state.virtual_class);
    const [modalSched, setModalSched] = useState(false);
    const [connectedClass, setConnectedClass] = useState(false);
    const [aux, setAux] = useState(false)
    const {userId, userName, bd, userRole, grayColorThree} = useSelector(state => state.rooot);
    const bodyMessage = {
        name: userName,
        room_id: room_id,
        user_id: userId,
        bd_school: bd,
        type_message: 2,
        date: moment().format('YYYY-MM-DD HH:mm:ss')
    }

    useEffect(() => {
        if (main_stream !== null) {
            // dispatch(changeChatStatus(true))
            setConnectedClass(true);
        } else {
            setConnectedClass(false);
            setAux(false)
            // dispatch(changeChatStatus(false))
            dispatch(startTransmission(false))
        }
    }, [main_stream])

    useEffect(() => {
        if (connectedClass && !connecting_class && main_stream !== null && userRole === 'Alumno') {
            setTimeout(() => {
                sendRequest('open_my_video', {id: userId, room_id: room_id})
                sendRequest('open_my_audio', {id: userId, room_id: room_id})
                dispatch(startTransmission(true));
                setAux(true)
            }, 2500)
        }
    }, [connecting_class, connectedClass, main_stream])

    const toggleFullScreen = () => {

        userMedia.current.removeAttribute("controls");
        if (userMedia.current.requestFullscreen) {
            userMedia.current.requestFullscreen();
        } else if (userMedia.current.msRequestFullscreen) {
            userMedia.current.msRequestFullscreen();
        } else if (userMedia.current.mozRequestFullScreen) {
            userMedia.current.mozRequestFullScreen();
        } else if (userMedia.current.webkitRequestFullscreen) {
            userMedia.current.webkitRequestFullscreen();
        }
    };

    const IconFullScreen = () => {
        return <img alt=""
                    style={{height: 20, cursor: 'pointer'}}
                    data-uk-tooltip="Pantalla completa"
                    src="https://img.icons8.com/material/32/0000000/full-screen--v1.png"/>

    };

    const IconViewAudio = () => {
        if (my_audio_status === null) {
            return <img alt=""
                        data-uk-tooltip="Micrófono no disponible"
                        style={{height: 20}}
                        src="https://img.icons8.com/material/50/808080/microphone--v1.png"/>
        } else if (my_audio_status) {
            return <img alt=""
                        data-uk-tooltip="Micrófono activado"
                        style={{height: 20}}
                        src="https://img.icons8.com/material/50/000000/microphone--v1.png"/>
        } else {
            return <img alt=""
                        data-uk-tooltip="Micrófono desactivado"
                        style={{height: 20}}
                        src="https://img.icons8.com/material/50/ff0000/no-microphone--v1.png"/>
        }
    };

    const IconViewVideo = () => {
        if (my_video_status === null) {
            return <img alt=""
                        data-uk-tooltip="Video no disponible"
                        style={{height: 20}}
                        src="https://img.icons8.com/material/50/808080/video-call--v1.png"/>
        } else if (my_video_status) {
            return <img alt=""
                        data-uk-tooltip="Video activado"
                        style={{height: 20}}
                        src="https://img.icons8.com/material/50/000000/video-call--v1.png"/>
        } else {
            return <img alt=""
                        data-uk-tooltip="Video desactivado"
                        style={{height: 20}}
                        src="https://img.icons8.com/material/50/ff0000/no-video--v1.png"/>
        }
    };

    return status_class === 1 ?
        <Row className="uk-width-1-1" style={{justifyContent: 'flex-start', backgroundColor: grayColorThree}}>
            <AnswerActivityBeta/>
            <Row
                className="uk-width-1-10 centerAll hover-background-gray"
                data-uk-tooltip="Revisar mi horario"
                onClick={() => setModalSched(true)}>
                <div>
                    <i className="fas fa-calendar-alt" style={{fontSize: '1.2rem'}}/>
                </div>
            </Row>
            {(connectedClass && connecting_class) && !no_video ? <Row
                className="uk-width-1-10 centerAll">
                CONECTANDO...
            </Row> : null}
            {(!aux && connectedClass) ? <Row
                className="uk-width-1-10 centerAll">
                INICIANDO TRANSMISIÓN...
            </Row> : null}
            {connectedClass && !connecting_class && start_transmission && !no_video
                ? <Row
                    className={"uk-width-1-10 centerAll hover-background-gray " + (my_video_status === null ? 'uk-disabled' : '')}
                    onClick={async () => {
                        if (start_transmission && my_video_status !== null) {
                            await dispatch(setMyVideoStatus(!my_video_status))
                        } else {
                            alertWarning('No cuentas con dispositivos de video, no puedes operar este botón')
                        }
                    }}>
                    <div>
                        {IconViewVideo()}
                    </div>
                </Row> : null}
            {connectedClass && !connecting_class && start_transmission && !no_video
                ? <Row
                    className={"uk-width-1-10 centerAll hover-background-gray " + (my_audio_status === null ? 'uk-disabled' : '')}
                    onClick={async () => {
                        if (start_transmission && my_audio_status !== null) {
                            await dispatch(setMyAudioStatus(!my_audio_status))
                        } else {
                            alertWarning('No cuentas con dispositivos de audio, no puedes operar este botón')
                        }
                    }}
                >
                    <div>
                        {IconViewAudio()}
                    </div>
                </Row> : null}

            {connectedClass && !connecting_class && start_transmission ?
                <Row className="centerAll hover-background-gray"
                     style={{height: '100%', padding: 3, width: '7%'}}>
                    <div onClick={toggleFullScreen}
                         style={{
                             margin: '0 0 0 10px',
                         }}>
                        {IconFullScreen()}
                    </div>
                </Row> : null}
            {/*{connectedClass && !connecting_class && start_transmission*/}
            {/*    ? <Row className="uk-width-1-10 centerAll hover-background-gray"*/}
            {/*           onClick={async () => {*/}
            {/*              */}
            {/*           }}>*/}
            {/*        <div>*/}
            {/*            <img*/}
            {/*                alt=""*/}
            {/*                data-uk-tooltip="Pantalla completa"*/}
            {/*                style={{height: 20}}*/}
            {/*                src="https://img.icons8.com/material-sharp/24/000000/full-screen--v1.png"/>*/}
            {/*        </div>*/}
            {/*    </Row> : null}*/}
            <Modal size={"medium-modal"} open={modalSched} onClose={() => {
                setModalSched(false)
            }}>
                <Row className="uk-width-1-1">
                    <HorarioAlumno/>
                </Row>
            </Modal>
        </Row> : null
}

