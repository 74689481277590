import React, {useState} from 'react';
import {Row} from 'react-material-responsive-grid';
import {pulseLoader} from "../NewFunctions";
import {useSelector} from "react-redux";

export const Button = (props) => {
    const [load, setLoad] = useState(false)
    const [active, setActive] = useState(false)

    const handleOnClick = async () => {
        setLoad(true)
        try {
            await action()
        } catch (error) {
            console.error('error -> ', error)
        } finally {
            setLoad(false)
        }
    }

    const {
        icon = "fas fa-save uk-width-1-1",
        action,
        type = 'primary',
        title,
        size = "n"
    } = props

    const {
        fourthColor,
        secondColor,
        fifthColor,
        thirdColor,
        grayColorFive
    } = useSelector(state => state.rooot)

    return <Row className="uk-width-1-1"
                onClick={() => handleOnClick()}
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
                style={{
                    border: "1px solid",
                    borderRadius: 10,
                    cursor: "pointer",
                    pointerEvents: load ? 'none' : null,
                    borderColor: load
                        ? grayColorFive
                        : type === 'primary'
                            ? secondColor
                            : thirdColor
                }}>
        <Row className="uk-width-1-5 centerAll"
             style={{
                 padding: size === 'n' ? 5 : 2,
                 backgroundColor: load
                     ? grayColorFive
                     : type === 'primary'
                         ? secondColor
                         : thirdColor,
                 borderRadius: "7px 0 0 7px"
             }}>
            <Row className="uk-width-1-1 centerAll">
                <i style={{color: fourthColor}} className={icon}/>
            </Row>
        </Row>
        <Row className="uk-width-4-5 centerAll"
             style={{
                 padding: size === 'n' ? 5 : 2,
                 backgroundColor: active
                     ? type === 'primary'
                         ? secondColor
                         : thirdColor
                     : fourthColor, borderRadius: "0 9px 9px 0"
             }}>
            {load
                ? <Row style={{padding: size === 'n' ? 5 : 2}}>
                    {pulseLoader(grayColorFive)}
                </Row>
                : <Row className="uk-width-1-1 centerAll uk-text-uppercase"
                       style={{
                           fontSize: size === 'n' ? null : 15,
                           fontFamily: 'SF-regular',
                           color: active
                               ? fourthColor
                               : fifthColor
                       }}>
                    {title}
                </Row>}

        </Row>
    </Row>;
}
