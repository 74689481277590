import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-material-responsive-grid';
import {useSelector} from "react-redux";
import LogsRoom from "./LogsRoom";
import GuestsView from "./GuestsView";

export default function ExtrasVirtualClass() {
    const {grayColorOne, grayColorTwo, userRole, grayColorThree} = useSelector(state => state.rooot);
    const {loading, currentItemClass, current_guests} = useSelector(state => state.virtual_class);
    const [viewSelected, setViewSelected] = useState('logs');

    useEffect(() => {
        if (currentItemClass !== null && userRole === 'Admin') {
            setViewSelected('guests');
        }
    }, [currentItemClass])

    useEffect(() => {
        if (current_guests.length !== 0 && userRole !== 'Admin') {
            setViewSelected('guests');
        } else {
            if (userRole !== 'Admin') {
                setViewSelected('logs');
            }
        }
    }, [current_guests])

    return loading ? <Row
        className="uk-width-1-1 centerAll normal_text animated-background"
        style={{
            padding: 5,
            height: '100%',
            borderRadius: 6,
            backgroundColor: grayColorTwo,
            justifyContent: 'flex-start'
        }}/> : <Row className="uk-width-1-1 centerAll"
                    style={{
                        height: '100%',
                        borderRadius: 6,
                        overflow: 'hidden'
                    }}
    >
        <Row className="uk-width-1-1"
             style={{height: '8%', backgroundColor: grayColorThree, justifyContent: 'flex-start'}}>
            <Row
                className="uk-width-1-8 centerAll hover-background-gray"
                style={{backgroundColor: viewSelected === 'logs' ? grayColorOne : null}}
                onClick={() => setViewSelected('logs')}
            >
                <img
                    alt=""
                    data-uk-tooltip="Historial de acciones de la clase"
                    style={{height: 22}}
                    src="https://img.icons8.com/material-outlined/100/000000/log.png"

                />
            </Row>
            <Row
                className="uk-width-1-8 centerAll hover-background-gray"
                style={{backgroundColor: viewSelected === 'guests' ? grayColorOne : null}}
                onClick={() => setViewSelected('guests')}
            >
                <img
                    alt=""
                    data-uk-tooltip="Invitados a la clase"
                    style={{height: 22}}
                    src="https://img.icons8.com/material/100/000000/user-shield.png"
                />
            </Row>
            {/*<Row*/}
            {/*    className="uk-width-1-8 centerAll hover-background-gray"*/}
            {/*    style={{backgroundColor:viewSelected ==='waiting room' ?grayColorOne:null }}*/}
            {/*    // onClick={()=>setViewSelected('guests')}*/}
            {/*>*/}
            {/*    <img*/}
            {/*        alt=""*/}
            {/*        data-uk-tooltip="Sala de espera"*/}
            {/*        style={{height: 22}}*/}
            {/*        src="https://img.icons8.com/ios-filled/48/000000/to-come.png"*/}
            {/*    />*/}
            {/*</Row>*/}
            {/*<Row*/}
            {/*    className="uk-width-1-8 centerAll hover-background-gray"*/}
            {/*    style={{backgroundColor:viewSelected ==='plannings' ?grayColorOne:null }}*/}
            {/*    // onClick={()=>setViewSelected('guests')}*/}
            {/*>*/}
            {/*    <img*/}
            {/*        alt=""*/}
            {/*        data-uk-tooltip="Planeación didáctica"*/}
            {/*        style={{height: 22}}*/}
            {/*        src="https://img.icons8.com/ios-filled/100/000000/class.png"*/}
            {/*    />*/}
            {/*</Row>*/}
        </Row>
        <Col className="uk-width-1-1 centerAll"
             style={{
                 margin: 0,
                 backgroundColor: grayColorOne,
                 height: 'calc(100% - 5%)'
             }}>
            {viewSelected === 'logs' ? <LogsRoom/> : null}
            {viewSelected === 'guests' ? <GuestsView/> : null}
        </Col>
    </Row>
}
