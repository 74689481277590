import {useDispatch, useSelector} from "react-redux";
import React, {memo, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {Row} from 'react-material-responsive-grid';
import {alertFail, alertWarning, customAlert, getFirstLetters, pulseLoader, warn} from "../../Global-W/NewFunctions";
import {
    connectingToClass,
    noVideoStream,
    producerStated,
    startTransmission
} from "../../../redux/actions/VirtualClassActions/VirtualClassActions";
import {confirmAlert} from "react-confirm-alert";
import KolegiaBroadcastContext from "../../../Context/KolegiaBroadcast";
import FaltaMensaje from "../../Global-W/Classes/FaltaMensaje";

const moment = require('moment');
require('moment/locale/es');
moment.locale('es');

const VideoBox = ({item = null, stream = null}) => {
    const streamUser = useRef();
    useEffect(() => {
        if (stream !== null && item !== null) {
            streamUser.current.srcObject = stream;
        }
    }, [item, stream]) // eslint-disable-line react-hooks/exhaustive-deps

    return <video ref={streamUser} className="uk-width-1-1" playsInline autoPlay
                  style={{backgroundColor: "black", height: '100%', objectFit: 'contain'}}/>
};

const VideoBox2 = ({item = null, stream = null}) => {
    const streamUser = useRef();

    useEffect(() => {
        if (stream !== null && item !== null) {
            streamUser.current.srcObject = stream;
        }
    }, [item, stream]) // eslint-disable-line react-hooks/exhaustive-deps

    return <video ref={streamUser} playsInline autoPlay
                  style={{display: 'none'}}/>
};

export function GuestsView() {
    const {sendRequest, handleConnect, handleDisconnectProducer} = useContext(KolegiaBroadcastContext);
    const {
        room_id,
        current_guests,
        remoteVideos,
        data_guest,
        start_transmission,
    } = useSelector(state => state.virtual_class);
    const {userId, secondColor, userRole, userName, bd, userPuesto} = useSelector(state => state.rooot);
    const localVideo = React.useRef();
    const localStream = React.useRef();
    const localStreamAudio = React.useRef();
    const localStreamVideo = React.useRef();
    const device = React.useRef();
    const producerTransport = React.useRef();
    const videoProducer = React.useRef({});
    const audioProducer = React.useRef({});
    const dispatch = useDispatch();
    const [useVideo, setUseVideo] = React.useState(true);
    const [useAudio, setUseAudio] = React.useState(true);
    const [connectClass, setConnectClass] = React.useState(false);
    const [statusConnection, setStatusConnection] = useState('');
    const [isStartMedia, setIsStartMedia] = useState(false);
    const [PageSize, setPageSize] = useState(2);

    const bodyMessage = {
        name: userName,
        room_id: room_id,
        user_id: userId,
        bd_school: bd,
        type_message: 2,
        date: moment().format('YYYY-MM-DD HH:mm:ss')
    }

    const mediaConstraits = {
        video: {
            width: {min: 240, ideal: 240, max: 320},
            height: {min: 180, ideal: 180, max: 240},
            aspectRatio: {ideal: 1.7777777778},
            frameRate: {
                min: 10,
                ideal: 15,
                max: 20,
            }
        },
        audio: {
            echoCancellationType: 'system',
            sampleSize: 16,
            channelCount: 2,
            volume: 1.5,
            googAutoGainControl: true,
            googNoiseSuppression: true,
        }
    };

    const guests = useMemo(() => {
        function order(a, b) {
            if (a.user_id.toString() === userId.toString()) {
                return -1
            }
            return 0;
        }

        const maped = current_guests.map((item, i) => {
            item.has_video = remoteVideos[item['socket_id']] !== undefined;
            item['video_box_stream'] = remoteVideos[item['socket_id']] !== undefined ? remoteVideos[item['socket_id']]['stream']['stream'] : null

            return item
        })

        return maped.sort(order)
    }, [current_guests, remoteVideos, PageSize]);

    useLayoutEffect(() => {
        function updateSize() {
            setPageSize(window.innerWidth >= 1600
                ? 2
                : window.innerWidth >= 960
                    ? 1
                    : window.innerWidth >= 750
                        ? 1
                        : 1)
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        return () => {
            if (userRole === 'Admin') {
                handleDisconnectProducer();
                handleStopMedia();
            }
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userRole === 'Admin' && start_transmission === true) {
            handleStartMedia();
        }
    }, [start_transmission]) // eslint-disable-line react-hooks/exhaustive-deps

    function handleStopMedia() {
        warn('ENTRA A StopMedia')
        if (localStream.current) {
            stopLocalStream(localStream.current);
            localStream.current = null;
            localVideo.current = null;
        }
    }


    function stopLocalStream(stream) {
        let tracks = stream.getTracks();
        if (!tracks) {
            return;
        }
        tracks.forEach((track) => track.stop());
    }

    //+-+-+-+-+ Media Controls
    const handleStartMedia = () => {
        setIsStartMedia(true);
        if (localStream.current) {
            console.warn('WARN: local media ALREADY started');
            return;
        }
        navigator.mediaDevices
            .getUserMedia(mediaConstraits)
            .then((stream) => {
                localStreamAudio.current = stream
                localStreamVideo.current = stream
                localStream.current = stream;
                playVideo(localVideo.current, localStream.current);
            }).catch((err) => {
            switch (err.toString()) {
                case "NotAllowedError: Permission denied":
                    let viewMessagePermissions = <Row className="uk-width-1-1">
                        Por favor revise que los permisos para acceder a la cámara y el micrófono estén
                        habilitados <br/>
                        <b>Ejemplo:</b>
                        <img alt=""
                             style={{marginTop: 5}}
                             src="https://otrasimagenes.s3.amazonaws.com/muestra/Screenshot+from+2021-09-21+13-40-51.png"/>
                    </Row>
                    confirmAlert({
                        customUI: ({onClose}) => {
                            return customAlert('Permisos denegados',
                                viewMessagePermissions, 1,
                                'Enterado', null, null, () => {
                                    setIsStartMedia(false);
                                    dispatch(startTransmission(false));
                                    dispatch(connectingToClass(false));
                                    onClose()
                                },);
                        }
                    });
                    return;
                case "NotFoundError: Requested device not found":
                    alertWarning("No se han encontrado dispositivos de audio y video");
                    setIsStartMedia(false);
                    dispatch(producerStated(true))
                    dispatch(noVideoStream(true))
                    return;
                case "NotReadableError: Could not start video source":
                    let viewMessageOther = <Row className="uk-width-1-1">
                        La cámara y el micrófono están siendo utilizados en otra aplicación, por favor desactive los
                        dispositivos de audio y video en la otra aplicación para poder utilizarlos en Kolegia
                    </Row>
                    confirmAlert({
                        customUI: ({onClose}) => {
                            return customAlert('Dispositivos en uso',
                                viewMessageOther, 1,
                                'Enterado', null, null, () => {
                                    setIsStartMedia(false);
                                    dispatch(startTransmission(false));
                                    dispatch(connectingToClass(false));
                                    onClose()
                                }, null);
                        }
                    });
                    return;
                default:
                    setIsStartMedia(false);
                    dispatch(startTransmission(false));
                    dispatch(connectingToClass(false));
                    alertFail('fail');
                    return;
            }
        });
    };

    function playVideo(element, stream) {
        if (element.srcObject) {
            console.warn('element ALREADY playing, so ignore');
            return;
        }
        element.srcObject = stream;
        element.volume = 0;
        handleConnect(localStreamVideo, localStreamAudio)
        return element.play();
    }


    const [currentPage, setCurrentPage] = useState(1);


    const showVideo = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return guests.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, current_guests, guests, remoteVideos, PageSize]);

    const audioVideo = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        const fslice = guests.slice(lastPageIndex, current_guests.length);
        const lslice = guests.slice(0, firstPageIndex);

        return lslice.concat(fslice).filter(item => item !== null && item.has_video);

    }, [currentPage, current_guests, guests, remoteVideos, PageSize]);

    const avatar = () => {
        return {
            backgroundColor: 'red',
            overflow: 'hidden',
            alignItems: 'flex-end',
            height: '100%',
            position: 'relative'
        }
    }

    const guestsBoxVideos = showVideo.map((item, i) => {
        const itsMe = Number(item.user_id) === Number(userId);
        if (itsMe) {
            if (localStream.current !== undefined && localVideo.current !== null) {
                if (localVideo.current.srcObject === null) {
                    localVideo.current.srcObject = localStream.current;
                }
            }
            return <Row
                key={"guest - " + item.id}
                className={showVideo.length === 1 ? "uk-width-1-1" : PageSize === 2 ? "uk-width-1-2" : "uk-width-1-1"}
                style={{height: showVideo.length === 1 ? '20%' : '50%', padding: 1}}>
                <Row
                    className="uk-width-1-1 centerAll"
                    style={avatar()}>
                    {isStartMedia
                        ? <video ref={localVideo} className="uk-width-1-1" playsInline muted autoPlay
                                 style={{backgroundColor: "black", objectFit: 'contain', height: '100%'}}/> : null}
                    {!isStartMedia
                        ? item.photo !== null && item.photo !== ""
                            ? null
                            : <Row className="uk-width-1-1 centerAll" style={{height: '100%'}}>
                                <label style={{color: item.font_color, fontWeight: 'bold', fontSize: '25px'}}
                                       className="uk-width-1-1 uk-text-truncate">{getFirstLetters(item.user_name)}
                                </label>
                            </Row>
                        : null}
                    <ButtonsBox
                        startedMedia={isStartMedia}
                        item={item}
                        callControls={true}
                        onStatusVideoChange={async (status) => {
                            if (localVideo.current !== null && localVideo.current !== undefined) {
                                localVideo.current.srcObject.getVideoTracks()[0].enabled = status;
                            }
                        }}
                        onStatusAudioChange={async (status) => {
                            if (localVideo.current !== null && localVideo.current !== undefined) {
                                localVideo.current.srcObject.getAudioTracks()[0].enabled = status;
                            }
                        }}
                    />
                </Row>
            </Row>
        } else {
            if (item.has_video) {
                return <Row
                    key={"guest - " + item.id}
                    className={showVideo.length === 1 ? "uk-width-1-1" : PageSize === 2 ? "uk-width-1-2" : "uk-width-1-1"}
                    style={{height: showVideo.length === 1 ? '20%' : '50%'}}>
                    <Row className="uk-width-1-1 centerAll"
                         style={avatar()}>
                        <VideoBox
                            item={item}
                            stream={item.video_box_stream}/>
                        <ButtonsBox
                            startedMedia={item.has_video}
                            item={item}
                            callControls={false}
                            onStatusVideoChange={(videoActive) => {

                            }}
                            onStatusAudioChange={(audioActive) => {

                            }}/>
                    </Row>
                </Row>
            }
        }

    });


    return (<Row className="uk-width-1-1">
        {showVideo.length !== 0
            ? userRole === 'Admin' && !start_transmission ? pulseLoader(secondColor) : guestsBoxVideos
            : <FaltaMensaje
                mensaje={<Row className="uk-width-1-1 centerAll">No hay invitados <br/> unidos a esta clase</Row>}
                margin={150}/>}
    </Row>)

}

export default memo(GuestsView)

const ButtonsBox = ({
                        item,
                        callControls = true,
                        startedMedia,
                        onStatusVideoChange,
                        onStatusAudioChange,
                    }) => {
    const {userId} = useSelector(state => state.rooot);
    const [videoActive, setVideoActive] = useState(true);
    const [audioActive, setAudioActive] = useState(true);
    const IconViewAudio = () => {
        if (audioActive) {
            return <img alt=""
                        data-uk-tooltip="Microfono activado"
                        style={{height: 13, width: 13}}
                        src="https://img.icons8.com/material/24/0d0d0d/microphone--v1.png"/>
        } else {
            return <img alt=""
                        data-uk-tooltip="Microfono desactivado"
                        style={{height: 13, width: 13}}
                        src="https://img.icons8.com/material/24/ff0000/no-microphone--v1.png"/>
        }
    };

    const IconViewVideo = () => {
        if (videoActive) {
            return <img alt=""
                        data-uk-tooltip="Video activado"
                        style={{height: 13, width: 13}}
                        src="https://img.icons8.com/material/24/0d0d0d/video-call--v1.png"/>
        } else {
            return <img alt=""
                        data-uk-tooltip="Video desactivado"
                        style={{height: 13, width: 13}}
                        src="https://img.icons8.com/material/24/ff0000/no-video--v1.png"/>
        }
    };

    return <Row className="uk-width-1-1 centerAll normal_text" style={{
        height: '100%',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        position: 'absolute',
        bottom: 0,
    }}>
        <Row className='uk-width-3-5 centerAll'
             style={{padding: "0 2px 0 0"}}>
            <Row className='uk-width-1-1' style={{
                backgroundColor: 'rgba(255, 255, 255, 0.81)',
                backdropFilter: "blur(10px)",
                borderTopRightRadius: 6,
                alignItems: 'center',
                padding: "3px 5px"
            }}
                 data-uk-tooltip={item.user_name}>
                <Row className='centerAll uk-width-1-6' style={{
                    backgroundColor: 'rgba(0, 160, 0, 0.81)',
                    backdropFilter: "blur(10px)",
                    borderRadius: 15,
                    height: 10,
                    width: 10
                }}>
                </Row>
                <label style={{fontSize: 10}} className="uk-width-5-6 uk-text-truncate">{item.user_name}</label>
            </Row>
        </Row>
        {startedMedia ? callControls
            ? <Row className='uk-width-2-5 centerAll'>
                <Row className='uk-width-1-2 centerAll'
                     style={{padding: "0 2px"}}>
                    <Row className='uk-width-1-1 centerAll' style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.81)',
                        backdropFilter: "blur(10px)",
                        borderTopRightRadius: 6,
                        borderTopLeftRadius: 6,
                        padding: "3px 5px",
                        cursor: Number(userId) === Number(item.user_id) ? 'pointer' : null,
                        pointerEvents: Number(userId) !== Number(item.user_id) ? 'none' : null
                    }}
                         onClick={() => {
                             setAudioActive(!audioActive);
                             onStatusAudioChange(!audioActive);
                         }}>
                        {IconViewAudio()}
                    </Row>
                </Row>

                <Row className='uk-width-1-2 centerAll'
                     style={{padding: "0 2px"}}>
                    <Row className=' uk-width-1-1 centerAll' style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.81)',
                        backdropFilter: "blur(10px)",
                        borderTopRightRadius: 6,
                        borderTopLeftRadius: 6,
                        padding: "3px 5px",
                        cursor: Number(userId) === Number(item.user_id) ? 'pointer' : null,
                        pointerEvents: Number(userId) !== Number(item.user_id) ? 'none' : null
                    }}
                         onClick={() => {
                             setVideoActive(!videoActive)
                             onStatusVideoChange(!videoActive)
                         }}>
                        {IconViewVideo()}
                    </Row>
                </Row>
            </Row> : null : null}
    </Row>
}
