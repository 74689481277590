import {ACADEMY_CONFIG, INICIO_FIN_CICLO1, INICIO_FIN_CICLO2} from '../../../actions/types';

const initialState = {
        loading: false,
        ciclo_actual: [],
        ciclo_proximo: [],
        variable_extra: false,
        variable_extra_new: false,
        periodos_variable_extra: [],
        periodos_variable_extra_new: []
    };

export default function ParametrosGeneralesReducer(state = initialState, action) {

    switch (action.type) {
        case INICIO_FIN_CICLO1:
            return {
                ...state,
                ciclo_actual: action.payload,
                loading: false
            };
        case INICIO_FIN_CICLO2:
            return {
                ...state,
                ciclo_proximo: action.payload,
                loading: false
            };
        case ACADEMY_CONFIG:
            return {
                ...state,
                variable_extra: action.variable_extra,
                periodos_variable_extra: action.periodos_variable_extra,
                variable_extra_new: action.variable_extra_new,
                periodos_variable_extra_new: action.periodos_variable_extra_new,
                loading: false
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
