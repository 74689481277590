import {
    GET_JORNADAS,
    GET_JORNADAS_COMMENTS,
    GET_JORNADAS_RELATION,
    ID_RELACION_GRUPO,
    JORNADAS_ADMIN_MAESTRO,
    JORNADAS_ALUMNO,
    JORNADAS_GRUPO,
    JORNADAS_MAESTRO,
    JORNADAS_PADRE,
    JORNADAS_PREFECTO,
    LOADING_JORNADAS,
    SEMANAS,
    SEMANAS_OCUPADAS
} from '../../../actions/types';

const initialState = {
        loading: false,
        jornadas_relation: [],
        jornadas: [],
        semanas: [],
        semanas_ocupadas: [],
        jornadas_grupo: [],
        jornadas_maestro: [],
        jornadas_padre: [],
        jornadas_alumno: [],
        jornadas_prefecto: [],
        jornadas_admin_maestro: [],
        id_relacion_grupo: '',
        jornadas_comments: []
    }
;

export default function JornadasReducer(state = initialState, action) {

    switch (action.type) {
        case GET_JORNADAS:
            return {
                ...state,
                jornadas: action.payload,
                loading: false
            };
        case GET_JORNADAS_RELATION:
            return {
                ...state,
                jornadas_relation: action.payload,
                loading: false
            };
        case LOADING_JORNADAS:
            return {
                ...state,
                loading: action.payload,
            };
        case SEMANAS:
            return {
                ...state,
                semanas: action.payload,
                loading: false,
            };
        case SEMANAS_OCUPADAS:
            return {
                ...state,
                semanas_ocupadas: action.payload,
                loading: false,
            };
        case ID_RELACION_GRUPO:
            return {
                ...state,
                id_relacion_grupo: action.payload,
                loading: false,
            };
        case JORNADAS_GRUPO:
            return {
                ...state,
                jornadas_grupo: action.payload,
                loading: false,
            };
        case JORNADAS_MAESTRO:
            return {
                ...state,
                jornadas_maestro: action.payload,
                loading: false,
            };
        case JORNADAS_PADRE:
            return {
                ...state,
                jornadas_padre: action.payload,
                loading: false,
            };
        case JORNADAS_ALUMNO:
            return {
                ...state,
                jornadas_alumno: action.payload,
                loading: false,
            };
        case JORNADAS_PREFECTO:
            return {
                ...state,
                jornadas_prefecto: action.payload,
                loading: false,
            };
        case JORNADAS_ADMIN_MAESTRO:
            return {
                ...state,
                jornadas_admin_maestro: action.payload,
                loading: false,
            };
        case GET_JORNADAS_COMMENTS:
            return {
                ...state,
                jornadas_comments: action.payload,
                loading: false,
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
