import {
    ALIAS,
    ALUMNOS_EGRESADOS,
    CICLO_ACTUAL,
    IMAGES,
    INIT,
    LISTA_ALUMNOS,
    LOADING_POSTS,
    MODAL,
    MODE,
    MY_ROLES,
    PERIODOS,
    ROOT_LOAD,
    SCHOOL_CONFIGURATION,
    SCHOOLS_NIVELES,
    SET_BETA,
    SET_KOLEGIA_THEME,
    SET_MENU,
    SET_MODAL_ACTIVITY_CLASS,
    SET_SUB_MENU,
    THEME,
    UPDATE_SCHOOL_CONFIGURATION,
    USER_PICTURE
} from './types';
import {AppEnv} from '../../components/env';
import axios from 'axios';
import {alertFail, alertSuccess, error as logError} from "../../components/Global-W/NewFunctions";
import Store from "../store"

const apiUrl = AppEnv.api;
const error = 'Ha ocurrido un error, si el error continúa pónganse en contacto con soporte@kolegia.com';

const dark = {
    backGround: 'rgba(0, 0, 0, .97)',
    mainColor: '#004b87',
    secondColor: '#932d1d',
    thirdColor: '#bEbEbE',
    fourthColor: '#222',
    fifthColor: '#FFF',

    greenColor: '#43D551',
    yellowColor: '#F1F100',
    orangeColor: '#FFA500',
    redColor: '#FF3333',
    blueColor: '#113FFF',

    grayBackground: '#444444',
    grayColorOne: '#545454',
    grayColorTwo: '#646464',
    grayColorThree: '#747474',
    grayColorFour: '#848484',
    grayColorFive: '#949494',

    $oxford1Light: '#d9cdc7',
    $oxfordDark: '#e1d7d2',
}

const normal = {
    backGround: AppEnv.backGround,
    mainColor: AppEnv.mainColor,
    secondColor: AppEnv.secondColor,
    thirdColor: AppEnv.thirdColor,
    fourthColor: AppEnv.fourthColor,
    fifthColor: AppEnv.fifthColor,

    greenColor: AppEnv.greenColor,
    yellowColor: AppEnv.yellowColor,
    orangeColor: AppEnv.orangeColor,
    redColor: AppEnv.redColor,
    blueColor: AppEnv.blueColor,

    grayBackground: AppEnv.grayBackground,
    grayColorOne: AppEnv.grayColorOne,
    grayColorTwo: AppEnv.grayColorTwo,
    grayColorThree: AppEnv.grayColorThree,
    grayColorFour: AppEnv.grayColorFour,
    grayColorFive: AppEnv.grayColorFive,

    $oxford1Light: AppEnv.oxford1Light,
    $oxfordDark: AppEnv.oxfordDark,
}

export function rootLoad(data) {
    return {
        type: ROOT_LOAD,
        payload: data
    };
}

export function switchTheme(data) {
    return {
        type: THEME,
        payload: data === 'dark' ? dark : normal
    };
}

export function modalSolicitud(state, type) {
    return {
        type: MODAL,
        modal: state,
        modalType: type
    };
}

export function fetchPosts(dataBase, token, state, inicio, ciclo, mes) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + dataBase + '/v3/noticias/get/' + inicio + '/' + ciclo + '/' + mes, {
            method: 'GET', headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchPostsSuccess(response.data));
        }).catch(error => {
            dispatch(fetchPostsFailed(state));
        });
    };
}

export function setLoading(data) {
    return {
        type: LOADING_POSTS,
        payload: data
    };
}

export function fetchPostsSuccess(response) {
    return {
        type: 'FETCH_POSTS',
        payload: response
    };
}

export function fetchPostsFailed(state) {
    alertFail(error);
    return {
        type: 'FETCH_POSTS',
        payload: state
    };
}

export function fetchCicloActual(dataBase) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + dataBase + '/get/ciclo/actual', {
            method: 'GET', headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            dispatch(fetchCicloActualSuccess(response.data));
        }).catch(error => {
            alertFail();
        });
    };
}

export function fetchCicloActualSuccess(response) {
    return {
        type: CICLO_ACTUAL,
        payload: response
    };
}

export function setMenu(data) {
    return {
        type: SET_MENU,
        index: data.index,
        name: data.name
    };
}

export function fetchPeriodos(db, token, ciclo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + db + '/periodos2/' + ciclo, {
                method: 'GET', headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchPeriodosSuccess(response.data));
                resolve()
            }).catch(error => {
                alertFail();
                resolve()
            });
        });
    };
}

export function fetchPeriodosSuccess(response) {
    return {
        type: PERIODOS,
        payload: response
    };
}

export function setUserPicture(data) {
    return {
        type: USER_PICTURE,
        payload: data
    }
}

export function setInitValues(data) {
    return {
        type: INIT,
        bd: data.bd,
        country: data.country,
        roles: data.roles,
        schoolId: data.schoolId,
        token: data.token,
        userEmail: data.email,
        userGrado: data.grado,
        userGrupo: data.grupo,
        userId: data.id,
        userName: data.name,
        userPicture: data.userPicture,
        userPuesto: data.puesto,
        userPuestoAlias: data.userRoleAlias,
        userRole: data.role,
        activo: data.activo,
        estatus: data.estatus,
    };
}

export function fetchSchoolData(dataBase, token) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios.get(AppEnv.apiGes + 'escuela/datos/' + dataBase, {
                method: 'GET', headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchSchoolDataSuccess(response.data[0]));
                resolve(response.data[0])
            }).catch(error => {
                resolve(error)
            });
        });
    };
}

export function fetchSchoolDataSuccess(response) {
    return {
        type: 'SCHOOL_DATA',
        payload: response
    };
}


export function fetchAlias(bd, token) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios.get(AppEnv.api + bd + '/v3/users/get/alias/roles', {
                method: 'GET', headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchAliasSuccess(response.data));
                resolve()
            }).catch(error => {
                resolve()
            });
        });
    };
}

export function fetchAliasSuccess(response) {
    return {
        type: ALIAS,
        payload: response
    };
}

export function getBrowser(a) {
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // if (!isChrome && a === '0') {
    //     confirmAlert({
    //         customUI: ({onClose}) => {
    //             return customAlert('Sugerencia',
    //                 'Para una mejor experiencia por favor utilice Google Chrome', 1, 'Gracias', null, null,
    //                 () => [onClose()]);
    //         }
    //     });
    // }
    sessionStorage.setItem('browserIntent', '1');
    return {
        type: 'BROWSER',
        payload: '1'
    };
}

export function fetchMyRoles(bd, token) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios.get(AppEnv.api + bd + '/v3/users/my/roles', {
                method: 'GET', headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchMyRolesSuccess(response.data));
                resolve()
            }).catch(error => {
                resolve()
            });
        });
    };
}

export function fetchListaDeAlumnos(bd, token, grado, grupo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/get/alumnos/relacion/' + grado + '/' + grupo, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchListaDeAlumnosSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchListaDeAlumnosFailed());
                resolve();
            });
        });
    };
}

export function fetchListaDeAlumnosPays(bd, token, grado, grupo, ciclo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/calificaciones/alumnos/boletas/ciclo/' + ciclo + '/' + grado + '/' + grupo, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchListaDeAlumnosSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchListaDeAlumnosFailed());
                resolve();
            });
        });
    };
}

export function fetchListaDeAlumnosSuccess(response) {
    return {
        type: LISTA_ALUMNOS,
        payload: response
    };
}

export function fetchListaDeAlumnosFailed() {
    alertFail();
    return {
        type: LISTA_ALUMNOS,
        payload: []
    };

}

export function promoverAlumno(token, bd, fd, grado, grupo, idSchool, school_level, ciclo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.post(AppEnv.api + bd + '/v3/users/promover/alumno/' + idSchool+'/'+school_level+ '/'+ ciclo, fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                alertSuccess();
                dispatch(fetchListaDeAlumnosPays(bd, token, grado, grupo, ciclo));
                resolve()
            }).catch(error => {
                alertFail();
                dispatch(fetchListaDeAlumnosPays(bd, token, grado, grupo, ciclo));
                resolve()
            });
        });
    };
}

export function fetchMyRolesSuccess(response) {
    return {
        type: MY_ROLES,
        payload: response
    };
}

export function fetchAlumnosEgresados(bd, token, cicle) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/v3/users/alumnos/egresados/' + cicle, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchAlumnosEgresadosSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchAlumnosEgresadosFailed());
                resolve();
            });
        });
    };
}


export function fetchAlumnosEgresadosSuccess(response) {
    return {
        type: ALUMNOS_EGRESADOS,
        payload: response
    };
}

export function fetchAlumnosEgresadosFailed() {
    alertFail();
    return {
        type: ALUMNOS_EGRESADOS,
        payload: []
    };
}


export function fetchChangeMode(mode) {
    return (dispatch) => {
        let newMode = mode === 'light' ? 'dark' : 'light'
        return {
            type: MODE,
            payload: newMode
        };
    };
}

export function fetchSetMode(mode) {
    return (dispatch) => {
        document.documentElement.setAttribute('data-theme', mode)
    };
}

export function fetchSchoolsNiveles(bd, token, name_school) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.apiGes + 'get/escuelas/nombre/' + name_school, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchSchoolsNivelesSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchSchoolsNivelesFailed());
                resolve();
            });
        });
    };
}


export function fetchSchoolsNivelesSuccess(response) {
    return {
        type: SCHOOLS_NIVELES,
        payload: response
    };
}

export function fetchSchoolsNivelesFailed() {
    alertFail();
    return {
        type: SCHOOLS_NIVELES,
        payload: []
    };
}

export function fetchImages(images) {
    return {
        type: IMAGES,
        payload: images
    };
}


export function setBeta(images) {
    return {
        type: SET_BETA,
        payload: images
    };
}

export function setKolegiaTheme(images) {
    return {
        type: SET_KOLEGIA_THEME,
        payload: images
    };
}


export function setSubMenu(data) {
    return {
        type: SET_SUB_MENU,
        index: data.index,
        name: data.name
    };
}


export function setModalActivityOnClass(data) {
    return {
        type: SET_MODAL_ACTIVITY_CLASS,
        payload: data,
    };
}

export function updateSchoolConfiguration(prop, value) {
    return {
        type: UPDATE_SCHOOL_CONFIGURATION,
        prop: prop,
        value: value,
    };
}

export function schoolConfigurationSuccess(data) {
    return {
        type: SCHOOL_CONFIGURATION,
        payload: data
    };
}

export function schoolConfiguration(db, token) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.paymentsApi + db + "/configuration/" + db, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                if (response.data.db === "") {
                    dispatch(schoolDefaultConfiguration(db, token))
                } else {
                    dispatch(schoolConfigurationSuccess(response.data))
                }
                resolve();
            }).catch(error => {

                reject(error);
            });
        });
    };
}

export function schoolDefaultConfiguration(db, token) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            const data = {
                db: db,
                has_discounts: false,
                scholarships_has_discounts: false,
                limit_day_discount: 10,
                has_multiple_discounts: false,
                type_discount: 'amount',
                has_surcharges: false,
                has_multiple_surcharges: false,
                type_surcharges: 'amount',
                max_amount_scholarship: 1000,
                max_percentage_scholarship: 100,
                scholarship_applicable_for: "normal",
                calculate_scholarship_on: "normal"
            }
            axios.post(AppEnv.paymentsApi + db + "/configurations/default", data, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(schoolConfiguration(db, token))
                resolve();
            }).catch(error => {

                reject(error);
            });
        });
    };
}

export function sendError(data) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            let s = Store.getState()
            let info = {
                error: "Ha ocurrido un error",
                data_base: s.rooot.bd.toString(),
                user_id: s.rooot.userId.toString(),
                role: s.rooot.userRole.toString(),
                job_position: s.rooot.userPuesto.toString(),
                school_level: s.rooot.schoolData.nivel.toString(),
                group: s.rooot.userGrupo.toString(),
                user_name: s.rooot.userName.toString(),
                school_id: s.rooot.schoolId,
                school_name: s.rooot.schoolData.nombre_escuela.toString(),
                description: "Ha ocurrido un error",
                function_name: "Sin definir",
                component: "Sin definir",
                parameters: "Sin definir",
                ...data
            }
            logError(info.description, info)
            axios.post(AppEnv.errosApi + "error", info, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then(() => {
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    };
}
