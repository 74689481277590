import React, {useEffect, useRef, useState} from 'react';
import socketIOClient from 'socket.io-client';
import {useDispatch, useSelector} from "react-redux";
import {
    changeAllMedia,
    changeChatStatus,
    changeOne,
    dropTeacher,
    setChangeVideo,
    changeStatusAudio,
    changeStatusVideo,
    leaveUserToRoom,
    setLoading,
    setMessages,
    SetTeachers,
    studentsInClass,
    producerStated,
    setGuests,
    startTransmission,
    setStudents,
    changeConnectionStatus,
    connectingToClass,
    changeMainStream,
    updateMediaTeacher,
    setRemoteVideos,
    setCurrentItemClass,
    SetStudents,
    myDataGuest,
    setNewGuest,
    updateMedia,
    setMyVideoStatus,
    setMyAudioStatus,
    setTeacher, noVideoStream, setStatusTeacher
} from "../redux/actions/VirtualClassActions/VirtualClassActions";
import {alertSuccess, alertWarning, customAlert, info, warn} from "../components/Global-W/NewFunctions";
import {confirmAlert} from "react-confirm-alert";
import {Device} from "mediasoup-client";
const moment = require('moment');
require('moment/locale/es');
moment.locale('es');

const KolegiaBroadcastContext = React.createContext();

const {Provider, Consumer} = KolegiaBroadcastContext;

const KolegiaBroadcastContextProvider = (props) => {
    //Mediasoup transports & devices
    const producerTransport = useRef();
    const device = useRef();
    const videoProducer = React.useRef({});
    const audioProducer = React.useRef({});
    const consumerTransport = useRef();
    const currentGuestRef = useRef();
    const chatReference = useRef();
    const audioConsumers = React.useRef({});
    const videoConsumers = React.useRef({});
    const consumersStream = React.useRef({});
    ///
    const {userId, websocket, userName, bd, schoolData, userRole, userPuesto} = useSelector(state => state.rooot);
    const {start_transmission, room_id, classData, my_video_status, my_audio_status, students_in_class, remoteVideos, chatStatus, current_guests, students, producer_started,lastVersion} = useSelector(state => state.virtual_class);
    const status_video = useSelector(state => state.virtual_class.status_video_student);
    const status_audio = useSelector(state => state.virtual_class.status_audio_student);
    const dispatch = useDispatch();
    const socketRef = useRef();
    const classDataRef = useRef();
    const roomIdRef = useRef();
    const studentsRef = useRef();
    const [newMessageRecive, setNewMessagerecive] = useState();

    useEffect(() => {
        socketRef.current = socketIOClient(
            websocket + '/video-conference', {
                query: {userId},
                transports: ['websocket']
            }
        );
        connectSocket()
        //-+-+-+- RECIVE NEW MESSAGE
        socketRef.current.on('set message', (data) => {
            setNewMessagerecive(data);
        });

        //-+-+-+-+-
        socketRef.current.on('chat', (data) => {
            dispatch(changeChatStatus(data.status))
        });

        socketRef.current.on('class_started',async () => {
                alertSuccess('EL MAESTRO HA INICIADO LA CLASE, POR FAVOR ÚNETE')
                await dispatch(setStatusTeacher('2'))
        })
        socketRef.current.on('connected_to_room',async () => {
                await dispatch(setStatusTeacher('1'))
        })

        //-+-+-+-+-+-+ Controlls alert +-+-+-+-+-+
        socketRef.current.on('close_video', async (data) => {
           if (data.close_all === true && userRole === 'Maestro'){

           }else{
               await dispatch(setMyVideoStatus(false))
           }
        });
        socketRef.current.on('close_audio', async (data) => {
            if (data.close_all === true && userRole === 'Maestro'){

            }else{
                await dispatch(setMyAudioStatus(false))
            }
        });
        socketRef.current.on('list users update', async (data) => {
            dispatch(studentsInClass(data));
        });
        socketRef.current.on('user leave room', async (user) => {
            if(userRole === 'Admin' && user.puesto === 'Maestro'){
                dispatch(dropTeacher({id:user.user_id}))
            }

            dispatch(leaveUserToRoom(user.user_id));
        });
        socketRef.current.on('send_students', (data) => {
            warn('--- RECIBE ALUMNOS ---')
            dispatch(setStudents(data));
        })
        // socketRef.current.on('semi_connected', async (data) => {
        //     if(userRole === 'Admin'){
        //         let find = teachers_list.indexOf(teachers_list.find(it => it.user_id.toString() === data.user_id.toString()))
        //         let clone = [...teachers_list]
        //         if(find !== -1){
        //             clone[find].connected = true
        //         }
        //         dispatch(SetTeachers(clone))
        //     }
        // })
        // broadCaster_socket
        return () => {
            dispatch(noVideoStream(false));
            dispatch(producerStated(false))
            socketRef.current.disconnect();
            socketRef.current.close();
            socketRef.current = null;
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        socketRef.current.on('open_video', (data) => {
            const bodyMessage = {
                name: userName,
                room_id: roomIdRef.current,
                user_id: userId,
                senderId: socketRef.current.id,
                bd_school: bd,
                id_subject: classDataRef.current.relacion !== undefined ? classDataRef.current.relacion.id_materia : 0,
                type_message:2,
                date: moment().format('YYYY-MM-DD HH:mm:ss')
            }
            if(userRole === 'Maestro'){
                return
            }
            if(data.socket_student === undefined){
                confirmAlert({
                    customUI: ({onClose}) => {
                        return customAlert('¡Atención!',
                            'El maestro solicita que enciendas tu cámara'.toUpperCase(), 2, 'DENEGAR', 'ENCENDER', null,
                            () => {
                                let messageContent = {...bodyMessage, body:'El alumno '+userName+' denegó encender su cámara'};
                                sendNewMessage(messageContent);
                                onClose();
                            },
                            async () => {
                                let messageContent = {...bodyMessage, body:'El alumno'+userName+' encendió su cámara'};
                                sendNewMessage(messageContent);
                                dispatch(changeStatusVideo(true));
                                await dispatch(setMyVideoStatus(true));
                                onClose()
                            });
                    }
                });
            }else{
                if(start_transmission ){
                    confirmAlert({
                        customUI: ({onClose}) => {
                            return customAlert('¡Atención!',
                                'El maestro solicita que enciendas tu cámara'.toUpperCase(), 2, 'DENEGAR', 'ENCENDER', null,
                                () => {
                                    let messageContent = {...bodyMessage, body:'El alumno '+userName+' denegó encender su cámara'};
                                    sendNewMessage(messageContent);
                                    onClose();
                                },
                                async () => {
                                    let messageContent = {...bodyMessage, body:'El alumno '+userName+' encendió su cámara'};
                                    sendNewMessage(messageContent);
                                    await dispatch(setMyVideoStatus(true));
                                    onClose()
                                });
                        }
                    });
                }
            }

        });
        socketRef.current.on('open_audio', (data) => {
            const bodyMessage = {
                name: userName,
                room_id: roomIdRef.current,
                user_id: userId,
                senderId: socketRef.current.id,
                bd_school: bd,
                id_subject: classDataRef.current.relacion !== undefined ? classDataRef.current.relacion.id_materia : 0,
                type_message:2,
                date: moment().format('YYYY-MM-DD HH:mm:ss')
            }
            if(userRole === 'Maestro'){
                return
            }
            if(data.socket_student === undefined){
                confirmAlert({
                    customUI: ({onClose}) => {
                        return customAlert('¡Atención!',
                            'El maestro solicita que enciendas tu micrófono'.toUpperCase(), 2, 'DENEGAR', 'ENCENDER', null,
                            () => {
                                let messageContent = {...bodyMessage, body:'El alumno '+userName+' denegó encender su micrófono'};
                                sendNewMessage(messageContent);
                                onClose();
                            },
                            async () => {
                                let messageContent = {...bodyMessage, body:'El alumno '+userName+' encendió su micrófono'};
                                sendNewMessage(messageContent);
                                await dispatch(setMyAudioStatus(true));
                                onClose();
                            });
                    }
                });
            }else{
                if(start_transmission){
                    confirmAlert({
                        customUI: ({onClose}) => {
                            return customAlert('¡Atención!',
                                'El maestro solicita que enciendas tu micrófono '.toUpperCase(), 2, 'DENEGAR', 'ENCENDER', null,
                                () => {
                                    let messageContent = {...bodyMessage, body:'El alumno'+userName+' denegó encender su micrófono'};
                                    sendNewMessage(messageContent);
                                    onClose();
                                },
                                async () => {
                                    let messageContent = {...bodyMessage, body:'El alumno '+userName+' encendió su micrófono'};
                                    sendNewMessage(messageContent);
                                    await dispatch(setMyAudioStatus(true));
                                    onClose();
                                });
                        }
                    });
                }
            }

        });
    }, [status_video, status_audio, start_transmission]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        classDataRef.current = classData
    },[classData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        currentGuestRef.current = current_guests
    }, [current_guests]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        chatReference.current = chatStatus
    }, [chatStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        roomIdRef.current = room_id
    }, [room_id]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        studentsRef.current = students
    }, [students]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (socketRef.current !== undefined && userRole === 'Admin') {
            sendRequest('create admin', {
                school_id: schoolData.id,
                user_name: userName,
                room_id: schoolData.id.toString() + "/ADMIN",
                bd_school: bd,
                puesto: userRole,
                version: lastVersion
            }).then(async (response) => {
                await sendRequest('get_teachers', {school_id: schoolData.id}).then(async (response) => {
                    await dispatch(SetTeachers(response.teachers.map((it,i) => {
                        it.camera = true
                        it.mic = true
                        it.buttons = true
                        if(i === 0){
                            it.audio = true
                        }
                        return it })))
                }).catch((error) => {

                })
            })

        }
    }, [socketRef]) // eslint-disable-line react-hooks/exhaustive-deps

    //-+-+-+- SEND NEW MESSAGE
    const sendNewMessage = (messageBody) => {
        socketRef.current.emit("send message", messageBody);
    }

    const sendRequest = (type, data) => {
        return new Promise((resolve, reject) => {
            socketRef.current.emit(type, data, (err, response) => {
                if (!err) {
                    // Success response, so pass the mediasoup response to the local Room.
                    resolve(response);
                } else {
                    reject(err);
                }
            });
        });
    }

    const joinToRoom = async (dataRoom) => {
        await sendRequest('create room', dataRoom).then(async (res) => {
            sendRequest('fetch users', {room_id: dataRoom.room_id})
            dispatch(setGuests(res.current_guests));
            dispatch(setMessages(res.messages))
            if(userRole === 'Maestro'){
                sendRequest('connected_to_room',dataRoom.room_id)
            }else{
                let status = "0";
                if (res.teacher !== undefined && res.teacher.puesto !== undefined ){
                    status = res.teacher.video_producer !== undefined ? '2' : '1'
                }
                await dispatch(setStatusTeacher(status))
            }
            // if(userRole === 'Maestro'){
            //     socketRef.current.emit('semi_connect',{socket_id:socketRef.current.id,user_id: userId,room_id:schoolData.id.toString() + "/ADMIN"})
            // }
            dispatch(setLoading(false))
        }).catch(err => console.error('error senRequest create room - ', err));
    }

    // =<<0>>= * =<<0>>= * =<<0>>= * =<<0>>= MEDIASOUP CONTROLS =<<0>>= * =<<0>>= * =<<0>>= * =<<0>>= * =<<0>>=
    const connectSocket = () => {
        // return new Promise((resolve, reject) => {
        try {
            socketRef.current.on('get_students', async (data) => {
                if (userRole === 'Maestro') {
                    sendRequest('send_updated_students', {user_id: data.user_id, students: studentsRef.current})
                }
            })
            socketRef.current.on("close class", async () => {
                if (userRole === 'Admin'){
                    await sendRequest('admin_leave_room', {room_id:roomIdRef.current}).then(async (res)=>{
                        warn('-data leave-', res);
                        // alertWarning("El maestro ha salido de la clase");
                        dispatch(changeMainStream(null))
                        dispatch(setCurrentItemClass(null))
                        dispatch(SetStudents([]));
                        dispatch(producerStated(false));
                        dispatch(myDataGuest(null));
                        dispatch(startTransmission(false));
                        dispatch(setGuests([]));
                    });
                }
            })

            socketRef.current.on('newTeacher', function (message) {
                if (message.socketId !== socketRef.current.id && consumerTransport.current !== null) {
                    info('––– hazlo admin–––');
                    sendRequest('get_teacher', {socket_id: message.socketId})
                        .then(async (response) => {
                            if(response.teacher !== undefined){
                                await dispatch(setTeacher(response.teacher))
                            }
                        })

                    console.log('socket.io newProducer:', message);
                    console.log("CONSUMER TRANSPORT->", consumerTransport)
                    const remoteId = message.socketId;
                    const prdId = message.producerId;
                    const kind = message.kind;
                    const mode = message.mode;

                    if (kind === 'video') {
                        // alertWarning('Un nuevo maestro ha iniciado su transmisión')
                        // dispatch(setNewTeacher(true))
                        console.log(
                            '--try consumeAdd remoteId=' +
                            remoteId +
                            ', prdId=' +
                            prdId +
                            ', kind=' +
                            kind
                            + ", transport= ", consumerTransport
                        );
                        consumeAdd(
                            consumerTransport.current,
                            remoteId,
                            prdId,
                            kind,
                            'stream',
                            'AdminDashBoard'
                        );
                    } else if (kind === 'audio') {
                        //console.warn('-- audio NOT SUPPORTED YET. skip remoteId=' + remoteId + ', prdId=' + prdId + ', kind=' + kind);
                        console.log(
                            '--try consumeAdd remoteId=' +
                            remoteId +
                            ', prdId=' +
                            prdId +
                            ', kind=' +
                            kind
                        );
                        consumeAdd(
                            consumerTransport.current,
                            remoteId,
                            prdId,
                            kind,
                            'stream',
                            'AdminDashBoard'
                        );
                    }
                }
            });

            socketRef.current.on('newProducer', function (message) {
                warn('socket.io newProducer:', message);
                info('––– hazlo participants–––');
                if (message.socketId !== socketRef.current.id && consumerTransport.current !== undefined) {

                    const remoteId = message.socketId;
                    const prdId = message.producerId;
                    const kind = message.kind;
                    const mode = message.mode;
                    if (userRole === "Maestro") {
                        socketRef.current.emit("chat_change", {
                            room_id: message.socketId,
                            status: chatReference.current
                        })
                    }
                    if (kind === 'video') {
                        console.log(
                            '--try consumeAdd remoteId=' +
                            remoteId +
                            ', prdId=' +
                            prdId +
                            ', kind=' +
                            kind
                        );
                        consumeAdd(
                            consumerTransport.current,
                            remoteId,
                            prdId,
                            kind,
                            mode
                        );

                    } else if (kind === 'audio') {
                        //console.warn('-- audio NOT SUPPORTED YET. skip remoteId=' + remoteId + ', prdId=' + prdId + ', kind=' + kind);
                        console.log(
                            '--try consumeAdd remoteId=' +
                            remoteId +
                            ', prdId=' +
                            prdId +
                            ', kind=' +
                            kind
                        );
                        consumeAdd(
                            consumerTransport.current,
                            remoteId,
                            prdId,
                            kind,
                            mode
                        );
                    }
                }
            });

            socketRef.current.on('newGuest', function (message) {
                warn('socket.io newProducer:', message);
                info('––– hazlo newGuest–––');
                if (message.socketId !== socketRef.current.id && consumerTransport.current !== undefined) {
                    const remoteId = message.socketId;
                    const prdId = message.producerId;
                    const kind = message.kind;
                    const mode = message.mode;
                    dispatch(setNewGuest(message.data_guest));
                    if (kind === 'video') {
                        console.log(
                            '--try consumeAdd remoteId=' +
                            remoteId +
                            ', prdId=' +
                            prdId +
                            ', kind=' +
                            kind
                        );
                        consumeAdd(
                            consumerTransport.current,
                            remoteId,
                            prdId,
                            kind,
                            mode
                        );
                    } else if (kind === 'audio') {
                        //console.warn('-- audio NOT SUPPORTED YET. skip remoteId=' + remoteId + ', prdId=' + prdId + ', kind=' + kind);
                        console.log(
                            '--try consumeAdd remoteId=' +
                            remoteId +
                            ', prdId=' +
                            prdId +
                            ', kind=' +
                            kind
                        );
                        consumeAdd(
                            consumerTransport.current,
                            remoteId,
                            prdId,
                            kind,
                            mode
                        );
                    }
                }
            });

            socketRef.current.on('producerClosed', function (message) {
                console.log('socket.io producerClosed:', message);
                const localId = message.localId;
                const remoteId = message.remoteId;
                const kind = message.kind;
                const mode = message.mode;
                console.log(
                    '--try removeConsumer remoteId=%s, localId=%s, track=%s',
                    remoteId,
                    localId,
                    kind
                );
                removeConsumer(remoteId, kind, mode);
                removeRemoteVideo(remoteId, mode);
            });

            socketRef.current.on('producerPaused', function (message) {
                if (message.remoteId !== socketRef.current.id && consumerTransport.current !== undefined) {
                    console.log('socket.io producerPaused:', message);
                    const localId = message.localId;
                    const remoteId = message.remoteId;
                    const kind = message.kind;
                    const mode = message.mode;
                    console.log(
                        '--try pauseConsumer remoteId=%s, localId=%s, track=%s',
                        remoteId,
                        localId,
                        kind
                    );
                    pauseConsumer(remoteId, kind, mode);
                }
            });

            socketRef.current.on('producerResumed', function (message) {
                if (message.remoteId !== socketRef.current.id && consumerTransport.current !== undefined) {
                    console.log('socket.io producerResumed:', message);
                    const localId = message.localId;
                    const remoteId = message.remoteId;
                    const kind = message.kind;
                    const mode = message.mode;
                    console.log(
                        '--try resumeConsumer remoteId=%s, localId=%s, track=%s',
                        remoteId,
                        localId,
                        kind
                    );
                    producerResumed(remoteId, kind, mode);
                }
            });
        } catch (e) {
            console.error('error => ',e)
        }
        // });
    };

    const bodyMessage = {
        name: userName,
        room_id: roomIdRef.current,
        user_id: userId,
        bd_school: bd,
        type_message: 2,
        date: moment().format('YYYY-MM-DD HH:mm:ss')
    }

    //+-+-+-+-+-+-+ Send transmission +-+-+-+-+-+-
    async function handleConnect(localStreamVideo, localStreamAudio) {
        try {
            // --- connect socket.io ---
            console.log('connected media');

            // --- get capabilities --
            const data = await sendRequest('getRouterRtpCapabilities', {
                socket_id: socketRef.current.id,
                type: 'producer'
            });
            console.log('getRouterRtpCapabilities:', data);
            await loadDevice(data);

            // --- get transport info ---
            console.log('--- createProducerTransport --');
            const params = await sendRequest('createProducerTransport', {
                mode: 'stream',
            });
            console.log('transport params:', params);
            producerTransport.current = device.current.createSendTransport(params);
            console.log('createSendTransport:', producerTransport.current);

            // --- join & start publish --
            producerTransport.current.on(
                'connect',
                async ({dtlsParameters}, callback, errback) =>  {
                    console.log('--trasnport connect');
                    sendRequest('connectProducerTransport', {
                        dtlsParameters: dtlsParameters,
                    })
                        .then(callback)
                        .catch(errback);
                }
            );

            producerTransport.current.on(
                'produce',
                async (
                    {kind, rtpParameters},
                    callback,
                    errback
                ) => {
                    console.log('--trasnport produce');
                    try {
                        const {id} = await sendRequest('produce', {
                            puesto: userPuesto,
                            room_id: roomIdRef.current,
                            transportId: producerTransport.current.id,
                            kind,
                            user_id: userId,
                            rtpParameters,
                            mode: 'stream',
                        });
                        dispatch(producerStated(true));
                        callback({id});
                    } catch (err) {
                        errback(err);
                    }
                }
            );

            producerTransport.current.on('connectionstatechange', (state) => {
                switch (state) {
                    case 'connecting':
                        console.log('publishing...');
                        break;

                    case 'connected':
                        break;

                    case 'failed':
                        producerTransport.current.close();
                        producerTransport.current = null;
                        break;

                    default:
                        break;
                }
            });

            if (localStreamVideo.current) {
               const videoTrack = localStreamVideo.current.getVideoTracks()[0];
               if (videoTrack) {
                   const trackParams = {track: videoTrack};
                   videoProducer.current[
                       'stream'
                       ] = await producerTransport.current.produce(trackParams);
               }
           }
            if(localStreamAudio.current){
                const audioTrack = localStreamAudio.current.getAudioTracks()[0];
                if (audioTrack) {
                    const trackParams = {
                        track: audioTrack,
                        codecOptions: {opusStereo: 1, opusDtx: 1, maxBitrate: 8000}
                    };
                    audioProducer.current[
                        'stream'
                        ] = await producerTransport.current.produce(trackParams);
                }
            }
        } catch (e) {
            console.log('error to connect-> ', e);
        }
    }

    //+-+-+-+-+- Change stream-track(video) +-+-+-+-+
    function changeStreamVideo(video){
        videoProducer.current['stream'].replaceTrack({track: video})
    }

    // +-+-+-+ handleDisconnect-producer +-+-+
    function handleDisconnectProducer() {
        for (const mode in videoProducer.current) {
            const producer = videoProducer.current[mode];
            producer.close();
            delete videoProducer.current[mode];
        }
        for (const mode in audioProducer.current) {
            const producer = audioProducer.current[mode];
            producer.close();
            delete audioProducer.current[mode];
        }
        if (producerTransport.current) {
            producerTransport.current.close();
            producerTransport.current = null;
        }
    }

    // useEffect(() => {
    //     if(userRole === 'Maestro' && producer_started){
    //         socketRef.current.emit('update_status',{room_id:room_id, user_id:userId ,socket_id:socketRef.current.id})
    //     }
    // }, [producer_started])

    useEffect(() => {
        if (producerTransport.current !== undefined){
            handleUseVideo()
        }
    }, [my_video_status])


    useEffect(() => {
        if (producerTransport.current !== undefined){
            // warn('CHANGE STATU')
            handleUseAudio()
        }
    },[my_audio_status])

    async function handleUseAudio(){
        try{
            warn('ENTRA CHANGE AUDIO')
            if (audioProducer.current['stream']._paused) {
            // if (!my_audio_status) {
                audioProducer.current['stream'].resume()
                socketRef.current.emit(
                    'resumeProducer', {producerId: socketRef.current.id, kind: 'audio'});
                let messageContent = {
                    ...bodyMessage,
                    body: 'activó su micrófono',
                    id_subject: classData.relacion.id_materia,
                    senderId: socketRef.current.id
                };
                sendNewMessage(messageContent)
                await dispatch(updateMedia({socket_id:socketRef.current.id, mode:'audio_active', value:'1'}))
            } else{
                audioProducer.current['stream'].pause()
                socketRef.current.emit(
                    'pauseProducer', {producerId: socketRef.current.id, kind: 'audio'});
                let messageContent = {
                    ...bodyMessage,
                    body: 'desactivó su micrófono',
                    id_subject: classData.relacion.id_materia,
                    senderId: socketRef.current.id
                };
                sendNewMessage(messageContent)
                await dispatch(updateMedia({socket_id:socketRef.current.id, mode:'audio_active', value:'0'}))
            }
        }catch (e) {

        }
    }

    async function handleUseVideo(){
        try{
            if (videoProducer.current['stream']._paused) {
            // if (!my_video_status) {
                videoProducer.current['stream'].resume()
                socketRef.current.emit(
                    'resumeProducer', {producerId: socketRef.current.id, kind: 'video'});
                let messageContent = {
                    ...bodyMessage,
                    body: 'activó su cámara',
                    id_subject: classData.relacion.id_materia,
                    senderId: socketRef.current.id
                };
                sendNewMessage(messageContent)
                await dispatch(updateMedia({socket_id:socketRef.current.id, mode:'video_active', value:'1'}))
            }
            else{
                videoProducer.current['stream'].pause()
                socketRef.current.emit(
                    'pauseProducer', {producerId: socketRef.current.id, kind: 'video'});
                let messageContent = {
                    ...bodyMessage,
                    body: 'desactivó su cámara',
                    id_subject: classData.relacion.id_materia,
                    senderId: socketRef.current.id
                };
                sendNewMessage(messageContent)
                await dispatch(updateMedia({socket_id:socketRef.current.id, mode:'video_active', value:'0'}))
            }
        }catch (e) {

        }
    }

    //+-+-+-+-+- SUBSCRIBE (ConsumerTransport) -+-+-+-+-+-+
    function pauseConsumer(id, kind, mode) {
        if (mode === undefined) {
            return false;
        }
        if (kind === 'video') {
            videoConsumers.current[id][mode].pause();
        } else if (kind === 'audio') {
                audioConsumers.current[id][mode].pause();
        } else {
            console.warn('UNKNOWN consumer kind=' + kind);
        }
    }

    function producerResumed(id, kind, mode) {
        if (mode === undefined) {
            return false;
        }
        if (kind === 'video') {
            videoConsumers.current[id][mode].resume();
        } else if (kind === 'audio') {
            audioConsumers.current[id][mode].resume();
        } else {
            console.warn('UNKNOWN consumer kind=' + kind);
        }
    }


    async function subscribe(from = 'VirtualClass') {

        warn('-ENTRA SUBSCRIBE-');
        // --- get capabilities --
        const data = await sendRequest('getRouterRtpCapabilities', {socket_id: socketRef.current.id, type: 'consumer'});
        console.log('getRouterRtpCapabilities:', data);
        await loadDevice(data);
        //  }

        // --- prepare transport ---
        console.log('--- createConsumerTransport --');
        if (!consumerTransport.current) {
            const params = await sendRequest('createConsumerTransport', {room_id: roomIdRef.current});
            console.log('transport params:', params);
            consumerTransport.current = device.current.createRecvTransport(
                params
            );
            console.log('createConsumerTransport:', consumerTransport.current);

            // --- join & start publish --
            consumerTransport.current.on(
                'connect',
                async (
                    {dtlsParameters},
                    callback,
                    errback
                ) => {
                    console.log('--consumer trasnport connect');
                    sendRequest('connectConsumerTransport', {
                        dtlsParameters: dtlsParameters,
                    })
                        .then(callback)
                        .catch(errback);
                }
            );

            consumerTransport.current.on(
                'connectionstatechange',
                (state) => {
                    switch (state) {
                        case 'connecting':
                            console.log('subscribing...');
                            break;

                        case 'connected':
                            warn('subscribed');
                            break;

                        case 'failed':
                            console.log('failed');
                            break;

                        default:
                            break;
                    }
                }
            );
        }
        if (userRole !== 'Maestro' && from === 'VirtualClass') {
            consumeCurrentProducers();
        }
        if (userRole === 'Admin'&& from === 'AdminDashBoard'){
            currentTeachersProducer(from)
        }
    }

    //+-+-+-+-+- GET CURRENT TEACHERS PRODUCER +-+-+-+-+-+
    async function currentTeachersProducer(from) {
        console.log('-- try consuleAll() --');

        const remoteInfo = await sendRequest('getTeachersProducers', {
            school_id: schoolData.id
        }).catch((err) => {
            console.error('getCurrentProducers ERROR:', err);
            return [];
        });
        console.log('remoteInfo.producerIds:', remoteInfo);
        console.log('remoteInfo.remoteVideoIds:', remoteInfo.remoteVideoIds);
        console.log('remoteInfo.remoteAudioIds:', remoteInfo.remoteAudioIds);
        if (remoteInfo.remoteVideoIds.length !== 0 && remoteInfo.remoteAudioIds.length !== 0) {
            consumeAll(
                consumerTransport.current,
                remoteInfo.remoteVideoIds,
                remoteInfo.remoteAudioIds,
                from
            );
        }
    }

    //+-+-+-+-+- GET TEACHER PRODUCER DATA -+-+-+-+-+-+-+
    async function consumeCurrentProducers() {
        console.log('-- try consuleAll() --');

        const remoteInfo = await sendRequest('getProducerTeacher', {
            puesto: 'Maestro',
            room_id: roomIdRef.current
        }).catch((err) => {
            console.error('getCurrentProducers ERROR:', err);
            return [];
        });
        if (remoteInfo.remoteVideoIds.length !== 0 && remoteInfo.remoteAudioIds.length !== 0) {
            consumeAdd(
                consumerTransport.current,
                remoteInfo.remoteVideoIds[0],
                null,
                'video',
                'stream'
            ).then(() => {
                consumeAdd(
                    consumerTransport.current,
                    remoteInfo.remoteAudioIds[0],
                    null,
                    'audio',
                    'stream'
                ).then(() => {
                    setTimeout(() => {
                        dispatch(connectingToClass(false));
                        dispatch(startTransmission(true));
                    }, 2500)
                });
            });

        } else {
            await dispatch(changeConnectionStatus('no_transmissions'));
            await dispatch(connectingToClass(false));
            alertWarning('El maestro aun no ha iniciado la clase');
            dispatch(changeMainStream(null))
            if (consumersStream.current) {
                consumersStream.current = {};
            }

            if (consumerTransport.current) {
                consumerTransport.current.close();
                consumerTransport.current = null;
            }
        }
    }
    // +-+-9+-+-+- GET STUDENTS AND GUESTS PRODUCER DATA +-+-+-+-+
    useEffect(() => {
        if (producer_started === true && userRole !== 'Maestro') {
            warn('!!---ENTRA AQUI---!!')
            getStudentsVideos();
        }
    }, [producer_started])

    async function getStudentsVideos() {
        const remoteInfo = await sendRequest('getCurrentProducers', {
            localId: socketRef.current.id,
            room_id: roomIdRef.current
        }).catch((err) => {
            console.error('getCurrentProducers ERROR:', err);
            return [];
        });
        info(' - current remotes - ', remoteInfo);
        if (remoteInfo.remoteVideoIds.length !== 0 && remoteInfo.remoteAudioIds.length !== 0) {
            consumeAll(
                consumerTransport.current,
                remoteInfo.remoteVideoIds,
                remoteInfo.remoteAudioIds
            );
        }
    }

    //+-+-+-+-+-+-+-+- Consume all producers -+-+-+-+-+-+-+-+
    function consumeAll(
        transport,
        remoteVideoIds,
        remotAudioIds,
        from = 'VirtualClass'
    ) {
        try {
            console.log('----- try consumeAll() -----');
            remoteVideoIds.forEach((rId, i) => {
                consumeAdd(transport, rId, null, 'video','stream', from).then(
                    (resp) => {
                        consumeAdd(
                            transport,
                            rId,
                            null,
                            'audio',
                            'stream',
                            from
                        );
                    }
                );
            });
        }catch (e) {

        }
    }

    //-+-+-+-+-+ consume & add new producer
    async function consumeAdd(
        transport,
        remoteSocketId,
        prdId,
        trackKind,
        mode = 'stream',
        from = 'VirtualClass'
    ) {
        console.log('--start of consumeAdd -- kind=%s', trackKind);
        if(device.current === undefined){
            return
        }
        const {rtpCapabilities} = device.current;
        console.log("DATA FOR CONSUME ADD->", roomIdRef.current)
        const data = await sendRequest('consumeAdd', {
            rtpCapabilities: rtpCapabilities,
            remoteId: remoteSocketId,
            kind: trackKind,
            mode: mode,
            room_id: roomIdRef.current
        }).catch((err) => {
            console.error('consumeAdd ERROR:', err);
        });
        const {producerId, id, kind, rtpParameters} = data;
        if (prdId && prdId !== producerId) {
            console.warn('producerID NOT MATCH');
        }

        let codecOptions = {};
        const consumer = await transport.consume({
            id,
            producerId,
            kind,
            rtpParameters,
            codecOptions,
            mode,
        });

        addConsumer(remoteSocketId, consumer, kind, mode);
        consumer.remoteId = remoteSocketId;
        consumer.on('transportclose', () => {
            warn('IN CLASS')
            console.log(
                '--consumer transport closed. remoteId=' + consumer.remoteId
            );
        });
        consumer.on('producerclose', () => {
            console.log(
                '--consumer producer closed. remoteId=' + consumer.remoteId
            );
            consumer.close();
            removeConsumer(consumer.remoteId, kind, mode);
            removeRemoteVideo(consumer.remoteId, mode);
        });
        consumer.on('trackended', () => {
            console.log('--consumer trackended. remoteId=' + consumer.remoteId);
        });

        consumer.observer.on('pause', async () => {
            console.log("ENTRA AL PAUSE")
            if (consumer.kind === "video") {
                if (userRole === 'Admin') {
                    await dispatch(updateMediaTeacher({
                        prop: 'camera',
                        value: false,
                        response: true,
                        user_id: consumer.remoteId
                    }))
                    // return
                }
                await dispatch(updateMedia({socket_id: consumer.remoteId, mode:'video_active', value:'0'}))
            } else {
                if (userRole === 'Admin') {
                    await dispatch(updateMediaTeacher({
                        prop: 'mic',
                        value: false,
                        response: true,
                        user_id: consumer.remoteId
                    }))
                }
                await dispatch(updateMedia({socket_id: consumer.remoteId, mode:'audio_active', value:'0'}))
            }
            console.log('--consumer paused on local. remoteId=' + consumer.remoteId);
        })

        consumer.observer.on('resume', async () => {
            console.log("ENTRA AL RESUME")
            if (consumer.kind === "video") {
                await dispatch(updateMediaTeacher({
                    prop: 'camera',
                    value: true,
                    response: true,
                    user_id: consumer.remoteId
                }))
                await dispatch(updateMedia({socket_id: consumer.remoteId, mode:'video_active', value:'1'}))
            } else {
                await dispatch(updateMediaTeacher({
                    prop: 'mic',
                    value: true,
                    response: true,
                    user_id: consumer.remoteId
                }))
                await dispatch(updateMedia({socket_id: consumer.remoteId, mode:'audio_active', value:'1'}))
            }
            console.log('--consumer resume. remoteId=' + consumer.remoteId);
        })
        console.log('--end of consumeAdd');

        if (kind === 'video') {
            console.log('--try resumeAdd --');
            sendRequest('resumeAdd', {
                remoteId: remoteSocketId,
                kind: kind,
                mode,
            }).then(() => {
                console.log('resumeAdd OK');
            }).catch((err) => {
                console.error('resumeAdd ERROR:', err);
            });
        }
        return new Promise((resolve, reject) => {
            addRemoteTrack(remoteSocketId, consumer.track, mode, from);
            resolve();
        });
    }

    function addRemoteTrack(id, track, mode, from) {
        if (id === socketRef.current.id) {
            return false;
        }
        if (consumersStream.current[id] === undefined) {
            consumersStream.current[id] = {};
        }

        if (consumersStream.current[id][mode] === undefined) {
            let newStream = new MediaStream();
            newStream.addTrack(track);
            consumersStream.current[id][mode] = {
                stream: newStream,
                socket_id: id,
            };
        } else {
            consumersStream.current[id][mode].stream.addTrack(track);
        }

        function findTeacher(select) {
            return select.puesto === 'Maestro' && select.socket_id === id && socketRef.current.id !== id;
        }

        if (students_in_class.find(findTeacher) !== undefined && from === 'VirtualClass') {
            let dataUsr = students_in_class.find(findTeacher);
            if (dataUsr.puesto === 'Maestro' && userRole !== 'Maestro') {
                dispatch(changeMainStream(consumersStream.current[id]['stream']['stream']));
            }
        }

        dispatch(setRemoteVideos({...consumersStream.current}));
    }

    function addConsumer(id, consumer, kind, mode) {
        if (id === socketRef.current.id) {
            return false;
        }
        if (kind === 'video') {
            if (videoConsumers.current[id] === undefined) {
                videoConsumers.current[id] = {};
            }
            videoConsumers.current[id][mode] = consumer;
            console.log(
                'videoConsumers count=' +
                Object.keys(videoConsumers.current).length
            );
        } else if (kind === 'audio') {
            if (audioConsumers.current[id] === undefined) {
                audioConsumers.current[id] = {};
            }
            audioConsumers.current[id][mode] = consumer;

            console.log(
                'audioConsumers count=' +
                Object.keys(audioConsumers.current).length
            );
        } else {
            console.warn('UNKNOWN consumer kind=' + kind);
        }
    }

    function removeConsumer(id, kind, mode) {
        if (mode === undefined) {
            return false;
        }
        if (kind === 'video') {
            if (mode === 'stream') {
                delete videoConsumers.current[id];
            } else {
                delete videoConsumers.current[id][mode];
            }

            console.log(
                'videoConsumers count=' +
                Object.keys(videoConsumers.current).length
            );
        } else if (kind === 'audio') {
            if (mode === 'stream') {
                delete audioConsumers.current[id];
            } else {
                delete audioConsumers.current[id][mode];
            }

            console.log(
                'audioConsumers count=' +
                Object.keys(audioConsumers.current).length
            );
        } else {
            console.warn('UNKNOWN consumer kind=' + kind);
        }
    }

    function removeRemoteVideo(id, mode) {
        console.log(' ---- removeRemoteVideo() id=' + id);
        if (mode === 'stream') {
            delete consumersStream.current[id];
        } else {
            delete consumersStream.current[id][mode];
        }

        const newRemotes = (peers) => {
            delete peers[id];
            return {...consumersStream.current};
        }

        function findGuest(select) {
            return select.socket_id === id;
        }

        info('-- ADMIN LEAVE --', id);
        info('-- SEARCH IN GUESTS --', currentGuestRef.current);
        let ixGuest = currentGuestRef.current.indexOf(currentGuestRef.current.find(findGuest));
        info('ADMIN EXIT', ixGuest);
        if (ixGuest !== -1) {
            const temp = [...currentGuestRef.current];
            temp.splice(ixGuest, 1);
            info('CURRENT ARR-->', temp)
            dispatch(setGuests(temp));
        }
        dispatch(setRemoteVideos(newRemotes(remoteVideos)));
    }

    function handleDisconnectConsumer() {
        for (const key in videoConsumers.current) {
            for (const key2 in videoConsumers.current[key]) {
                const consumer = videoConsumers.current[key][key2];
                consumer.close();
                delete videoConsumers.current[key][key2];
            }
        }
        for (const key in audioConsumers.current) {
            for (const key2 in audioConsumers.current[key]) {
                const consumer = audioConsumers.current[key][key2];
                consumer.close();
                delete audioConsumers.current[key][key2];
            }
        }

        if (consumersStream.current) {
            consumersStream.current = {};
        }

        if (consumerTransport.current) {
            consumerTransport.current.close();
            consumerTransport.current = null;
        }

        removeAllRemoteVideo()
    }

    function removeAllRemoteVideo() {
        console.log(' ---- removeAllRemoteVideo() id ---- ');
        consumersStream.current = {};
        dispatch(setRemoteVideos({}));
    }

    async function loadDevice(routerRtpCapabilities) {
        try {
            device.current = new Device();
        } catch (error) {
            if (error.name === 'UnsupportedError') {
                console.error('browser not supported');
            }
        }
        await device.current.load({routerRtpCapabilities});
    }

    return (
        <Provider
            value={{
                socketRef,
                sendNewMessage,
                newMessageRecive,
                sendRequest,
                joinToRoom,
                handleConnect,
                handleDisconnectProducer,
                changeStreamVideo,
                subscribe,
                handleDisconnectConsumer
            }}>
            {props.children}
        </Provider>
    )
}

export default KolegiaBroadcastContext;
export {KolegiaBroadcastContextProvider, Consumer as KolegiaBroadcastContextConsumer};
