import {
    LOADING_DATOS,
    DATOS_USER_ID,
    LOADING_POST_DATOS,
    DATOS_USER_ALUMNO
} from '../../actions/types';

const initialState = {
    loading: false,
    loading_post: false,
    datos_user_id: [],
    datos_user_alumno: [],
};

export default function DatosDeContactoReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_DATOS:
            return {
                ...state,
                loading: action.payload,
            };
        case DATOS_USER_ID:
            return {
                ...state,
                datos_user_id: action.payload,
                loading: false,
            };
        case DATOS_USER_ALUMNO:
            return {
                ...state,
                datos_user_alumno: action.payload,
                loading: false,
            };
        case LOADING_POST_DATOS:
            return {
                ...state,
                loading_post: action.payload,
            };
        default:
            return {
                ...state,
                loading: false,
                loading_post: false,
            };
    }
}
