import React, {useEffect, useState} from 'react';
import {Row} from 'react-material-responsive-grid';
import {pulseLoader} from "../NewFunctions";
import {useSelector} from "react-redux";


export default function SaveButtonV2(props) {
    const {
        secondColor,
        fourthColor,
    } = useSelector(state => state.rooot)
    const post_loading = useSelector(state => state.plan_estudios.post_loading);
    const {
        title = 'guardar',
        onClick,
        size = '2',
        icon = "fas fa-save uk-width-1-3",
        colorButton = secondColor,
        isVisible = false,
        styles = {
            padding: 10,
            lineHeight: 1,
            backgroundColor: secondColor,
            borderRadius: 7
        },
        text_color = fourthColor
    } = props;

    const [visibleSpin, setVisibleSpin] = useState(false);


    useEffect(() => {
        setVisibleSpin(isVisible);
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    return <Row className="centerAll uk-width-1-1@s">
        <Row data-uk-tooltip={title}
             className={'uk-button uk-button-default uk-width-1-' + size}
             style={Object.assign({
                 padding: 10,
                 borderWidth: 0,
                 lineHeight: 1,
                 backgroundColor: secondColor,
                 borderRadius: 7,
                 pointerEvents: post_loading ? 'none' : null
             }, styles)}
             onClick={() => {
                 onClick();
             }}>
            {post_loading === true ?
                <Row className='uk-width-1-1 centerAll'>
                    {pulseLoader(text_color)}
                </Row>
                : <Row className='uk-width-1-1 centerAll'>
                    <div><i className={icon} style={{color: text_color}}/></div>
                    <label className='normal_text uk-width-2-3 uk-text-truncate'
                           style={{marginLeft: 10, color: text_color, lineHeight: 1}}>
                        {title}
                    </label>
                </Row>}
        </Row>
    </Row>;
}
