import {
    ALUMNOS_GG,
    CALIFICACIONES,
    COMENTARIOS_PREFECTO,
    CONCENTRADO_CALIFS,
    CONVERTIONS,
    CONVERTIONS_MAESTRO,
    CONVERTIONS_MATERIA,
    LOADING_CALIFICACIONES,
    MATERIAS,
    PROMEDIO_ANUAL,
    RANKINGS_ALUMNOS,
    SEARCH_CALIF_SCHEDULE,
    THE_CALIFICACIONES
} from '../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import {alertFail, alertSuccess, sortList, sortListNombre} from "../../../components/Global-W/NewFunctions";

export function setLoading(data) {
    return {
        type: LOADING_CALIFICACIONES,
        payload: data
    };
}

export function saveSello(bd, token, file) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            let formData = new FormData();
            formData.append('imagen', file);
            axios.post(AppEnv.api + bd + "/calificaciones/save/image/sello", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: 'Bearer ' + token
                },
            }).then(async (response) => {
                alertSuccess()
                dispatch(fetchConfCalificaciones(bd, token))
            }).catch((error) => {
                alertFail()
            });
        });
    };

}

export function periodosRankingConfig(bd, token) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/calificaciones/save/periodos/ranking', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                resolve();
            }).catch(error => {
                alertFail();
                resolve();
            });
        });
    };
}

// <=<●>=><=<●>=><=<●>=> Configuracion <=<●>=><=<●>=><=<●>=>
export function fetchConfCalificaciones(bd, token) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/config/all', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(periodosRankingConfig(bd, token));
                dispatch(fetchConfCalificacionesSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchConfCalificacionesFailed());
                resolve();
            });
        });
    };
}

export function fetchConfCalificacionesSuccess(response) {
    return {
        type: CALIFICACIONES,
        alias_boleta: response.alias_boleta ? response.alias_boleta : 'Boleta',
        colegiatura_calificaciones: response.colegiatura_calificaciones === '1',
        comments_prefecto: response.comments_prefecto === '1',
        comments_maestro: response.comments_maestro === '1',
        dos_decimales: response.dos_decimales === '1',
        download_colegiatura_calificaciones: response.download_colegiatura_calificaciones === '1',
        fecha_calificaciones: response.fecha_calificaciones ? response.fecha_calificaciones : '2080-01-01',
        firmas: response.firmas ? response.firmas : '[]',
        firmas_planes: response.firmas_planes ? response.firmas_planes : '[]',
        periodos_ranking: response.periodos_ranking ? JSON.parse(response.periodos_ranking) : '[]',
        periodos_ranking_extra: response.periodos_ranking_extra ? JSON.parse(response.periodos_ranking_extra) : [],
        indicadores: response.indicadores === '1',
        indicadores_generales: response.indicadores_generales === '1',
        minima_aprobatoria: response.minima_aprobatoria ? response.minima_aprobatoria : '6',
        minima_boleta: response.minima_boleta ? response.minima_boleta : '5',
        mostrar_calificaciones: response.mostrar_calificaciones === '1',
        mostrar_fecha_calificaciones: response.mostrar_fecha_calificaciones === '1',
        ninguno: response.un_decimal !== '1' && response.dos_decimales !== '1',
        periodos_fechas: response.periodos_fechas ? response.periodos_fechas : '[]',
        tabla_indicadores: response.tabla_indicadores === '1',
        tabla_observaciones: response.tabla_observaciones === '1',
        titulo_boleta: response.titulo_boleta ? response.titulo_boleta : 'Boleta de calificaciones',
        un_decimal: response.un_decimal === '1',
        mostrar_faltas: response.mostrar_faltas === '1',
        mostrar_ranking: response.mostrar_ranking === '1',
        ranking_por_materia: response.ranking_por_materia === '1',
        ranking_por_grado: response.ranking_por_grado === '1',
        ranking_por_grupo: response.ranking_por_grupo === '1',
        alias_ranking: response.alias_ranking ? response.alias_ranking : 'Ranking',
        tipo_faltas: response.tipo_faltas ? response.tipo_faltas : 'calculadas',
        alias_falta: response.alias_falta ? response.alias_falta : 'Inasistencias',
        cantidad_deudas_posibles: response.cantidad_deudas_posibles ? response.cantidad_deudas_posibles : '1',
        mostrar_promedio_area: response.mostrar_promedio_area === '1',
        mostrar_resultados: response.mostrar_resultados === '1',
        mostrar_resultados_area: response.mostrar_resultados_area === '1',
        mostrar_firma_tutor: response.mostrar_firma_tutor === '1',
        mostrar_firma_prefecto: response.mostrar_firma_prefecto === '1',
        mostrar_firma_maestro: response.mostrar_firma_tutor === '1',
        mostrar_logo_oficial: response.mostrar_firma_maestro === '1',
        mostrar_promedio_general: response.mostrar_promedio_general === '1',
        restriction_quizzes: response.restriction_quizzes === '1',
        sello: response.sello ? response.sello : '',
        paper_size: response.paper_size ? response.paper_size : 'letter',
        paper_width: response.paper_width ? response.paper_width : 21.6,
        paper_height: response.paper_height ? response.paper_height : 27.9,
    };
}

export function fetchConfCalificacionesFailed() {
    alertFail();
    return {
        type: CALIFICACIONES,
        payload: []
    };
}

// <=<●>=><=<●>=><=<●>=> Alumnos <=<●>=><=<●>=><=<●>=>
export function fetchAlumnosGG(bd, token, grado, grupo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/calificaciones/alumnos/boletas/' + grado + '/' + grupo, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchAlumnosGGSuccess(response.data));
            }).catch(error => {
                dispatch(fetchAlumnosGGFailed());
            });
            resolve()
        });
    };
}

// <=<●>=><=<●>=><=<●>=> Alumnos <=<●>=><=<●>=><=<●>=>
export function fetchAlumnosGGCiclo(bd, token, ciclo, grado, grupo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/calificaciones/alumnos/boletas/ciclo/' + ciclo + '/' + grado + '/' + grupo, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchAlumnosGGSuccess(response.data));
            }).catch(error => {
                dispatch(fetchAlumnosGGFailed());
            });
            resolve()
        });
    };
}

export function fetchAlumnosGGSuccess(response) {
    return {
        type: ALUMNOS_GG,
        payload: sortList(response)
    };
}

export function fetchAlumnosGGFailed() {
    alertFail();
    return {
        type: ALUMNOS_GG,
        payload: []
    };
}


export function getConversiones(bd, token) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/conversiones/get', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchConversionesSuccess(response.data));
                resolve()
            }).catch(error => {
                dispatch(fetchConversionesFailed());
                resolve()
            });
        });
    };
}

export function fetchConversionesSuccess(response) {

    if (response.conversiones.length === 0) {
        return {
            type: CONVERTIONS,
            conversiones: [
                {numerico: '10', alfabetico: 'A', nivel: 'IV'},
                {numerico: '9', alfabetico: 'B', nivel: 'III'},
                {numerico: '8', alfabetico: 'B', nivel: 'III'},
                {numerico: '7', alfabetico: 'C', nivel: 'II'},
                {numerico: '6', alfabetico: 'C', nivel: 'II'},
                {numerico: '5', alfabetico: 'D', nivel: 'I'}
            ],
            tipo: 'numerico',
            tipos_selected: [
                {nombre: 'Numérico', tipo: 'numerico'},
                {nombre: 'Alfabético', tipo: 'alfabetico'},
                {nombre: 'Nivel', tipo: 'nivel'}
            ],
            alfabetico: [],
            numerico: [],
            niveles: [],
        };
    } else {
        return {
            type: CONVERTIONS,
            alfabetico: response.alfabetico,
            conversiones: response.conversiones,
            niveles: response.niveles,
            numerico: response.numerico,
            tipo: response.tipo,
            tipos_selected: response.tipos_selected,
        };
    }
}

export function fetchConversionesFailed() {
    alertFail();
    return {
        type: CONVERTIONS,
        payload: []
    };
}

// <=<●>=><=<●>=><=<●>=> Promedio <=<●>=><=<●>=><=<●>=>
export function fetchPromedioAnual(bd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(AppEnv.api + bd + '/calificaciones/promedio/anual', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => {
            dispatch(fetchPromedioAnualSuccess(response.data));
        }).catch(error => {
            dispatch(fetchPromedioAnualFailed());
        });
    };
}

export function fetchPromedioAnualSuccess(response) {
    return {
        type: PROMEDIO_ANUAL,
        payload: response
    };
}

export function fetchPromedioAnualFailed() {
    alertFail();
    return {
        type: PROMEDIO_ANUAL,
        payload: 0.00
    };
}

// <=<●>=><=<●>=><=<●>=> Materias <=<●>=><=<●>=><=<●>=>
export function fetchMaterias(bd, token, ciclo, grado) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(AppEnv.api + bd + '/subjects/v2/get/materias/' + ciclo + '/' + grado, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(fetchMateriasSuccess(response.data));
        }).catch(error => {
            dispatch(fetchMateriasFailed());
        });
    };
}

export function fetchMateriasSuccess(response) {
    return {
        type: MATERIAS,
        payload: response
    };
}

export function fetchMateriasFailed() {
    alertFail();
    return {
        type: MATERIAS,
        payload: []
    };
}

// <=<●>=><=<●>=><=<●>=> Materias <=<●>=><=<●>=><=<●>=>
export function fetchCalificaciones(bd, token, ciclo, periodo, materia, grado, grupo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/calif/maestro/' + ciclo + '/' +
                periodo + '/' + materia + '/' + grado + '/' + grupo, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchCalificacionesSuccess(response.data));
                resolve()
            }).catch(error => {
                dispatch(fetchCalificacionesFailed());
                resolve()
            });
        });
    };
}

export function fetchQualifications(bd, token, ciclo, materia, grado, grupo, typePeriod, period, subPeriod) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/qualifications/teacher/' + ciclo + '/' + materia + '/' + grado + '/' + grupo + '/' + typePeriod + '/' + period + '/' + subPeriod, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchCalificacionesSuccess(response.data));
                resolve()
            }).catch(error => {
                dispatch(fetchCalificacionesFailed());
                resolve()
            });
        });
    };
}

export function fetchCalificacionesSuccess(response) {
    return {
        type: THE_CALIFICACIONES,
        payload: sortListNombre(response)
    };
}

export function fetchCalificacionesFailed() {
    alertFail();
    return {
        type: THE_CALIFICACIONES,
        payload: []
    };
}


export function setCalificaciones(response) {
    return {
        type: THE_CALIFICACIONES,
        payload: response
    };
}

// <=<●>=><=<●>=><=<●>=> Comentarios <=<●>=><=<●>=><=<●>=>
export function fetchAlumnosComentarioPrefecto(bd, token, grado, grupo, periodo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(AppEnv.api + bd + '/calificaciones/comentarios/prefecto/' + grado + '/' + grupo + '/' + periodo, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(comentariosPrefectoSuccess(response.data));
        }).catch(error => {
            dispatch(comentariosPrefectoFailed());
        });
    };
}

export function fetchGeneralRemarks(bd, token, grado, grupo, typePeriod, periodo, subPeriod) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(AppEnv.api + bd + '/qualifications/general/remarks/' + grado + '/' + grupo + '/' + typePeriod + '/' + periodo + '/' + subPeriod, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(comentariosPrefectoSuccess(response.data));
        }).catch(error => {
            dispatch(comentariosPrefectoFailed());
        });
    };
}

export function generalRemarksStudent(bd, token, grado, grupo, periodo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(AppEnv.api + bd + '/calificaciones/comentarios/prefecto/' + grado + '/' + grupo + '/' + periodo, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            dispatch(comentariosPrefectoSuccess(response.data));
        }).catch(error => {
            dispatch(comentariosPrefectoFailed());
        });
    };
}

export function comentariosPrefectoSuccess(response) {
    return {
        type: COMENTARIOS_PREFECTO,
        payload: response
    };
}

export function comentariosPrefectoFailed() {
    alertFail();
    return {
        type: COMENTARIOS_PREFECTO,
        payload: []
    };

}

export function saveCommentPrefecto(token, bd, fd, grado, grupo, periodo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.post(AppEnv.api + bd + '/calificaciones/save/comments/prefecto', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                alertSuccess();
                dispatch(fetchAlumnosComentarioPrefecto(bd, token, grado, grupo, periodo));
                resolve()
            }).catch(error => {
                alertFail();
                resolve()
                dispatch(fetchAlumnosComentarioPrefecto(bd, token, grado, grupo, periodo));
            });
        });
    };
}

export function saveRemarks(token, bd, fd, grado, grupo, typePeriod, periodo, subPeriod) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.post(AppEnv.api + bd + '/qualifications/save/remarks', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                alertSuccess();
                dispatch(fetchGeneralRemarks(bd, token, grado, grupo, typePeriod, periodo, subPeriod));
                resolve()
            }).catch(error => {
                alertFail();
                resolve()
                dispatch(fetchGeneralRemarks(bd, token, grado, grupo, typePeriod, periodo, subPeriod));
            });
        });
    };
}


// <=<●>=><=<●>=><=<●>=><=<●>=> Converisones maestro  <=<●>=><=<●>=><=<●>=><=<●>=>

export function fetchConversionesMaestro(bd, token, ciclo, periodo, materia, maestro, categoria) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/conversiones/maestros/' + ciclo + '/' + periodo + '/' + materia + '/' + maestro + '/' + categoria, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchConversionesMaestroSuccess(response.data));
                resolve()
            }).catch(error => {
                dispatch(fetchConversionesMaestroFailed());
                resolve()
            });
        });
    };
}

export function fetchConversionsTeacher(bd, token, ciclo, materia, maestro, categoria, type_period, periodo, sub_period) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/conversions/teachers/' + ciclo + '/' + materia + '/' + maestro + '/' + categoria + '/' + type_period + '/' + periodo + '/' + sub_period, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchConversionesMaestroSuccess(response.data));
                resolve()
            }).catch(error => {
                dispatch(fetchConversionesMaestroFailed());
                resolve()
            });
        });
    };
}

export function fetchConversionesMaestroSuccess(response) {
    return {
        type: CONVERTIONS_MAESTRO,
        payload: response
    };
}

export function fetchConversionesMaestroFailed() {
    alertFail();
    return {
        type: CONVERTIONS_MAESTRO,
        payload: []
    };
}

// <=<●>=><=<●>=><=<●>=><=<●>=> Rankings alumno  <=<●>=><=<●>=><=<●>=><=<●>=>

export function fetchRankingAlumnos(bd, token, grado, grupo, periodo, ciclo, variable) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/calificaciones/alumnos/ranking/' + grado + '/' + grupo + '/' + periodo + '/' + ciclo + '/' + variable, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                if (variable < 7) {
                    let suma = variable + 1;
                    dispatch(fetchRankingAlumnos(bd, token, grado, grupo, periodo, ciclo, suma))
                } else {
                    dispatch(fetchRankingAlumnosSuccess(response.data));
                }
                // resolve()
            }).catch(error => {
                dispatch(fetchRankingAlumnosFailed());
                resolve()
            });
        });
    };
}

export function fetchRankingStudents(bd, token, grado, grupo, ciclo, variable, type_period ,periodo, subPeriod) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/qualifications/students/ranking/' + grado + '/' + grupo + '/' + ciclo + '/' + variable +'/'+type_period + '/' +periodo +'/'+subPeriod, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                if (variable < 7) {
                    let suma = variable + 1;
                    dispatch(fetchRankingAlumnos(bd, token, grado, grupo, periodo, ciclo, suma))
                } else {
                    dispatch(fetchRankingAlumnosSuccess(response.data));
                }
                // resolve()
            }).catch(error => {
                dispatch(fetchRankingAlumnosFailed());
                resolve()
            });
        });
    };
}

export function fetchRankingAlumnosSuccess(response) {
    return {
        type: RANKINGS_ALUMNOS,
        payload: response
    };
}

export function fetchRankingAlumnosFailed() {
    alertFail();
    return {
        type: RANKINGS_ALUMNOS,
        payload: []
    };
}

// <=<●>=><=<●>=><=<●>=><=<●>=> Converisones materia  <=<●>=><=<●>=><=<●>=><=<●>=>

export function fetchConversionesMateria(bd, token, ciclo, categoria) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/conversiones/materia/' + ciclo + '/' + categoria, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchConversionesMateriaSuccess(response.data));
                resolve()
            }).catch(error => {
                resolve()
            });
        });
    };
}

export function fetchConversionesMateriaSuccess(response) {
    return {
        type: CONVERTIONS_MATERIA,
        alfabetico: response.alfabetico,
        conversiones: response.conversiones,
        niveles: response.niveles,
        numerico: response.numerico,
        tipo: response.tipo,
        tipos_selected: response.tipos_selected,
    };
}

// <=<●>=><=<●>=><=<●>=><=<●>=> Rankings alumno  <=<●>=><=<●>=><=<●>=><=<●>=>

export function fetchSearchCalifSchedule(bd, token, id_materia, grupo, periodo, sup_period = null) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            let api = '/calificaciones/search/califications/' + id_materia + '/' + grupo + '/' + periodo + '/';
            if (api !== null) {
                api = '/calificaciones/search/califications/' + id_materia + '/' + grupo + '/' + periodo + '/' + sup_period.name;
            }
            axios.get(AppEnv.api + bd + api, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchSearchCalifScheduleSuccess(response.data));
                resolve(true)
            }).catch(error => {
                dispatch(fetchSearchCalifScheduleFailed());
                reject(false)
            });
        });
    };
}

export function fetchSearchQualifsSchedule(bd, token, id_materia, grupo, typePeriod, periodo, supPeriod ) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/qualifications/search/' + id_materia + '/' + grupo + '/' + periodo+'/'+typePeriod + '/' + supPeriod, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchSearchCalifScheduleSuccess(response.data));
                resolve(true)
            }).catch(error => {
                dispatch(fetchSearchCalifScheduleFailed());
                reject(false)
            });
        });
    };
}

export function fetchSearchCalifScheduleSuccess(response) {
    return {
        type: SEARCH_CALIF_SCHEDULE,
        payload: response
    };
}

export function fetchSearchCalifScheduleFailed() {
    alertFail();
    return {
        type: SEARCH_CALIF_SCHEDULE,
        payload: []
    };
}

// <=<●>=><=<●>=><=<●>=><=<●>=> ConcentradoCalifs  <=<●>=><=<●>=><=<●>=><=<●>=>
let CancelToken = axios.CancelToken;
let source = CancelToken.source();

export function fetchConcentradoCalifs(bd, token, grado, grupo, periodo, ciclo) {
    source.cancel('Operation canceled by the user.');
    CancelToken = axios.CancelToken;
    source = CancelToken.source();
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/calificaciones/concentrado/califications/' + grado + '/' + grupo + '/' + periodo + '/' + ciclo, {
                    cancelToken: source.token,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchConcentradoCalifsSuccess(response.data));
                resolve()
            }).catch(error => {
                dispatch(fetchConcentradoCalifsFailed(error));
                resolve()
            });
        });
    };
}

export function fetchDumpByStudent(bd, token, grado, grupo, periodo, subPeriod = null, typePeriod, ciclo) {
    source.cancel('Operation canceled by the user.');
    CancelToken = axios.CancelToken;
    source = CancelToken.source();
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(AppEnv.api + bd + '/qualifications/dump/student/' + grado + '/' + grupo + '/' + ciclo + '/' + typePeriod + '/' + periodo + '/' + subPeriod, {
                    cancelToken: source.token,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchConcentradoCalifsSuccess(response.data));
                resolve()
            }).catch(error => {
                dispatch(fetchConcentradoCalifsFailed(error));
                resolve()
            });
        });
    };
}

export function fetchConcentradoCalifsSuccess(response) {
    return {
        type: CONCENTRADO_CALIFS,
        payload: response
    };
}

export function fetchConcentradoCalifsFailed(error) {
    if (axios.isCancel(error)) {
        console.error('Request canceled', error.message);
    } else {
        alertFail();
    }
    return {
        type: CONCENTRADO_CALIFS,
        payload: []
    };
}

