import React, {useEffect, useState} from 'react';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import {Row} from 'react-material-responsive-grid';
import ReactPlayer from 'react-player';
import {alertWarning, diasRestantes, horasRestantes, suspenseFallback} from "../Global-W/NewFunctions";
import {useSelector} from "react-redux";
import ImageZoom from "../Global-W/Hooks/ImageZoom";

const MultimediaButton = (props) => {
    const {grayColorOne,} = useSelector(state => state.rooot);
    const {icon, title,} = props
    return <Row
        key={title}
        className='uk-width-1-1 centerAll'
        style={{
            backgroundColor: grayColorOne,
            borderRadius: 6,
            borderWidth: 0,
            margin: 0,
            padding: 3
        }}>
        <Row className="uk-width-1-1 centerAll normal_text"
             style={{margin: 0, padding: 3}}>
            {icon}
        </Row>
        <Row className="uk-width-1-1 centerAll normal_text"
        >
            <label className="uk-text-truncate">
                {title}
            </label>
        </Row>
    </Row>
}

const moment = require('moment/moment');
require('moment/locale/es');
moment.locale('es');

export default function CardActivity(props) {
    const {
        fecha_prorroga = '',
        is_aviso = false,
        estatus_actividad = '1',
        published = null,
        retroalimentacion = [],
        fecha_enviado = '',
        enviado = false,
        editable = true,
        id_criterio = null,
        autor,
        tema,
        deleted,
        fecha,
        logo,
        dueDate,
        dueTime,
        resumen,
        requeridos = null,
        otros_requeridos = null,
        tipo_entrega = null,
        role,
        titulo,
        updated,
        files,
        openFiles,
        destinatarios,
        imagenes = [],
        documentos = [],
        videos = [],
        audios = [],
        links = [],
        ponderacion
    } = props;
    const {
        schoolData,
        mainColor,
        thirdColor,
        fourthColor,
        fifthColor,
        orangeColor,
        redColor,
        grayColorOne,
        grayColorTwo,
        grayColorFive
    } = useSelector(state => state.rooot);
    const [openPdf, setOpenPdf] = useState(false);
    const [openImage, setOpenImage] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);
    const [openAudio, setOpenAudio] = useState(false);
    const [openLinks, setOpenLinks] = useState(false);
    // const [buttonsMedias, setButtonsMedias] = useState([]);
    // const [urlVideo, setUrlVideo] = useState('');
    // const [urlAudio, setUrlAudio] = useState('');
    const [counDay, setCounDay] = useState(0);
    const [countHour, setCountHour] = useState(0);
    const [imagenIndex, setImagenIndex] = useState(0);
    const [documentosIndex, setDocumentosIndex] = useState(0);
    const [videoIndex, setVideoIndex] = useState(0);
    const [audiosIndex, setAudiosIndex] = useState(0);
    const [modalFeedBack, setModalFeedBack] = useState(false);
    const [loadFile, setLoadFile] = useState(true);
    // const [feedBack, setFeedBack] = useState([]);

    useEffect(() => {
        setCounDay(diasRestantes(moment().format('YYYY-MM-DD'), moment(dueDate).format('YYYY-MM-DD')));
        setCountHour(horasRestantes(moment().format('YYYY-MM-DD HH:mm:ss'), moment(dueDate + ' ' + dueTime).format('YYYY-MM-DD HH:mm:ss')));
    }, [dueDate]) // eslint-disable-line react-hooks/exhaustive-deps

    const countDay = () => {
        let textDiasRest = 'DEBE ENTREGARSE DENTRO DE ' + counDay + ' DÍAS ';
        if (counDay === 1) {
            textDiasRest = 'SE ENTREGA MAÑANA';
        }
        return textDiasRest;
    }

    const renderFeedbacks = retroalimentacion.map((item, i) => {
        return <Row
            className="uk-width-1-1"
            style={{
                margin: 0,
                padding: 10,
                borderRadius: 6,
                backgroundColor: grayColorOne,
                marginBottom: 8
            }}>
            <Row className="uk-width-1-1 txtnegritasMiniBlack">
                Titulo
            </Row>
            <Row className=" uk-width-1-1">
                <div
                    className='uk-width-1-1 work'
                    style={{
                        margin: 0,
                        padding: 10,
                        backgroundColor: grayColorTwo,
                        borderRadius: 6,
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        pointerEvents: 'none'
                    }}
                >
                    {item.titulo}
                </div>
            </Row>
            <Row className="uk-width-1-1 txtnegritasMiniBlack" style={{marginTop: 10}}>
                Retroalimentación
            </Row>
            <Row className=" uk-width-1-1">
                <Row
                    className='uk-width-1-1 work'
                    rows="7"
                    style={{
                        margin: 0,
                        padding: 10,
                        backgroundColor: grayColorTwo,
                        borderRadius: 6,
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        pointerEvents: 'none'
                    }}
                >
                    {item.descripcion}
                </Row>
            </Row>
        </Row>
    })

    const extra = () => {
        return <Row className="uk-width-1-1">
            <Modal
                center
                onClose={() => setOpenImage(false)}
                open={openImage}>
                <Row className='modalWidth1 centerAll'
                     tabIndex="-1"
                     data-uk-slider
                     style={{
                         marginTop: 20,
                         backgroundColor: grayColorFive
                     }}>
                    {renderImages2}
                </Row>
            </Modal>

            <Modal
                center
                onClose={() => setOpenPdf(false)}
                open={openPdf}>
                <Row className="uk-width-1-1 centerAll modalWidth1"
                     style={{height: '75vh',position:'relative'}}>
                    {loadFile ? <Row className="uk-width-1-1 centerAll" style={{backgroundColor:'rgba(0,0,0,0.45)', position:'absolute', height:'80vh'}}>
                        {suspenseFallback()}
                    </Row>:null}
                    {renderDocumentos}
                </Row>
            </Modal>

            <Modal
                center
                onClose={() => {
                    setOpenVideo(false);
                }}
                open={openVideo}>
                <Row className="uk-width-1-1 centerAll modalWidth1"
                     style={{height: '75vh',}}>
                    {renderVideos}
                </Row>
            </Modal>

            <Modal
                center
                onClose={() => {
                    setOpenAudio(false);
                    // setUrlAudio('');
                }}
                open={openAudio}>
                <Row className="uk-width-1-1 centerAll modalWidth1"
                     style={{height: '75vh',}}>
                    {renderAudios}
                </Row>
            </Modal>

            <Modal
                center
                onClose={() => {
                    setOpenLinks(false);
                    // setUrlAudio('');
                }}
                open={openLinks}>
                <Row className="uk-width-1-1 centerAll modalWidth1"
                     style={{height: '75vh'}}>
                    {renderLinks}
                </Row>
            </Modal>
            <Modal
                center
                onClose={() => {
                    setModalFeedBack(false);
                    // setFeedBack([]);
                }}
                open={modalFeedBack}>
                <Row className="modalWidth1" style={{width: '30vw'}}>
                    <Row className="uk-width-1-1 module_title"
                         style={{
                             margin: 0,
                             padding: 0,
                             marginTop: 20,
                             fontSize: '20px',
                             textAlign: 'left',
                             lineHeight: 1,
                             marginBottom: 8
                         }}>
                        Comentarios del maestro sobre la actividad:<br/>{titulo}
                    </Row>
                    <div className="uk-width-1-1 centerAll scroll"
                         style={{maxHeight: '75vh'}}>
                        {renderFeedbacks}
                    </div>
                </Row>
            </Modal>
        </Row>
    }

    const renderMedias = () => {
        return <Row className='uk-width-1-1'
                    style={{
                        borderWidth: 0,
                        margin: 0,
                        padding: 0
                    }}>
            {videos.length !== 0
                ? <Row
                    className='uk-width-1-5 centerAll'
                    style={{
                        borderWidth: 0,
                        margin: 0,
                        padding: 5
                    }}
                    onClick={() => setOpenVideo(true)}>
                    <MultimediaButton
                        icon={<i className='fas fa-video'/>}
                        title={videos.length > 1 ? 'Videos' : 'Video'}/>
                </Row>
                : null}

            {imagenes.length !== 0
                ? <Row
                    className='uk-width-1-5 centerAll'
                    style={{
                        borderWidth: 0,
                        margin: 0,
                        padding: 5
                    }}
                    onClick={() => setOpenImage(true)}>
                    <MultimediaButton
                        icon={<i className='far fa-image'/>}
                        title={imagenes.length > 1 ? 'Imágenes' : 'Imagen'}/>
                </Row>
                : null}

            {documentos.length !== 0
                ? <Row
                    className='uk-width-1-5 centerAll'
                    style={{
                        borderWidth: 0,
                        margin: 0,
                        padding: 5
                    }}
                    onClick={() => {
                        setOpenPdf(true);
                        setLoadFile(true);
                    }}>
                    <MultimediaButton
                        icon={<i className='far fa-file-pdf'/>}
                        title={documentos.length > 1 ? 'Archivos' : 'Archivo'}/>
                </Row>
                : null}

            {audios.length !== 0
                ? <Row
                    className='uk-width-1-5 centerAll'
                    style={{
                        borderWidth: 0,
                        margin: 0,
                        padding: 5
                    }}
                    onClick={() => setOpenAudio(true)}>
                    <MultimediaButton
                        icon={<i className='fas fa-headphones'/>}
                        title={audios.length > 1 ? 'Audios' : 'Audio'}/>
                </Row>
                : null}

            {links.length !== 0
                ? <Row
                    className='uk-width-1-5 centerAll'
                    style={{
                        borderWidth: 0,
                        margin: 0,
                        padding: 5
                    }}
                    onClick={() => {
                        if (links.length > 1) {
                            setOpenLinks(true);
                        } else {
                            window.open(links[0].file, '_blank')
                        }
                    }}>
                    <MultimediaButton
                        icon={<i className='fas fa-link'/>}
                        title={links.length > 1 ? 'Enlaces' : 'Enlace'}/>
                </Row>
                : null}
        </Row>
    };

    const renderImages = imagenes.map((it, i) => {
        if (imagenIndex === i) {
            return <Row key={i + 'images'}
                        className="uk-width-1-1 centerAll"
                        style={{
                            margin: 0,
                            padding: 0,
                            position: 'relative',
                        }}>
                {imagenes.length > 0 && i > 0
                    ? <div
                        style={{
                            backgroundColor: grayColorOne,
                            left: 0,
                            padding: '2px 0',
                            position: 'absolute',
                            zIndex: 1,
                        }}
                        onClick={() => setImagenIndex(i - 1)}>
                        <i style={{
                            height: 28,
                            width: 28,
                        }} className="fas fa-chevron-left"/>
                    </div>
                    : null}
                {imagenes.length > 0 && (i + 1) < imagenes.length
                    ? <div className='centerAll'
                           style={{
                               backgroundColor: grayColorOne,
                               padding: '2px 0',
                               position: 'absolute',
                               right: 0,
                               zIndex: 1,
                           }}
                           onClick={() => setImagenIndex(i + 1)}>
                        <i style={{
                            height: 28,
                            width: 28,
                        }} className="fas fa-chevron-right"/>
                    </div>
                    : null}
                {imagenes[imagenIndex] !== undefined
                    ? <img
                        alt=''
                        className='lazyload uk-width-1-1'
                        data-src={it.file}
                        style={{
                            maxHeight: '70vh',
                            objectFit: 'contain'
                        }}
                    />
                    : null}
            </Row>
        } else {
            return null;
        }
    });


    const renderImages2 = imagenes.map((it, i) => {
        if (imagenIndex === i) {
            return <Row key={i + 'images'}
                        className="uk-width-1-1 centerAll"
                        style={{
                            margin: 0,
                            padding: 0,
                            position: 'relative',
                        }}>
                {imagenes.length > 0 && i > 0
                    ? <div
                        style={{
                            backgroundColor: grayColorOne,
                            left: 0,
                            padding: '2px 0',
                            position: 'absolute',
                            zIndex: 1,
                        }}
                        onClick={() => setImagenIndex(i - 1)}>
                        <i style={{
                            height: 28,
                            width: 28,
                        }} className="fas fa-chevron-left"/>
                    </div>
                    : null}
                {imagenes.length > 0 && (i + 1) < imagenes.length
                    ? <div className='centerAll'
                           style={{
                               backgroundColor: grayColorOne,
                               padding: '2px 0',
                               position: 'absolute',
                               right: 0,
                               zIndex: 1,
                           }}
                           onClick={() => setImagenIndex(i + 1)}>
                        <i style={{
                            height: 28,
                            width: 28,
                        }} className="fas fa-chevron-right"/>
                    </div>
                    : null}
                {imagenes[imagenIndex] !== undefined
                    ?
                    <ImageZoom
                        download={true}
                        src={it.file}
                    />
                    : null}
            </Row>
        } else {
            return null;
        }
    });


    const renderDocumentos = documentos.map((it, i) => {
        let urlDocument  = 'https://docs.google.com/viewer?embedded=true&url=' + it.file;
        if (it.file_type !== undefined && it.file_type !== 'pdf'){
            urlDocument = 'https://view.officeapps.live.com/op/embed.aspx?src=' + it.file;
        }
        if (documentosIndex === i) {
            return <Row key={i + 'Documents'}
                        className="uk-width-1-1 centerAll"
                        style={{
                            margin: 0,
                            padding: 0,
                            position: 'relative',
                        }}>
                {i > 0
                    ? <div
                        style={{
                            backgroundColor: grayColorOne,
                            left: 0,
                            padding: '2px 0',
                            position: 'absolute',
                            zIndex: 1,
                        }}
                        onClick={() => {
                            setDocumentosIndex(i - 1);
                            setLoadFile(true);
                        }}>
                        <i style={{height: 28, width: 28}} className="fas fa-chevron-left"/>
                    </div>
                    : null}
                {(i + 1) < documentos.length
                    ? <div className='centerAll'
                           style={{
                               backgroundColor: grayColorOne,
                               padding: '2px 0',
                               position: 'absolute',
                               right: 0,
                               zIndex: 1,
                           }}
                           onClick={() => {
                               setDocumentosIndex(i + 1);
                               setLoadFile(true);
                           }}>
                        <i style={{height: 28, width: 28}} className="fas fa-chevron-right"/>
                    </div>
                    : null}
                <iframe
                    title={it.name}
                    loading="lazy"
                    onLoad={(data)=>{
                        setLoadFile(false)
                    }}
                    style={{
                        height: '70vh'
                    }}
                    className='uk-width-1-1 uk-height-1-1'
                    src={urlDocument}
                    />
            </Row>
        } else {
            return null;
        }
    })
    const renderVideos = videos.map((it, i) => {
        if (videoIndex === i) {
            return <Row key={'videos' + i} className="uk-width-1-1 centerAll"
                        style={{
                            margin: 0,
                            padding: 0,
                            position: 'relative',
                        }}>
                {i > 0
                    ? <div
                        style={{
                            backgroundColor: grayColorOne,
                            left: 0,
                            padding: '2px 0',
                            position: 'absolute',
                            zIndex: 1,
                        }}
                        onClick={() => setVideoIndex(i - 1)}>
                        <i style={{
                            height: 28,
                            width: 28,
                        }} className="fas fa-chevron-left"/>
                    </div>
                    : null}
                {(i + 1) < videos.length
                    ? <div className='centerAll'
                           style={{
                               backgroundColor: grayColorOne,
                               padding: '2px 0',
                               position: 'absolute',
                               right: 0,
                               zIndex: 1,
                           }}
                           onClick={() => setVideoIndex(i + 1)}>
                        <i style={{
                            height: 28,
                            width: 28,
                        }} className="fas fa-chevron-right"/>
                    </div>
                    : null}

                <ReactPlayer
                    height={250}
                    style={{backgroundColor: fourthColor}}
                    controls
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload'
                            }
                        }
                    }}
                    url={it.file}/>
            </Row>
        } else {
            return null;
        }
    })
    const renderAudios = audios.map((it, i) => {
        if (audiosIndex === i) {
            return <Row key={'audios' + i} className="uk-width-1-1 centerAll"
                        style={{
                            margin: 0,
                            padding: 0,
                            position: 'relative',
                        }}>
                {i > 0
                    ? <div
                        style={{
                            backgroundColor: grayColorOne,
                            left: 0,
                            padding: '2px 0',
                            position: 'absolute',
                            zIndex: 1,
                        }}
                        onClick={() => setAudiosIndex(i - 1)}>
                        <i style={{
                            height: 28,
                            width: 28,
                        }} className="fas fa-chevron-left"/>
                    </div>
                    : null}
                {(i + 1) < audios.length
                    ? <div className='centerAll'
                           style={{
                               backgroundColor: grayColorOne,
                               padding: '2px 0',
                               position: 'absolute',
                               right: 0,
                               zIndex: 1,
                           }}
                           onClick={() => setAudiosIndex(i + 1)}>
                        <i style={{
                            height: 28,
                            width: 28,
                        }} className="fas fa-chevron-right"/>
                    </div>
                    : null}

                <ReactPlayer
                    height={250}
                    style={{backgroundColor: fourthColor}}
                    controls
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload'
                            }
                        }
                    }}
                    url={it.file}/>
            </Row>
        } else {
            return null;
        }
    });

    const renderLinks = links.map((it, i) => {
        return <Row key={'links' + i}
                    data-uk-tooltip={it.file}
                    className="uk-width-1-6 centerAll"
                    style={{
                        margin: 0,
                        padding: 3,
                    }}>
            <Row className='uk-width-1-1 centerAll'
                 style={{
                     borderWidth: 0,
                     margin: 0,
                     padding: 4
                 }}>
                <Row onClick={() => window.open(it.file, '_blank')}
                     className='uk-width-1-1 centerAll'
                     style={{
                         backgroundColor: grayColorOne,
                         borderRadius: 6,
                         borderWidth: 0,
                         margin: 0,
                         padding: 3
                     }}>
                    <Row className="uk-width-1-1 centerAll normal_text"
                         style={{margin: 0, padding: 2}}>
                        <i className='fas fa-link'/>
                    </Row>
                    <Row className="uk-width-1-1 centerAll normal_text"
                    >
                        Enlace {i + 1}
                    </Row>
                </Row>
            </Row>
        </Row>
    });
    let messageProrroga = "title: Aún puedes entregar esta actividad.";
    if (fecha_prorroga !== null) {
        let timeH = moment(fecha_prorroga, 'HH:mm:ss').format('HH:mm').toString();
        let date = moment(fecha_prorroga).format('DD/MM/YYYY').toString();
        messageProrroga = "title: Se te ha dado un tiempo de prórroga. Tienes hasta el " + date + " a las " + timeH + " para entregar la actividad.";
        if (estatus_actividad === '2') {
            messageProrroga = "title: Se te ha dado un tiempo de límite para mejorar la calificación de la actividad. Tienes hasta el " + date + " a las " + timeH + " para volver a enviarla.";
        }
    }

    return (
        <Row className="uk-width-1-1 centerAll"
             style={{borderRadius: 7}}>
            <Row className="uk-width-1-1 centerAll"
                 style={{margin: 0, padding: 4}}>
                <Row className="uk-width-1-1 centerAll"
                     style={{borderRadius: 7, overflow: 'hidden'}}>
                    <Row className="uk-width-1-1 centerAll"
                         style={{
                             margin: 0,
                             padding: 5,
                             backgroundColor: fourthColor,
                             borderBottomWidth: .5,
                             borderColor: grayColorOne,
                             borderRadius: 7,
                             borderBottomRightRadius: 0,
                             borderBottomLeftRadius: 0
                         }}>
                        <Row className="uk-width-1-8 centerAll"
                             style={{margin: 0, padding: 5, width: '15%'}}>
                            <img style={{
                                flex: 1,
                                resizeMode: 'contain',
                                width: 100,
                            }} alt='' className='lazyload' data-src={logo}/>
                        </Row>
                        <Row className="uk-width-3-4 centerAll"
                             style={{margin: 0, padding: 10, width: '65%'}}>
                            <Row className="uk-width-1-1 normal_text"
                                 style={{color: grayColorFive}}>
                                {is_aviso ? <label className='uk-text-truncate'>
                                    NUEVO AVISO PUBLICADO DE
                                </label> : <label className='uk-text-truncate'>
                                    NUEVA ACTIVIDAD PUBLICADA DE
                                </label>}
                            </Row>
                            <Row className="uk-width-1-1 sub_title"
                                 style={{lineHeight: 1}}>
                                <label className='uk-text-truncate'>
                                    {schoolData.nombre_escuela.toUpperCase()}
                                </label>
                            </Row>
                            <Row className="uk-width-1-1 normal_text"
                                 style={{color: grayColorFive}}>
                                <label className='uk-text-truncate'>
                                    NIVEL {schoolData.conjunto_uno.toUpperCase()}
                                </label>
                            </Row>
                        </Row>
                        {role === 'Maestro' ?
                            <Row className="uk-width-1-8 centerAll"
                                 style={{width: '20%'}}>
                                {!editable ?
                                    <div
                                        data-uk-tooltip="Actividad con alumnos evaluados"
                                        onClick={() => alertWarning('Ya tiene alumnos evaluados con esta actividad por lo tanto no podrá ser eliminada o editada')}
                                    >
                                        <i className="fas fa-exclamation-triangle"
                                           style={{fontSize: '1.2rem', color: 'orange'}}/>
                                    </div> : null}
                                {editable ?
                                    <div className="uk-width-1-2">
                                        <div
                                            data-uk-tooltip="Editar actividad"
                                            onClick={() => updated()}
                                        >
                                            <i className="fas fa-edit" style={{fontSize: '1rem', color: 'gray'}}/>
                                        </div>
                                    </div> : null}
                                {editable ?
                                    <div className="uk-width-1-2">
                                        <div
                                            data-uk-tooltip="Borrar actividad"
                                            onClick={() => deleted()}
                                        >
                                            <i className="fas fa-trash-alt"
                                               style={{fontSize: '1rem', color: redColor}}/>
                                        </div>
                                    </div> : null}
                            </Row>
                            : null}
                        {!is_aviso ? <Row className="uk-width-1-8 centerAll"
                                          style={{width: '20%'}}>

                                {role === 'Admin' ? <Row className="uk-width-1-1 centerAll"
                                /> : null}
                                {role === 'Padre' || role === 'Alumno' ?
                                    id_criterio === 'ninguno' ?
                                        <Row className="uk-width-1-1 centerAll">
                                            <div
                                                data-uk-tooltip="Actividad sin criterio de evaluación asignado. Esta actividad no se evalúa">
                                                <i className="fas fa-exclamation"
                                                   style={{fontSize: '1.6rem', color: '#f19c02'}}/>
                                            </div>
                                        </Row>
                                        : <Row className="uk-width-1-1 centerAll">
                                            {files !== 0
                                                ? <Row className="uk-width-1-3 centerAll">
                                                    <Row className="uk-width-1-1">
                                                        <div data-uk-tooltip="Respuesta enviada por el alumno"
                                                             onClick={() => openFiles()}>
                                                            <i className="fas fa-eye"
                                                               style={{fontSize: '1.3rem', color: 'gray'}}/>
                                                        </div>
                                                    </Row>
                                                </Row>
                                                : null}
                                            {retroalimentacion.length ?
                                                <Row className="uk-width-1-3">
                                                    <div data-uk-tooltip="Comentarios del maestro"
                                                         onClick={() => setModalFeedBack(true)}>
                                                        <i className="fas fa-comment-alt"
                                                           style={{fontSize: '1.3rem', color: 'gray'}}/>
                                                    </div>
                                                </Row> : null}
                                            {tipo_entrega === 'en linea'
                                                ? published === 1 || published === 0
                                                    ? <Row className="uk-width-1-3 centerAll">
                                                        <div
                                                            data-uk-tooltip="Actividad pendiente de subir"
                                                            onClick={() => updated()}
                                                        >
                                                            <i className="fas fa-flag"
                                                               style={{fontSize: '1.3rem', color: orangeColor}}/>
                                                        </div>
                                                    </Row>
                                                    : <Row className="uk-width-1-3 centerAll">
                                                        {!enviado && editable ? <div
                                                            data-uk-tooltip="Responder actividad"
                                                            onClick={() => updated()}
                                                        >
                                                            <i className="fas fa-upload"
                                                               style={{fontSize: '1.3rem', color: thirdColor}}/>
                                                        </div> : null}
                                                        {enviado && editable ? <div className="uk-width-1-1 centerAll"
                                                        >
                                                            {estatus_actividad === '1'
                                                                ? <div
                                                                    data-uk-tooltip="Actividad enviada, en espera de revisión por parte del docente"
                                                                    onClick={() => {
                                                                        updated();
                                                                    }}
                                                                >
                                                                    <i className="fas fa-clock"
                                                                       style={{fontSize: '1.3rem', color: 'gray'}}/>
                                                                </div> : null}
                                                            {estatus_actividad === '0'
                                                                ? <div
                                                                    data-uk-tooltip="Actividad con correcciones pendientes"
                                                                    onClick={() => updated()}
                                                                >
                                                                    <i className="fas fa-exclamation-triangle alertPulse-css-1"
                                                                       style={{fontSize: '1.3rem', color: 'red'}}/>
                                                                </div> : null}
                                                        </div> : null}

                                                        {!editable && estatus_actividad === '2'
                                                            ? <div
                                                                data-uk-tooltip={messageProrroga}
                                                                // data-uk-tooltip="Mejorar respuesta de actividad"
                                                                onClick={() => updated()}>
                                                                <i className="fas fa-redo-alt"
                                                                   style={{fontSize: '1.3rem', color: '#c9d700'}}/>
                                                            </div>
                                                            : null}
                                                        {!editable && estatus_actividad === '1'
                                                            ? <div data-uk-tooltip="Actividad evaluada">
                                                                <i className="fas fa-check-square"
                                                                   style={{fontSize: '1.6rem', color: '#1c7300'}}/>
                                                            </div>
                                                            : null}
                                                        {estatus_actividad === '3'
                                                            ? <div data-uk-tooltip="Actividad no entregada">
                                                                <i className="fas fa-times-circle"
                                                                   style={{fontSize: '1.3rem', color: 'red'}}/>
                                                            </div>
                                                            : null}
                                                        {estatus_actividad === '4'
                                                            ? <div
                                                                data-uk-tooltip={messageProrroga}
                                                                onClick={() => updated()}>
                                                                <i className="fas fa-stopwatch"
                                                                   style={{fontSize: '1.4rem', color: fifthColor}}/>
                                                            </div>
                                                            : null}
                                                    </Row>
                                                : <Row className="uk-width-1-1 centerAll">
                                                    <div data-uk-tooltip="Esta actividad se entregará de manera presencial">
                                                        <i className="fas fa-handshake"
                                                           style={{fontSize: '1.3rem', color: 'gray'}}/>
                                                    </div>
                                                </Row>}
                                        </Row> : null}
                            </Row>
                            : <Row className="uk-width-1-8 centerAll"
                            />}
                    </Row>

                    {videos.length !== 0 || imagenes.length !== 0
                        ?
                        videos.length !== 0 ?
                            renderVideos
                            : imagenes.length !== 0
                                ? renderImages
                                : null
                        : null}

                    {videos.length !== 0 || audios.length !== 0 || imagenes.length !== 0 || documentos.length !== 0 || links.length !== 0
                        ? <Row className='uk-width-1-1' style={{
                            backgroundColor: fourthColor,
                            borderWidth: 0,
                            padding: 5
                        }}>
                            {renderMedias()}
                        </Row>
                        : null}

                    <Row className="uk-width-1-1 centerAll"
                    >

                        <Row className="uk-width-1-1 centerAll"
                             style={{
                                 backgroundColor: fourthColor,
                                 borderRadius: 0,
                                 margin: 0,
                                 padding: 10,
                                 paddingBottom: 15
                             }}>
                            <Row className="uk-width-1-1  main_title truncate"
                                 style={{color: mainColor}}>
                                {titulo}
                            </Row>
                            {is_aviso ? <Row className="uk-width-1-1  normal_text"
                                             style={{textTransform: 'uppercase'}}>
                                    <b>Maestro:</b> {autor}
                                </Row>
                                : <Row className="uk-width-1-1  normal_text"
                                       style={{textTransform: 'uppercase'}}>
                                    <b>Materia:</b> {autor}
                                </Row>}
                            {!is_aviso ? <Row className="uk-width-1-1  normal_text"
                            >
                                <label className="uk-width-1-1 uk-text-truncate"
                                       style={{textAlign: 'left', textTransform: 'uppercase'}}>
                                    <b>Tema:</b> {tema}
                                </label>
                            </Row> : null}
                            <Row className="uk-width-1-1 normal_text"
                                 style={{color: grayColorFive}}>
                                PUBLICADO EL {moment(fecha).format('D MMMM YYYY').toUpperCase()}
                            </Row>
                            <Row className="uk-width-1-1 normal_text"
                                 style={{marginTop: 10, textAlign: 'left'}}>
                                <Row className="uk-width-1-1 txtnegritasMiniBlack"
                                     style={{marginBottom: 3, textAlign: 'left'}}>
                                    Descripción
                                </Row>
                                <div style={{
                                    textAlign: 'justify',
                                    overflow: 'auto',
                                    whiteSpace: 'pre-line',
                                    textTransform: 'uppercase'
                                }}>
                                    {resumen}
                                </div>
                            </Row>
                            {ponderacion !== null
                                ?  ponderacion.tipo_valor !== 'ninguno' ? <Row className="uk-width-1-1 normal_text"
                                                                          style={{
                                                                              margin: 0,
                                                                              padding: 0,
                                                                              marginTop: 10,
                                                                              textAlign: 'left'
                                                                          }}>
                                    <Row className="uk-width-1-1 txtnegritasMiniBlack"
                                         style={{marginBottom: 3, textAlign: 'left'}}>
                                        Calificación:
                                    </Row>
                                    <div style={{textAlign: 'justify', overflow: 'auto', whiteSpace: 'pre-line'}}>
                                        Valor {ponderacion.calificacion}{ponderacion.tipo_valor === 'ponderacion' && ponderacion.valor !== 'N'&& ponderacion.ponderacion !== null ? ' ,'+ ponderacion.ponderacion.descripcion :null}
                                    </div>
                                </Row> : null : null}
                            {requeridos !== null && requeridos !== "" ?
                                <Row className="uk-width-1-1 normal_text"
                                     style={{marginTop: 10, textAlign: 'left'}}>
                                    <Row className="uk-width-1-1 txtnegritasMiniBlack"
                                         style={{marginBottom: 3, textAlign: 'left'}}>
                                        Materiales o equipo
                                    </Row>
                                    {requeridos}
                                </Row> : null}
                            {otros_requeridos !== null && otros_requeridos !== "" ?
                                <Row className="uk-width-1-1 normal_text"
                                     style={{marginTop: 10, textAlign: 'left'}}>
                                    <Row className="uk-width-1-1 txtnegritasMiniBlack"
                                         style={{marginBottom: 3, textAlign: 'left'}}>
                                        Otros requeridos
                                    </Row>
                                    {otros_requeridos}
                                </Row> : null}
                            {!is_aviso ? role === 'Admin' || role === 'Maestro'
                                ? <Row className='uk-width-1-1 normal_text'
                                       style={{
                                           margin: 0,
                                           padding: 0,
                                           marginTop: 10
                                       }}>
                                    <label className="txtnegritasMiniBlack" style={{
                                        backgroundColor: fifthColor,
                                        textTransform: 'uppercase',
                                        color: fourthColor,
                                        padding: 5,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                    }}>
                                        Enviado a {destinatarios}
                                    </label>
                                </Row>
                                : null : null}
                            {!is_aviso ?
                                <Row style={{marginTop: 5, justifyContent: 'flex-start'}}
                                     className='uk-width-1-1 work'>
                                    <label style={{textAlign: 'left'}}
                                           className='uk-width-1-1 work txtnegritasMiniBlack'>
                                        <label style={{textAlign: 'left'}}
                                               className='txtnegritasMiniBlack'>
                                            <b>FECHA Y HORA DE ENTREGA: </b>
                                            {moment(dueDate + ' ' + dueTime).format('DD/MMMM/YYYY - HH:mm').toUpperCase()}
                                        </label>
                                    </label>
                                    {(role === 'Padre' || role === 'Alumno') && (published === 0 || published === 1)
                                        ? <label
                                            className={'uk-width-1-1 txtnegritasMiniBlack alertPulse-css-1'}
                                            style={{
                                                color: orangeColor,
                                                textAlign: 'left',
                                                textTransform: 'uppercase'
                                            }}>
                                            Actividad pendiente de subir
                                        </label>
                                        : (role === 'Padre' || role === 'Alumno') && !editable && estatus_actividad === '2'
                                            ? <label
                                                className='txtnegritasMiniBlack'
                                                style={{
                                                    color: fourthColor,
                                                    textAlign: 'left',
                                                    backgroundColor: '#c9d700',
                                                    padding: 5
                                                }}>
                                                <b>Esta actividad ya fue evaluada y aun puedes mejorar la respuesta</b>
                                            </label>
                                            : (role === 'Padre' || role === 'Alumno') && !editable && estatus_actividad === '1'
                                                ? <label
                                                    className='txtnegritasMiniBlack'
                                                    style={{
                                                        color: fourthColor,
                                                        textAlign: 'left',
                                                        backgroundColor: '#1c7300',
                                                        padding: 5
                                                    }}
                                                >
                                                    <b>Esta actividad ya ha sido evaluada</b>
                                                </label>
                                                : enviado && estatus_actividad === '1'
                                                    ? <Row style={{justifyContent: 'flex-start'}}
                                                           className='uk-width-1-1 work'>
                                                        <label
                                                            className={'uk-width-1-1 txtnegritasMiniBlack'}
                                                            style={{
                                                                color: '#1c7300',
                                                                textAlign: 'left',
                                                                textTransform: 'uppercase'
                                                            }}>
                                                            {'Actividad enviada el dia ' + moment(fecha_enviado).format('LL')}
                                                        </label>
                                                        {moment(fecha_enviado).format('YYYY-MM-DD') > moment(dueDate).format('YYYY-MM-DD') ?
                                                            <label
                                                                className={'uk-width-1-1 txtnegritasMiniBlack'}
                                                                style={{
                                                                    color: orangeColor,
                                                                    textAlign: 'left',
                                                                    textTransform: 'uppercase'
                                                                }}>
                                                                {'La actividad se entregó ' + moment(fecha_enviado, 'YYYY-MM-DD').diff(moment(dueDate, 'YYYY-MM-DD'), 'days') + ' días después de la fecha de entrega'}
                                                            </label>
                                                            : null}
                                                    </Row>
                                                    : counDay < 0
                                                        ? <label
                                                            className='txtnegritasMiniBlack alertPulse-css-1'
                                                            style={{
                                                                color: fourthColor,
                                                                textAlign: 'left',
                                                                backgroundColor: redColor,
                                                                padding: 5
                                                            }}>
                                                            {counDay < 0 ? 'DEBIÓ ENTREGARSE ' + moment(dueDate + ' ' + dueTime).endOf('hour').fromNow().toUpperCase() : null}
                                                        </label>
                                                        : countHour < 0
                                                            ? <label
                                                                className='txtnegritasMiniBlack alertPulse-css-1'
                                                                style={{
                                                                    color: fourthColor,
                                                                    textAlign: 'left',
                                                                    backgroundColor: redColor,
                                                                    padding: 5
                                                                }}>
                                                                {'DEBIÓ ENTREGARSE ' + moment(dueDate + ' ' + dueTime).endOf('hour').fromNow().toUpperCase()}
                                                            </label>
                                                            : <label
                                                                className={'uk-width-1-1 txtnegritasMiniBlack'}
                                                                style={{color: redColor, textAlign: 'left'}}>
                                                                {counDay > 0 ? countDay() : null}
                                                                {counDay === 0
                                                                    ? 'SE ENTREGA HOY ' + moment(dueDate + ' ' + dueTime).fromNow().toUpperCase()
                                                                    : null}
                                                            </label>}
                                </Row>
                                : null}
                        </Row>
                    </Row>
                </Row>
            </Row>
            {extra()}
        </Row>
    )
}
