import {Row} from "react-material-responsive-grid";
import {alertFail, alertWarning, customAlert, getFirstLetters, info} from "../../Global-W/NewFunctions";
import React, {memo, useContext, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import KolegiaBroadcastContext from "../../../Context/KolegiaBroadcast";
import {
    changeMainStream,
    connectingToClass,
    setMyAudioStatus,
    setMyVideoStatus,
    startTransmission
} from "../../../redux/actions/VirtualClassActions/VirtualClassActions";
import {confirmAlert} from "react-confirm-alert";
import moment from "moment/moment";
import Modal from "../../Global-W/Hooks/Modal";
import {Button} from "../../Global-W/Hooks/Button";

export function VideoBox({item, itsMe = false, str = null}) {
    const [isStartMedia, setIsStartMedia] = useState(false);
    const [camera, setCamera] = useState(false)
    const [mic, setMic] = useState(false)
    const avatar = (item.photo !== null && item.photo !== "") ? {
        backgroundColor: item.back_color,
        overflow: 'hidden',
        alignItems: 'flex-end',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: 'url(' + item.photo + ')',
        height: '100%',
        position: 'relative'
    } : {
        backgroundColor: item.back_color,
        overflow: 'hidden',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        height: '100%',
        position: 'relative'
    }

    const {
        userId,
        fourthColor,
        userRole,
        userName,
        bd,
        grayColorOne,
        grayColorTwo
    } = useSelector(state => state.rooot);

    const {
        start_transmission,
        my_audio_status,
        my_video_status
    } = useSelector(state => state.virtual_class);
    const dispatch = useDispatch();
    const [audioDevice, setAudioDevice] = useState('none')
    const [videoDevice, setVideoDevice] = useState('none')
    const {
        sendRequest,
        handleConnect,
        socketRef,
        handleDisconnectConsumer,
        sendNewMessage
    } = useContext(KolegiaBroadcastContext)
    const [modal, setModal] = useState(false)
    const stream = useRef(null);
    const streamAux = useRef(null);
    const streamAuxVideo = useRef(null);
    const localVideo = useRef();
    const localStream = useRef();
    const localStreamAudio = useRef();
    const localStreamAudioAux = useRef();
    const localStreamVideoAux = useRef();
    const localStreamVideo = useRef();
    const itsSpotLight = Number(item.spotLight) === 1;
    const [devices, setDevices] = useState([])
    const bodyMessage = {
        name: userName,
        user_id: userId,
        bd_school: bd,
        type_message: 2,
        date: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    const videoConstraints = {
        video: {
            width: {min: 240, ideal: 320, max: 360},
            height: {min: 180, ideal: 240, max: 240},
            aspectRatio: {ideal: 1.7777777778},
            frameRate: {
                min: 10,
                ideal: 15,
                max: 20,
            },
            deviceId: videoDevice
        }
    };
    const audioConstraints = {
        audio: {
            echoCancellationType: 'system',
            sampleSize: 16,
            channelCount: 2,
            volume: 1.5,
            googAutoGainControl: true,
            googNoiseSuppression: true,
            deviceId: audioDevice
        }
    }
    const isMine = itsMe ? stream.current : item.has_video
    const cursor = itsMe || userRole === "Maestro" ? 'pointer' : null
    const pointerEvents = !itsMe ? userRole === "Maestro" ? null : 'none' : null

    useEffect(() => {
        if (socketRef.current !== undefined) {
            socketRef.current.on("close class", async () => {
                if (userRole === 'Alumno') {
                    handleStopMedia()
                }
            })
        }
    }, [socketRef])

    useEffect(() => {
        return () => {
            handleStopMedia()
        }
    }, [])

    useEffect(() => {
        if (itsMe) {
            if (localStream.current !== undefined && stream.current !== null) {
                if (stream.current.srcObject === null) {
                    stream.current.srcObject = localStream.current;
                }
            }
        } else {
            if (item.video_box_stream !== null) {
                stream.current.srcObject = item.video_box_stream;
            }
        }
    }, [item, str, localStream.current])

    // useEffect(() => {
    //     if (localStreamAudioAux.current !== undefined && streamAux.current !== null) {
    //         if (streamAux.current.srcObject === null) {
    //             streamAux.current.srcObject = localStreamAudioAux.current;
    //         }
    //     }
    // },[localStreamAudioAux, streamAux])

    useEffect(() => {
        if (start_transmission && itsMe) {
            dispatch(connectingToClass(true));
            setModal(true)
            // handleStartMedia()
        }
    }, [start_transmission]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(async () => {
        try {
            await setDevices(await navigator.mediaDevices.enumerateDevices())
        } catch (e) {
            console.error("error while getting devices => ",e)
        }
    }, [])


    const testMicDevice = async () => {
        await navigator.mediaDevices
            .getUserMedia(audioConstraints)
            .then(async (streamObj) => {
                localStreamAudioAux.current = streamObj;
                playAudio(streamAux.current, localStreamAudioAux.current);
            }).catch(async (e) => {
                console.error('error => ',e)
            })
    }
    const testCamDevice = async () => {
        await navigator.mediaDevices
            .getUserMedia(videoConstraints)
            .then(async (streamObj) => {
                localStreamVideoAux.current = streamObj;
                playVideo(streamAuxVideo.current, localStreamVideoAux.current);
            }).catch(async (e) => {
                console.error('error => ',e)
            })
    }
    const handleContinue = () => {
        if (audioDevice === 'none' && videoDevice === 'none') {
            alertWarning('Por favor seleccione al menos un dispositivo de audio o de video para continuar')
            return
        }
        if (localStreamAudioAux.current) {
            stopLocalStream(localStreamAudioAux.current)
        }
        if (localStreamVideoAux.current) {
            stopLocalStream(localStreamVideoAux.current)
        }
        streamAux.current.srcObject = undefined
        streamAuxVideo.current.srcObject = undefined
        setModal(false)
        handleStartMedia()

    }

    const renderDevices = () => {
        return (
            <Row className={'uk-width-1-1'} style={{padding: 5, height: '100%'}}>
                <Row className={'uk-width-1-1'} style={{marginTop: 10, padding: 5, height: '100%'}}>
                    <Row className={'uk-width-1-1 normal_text'}> Dispositivos de entrada de voz </Row>
                    <Row className={'uk-width-1-1'}>
                        <select style={{borderRadius: 5, backgroundColor: grayColorTwo}} value={audioDevice}
                                onChange={(value) => {
                                    handleChange(value);
                                }}>
                            <option value={'none'}> {'Selecciona un micrófono'} </option>
                            {devices.filter(it => it.kind === 'audioinput' && it.label !== 'Default').map((item, i) => {
                                return (
                                    <option value={item.deviceId}> {item.label} </option>
                                )
                            })}
                        </select>
                    </Row>
                    <Row className={'uk-width-1-1 centerAll'}>
                        <video id={'my_video'} src={streamAux} ref={streamAux} playsInline autoPlay muted={false}
                               style={{height: '1%', width: '1%'}}/>
                    </Row>
                    <Row className={'uk-width-1-3'}
                         style={{marginTop: 10, visibility: audioDevice === 'none' ? 'hidden' : 'visible'}}>
                        <Button icon={"fas fa-microphone"} className={'uk-width-1-1 uk-height-1-1'} title={'Probar'}
                                action={() => {
                                    testMicDevice()
                                }}/>
                    </Row>

                </Row>
                <Row className={'uk-width-1-1'} style={{marginTop: 10, padding: 5, height: '100%'}}>
                    <Row className={'uk-width-1-1 normal_text'}> Dispositivos de entrada de video </Row>
                    <Row className={'uk-width-1-1'}>
                        <select style={{borderRadius: 5, backgroundColor: grayColorTwo}} value={videoDevice}
                                onChange={(value) => {
                                    handleChangeCam(value)
                                }}>
                            <option value={'none'}> {'Selecciona una cámara'} </option>
                            {devices.filter(it => it.kind === 'videoinput' && it.label !== 'Default').map((item, i) => {
                                return (
                                    <option value={item.id}> {item.label} </option>
                                )
                            })}
                        </select>
                    </Row>
                    <Row className={'uk-width-1-3'} style={{
                        justifyContent: 'center',
                        marginTop: 10,
                        visibility: videoDevice === 'none' ? 'hidden' : 'visible'
                    }}>
                        <Button icon={"fas fa-camera"} title={'Probar'} action={() => {
                            testCamDevice()
                        }}/>
                    </Row>
                    <Row className={'uk-width-1-1 centerAll uk-height-1-2'} style={{padding: 2}}>
                        <video id={'my_video'} src={streamAuxVideo} ref={streamAuxVideo} autoPlay muted={true}
                               style={{height: '100%', width: '45%'}}/>
                    </Row>
                </Row>
            </Row>
        )
    }

    const handleChangeCam = (event) => {
        setVideoDevice(event.target.value)
        if (localStreamVideoAux.current) {
            stopLocalStream(localStreamVideoAux.current)
        }

        streamAuxVideo.current.srcObject = undefined
    }
    const handleChange = (event) => {
        setAudioDevice(event.target.value)
        if (localStreamAudioAux.current) {
            stopLocalStream(localStreamAudioAux.current)
        }

        streamAux.current.srcObject = undefined
    }

    const modalDevices = () => {
        return (
            <Modal open={modal} onClose={() => {
                setModal(false)
            }} size={'x_small-modal'} showCloseIcon={false}>
                <Row className={'uk-width-1-1 centerAll'} style={{padding: 5, backgroundColor: grayColorOne}}>
                    <Row className={'uk-width-1-1 normal_text'} style={{fontSize: '.8rem'}}> Por favor seleccione los
                        dispositivos de micrófono y cámara con los que desea ingresar al aula virtual</Row>
                    <Row className={'uk-width-1-1'} style={{justifyContent: 'center'}}>
                        {renderDevices()}
                    </Row>
                    <Row className={'uk-width-1-5'} style={{marginTop: 10,}}>
                        <Button icon={"fas fa-arrow-circle-right"} title={'Continuar'} action={() => {
                            handleContinue()
                        }}/>
                    </Row>
                </Row>
            </Modal>
        )
    }

    function playAudio(element, mediaStream) {
        if (element.srcObject) {
            console.warn('element ALREADY playing, so ignore');
            return;
        }
        element.srcObject = mediaStream;
        element.volume = 1;
        return element.play();
    }

    //+-+-+-+-+ Media Controls
    const handleStartMedia = async () => {
        setIsStartMedia(true);
        if (localStreamVideo.current) {
            console.warn('WARN: local media ALREADY started');
        }
        if (localStreamAudio.current) {
            console.warn('WARN: local media ALREADY started');
        }
        info('-entra media-')
        await navigator.mediaDevices
            .getUserMedia(videoConstraints)
            .then(async (streamObj) => {
                await setCamera(true)
                localStreamVideo.current = streamObj;
                playVideo(stream.current, localStreamVideo.current);
                await navigator.mediaDevices
                    .getUserMedia(audioConstraints)
                    .then(async (streamObj) => {
                        if (streamObj.getAudioTracks().length > 0) {
                            await setMic(true)
                            localStreamAudio.current = streamObj;
                        } else {
                            await setMic(false)
                            dispatch(setMyAudioStatus(null))
                        }
                    }).catch(async (err) => {
                        await dispatch(setMyAudioStatus(null))
                        console.error('error => ', err)
                        switch (err.toString()) {
                            case "NotAllowedError: Permission denied":
                                setIsStartMedia(false);
                                dispatch(startTransmission(false));
                                dispatch(connectingToClass(false));
                                dispatch(changeMainStream(null))
                                handleDisconnectConsumer()
                                let viewMessagePermissions = <Row className="uk-width-1-1">
                                    Por favor revise que los permisos para acceder a la cámara y el micrófono estén
                                    habilitados <br/>
                                    <b>Ejemplo:</b>
                                    <img alt=""
                                         style={{marginTop: 5}}
                                         src="https://otrasimagenes.s3.amazonaws.com/muestra/Screenshot+from+2021-09-21+13-40-51.png"/>
                                </Row>
                                confirmAlert({
                                    customUI: ({onClose}) => {
                                        return customAlert('Permisos denegados',
                                            viewMessagePermissions, 1,
                                            'Enterado', null, null, () => [onClose()],);
                                    }
                                });
                                return;
                            case "NotFoundError: Requested device not found":
                                alertWarning("No se han encontrado dispositivos de audio, ingresarás sin micrófono a la clase");
                                return;
                            case "NotReadableError: Could not start video source":
                                setIsStartMedia(false);
                                dispatch(startTransmission(false));
                                dispatch(connectingToClass(false));
                                dispatch(changeMainStream(null))
                                handleDisconnectConsumer()
                                let viewMessageOther = <Row className="uk-width-1-1">
                                    La cámara y el micrófono están siendo utilizados en otra aplicación, por favor
                                    desactive los
                                    dispositivos de audio y video en la otra aplicación para poder utilizarlos en
                                    Kolegia
                                </Row>
                                confirmAlert({
                                    customUI: ({onClose}) => {
                                        return customAlert('Dispositivos en uso',
                                            viewMessageOther, 1,
                                            'Enterado', null, null, () => [onClose()], null);
                                    }
                                });
                                return;
                            default:
                                alertFail('fail');
                                return;
                        }
                    });
            }).catch(async (err) => {
                await dispatch(setMyVideoStatus(null))
                setCamera(false)
                await navigator.mediaDevices
                    .getUserMedia(audioConstraints)
                    .then(async (streamObj) => {
                        if (streamObj.getAudioTracks().length > 0) {
                            await setMic(true)
                            localStreamAudio.current = streamObj;
                        } else {
                            await setMic(false)
                            await dispatch(setMyAudioStatus(null))
                            alertWarning("No se han encontrado dispositivos de audio y video, ingresarás a la clase sin audio ni video");
                        }
                    }).catch(async (err) => {
                        setMic(false)
                        await dispatch(setMyAudioStatus(null))
                        console.error('error => ', err)
                        switch (err.toString()) {
                            case "NotAllowedError: Permission denied":
                                setIsStartMedia(false);
                                dispatch(startTransmission(false));
                                dispatch(connectingToClass(false));
                                dispatch(changeMainStream(null))
                                handleDisconnectConsumer()
                                let viewMessagePermissions = <Row className="uk-width-1-1">
                                    Por favor revise que los permisos para acceder a la cámara y el micrófono estén
                                    habilitados <br/>
                                    <b>Ejemplo:</b>
                                    <img alt=""
                                         style={{marginTop: 5}}
                                         src="https://otrasimagenes.s3.amazonaws.com/muestra/Screenshot+from+2021-09-21+13-40-51.png"/>
                                </Row>
                                confirmAlert({
                                    customUI: ({onClose}) => {
                                        return customAlert('Permisos denegados',
                                            viewMessagePermissions, 1,
                                            'Enterado', null, null, () => [onClose()],);
                                    }
                                });
                                return;
                            case "NotFoundError: Requested device not found":
                                setIsStartMedia(false);
                                dispatch(startTransmission(false));
                                dispatch(connectingToClass(false));
                                dispatch(changeMainStream(null))
                                handleDisconnectConsumer()
                                alertWarning("No se han encontrado dispositivos de audio y video, ingresarás a la clase sin audio ni video");
                                return;
                            case "NotReadableError: Could not start video source":
                                let viewMessageOther = <Row className="uk-width-1-1">
                                    La cámara y el micrófono están siendo utilizados en otra aplicación, por favor
                                    desactive los
                                    dispositivos de audio y video en la otra aplicación para poder utilizarlos en
                                    Kolegia
                                </Row>
                                confirmAlert({
                                    customUI: ({onClose}) => {
                                        return customAlert('Dispositivos en uso',
                                            viewMessageOther, 1,
                                            'Enterado', null, null, () => [onClose()], null);
                                    }
                                });
                                return;
                            default:
                                alertFail('fail');
                                return;
                        }
                    });
                console.error('error => ', err)
                switch (err.toString()) {
                    case "NotAllowedError: Permission denied":
                        setIsStartMedia(false);
                        dispatch(startTransmission(false));
                        dispatch(connectingToClass(false));
                        dispatch(changeMainStream(null))
                        let viewMessagePermissions = <Row className="uk-width-1-1">
                            Por favor revise que los permisos para acceder a la cámara y el micrófono estén
                            habilitados <br/>
                            <b>Ejemplo:</b>
                            <img alt=""
                                 style={{marginTop: 5}}
                                 src="https://otrasimagenes.s3.amazonaws.com/muestra/Screenshot+from+2021-09-21+13-40-51.png"/>
                        </Row>
                        confirmAlert({
                            customUI: ({onClose}) => {
                                return customAlert('Permisos denegados',
                                    viewMessagePermissions, 1,
                                    'Enterado', null, null, () => [onClose(), handleDisconnectConsumer()],);
                            }
                        });
                        return;
                    case "NotFoundError: Requested device not found":
                        alertWarning("No se han encontrado dispositivos de video, ingresarás a la clase sin cámara");
                        return;
                    case "NotReadableError: Could not start video source":
                        setIsStartMedia(false);
                        dispatch(startTransmission(false));
                        dispatch(connectingToClass(false));
                        dispatch(changeMainStream(null))
                        let viewMessageOther = <Row className="uk-width-1-1">
                            La cámara y el micrófono están siendo utilizados en otra aplicación, por favor desactive los
                            dispositivos de audio y video en la otra aplicación para poder utilizarlos en Kolegia
                        </Row>
                        confirmAlert({
                            customUI: ({onClose}) => {
                                return customAlert('Dispositivos en uso',
                                    viewMessageOther, 1,
                                    'Enterado', null, null, () => [onClose(), handleDisconnectConsumer()], null);
                            }
                        });
                        return;
                    default:
                        alertFail('fail');
                        return;
                }
            });
        if (my_video_status === null && my_audio_status === null) {
            let messageContent = {
                ...bodyMessage,
                room_id: item.room_id,
                body: ' se ha unido a la clase sin cámara ni micrófono',
                id_subject: item.id_materia,
                senderId: socketRef.current.id
            };
            sendNewMessage(messageContent);
        }
        handleConnect(localStreamVideo, localStreamAudio)

    };


    function playVideo(element, mediaStream) {
        if (element.srcObject) {
            console.warn('element ALREADY playing, so ignore');
            return;
        }
        element.srcObject = mediaStream;
        element.volume = 0;
        return element.play();
    }


    function handleStopMedia() {
        // if (localStream.current) {
        //     stopLocalStream(localStream.current);
        //     localStream.current = undefined;
        //     localVideo.current = null;
        //     setIsStartMedia(false);
        // }
        if (modal) {
            setModal(false)
        }
        if (localStreamVideo.current) {
            stopLocalStream(localStreamVideo.current);
            localStreamVideo.current = undefined;
            localVideo.current = null;
            setIsStartMedia(false);
        }
        if (localStreamAudio.current) {
            stopLocalStream(localStreamAudio.current);
            localStreamAudio.current = undefined;
            setIsStartMedia(false);
        }
    }

    function stopLocalStream(mediaStream) {
        let tracks = mediaStream.getTracks();
        if (!tracks) {
            console.warn('NO tracks');
            return;
        }

        tracks.forEach((track) => track.stop());
    }

    const NoVideo = () => {
        return isMine && item.video_active === "0"
            ? <Row className="uk-width-1-1 centerAll" style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(73,73,73,0.99)',
                position: 'absolute'
            }}>
                <div className={"normal_text"} style={{fontSize: '1rem', color: fourthColor}}>
                    Video desactivado
                </div>
            </Row>
            : null
    };

    const NoAudio = () => {
        return itsSpotLight
            ? item.has_video && item.audio_active === "0"
                ? <Row className="uk-width-1-1 centerAll" style={{
                    width: '100%',
                    height: '5%',
                    backgroundColor: 'rgba(73,73,73,0.99)',
                    position: 'absolute',
                    bottom: 0
                }}>
                    <div className={"normal_text"} style={{fontSize: '1rem', color: fourthColor}}>
                        Audio desactivado
                    </div>
                </Row>
                : null
            : null
    };

    const NoPhoto = () => {
        return (isMine && isStartMedia) || item.has_video
            ? null
            : item.photo !== null && item.photo !== ""
                ? null
                : <Row className="uk-width-1-1 centerAll" style={{height: '100%'}}>
                    <label style={{color: item.font_color, fontWeight: 'bold', fontSize: '25px'}}
                           className="uk-width-1-1 uk-text-truncate">{getFirstLetters(item.nombre)}
                    </label>
                </Row>

    };

    const Buttons = () => {
        const AudioIcon = () => {
            if (itsMe) {
                return <img alt=""
                            data-uk-tooltip={my_audio_status === null ? "Micrófono no disponible" : my_audio_status ? "Micrófono activado" : "Micrófono desactivado"}
                            style={{height: 13, width: 13}}
                            src={
                                my_audio_status === null ?
                                    "https://img.icons8.com/material/30/808080/microphone--v1.png"
                                    : my_audio_status ? "https://img.icons8.com/material/30/000000/microphone--v1.png"
                                        : "https://img.icons8.com/material/30/ff0000/no-microphone--v1.png"
                            }/>
            }
            return <img alt=""
                        data-uk-tooltip={item.audio_active === "1" ? "Micrófono activado" : "Micrófono desactivado"}
                        style={{height: 13, width: 13}}
                        src={
                            item.audio_active === "1"
                                ? "https://img.icons8.com/material/30/000000/microphone--v1.png"
                                : "https://img.icons8.com/material/30/ff0000/no-microphone--v1.png"
                        }/>
        };

        const VideoIcon = () => {
            if (itsMe) {
                return <img alt=""
                            data-uk-tooltip={my_video_status === null ? "Video no disponible" : my_video_status ? "Video activado" : "Video desactivado"}
                            style={{height: 13, width: 13}}
                            src={
                                my_video_status === null ?
                                    "https://img.icons8.com/material/30/808080/video-call--v1.png"
                                    : my_video_status ? "https://img.icons8.com/material/30/000000/video-call--v1.png"
                                        : "https://img.icons8.com/material/30/ff0000/no-video--v1.png"
                            }/>
            }
            return <img alt=""
                        data-uk-tooltip={item.video_active === "1" ? "Video activado" : "Video desactivado"}
                        style={{height: 13, width: 13}}
                        src={
                            item.video_active === "1"
                                ? "https://img.icons8.com/material/30/000000/video-call--v1.png"
                                : "https://img.icons8.com/material/30/ff0000/no-video--v1.png"
                        }/>
        };

        return <Row className="uk-width-1-1 centerAll" style={{
            height: '100%',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
            position: 'absolute',
            bottom: 0,
        }}>
            <Row className='uk-width-3-5' style={{
                backgroundColor: 'rgba(255, 255, 255, 0.81)',
                backdropFilter: "blur(10px)",
                borderTopRightRadius: 6,
                alignItems: 'center',
                padding: "3px 5px"
            }}
                 data-uk-tooltip={item.name}>
                <Row className='centerAll uk-width-1-6' style={{
                    backgroundColor: item.online ? 'rgba(0, 160, 0, 0.81)' : 'rgba(64, 64, 64, 0.81)',
                    backdropFilter: "blur(10px)",
                    borderRadius: 15,
                    height: 10,
                    width: 10
                }}>
                </Row>
                <label style={{fontSize: 10}} className="uk-width-5-6 uk-text-truncate">{item.nombre}</label>
            </Row>
            {item.has_video || (itsMe && start_transmission)
                ? <Row className='uk-width-2-5 centerAll'>
                    <Row className='uk-width-1-2 centerAll'
                         style={{padding: "0 2px"}}>
                        <Row className=' uk-width-1-1 centerAll' style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.81)',
                            backdropFilter: "blur(10px)",
                            borderTopRightRadius: 6,
                            borderTopLeftRadius: 6,
                            padding: 2,
                            cursor: cursor,
                            pointerEvents: pointerEvents
                        }}
                             onClick={async () => {
                                 if (itsMe) {
                                     dispatch(setMyVideoStatus(!my_video_status))
                                 } else if (userRole === "Maestro") {
                                     sendRequest(item.video_active === "1" ? 'close_video_student' : 'open_video_student', {
                                         socket_student: item.socket_id,
                                         student_id: item.id
                                     })
                                 }
                             }}>
                            {VideoIcon()}
                        </Row>
                    </Row>
                    <Row className='uk-width-1-2 centerAll'
                         style={{padding: "0 2px"}}>
                        <Row className='uk-width-1-1 centerAll' style={{
                            backgroundColor: 'rgba(255, 255, 255, 0.81)',
                            backdropFilter: "blur(10px)",
                            borderTopRightRadius: 6,
                            borderTopLeftRadius: 6,
                            padding: 2,
                            cursor: cursor,
                            pointerEvents: pointerEvents
                        }}
                             onClick={async () => {
                                 if (itsMe) {
                                     await dispatch(setMyAudioStatus(!my_audio_status))
                                 } else if (userRole === "Maestro") {
                                     sendRequest(item.audio_active === "1" ? 'close_audio_student' : 'open_audio_student', {
                                         socket_student: item.socket_id,
                                         student_id: item.id
                                     })
                                 }
                             }}>
                            {AudioIcon()}
                        </Row>
                    </Row>
                </Row> : null}
        </Row>
    }

    return <Row className="uk-width-1-1 centerAll"
                style={avatar}>
        {NoVideo()}
        {NoAudio()}
        {NoPhoto()}
        <video id={'video_stream' + item.id} ref={stream} className="uk-width-1-1" playsInline autoPlay muted={itsMe}
               style={{backgroundColor: "black", height: '100%', objectFit: 'contain'}}/>
        {Buttons()}
        {modalDevices()}
    </Row>
}
export default memo(VideoBox)
