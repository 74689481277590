import {
    ASSISTANCE_BY_GROUP,
    ASSISTANCE_PER_DAY,
    ASSISTANCE_PER_MODULE,
    ASSISTANCE_TEACHERS_DATE,
    ASSISTANCE_TODAY_ADMIN,
    CHECK_ASSISTANCE_TEACHER,
    EVENT_CHECK,
    FALTAS_TOTALES_ALUMNO,
    FALTAS_TOTALES_MATERIA_ALUMNO,
    LOADING_ASISTENCIAS,
    MODULES_CLASS_TEACHER,
    SCHEDULES_FROM_DATE
} from '../../actions/types';

const initialState = {
    loading: false,
    alumnos_total_faltas: [],
    alumnos_total_materia_faltas: [],
    event_check: [],
    schedules_from_date: [],
    assistance_by_group: [],
    assistance_today_admin: [],
    assistance_per_day: [],
    assistance_per_module: [],
    modules_teacher: [],
    check_assistance_teacher: ['No'],
    assistance_teachers_date: []
};

export default function AsistenciasReducers(state = initialState, action) {
    switch (action.type) {
        case LOADING_ASISTENCIAS:
            return {
                ...state,
                loading: action.payload,
            };
        case FALTAS_TOTALES_ALUMNO:
            return {
                ...state,
                alumnos_total_faltas: action.payload,
                loading: false,
            };
        case FALTAS_TOTALES_MATERIA_ALUMNO:
            return {
                ...state,
                alumnos_total_materia_faltas: action.payload,
                loading: false,
            };
        case EVENT_CHECK:
            return {
                ...state,
                event_check: action.payload,
                loading: false,
            };
        case SCHEDULES_FROM_DATE:
            return {
                ...state,
                schedules_from_date: action.payload,
                loading: false,
            };
        case ASSISTANCE_BY_GROUP:
            return {
                ...state,
                assistance_by_group: action.payload,
                loading: false,
            };
        case ASSISTANCE_TODAY_ADMIN:
            return {
                ...state,
                assistance_today_admin: action.payload,
                loading: false,
            };
        case ASSISTANCE_PER_DAY:
            return {
                ...state,
                assistance_per_day: action.payload,
                loading: false,
            };
        case ASSISTANCE_PER_MODULE:
            return {
                ...state,
                assistance_per_module: action.payload,
                loading: false,
            };
        case MODULES_CLASS_TEACHER:
            return {
                ...state,
                modules_class_teacher: action.payload,
                loading: false,
            };
        case CHECK_ASSISTANCE_TEACHER:
            return {
                ...state,
                check_assistance_teacher: action.payload,
                loading: false,
            };
        case ASSISTANCE_TEACHERS_DATE:
            return {
                ...state,
                assistance_teachers_date: action.payload,
                loading: false,
            };

        default:
            return {
                ...state,
                loading: false,
            };
    }
}
