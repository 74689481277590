import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import KolegiaBroadcastContext from "../../../Context/KolegiaBroadcast";
import moment from "moment/moment";
import {fetchAllMateriasMaestro} from "../../../redux/actions/Configuracion/AsignarMaestrosActions/AsignarMaestrosActions";
import {Row} from "react-material-responsive-grid";
import {alertWarning, pulseLoader, suspenseFallback} from "../../Global-W/NewFunctions";
import axios from "axios";
import {
    RoomId,
    setCurrentItemClass,
    setStatusClass,
    SetTeachers
} from "../../../redux/actions/VirtualClassActions/VirtualClassActions";
import FullClass from "./FullClass";


export const VideoTeacher = ({item = null, stream = null, enable = false, fullScreen, setFullScreen}) => {
    const streamUser = useRef();
    useEffect(() => {
        if (stream !== null && item !== null) {
            streamUser.current.srcObject = stream;
        }
    }, [item, stream]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fullScreen) {
            if (streamUser.current !== undefined) {
                streamUser.current.removeAttribute("controls");
                if (streamUser.current.requestFullscreen) {
                    streamUser.current.requestFullscreen().then(event => setFullScreen(false)).catch(e => {
                    });
                } else if (streamUser.current.msRequestFullscreen) {
                    streamUser.current.msRequestFullscreen();
                } else if (streamUser.current.mozRequestFullScreen) {
                    streamUser.current.mozRequestFullScreen();
                } else if (streamUser.current.webkitRequestFullscreen) {
                    streamUser.current.webkitRequestFullscreen();
                }
            }
        }
    }, [fullScreen]) // eslint-disable-line react-hooks/exhaustive-deps

    return <video muted={!enable} ref={streamUser} className="uk-width-1-1" autoPlay playsInline
                  style={{backgroundColor: "black", height: '80%', objectFit: 'contain'}}/>
};

export default function VideoBox({connectToClass, sendDisconnect}) {
    const {
        grayColorThree,
        grayColorFive,
        bd,
        token,
        schoolData,
        api,
        fifthColor,
        userName,
        userId,
        cicloActual,
        redColor,
        fourthColor,
        grayColorOne
    } = useSelector(state => state.rooot)
    const dispatch = useDispatch()
    const {
        socketRef,
        sendNewMessage,
        sendRequest
    } = useContext(KolegiaBroadcastContext);
    const {
        teachers_list,
        remoteVideos,
        currentItemClass,
        lastVersion
    } = useSelector(state => state.virtual_class)
    const [start, setStart] = useState(false)
    const [modal, setModal] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    // const [currentItemClass, setCurrentItemClass] = useState(null)
    const [classes, setClasses] = useState([])
    const [groups, setGroups] = useState([])
    const [type, setType] = useState('')
    const [loadClasses, setLoadClasses] = useState(true);
    const userMedia = useRef()

    const all_materias_maestro = useSelector((state) => state.asignarMaestros.all_materias_maestro);
    const today = moment().format('DD/MMMM/YYYY').split("/")
    const bodyMessage = {
        name: userName,
        user_id: userId,
        bd_school: bd,
        type_message: 2,
        date: moment().format('YYYY-MM-DD HH:mm:ss')
    }

    useEffect(async () => {
        if (currentItemClass === null && socketRef.current !== undefined) {
            await sendRequest('get_teachers', {school_id: schoolData.id}).then(async (response) => {
                await dispatch(SetTeachers(response.teachers.map((it, i) => {
                    it.camera = true
                    it.mic = true
                    it.buttons = true
                    if (i === 0) {
                        it.audio = true
                    }
                    return it
                })))
            }).catch((error) => {

            })
        }
    }, [socketRef, currentItemClass])

    useEffect(() => {
        if (currentItemClass === null) {
            getAllGroups()
            if (all_materias_maestro.length < 1) {
                dispatch(fetchAllMateriasMaestro(bd, token));
            }
        }
        return () => {
        }
    }, [currentItemClass]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentItemClass === null) {
            if (all_materias_maestro.length > 0) {
                for (let i = 0; i < all_materias_maestro.length; i++) {
                    getClassesOfDay(all_materias_maestro[i].id, i)
                }
            }
        }
    }, [all_materias_maestro, currentItemClass]) // eslint-disable-line react-hooks/exhaustive-deps

    const getAllGroups = async () => {
        try {
            const {data} = await axios.get(api + bd + '/get/all_groups/' + cicloActual.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (data.length !== 0) {
                function sortGroups(a, b) {
                    if (a.grados.grado + a.grupos.grupo < b.grados.grado + b.grupos.grupo) return -1
                    if (a.grados.grado + a.grupos.grupo > b.grados.grado + b.grupos.grupo) return 1
                    return 0
                }

                setGroups(data.sort(sortGroups).map(it => {
                    it.load = true;
                    return it;
                }))
            }
        } catch (e) {

        }
    }

    const getClassesOfDay = async (id, i) => {
        try {
            const {data} = await axios.get(api + bd + '/schedules/v3/get/current/classes/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            });
            if (data.length !== 0) {
                setClasses(classes => classes.concat(data.filter(item => item.tipo_clase === 'clase').filter(functionFilter)))
            }
            if (all_materias_maestro.length === i + 1) {
                setLoadClasses(false)
            }
        } catch (e) {

        }
    }

    const allTeachers = useMemo(() => {
        return teachers_list
    }, [teachers_list])

    useEffect(() => {
        setTimeout(() => {
            if (currentItemClass === null) {
                setStart(true)
                connectToClass(true)
            }
        }, 1250)
    }, [currentItemClass]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleClickAudio = (item) => {
        let i = allTeachers.indexOf(allTeachers.find((ix) => ix.user_id.toString() === item.user_id.toString()))
        if (i !== -1) {
            if (item.audio) {
                allTeachers[i].audio = false
                let clone = [...allTeachers]
                dispatch(SetTeachers(clone))
            } else {
                allTeachers.map((it, ix) => {
                    if (ix === i) {
                        it.audio = true
                        return it
                    } else {
                        if (it.audio) {
                            it.audio = false
                        }
                        return it
                    }
                })
                let clone = [...allTeachers]
                dispatch(SetTeachers(clone))
            }
        }
    }

    const handleEnterToClass = (item) => {
        sendDisconnect()
        dispatch(setCurrentItemClass(item))
        setModal(true)
        // dispatch(changeMainStream(item.video_box_stream))
        dispatch(setStatusClass(1))
        dispatch(RoomId(item.room_id))
        setStart(false)
    }

    function getNameAndGroup(obj) {
        if (obj.relacion !== undefined) {
            return obj.relacion.nombre.nombre + ' : ' + obj.relacion.maestro.name
        }
    }


    const noVideo = (item) => {
        let data = getNameAndGroup(item)
        return (
            <Row className={'uk-width-1-1 uk-width-1-2@m uk-width-1-4@l normal_text'}
                 style={{padding: 5, height: '320px', marginTop: 10, marginBottom: 10}}>
                <Row className={'uk-width-1-1'}
                     style={{
                         alignItems: 'flex-start',
                         justifyContent: 'center',
                         height: '100%',
                         backgroundColor: grayColorOne
                     }}>
                    <Row className={'uk-width-1-1'}
                         style={{padding: 5, backgroundColor: grayColorThree, color: 'black', height: '30px'}}>
                        <Row className={'uk-width-1-1'} title={data} style={{
                            fontWeight: 'bold',
                            paddingLeft: 5,
                            fontSize: '1rem',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <label className={'uk-width-1-1 uk-text-truncate'}> {data}</label>
                        </Row>
                    </Row>
                    <Row className={'uk-width-1-1'}
                         style={{alignItems: 'flex-start', justifyContent: 'center', padding: 10}}>
                        {<Row className={'uk-width-1-1'}
                              style={{color: 'black', fontWeight: 'bold', justifyContent: 'center', fontSize: '2rem'}}>
                            <label className={'uk-text-truncate'}> {item.grade.alias + ' ' + item.group.grupo}</label>
                        </Row>}
                        {<Row className={'uk-width-1-1'} style={{color: 'red', justifyContent: 'center'}}> <label
                            className={'uk-text-truncate'}>El maestro no ha ingresado al aula virtual</label></Row>}
                        {<Row className={'uk-width-1-1'} style={{color: 'black', justifyContent: 'center'}}> <label
                            className={'uk-text-truncate'}>{data.split(":")[0]}</label> </Row>}
                        {<Row className={'uk-width-1-1'} style={{color: 'black', justifyContent: 'center'}}> <label
                            className={'uk-text-truncate'}>{data.split(":")[1]}</label> </Row>}
                    </Row>

                </Row>
            </Row>
        )
    }
    const noClass = (item) => {
        let it = classes.find(cl => cl.grade === null && cl.group === null)
        let name = ''
        let mtro = ''
        if (it !== undefined) {
            if (it.relacion !== null) {
                if (it.relacion.nombre.club === '2') {
                    name = it.relacion.nombre.nombre
                }
                if (it.relacion.maestro !== null) {
                    mtro = it.relacion.maestro.name
                }
            }
        }
        if (loadClasses) {
            return <Row className={'uk-width-1-1 uk-width-1-2@m uk-width-1-4@l normal_text'}
                        style={{padding: 5, height: '320px', marginTop: 10, marginBottom: 10}}>
                <Row className={'uk-width-1-1'}
                     style={{
                         alignItems: 'flex-start',
                         justifyContent: 'center',
                         height: '100%',
                         backgroundColor: grayColorOne
                     }}>
                    <Row className={'uk-width-1-1'}
                         style={{padding: 5, backgroundColor: grayColorThree, color: 'black', height: '30px'}}>
                        <Row className={'uk-width-1-1'} title={'Cargando datos de clase'} style={{
                            // fontWeight: 'bold',
                            paddingLeft: 5,
                            fontSize: '1rem',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <label className={'uk-width-1-1 uk-text-truncate'}> {'Cargando'}</label>
                        </Row>
                    </Row>
                    <Row className={'uk-width-1-1 centerAll'}
                         style={{alignItems: 'flex-start', justifyContent: 'center', padding: 10}}>
                        {pulseLoader()}
                    </Row>

                </Row>
            </Row>
        }
        return (
            <Row className={'uk-width-1-1 uk-width-1-2@m uk-width-1-4@l normal_text'}
                 style={{padding: 5, height: '320px', marginTop: 10, marginBottom: 10}}>
                <Row className={'uk-width-1-1'}
                     style={{
                         alignItems: 'flex-start',
                         justifyContent: 'center',
                         height: '100%',
                         backgroundColor: grayColorOne
                     }}>
                    <Row className={'uk-width-1-1'}
                         style={{padding: 5, backgroundColor: grayColorThree, color: 'black', height: '30px'}}>
                        <Row className={'uk-width-1-1'} title={'Sin transmisión'} style={{
                            // fontWeight: 'bold',
                            paddingLeft: 5,
                            fontSize: '1rem',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <label
                                className={'uk-width-1-1 uk-text-truncate'}> {name === '' ? 'Sin transmisión' : name}</label>
                        </Row>
                    </Row>
                    <Row className={'uk-width-1-1'}
                         style={{alignItems: 'flex-start', justifyContent: 'center', padding: 10}}>
                        {<Row className={'uk-width-1-1'}
                              style={{color: 'black', fontWeight: 'bold', justifyContent: 'center', fontSize: '2rem'}}>
                            <label className={'uk-text-truncate'}> {item.grados.grado + "" + item.grupos.grupo}</label>
                        </Row>}
                        {mtro === '' ? null :
                            <Row className={'uk-width-1-1'} style={{color: 'red', justifyContent: 'center'}}> <label
                                className={'uk-text-truncate'}>El maestro no ha ingresado al aula virtual</label></Row>}
                        {<Row className={'uk-width-1-1'} style={{color: 'red', justifyContent: 'center'}}> <label
                            className={'uk-text-truncate'}>{mtro === '' ? 'El grupo no tiene clase asignada a esta hora' : mtro}</label></Row>}
                    </Row>

                </Row>
            </Row>
        )
    }

    const noVideoConnected = (item) => {
        return (
            <Row className={'uk-width-1-1 uk-width-1-2@m uk-width-1-4@l normal_text'}
                 style={{padding: 5, height: '320px', marginTop: 10, marginBottom: 10}}>
                <Row className={'uk-width-1-1'}
                     style={{
                         alignItems: 'flex-start',
                         justifyContent: 'center',
                         height: '100%',
                         backgroundColor: grayColorThree
                     }}>
                    <Row className={'uk-width-1-1'}
                         style={{padding: 5, backgroundColor: grayColorFive, color: 'black', height: '30px'}}>
                        <Row className={'uk-width-1-1'} title={getNameAndGroup(item)} style={{
                            fontWeight: 'bold',
                            paddingLeft: 5,
                            fontSize: '1rem',
                            alignItems: 'center',
                            height: '100%'
                        }}>
                            <label className={'uk-width-1-1 uk-text-truncate'}> {getNameAndGroup(item)}</label>
                        </Row>
                    </Row>
                    <Row className={'uk-width-1-1'}
                         style={{alignItems: 'flex-start', justifyContent: 'center', padding: 10}}>
                        {<Row className={'uk-width-1-1'}
                              style={{color: 'black', fontWeight: 'bold', justifyContent: 'center', fontSize: '2rem'}}>
                            <label className={'uk-text-truncate'}> {item.grade.alias + ' ' + item.group.grupo}</label>
                        </Row>}
                        {<Row className={'uk-width-1-1'} style={{color: 'red', justifyContent: 'center'}}> <label
                            className={'uk-text-truncate'}>El maestro se encuentra conectado, pero no ha iniciado la
                            transmisión</label></Row>}
                        {<Row className={'uk-width-1-1'} style={{color: 'black', justifyContent: 'center'}}> <label
                            className={'uk-text-truncate'}>{getNameAndGroup(item)}</label> </Row>}
                    </Row>

                </Row>
            </Row>
        )
    }
    const handleMuteTeacher = async (item) => {
        let i = allTeachers.indexOf(allTeachers.find((ix) => ix.user_id.toString() === item.user_id.toString()))
        if (i !== -1) {
            let clone = [...allTeachers]
            let body = ''
            if (item.mic) {
                body = 'desactivó el audio del docente'
                socketRef.current.emit('close_audio_to_teacher', {
                    socket_teacher: item.room_id,
                    teacher_id: item.user_id,
                    user_id: item.user_id,
                    requesting: userId,
                    admin_room: schoolData.id.toString() + "/ADMIN"
                })
                allTeachers[i].mic = false
            } else {
                body = 'solicitó encender el micrófono del docente'
                allTeachers[i].buttons = false
                socketRef.current.emit('open_audio_to_teacher', {
                    socket_teacher: item.room_id, teacher_id: item.user_id, user_id: item.user_id,
                    requesting: userId, admin_room: schoolData.id.toString() + "/ADMIN",
                    admin: userName,
                })
            }
            let messageContent = {
                ...bodyMessage,
                room_id: item.room_id,
                body: body,
                id_subject: item.id_materia,
                senderId: socketRef.current.id
            };
            sendNewMessage(messageContent);
            await dispatch(SetTeachers(clone))
        }
    }
    const handleClickVideo = async (item) => {
        let i = allTeachers.indexOf(allTeachers.find((ix) => ix.user_id.toString() === item.user_id.toString()))
        if (i !== -1) {
            let body = ''
            let clone = [...allTeachers]
            if (item.camera) {
                body = 'desactivó la cámara del docente'
                socketRef.current.emit('close_video_to_teacher', {
                    socket_teacher: item.room_id,
                    teacher_id: item.user_id,
                    user_id: item.user_id,
                    requesting: userId,
                    admin_room: schoolData.id.toString() + "/ADMIN"
                })
                allTeachers[i].camera = false
            } else {
                body = 'solicitó encender la cámara del docente'
                socketRef.current.emit('open_video_to_teacher', {
                    socket_teacher: item.room_id, teacher_id: item.user_id, user_id: item.user_id,
                    requesting: userId, admin_room: schoolData.id.toString() + "/ADMIN",
                    admin: userName,
                })
                allTeachers[i].buttons = false
            }
            let messageContent = {
                ...bodyMessage,
                room_id: item.room_id,
                body: body,
                id_subject: item.id_materia,
                senderId: socketRef.current.id
            };
            sendNewMessage(messageContent);
            await dispatch(SetTeachers(clone))
        }
    }
    const Receso = () => {
        return (
            <Row>

            </Row>
        )
    }

    function functionFilter(time) {
        if (schoolData.id === 8 || schoolData.id === 42) {
            return moment(time.horarios.inicio, 'HH:mm:ss').format('HH:mm') === moment('08:00:00', 'HH:mm:ss').format('HH:mm');
        } else {
            return moment() < moment(time.horarios.fin, 'HH:mm:ss') && moment() > moment(time.horarios.inicio, 'HH:mm:ss');
        }
    }

    const response = (name) => 'Ya enviaste una petición previa a ' + name + ' para  ' + (type === 'camara' ? 'encender su cámara ' : 'encender su micrófono ') + 'y aún no la ha respondido. Por favor espera a que la responda.'

    const renderGroups = groups.length === 0 ? suspenseFallback() : groups.map((gr, i) => {
        // if(classes.length === 0){
        //     return pulseLoader()
        // }
        let item = classes.find(cl => (cl.grade !== null && cl.group !== null) && cl.grade.grado.toString() === gr.grados.grado && cl.group.grupo.toString() === gr.grupos.grupo.toString())
        if (item === undefined) {
            return noClass(gr)
        }
        let teacher = allTeachers.find(ix => ix.user_id.toString() === item.relacion.maestro.id.toString())
        if (teacher === undefined) {
            gr.load = false;
            return noVideo(item)
        } else {
            gr.load = false;
            teacher['grado'] = item.grado
            teacher['grupo'] = item.grupo
            teacher['has_video'] = remoteVideos[teacher['socket_id']] !== undefined
            teacher['video_box_stream'] = remoteVideos[teacher['socket_id']] !== undefined
                ? remoteVideos[teacher['socket_id']]['stream']['stream']
                : null
            teacher.data = getNameAndGroup(item)
            teacher.id_materia = item.relacion.id_materia
            return teacher.has_video ?
                <Row className={'uk-width-1-1 uk-width-1-2@m uk-width-1-4@l'}
                     style={{padding: 5, height: '320px', marginTop: 10, marginBottom: 10}}>
                    <Row className={'uk-width-1-1'} style={{height: '100%'}}>
                        <Row className={'uk-width-1-1'}
                             style={{padding: 5, backgroundColor: grayColorThree, color: 'black', height: '30px'}}>
                            <Row className={'uk-width-3-4 normal_text'} style={{
                                fontWeight: 'bold',
                                paddingLeft: 5,
                                fontSize: '0.8rem',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <label className={'uk-width-1-1 uk-text-truncate'}> {teacher.data}</label>
                            </Row>
                            {teacher.has_video ? <Row className={'uk-width-1-4'}
                                                      onClick={() => {
                                                          handleEnterToClass(teacher)
                                                      }}
                                                      style={{
                                                          cursor: 'pointer',
                                                          height: '100%',
                                                          paddingRight: 5,
                                                          justifyContent: 'flex-end',
                                                          alignItems: 'center',
                                                          padding: 2,
                                                          borderRadius: 5,
                                                          backgroundColor: redColor,
                                                          color: 'black'
                                                      }}>
                                <Row className={'uk-width-1-1 normal_text'} style={{
                                    justifyContent: 'center',
                                    fontWeight: 'bold',
                                    fontSize: '0.75rem',
                                    height: '100%',
                                    padding: 2,
                                    color: fourthColor
                                }}>
                                    <Row className={'uk-width-2-3'}
                                         style={{justifyContent: 'center', color: fourthColor}}>
                                        <label className={'uk-text-truncate'}>
                                            ENTRAR
                                        </label>
                                    </Row>
                                    <Row className={'uk-width-1-3'} style={{justifyContent: 'flex-end'}}>
                                        <div><i className="fas fa-arrow-circle-right"/></div>
                                    </Row>

                                </Row>
                            </Row> : null}
                        </Row>
                        {teacher.has_video
                            ? <VideoTeacher
                                item={teacher}
                                fullScreen={fullScreen}
                                setFullScreen={(event) => setFullScreen(event)}
                                stream={teacher.video_box_stream}
                                enable={teacher.audio}/> : null}
                        <Row className={'uk-width-1-1'}
                             style={{padding: 5, backgroundColor: grayColorThree, color: 'black', height: '30px'}}>
                            <Row className={'uk-width-1-3'}
                                 style={{
                                     alignItems: 'center',
                                     fontWeight: 'bold',
                                     paddingLeft: 5,
                                     fontSize: '0.5rem',
                                     justifyContent: 'flex-start',
                                     height: '100%'
                                 }}>
                                <Row className={'uk-width-1-5'} style={{cursor: 'pointer'}} onClick={() => {
                                    handleClickAudio(teacher)
                                }}>
                                    {!teacher.audio ? <div>
                                            <img src="https://img.icons8.com/ios-filled/20/FF0000/no-audio--v1.png"
                                                 alt={''}/>
                                        </div> :
                                        <div>
                                            <img
                                                src="https://img.icons8.com/external-kiranshastry-solid-kiranshastry/20/000000/external-speaker-interface-kiranshastry-solid-kiranshastry.png"
                                                alt={''}/>
                                        </div>}
                                </Row>
                            </Row>
                            <Row className={'uk-width-2-3 '} style={{justifyContent: 'flex-end'}}>
                                <Row
                                    title={teacher.buttons ? 'activados' : 'Esperando la respuesta del maestro, no puede presionar ningún botón'}
                                    className={'uk-width-1-1'} style={{height: '100%', justifyContent: 'flex-end'}}>
                                    <Row title={'Controles de cámara del maestro'}
                                         className={'uk-width-1-3'}
                                         onClick={() => {
                                             if (teacher.buttons) {
                                                 setType('camara')
                                                 handleClickVideo(teacher)
                                             } else {
                                                 alertWarning(response(teacher.data.split(":")[1]))
                                             }
                                         }}
                                         style={{
                                             cursor: 'pointer',
                                             alignItems: 'center',
                                             fontWeight: 'bold',
                                             fontSize: '0.5rem',
                                             justifyContent: 'center',
                                             height: '100%',
                                             // marginRight:2,
                                         }}>

                                        {!teacher.camera ? <div>
                                                <img
                                                    src="https://img.icons8.com/material-rounded/20/FF0000/no-video--v1.png"/>
                                            </div> :
                                            <div>
                                                <img src="https://img.icons8.com/ios-glyphs/20/000000/video-call.png"/>
                                            </div>}
                                    </Row>
                                    <Row title={'Controles de micrófono del maestro'}
                                         className={'uk-width-1-3'}
                                         onClick={() => {
                                             if (teacher.buttons) {
                                                 handleMuteTeacher(teacher, i)
                                                 setType('')
                                             } else {
                                                 alertWarning(response(teacher.data.split(":")[1], 'micro'))
                                             }
                                         }}
                                         style={{
                                             cursor: 'pointer',
                                             alignItems: 'center',
                                             fontWeight: 'bold',
                                             fontSize: '0.5rem',
                                             justifyContent: 'center',
                                             height: '100%',
                                             // marginLeft:2
                                         }}>
                                        {!teacher.mic ? <div>
                                                <img src="https://img.icons8.com/ios-glyphs/20/FF0000/no-microphone.png"
                                                     alt={''}/>
                                            </div> :
                                            <div>
                                                <img src="https://img.icons8.com/ios-glyphs/20/000000/microphone.png"
                                                     alt={''}/>
                                            </div>}
                                    </Row>
                                    <Row title={'Pantalla completa'}
                                         className={'uk-width-1-3'}
                                         onClick={() => {
                                             if (item.video_box_stream !== null) {
                                                 userMedia.current = item.video_box_stream
                                             }
                                             setFullScreen(true)
                                         }}
                                         style={{
                                             cursor: 'pointer',
                                             alignItems: 'center',
                                             fontWeight: 'bold',
                                             fontSize: '0.5rem',
                                             justifyContent: 'center',
                                             height: '100%'
                                         }}>
                                        <div
                                            style={{
                                                margin: '0 0 0 10px',
                                            }}>
                                            <img alt=""
                                                 data-uk-tooltip="Pantalla completa"
                                                 src="https://img.icons8.com/material/20/0000000/full-screen--v1.png"/>
                                        </div>
                                    </Row>
                                </Row>
                            </Row>
                        </Row>
                    </Row>
                </Row> : noVideo(item)
        }

    })


    const container = () => {
        return (
            <Row className={'uk-width-1-1'} style={{height: '70vh', maxHeight: '70vh'}}>
                <Row className={'uk-width-1-1 scroll'} style={{padding: 5, overflow: 'auto'}}>
                    {renderGroups}
                </Row>
            </Row>
        )
    }


    if (currentItemClass !== null) {
        return <Row className={'uk-width-1-1'} style={{height: '80vh', marginTop: 5}}>
            <Row className={'uk-width-1-1 uk-animation-slide-right'}
                 style={{height: '80vh', alignItems: 'flex-start'}}>
                <FullClass/>
            </Row>
        </Row>
    } else {
        return (
            <Row className={'uk-width-1-1 centerAll'} style={{height: '80vh'}}>
                <Row className={'uk-width-1-1 module_title'} style={{padding: '0 0 0 10px '}}>
                    Aulas virtuales
                </Row>
                <Row className={'uk-width-1-1 uk-animation-slide-left subnegritas'}
                     style={{padding: 5, alignItems: 'center'}}>
                    <Row className="centerAll" style={{width: '3%', marginRight: 3}}>
                        <i data-uk-tooltip={lastVersion} className="fas fa-info-circle"
                           style={{color: fifthColor, fontSize: '1rem'}}/>
                    </Row>
                    Clases
                    <Row className="centerAll"
                         style={{padding: 2, justifyContent: 'flex-start', alignItems: 'center', margin: '0px 4px'}}>
                        <Row
                            className="txtnegritasMiniBlack centerAll uk-width-1-1"
                            style={{
                                padding: '3px 10px',
                                borderRadius: 3,
                                backgroundColor: redColor,
                                fontSize: '.7rem',
                                color: fourthColor
                            }}>
                            EN VIVO
                        </Row>
                    </Row>
                    de hoy {today[0] + ' de ' + today[1] + ' de ' + today[2]}

                    <Row className="uk-width-1-1" style={{justifyContent: 'flex-start'}}>
                        {container()}
                    </Row>
                </Row>
            </Row>
        )
    }
}
