import {DELETE_POST, FETCH_POSTS, LOADING_POSTS} from '../actions/types';

const initialState = {
    list: [],
    loading: false
};

export default function posts(state = initialState, action) {

    switch (action.type) {
        case FETCH_POSTS:
            return {
                ...state,
                list: action.payload,
                loading: false
            };
        case DELETE_POST:
            return {
                ...state,
                list: action.payload,
                loading: false
            };
        case LOADING_POSTS:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
