import styled, {css, keyframes} from "styled-components";
import {Row} from 'react-material-responsive-grid';

export const InputLabel = styled.label`
  color: ${({theme}) => theme.colors.text};
  font-family: 'SF-regular', sans-serif;
  text-align: left;
  line-height: normal;
  font-size: 12px;
  font-weight: 600;
`;

export const ItemText = styled.label`
  color: ${({theme}) => theme.colors.text};
  font-family: 'Work Sans', sans-serif;
  text-align: left;
  font-size: 12px;
  font-weight: 600;
`;

export const LabelIcon = styled.i`
  color: ${({
              theme, type, disabled
            }) => type !== undefined ? disabled ? theme.button[type].disabled_text : theme.button[type].icon_color : theme.colors.text.icon_color};
  margin-right: ${({type}) => type !== undefined ? "0" : "4px"}
`;

export const LabelSelectButton = styled.label`
  color: ${({theme}) => theme.button.select.text};
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  transition: color .4s;
`;

export const LabelTabButton = styled.label`
  color: ${({theme}) => theme.button.tab.text};
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  transition: color .4s;
`;

export const ModuleTitle = styled(Row)`
  align-items: flex-start;
  color: ${({theme}) => theme.colors.text};
  font-family: 'work Sans', sans-serif;
  font-size: 30px;
  font-weight: 800;
`;

export const RowSelectButton = styled(Row)`
  background-color: ${({
                         theme, disabled
                       }) => disabled ? theme.button.select.disabled_background : theme.button.select.background};
  border-radius: 6px;
  padding: 8px 16px !important;
  transition: background-color .4s;
  cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};

  &:hover {
    background-color: ${({
                           theme, disabled
                         }) => disabled ? theme.button.select.disabled_background : theme.button.select.hover_background};
  }
;

  &:hover ${LabelSelectButton} {
    cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};
    color: ${({theme, disabled}) => disabled ? theme.button.select.disabled_text : theme.button.select.hover_text};
  }
;
  ${props => props.selected && css`
    background-color: ${({
                           theme, disabled
                         }) => disabled ? theme.button.select.disabled_background : theme.button.select.selected_background};

    ${LabelSelectButton} {
      color: ${({
                  theme, disabled
                }) => disabled ? theme.button.select.disabled_text : theme.button.select.selected_text};
      font-weight: ${({selected}) => selected ? "400" : 'bold'};
    }
    ${SubTitle} {
      color: ${({
                  theme, disabled
                }) => disabled ? theme.button.select.disabled_text : theme.button.select.selected_text}; 
    }
    ${ItemText} {
      color: ${({
                  theme, disabled
                }) => disabled ? theme.button.select.disabled_text : theme.button.select.selected_text}; 
    }
  ;
  `}
  ${props => props.disabled && css`
    ${LabelSelectButton} {
      color: ${({
                  theme, disabled
                }) => disabled ? theme.button.select.disabled_text : theme.button.select.selected_text};
      font-weight: ${({selected}) => selected ? "400" : 'bold'};
    }
  ;
  `}
`;

export const RowSelectSubject = styled(Row)`
  background-color: ${({
                                                                      theme, disabled
                                                                  }) => disabled ? theme.button.select.disabled_background : theme.button.select.background};
  border-radius: 6px;
  padding: 8px 16px !important;
  transition: background-color .4s;
  cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};

  &:hover {
    background-color: ${({
                                                                                                                                                                                                                                                          theme, disabled
                                                                                                                                                                                                                                                      }) => disabled ? theme.button.select.disabled_background : theme.button.select.hover_background};
  }
;

  &:hover ${LabelSelectButton} {
    cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};
    color: ${({theme, disabled}) => disabled ? theme.button.select.disabled_text : theme.button.select.hover_text};
  }
;
  ${props => props.selected && css`
   border: 2px solid #000
  ;
  `}
  ${props => props.disabled && css`
    ${LabelSelectButton} {
      color: ${({
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     theme, disabled
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 }) => disabled ? theme.button.select.disabled_text : theme.button.select.selected_text};
      font-weight: ${({selected}) => selected ? "400" : 'bold'};
    }
  ;
  `}
`;

export const TabButton = styled(Row)`
  background-color: ${({theme}) => theme.button.tab.background};
  border-radius: 6px 6px 0 0;
  border: 1px solid;
  border-color: ${({theme}) => theme.button.tab.border_color};
  border-bottom-width: 0;
  padding: 8px 16px !important;
  transition: background-color .4s;
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.button.tab.hover_background};
    border-color: ${({theme}) => theme.button.tab.hover_border_color};
  }
;

  &:hover ${LabelTabButton} {
    cursor: pointer;
    color: ${({theme}) => theme.button.tab.hover_text};
  }
;
  ${props => props.selected && css`
    background-color: ${({theme}) => theme.button.tab.selected_background};
    border-color: ${({theme}) => theme.button.tab.selected_border_color};

    ${LabelTabButton} {
      color: ${({theme}) => theme.button.tab.selected_text};
      font-weight: 600;
    }
  ;
  `}
`;

export const StyledInput = styled.input`
  color: ${({theme, styleType}) => theme.input[styleType].text};
  cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};
  border: 1px solid ${({theme, styleType}) => theme.input[styleType].border};
  background-color: ${({theme, styleType}) => theme.input[styleType].background};
  border-radius: 6px;
  font-family: 'SF-regular', sans-serif;
  font-size: 11px;
  padding: 9px !important;
  text-align: ${({align}) => align !== undefined ? align : "left"};
  transition: border .4s;

  &:focus {
    border: 1px solid ${({theme}) => theme.colors.focus};
  }

  ::placeholder {
    text-transform: uppercase;
  }

  ${props => props.transform && css`
    text-transform: ${({transform}) => transform};
  `}
`;

export const StyledSelect = styled.select`
  color: ${({theme, disabled}) => disabled ? theme.select.disabled_text : theme.select.text};
  cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};
  border: 1px solid ${({theme}) => theme.select.border};
  background-color: ${({theme}) => theme.select.background};
  font-family: 'SF-regular', sans-serif;
  font-size: 11px;
  border-radius: 6px;
  padding: 8px;
  text-transform: uppercase;
  text-align-last: ${({center}) => center ? "center" : "left"};
  transition: border .4s;

  &:focus {
    border: 1px solid ${({theme}) => theme.colors.focus};
  }
`;

export const StyledTextArea = styled.textarea`
  color: ${({theme}) => theme.colors.text};
  border: 1px solid ${({theme}) => theme.colors.border};
  border-radius: 6px;
  background-color: ${({theme}) => theme.colors.background_gray};
  font-family: 'SF-regular', sans-serif;
  font-size: 12px;
  resize: none;
  padding: 8px;
  text-align: left;
  transition: border .4s;

  &:focus {
    border: 1px solid ${({theme}) => theme.colors.focus};
  }

  ::placeholder {
    text-transform: uppercase;
  }

  ${props => props.transform && css`
    text-transform: ${({transform}) => transform};
  `}
`;

export const SubTitle = styled.label`
  align-items: flex-start;
  color: ${({theme, selected}) => selected ? theme.colors.background : theme.colors.subtitle};
  font-family: 'SF-bold', sans-serif;
  font-size: 16px;
  padding: 3px 0 !important;
  text-align: left;
  line-height: normal;
  margin-top: 3px;
`;

export const ModalTitle = styled.label`
  align-items: flex-start;
  color: ${({theme}) => theme.colors.subtitle};
  font-family: 'work Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
`;

export const SwitchRow = styled(Row)`
  color: ${({theme}) => theme.colors.text};
  border: 1px solid ${({theme}) => theme.colors.border};
  border-radius: 6px;
  background-color: ${({theme}) => theme.colors.background_gray};
  font-family: 'SF-regular', sans-serif;
  font-size: 12px;
  padding: 7px !important;
  text-align: left;
  text-transform: uppercase;
`;

export const Text = styled.label`
  color: ${({
              theme, type, disabled
            }) => type !== undefined ? disabled ? theme.button[type].disabled_text : theme.button[type].text : disabled ? theme.colors.disabled_text : theme.colors.text};
  font-family: 'SF-regular', sans-serif;
  font-size: 12px;
  font-weight: ${({weight}) => weight !== undefined ? weight : "400"};
  text-align: ${({align}) => align !== undefined ? align : "left"};
  line-height: normal;
`;

export const AddRow = styled(Row)`
  color: ${({
              theme, type, disabled, selected
            }) => type !== undefined ? disabled ? theme.button[type].disabled_text : selected ? theme.button[type].hover_text : theme.button[type].text : theme.colors.text};
  border: 1px solid ${({
                         theme, type, disabled, selected
                       }) => type !== undefined ? disabled ? theme.button[type].disabled_border : selected ? theme.button[type].hover_border : theme.button[type].border : theme.colors.border};
  border-radius: 6px;
  background-color: ${({
                         theme, type, disabled, selected
                       }) => type !== undefined ? disabled ? theme.button[type].disabled_background : selected ? theme.button[type].hover_background : theme.button[type].background : theme.colors.background};
  font-family: 'SF-regular', sans-serif;
  font-size: .75rem;
  max-height: ${({half}) => half ? "22px" : "34px"};
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  transition: background-color .4s;
  cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};

  &:hover {
    background-color: ${({
                           theme, type, disabled
                         }) => type !== undefined ? disabled ? theme.button[type].disabled_background : theme.button[type].hover_background : theme.colors.hover_background};
  }
;

  &:hover ${Text} {
    cursor: ${({disabled}) => disabled ? "not-allowed" : "pointer"};
    color: ${({
                theme, type, disabled
              }) => type !== undefined ? disabled ? theme.button[type].disabled_text : theme.button[type].hover_text : theme.colors.hover_text};
  }
;

  ${props => props.selected && css`
    ${Text} {
      color: ${({theme}) => theme.button[props.type].hover_text};
    }
  ;
  `}
`;

export const ButtonIconContainer = styled(Row)`
  color: ${({
              theme, type, disabled
            }) => type !== undefined ? disabled ? theme.button[type].disabled_text : theme.button[type].text : theme.colors.text};
  background-color: ${({
                         theme, type, disabled
                       }) => type !== undefined ? disabled ? theme.button[type].disabled_icon_background : theme.button[type].icon_background : theme.colors.background};
  border-radius: ${({label}) => label !== undefined ? "4px 0 0 4px" : "4px"};
  padding: ${({half}) => half ? "1px !important" : " 8px !important"};
`;


export const StepButton = styled(Row)`
  background-color: ${({theme}) => theme.button.select.background};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 8px 8px !important;
  transition: background-color .4s;

  &:hover {
    background-color: ${({theme}) => theme.button.select.hover_background};
  }
;

  &:hover ${LabelSelectButton} {
    color: ${({theme}) => theme.button.select.hover_text};
  }
;
  ${props => props.selected && css`
    background-color: ${({theme}) => theme.button.select.selected_background};

    ${LabelSelectButton} {
      color: ${({theme}) => theme.button.select.selected_text};
    }
  ;
  `}
`;

const bounce = keyframes`
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

export const SpinnerContainer = styled(Row)`
  width: 10px;
  height: 10px;
  margin: 0 3px !important;
  background-color: ${({theme, type}) => type !== undefined ? theme.spinner[type].color : theme.spinner[type].color};
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: ${bounce} 1.2s infinite ease-in-out both;
  animation: ${bounce} 1.2s infinite ease-in-out both;
  ${props => props.delay === 1 && css`
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  `}
  ${props => props.delay === 2 && css`
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  `}
`;


export const Card = styled(Row)`
  padding: 4px !important;
  border-radius: 6px;
`;
export const CardHeader = styled(Row)`
  padding: 0 5px !important;
  background-color: ${({theme}) => theme.card.background};
  border-bottom-width: 0.5px;
  border-color: ${({theme}) => theme.card.border};
  border-radius: 7px 7px 0 0;
`;
export const CardLogo = styled.img`
  flex: 1;
  resize-mode: contain;
  width: 100px;
`;
export const CardTextInfo = styled.label`
  flex: 1;
  resize-mode: contain;
  width: 100px;
`;

