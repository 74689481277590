// Dependencies
import React, {Suspense} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {AppEnv} from './components/env';
import Loading from './components/Global-W/Classes/Loading';
import App from './App';

const Login = React.lazy(() => import('./components/Login-W'));
const Landing = React.lazy(() => import("./components/Home/Landing/Default"));
const Page404 = React.lazy(() => import('./components/Page404'));
const CambioContrasena = React.lazy(() => import('./components/Login-W/CambioContrasena'));
const Confirmacion = React.lazy(() => import('./components/Login-W/Confirmacion'));
const Contrasena = React.lazy(() => import('./components/Login-W/Contrasena'));
const LandingAdmision = React.lazy(() => import('./components/Preinscripciones-W/LandingAdmision'));
const TermsConditions = React.lazy(() => import('./components/Global-W/Hooks/TermsConditions'));
const Privacidad = React.lazy(() => import('./components/Global-W/Hooks/Privacidad'));
const Support = React.lazy(() => import('./components/Global-W/Hooks/Support'));
const Documentation = React.lazy(() => import('./components/Zoom-W/Documentation'));
const Pago = React.lazy(() => import('./components/Pagos-W/Padre/Pago'));
const Partner = React.lazy(() => import('./components/Home/Extras/Partner'));
const Verification = React.lazy(() => import('./components/Global-W/Classes/CorreoValidado'));
const Dashboard = React.lazy(() => import('./components/Global-W/Dashboard/Dashboard'));
const DashboardSelector = React.lazy(() => import('./components/Global-W/Dashboard/DashboardSelector'));
const Registro = React.lazy(() => import('./components/Preinscripciones-W/Registro/Register'));

export default function AppRoutes() {
    const navigation = useNavigate();
    return <Suspense fallback={<Loading/>}>
        <App>
            <Routes>
                {AppEnv.Landing === "true"
                    ? <Route path='/' element={<Landing/>}/>
                    : <Route path='/' element={<Login history={navigation}/>}/>}
                {AppEnv.Landing === "true"
                    ? <Route path='/login/*' element={<Login history={navigation}/>}/>
                    : null}
                <Route path='/search/*' element={<Landing/>}/>
                <Route path='Terminos-Y-Condiciones' element={<TermsConditions/>}/>
                <Route path="terms/conditions" element={<TermsConditions/>}/>
                <Route path='/aviso-privacidad' element={<Privacidad/>}/>
                <Route path='/privacy' element={<Privacidad/>}/>
                <Route path='/support' element={<Support/>}/>
                <Route path='/Admisiones/*' element={<LandingAdmision/>}/>
                <Route path='/admisiones/*' element={<LandingAdmision/>}/>
                <Route path='/pago' element={<Pago/>}/>
                <Route path='/verification' element={<Verification/>}/>
                <Route path='/dash-board' element={<Dashboard/>}/>
                <Route path='dashboard/*' element={<DashboardSelector/>}/>
                <Route path='/registro' element={<Registro/>}/>
                <Route path='/register' element={<Registro/>}/>
                <Route path='/CambioContrasena/*' element={<CambioContrasena/>}/>
                <Route path='/change/password/*' element={<CambioContrasena/>}/>
                <Route path='/Confirmacion' element={<Confirmacion/>}/>
                <Route path='/confirmation' element={<Confirmacion/>}/>
                <Route path='/zoom/documentation' element={<Documentation/>}/>
                <Route path='/api'/>
                <Route path='/Contrasena' element={<Contrasena/>}/>
                <Route path='/password' element={<Contrasena/>}/>
                <Route path='/partner' element={<Partner/>}/>
                <Route element={<Page404/>}/>
            </Routes>
        </App>
    </Suspense>
}
