import {
    ROOT_FILES
} from '../../actions/types';

const initialState:any = {
    root_files:[],
    total_uploaded:0,
    loading: false
}

export default function KsmCloudReducers(state = initialState, action:any) {
    switch (action.type){
        case ROOT_FILES:
            return {
                ...state,
                root_files: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
