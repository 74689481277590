import {GET_LAST_REFERENCES, GET_REFERENCES, LOADING_REFERENCES,} from "../../actions/types";

const initialState = {
    loading:false,
    references:[],
    last_references:[]
}

export default function ReferencesReducer(state = initialState, action) {

    switch (action.type) {
        case GET_REFERENCES:
            return {
                ...state,
                references: action.payload
            };
        case GET_LAST_REFERENCES:
            return {
                ...state,
                last_references: action.payload
            };
        case LOADING_REFERENCES:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}