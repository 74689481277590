import {
    AVISOS,
    COUNTRIES,
    LOADING_COUNTRIES,
    PLANES_GESTION,
    REINICIO,
    SELECTED_COUNTRY,
    TIPOS_MATERIAS
} from '../../actions/types';

const initialState = {
        loading: false,
        country: {
            id: 1,
            code: 'MX',
            nombre: 'MEXICO',
            bandera: 'mx',
            globals: [{id_pais: '1', option: 'data_base', value: '1'},
                {id_pais: '1', option: 'apellido_materno', value: '1'},
                {id_pais: '1', option: 'id_ciudadano', value: '1'},
                {id_pais: '1', option: 'codigo_postal', value: '1'},
                {id_pais: '1', option: 'alias_id_ciudadano', value: 'CURP'},
                {id_pais: '1', option: 'id_escuela', value: '1'},
                {id_pais: '1', option: 'alias_id_escuela', value: 'CCT'},
                {id_pais: '1', option: 'alias_colonia', value: 'Colonia'},
                {id_pais: '1', option: 'alias_ciudad', value: 'Ciudad'},
                {id_pais: '1', option: 'alias_estado', value: 'Estado'},
                {id_pais: '1', option: 'estado', value: '1'}
            ]
        },
        countries: [],
        avisos: [],
        reinicio: '',
        tipos_materias: [],
        planes_estudio: [],
    }
;

export default function GestionReducer(state = initialState, action) {

    switch (action.type) {
        case COUNTRIES:
            return {
                ...state,
                countries: action.payload,
                loading: false
            };
        case SELECTED_COUNTRY:
            return {
                ...state,
                country: action.payload
            };
        case LOADING_COUNTRIES:
            return {
                ...state,
                loading: action.payload
            };
        case TIPOS_MATERIAS:
            return {
                ...state,
                tipos_materias: action.payload,
                loading: false
            };
        case PLANES_GESTION:
            return {
                ...state,
                planes_estudio: action.payload,
                loading: false
            };
        case AVISOS:
            return {
                ...state,
                avisos: action.payload,
                loading: false
            };
        case REINICIO:
            return {
                ...state,
                reinicio: action.payload,
                loading: false
            };
        default:
            return {
                ...state,
                loading: false
            };
    }
}
