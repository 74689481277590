import {
    ACTIVIDADES_ADMIN,
    ACTIVIDADES_ALUMNOS,
    ACTIVITIES_MAESTRO,
    ALUMNOS_REVISION,
    CLASES_CICLO,
    CLASES_CREADAS,
    CLASES_MAESTRO,
    CLASS_PLANNING,
    CLASSES_GROUP,
    DIAS_CLASES,
    GRUPOS_MAESTRO,
    INFO_ACTIVITIES_ADMIN,
    LOADING_PLANES,
    MATERIAS_MAESTRO,
    OPTIONAL_CONTENT,
    PLANEACIONES_MAESTRO,
    PLANES,
    PLANES_ADMIN,
    PLANES_IN_CALENDARIO,
    PLANES_MATERIAS_ADMIN,
    PLANES_MATERIAS_TEACHER,
    POST_LOADING_PLANES,
    SESSIONS_SCHEDULE,
    STATUS_MODAL_CRITERIA,
    SUSPENCIONES_CLASES,
    TEMAS_ASIGNADOS_MATERIA,
    THEMES_SUBJECT,
    VALIDATION_TO_CREATE,
    VARIABLE_EXTRA_ALL,
    VARIABLE_GRUPOS
} from '../types';
import {AppEnv} from '../../../components/env';
import axios from 'axios';
import {alertFail, alertSuccess} from "../../../components/Global-W/NewFunctions";

const apiUrl = AppEnv.api;

export function setLoading(data) {
    return {
        type: LOADING_PLANES,
        payload: data
    };
}

export function setLoadPost(data) {
    return {
        type: POST_LOADING_PLANES,
        payload: data
    };
}

export function createPlan(token, bd, user, fd) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.post(apiUrl + bd + '/planes/create', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                alertSuccess();
                dispatch(fetchPlanes(token, bd, user));
                resolve(response);
            }).catch(error => {
                alertFail();
                dispatch(fetchPlanes(token, bd, user));
                reject(error);
            });
        });
    };
}

export function updatePlan(token, bd, user, fd, id) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.post(apiUrl + bd + '/planes/update/' + id, fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                alertSuccess();
                dispatch(fetchPlanes(token, bd, user));
                resolve(response);
            }).catch(error => {
                alertFail();
                dispatch(fetchPlanes(token, bd, user));
                reject(error);
            });
        });
    };
}

export function deletePlan(token, country, nivel, tipoNivel, id) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + 'planes/delete/' + id, {
                method: 'get', headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            alertSuccess();
            dispatch(fetchPlanes(token, country, nivel, tipoNivel));
        }).catch(error => {
            alertFail();
            dispatch(fetchPlanes(token, country, nivel, tipoNivel));
        });
    };
}

export function fetchPlanes(token, bd, user) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/get/' + user, {
                    method: 'get', headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchPlanesSuccess(response.data));
                resolve(response)
            }).catch(error => {
                dispatch(fetchPlanesFailed());
                reject(error)
            });
        })
    };
}

export function fetchPlanesSuccess(response) {
    return {
        type: PLANES,
        payload: response
    };
}

export function fetchPlanesFailed() {
    alertFail();
    return {
        type: PLANES,
        payload: []
    };
}

export function fetchPlanesAdmin(token, bd, user) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/admin', {
                    method: 'get', headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(fetchPlanesAdminSuccess(response.data));
                resolve(response);
            }).catch(error => {
                dispatch(fetchPlanesAdminFailed());
                reject(error);
            });
        })
    };
}

export function fetchPlanesAdminSuccess(response) {
    return {
        type: PLANES_ADMIN,
        payload: response
    };
}

export function fetchPlanesAdminFailed() {
    alertFail();
    return {
        type: PLANES_ADMIN,
        payload: []
    };
}

export function fetchMateriasMaestro(token, bd, periodo = '') {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/materias/maestro/V2/true/true/' + periodo, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchMateriasMaestroSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                reject(error);
                dispatch(fetchMateriasMaestroFailed());
            });
        })
    }
}

export function fetchSubjectsTeacher(token, bd, periodo = '', sub_period = '', typePeriod) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/subjects/by/teacher/true/true/'+ typePeriod+ '/' + periodo+ '/' + sub_period, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchMateriasMaestroSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                reject(error);
                dispatch(fetchMateriasMaestroFailed());
            });
        })
    }
}

export function fetchMateriasMaestroSuccess(response) {
    return {
        type: MATERIAS_MAESTRO,
        payload: response
    };
}

export function fetchMateriasMaestroFailed() {
    alertFail();
    return {
        type: MATERIAS_MAESTRO,
        payload: []
    };
}

export function updateAsignacion(token, bd, fd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.post(apiUrl + bd + '/planes/asignar', fd, {
                method: 'post', headers: {
                    Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }
        ).then((response) => {
            alertSuccess();
            dispatch(fetchMateriasMaestro(token, bd));
        }).catch(error => {
            alertFail();
            dispatch(fetchMateriasMaestro(token, bd));
        });
    };
}

export function fetchGruposMaestro(token, bd, id_materia) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/grupos/materias/' + id_materia, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchMateriasGruposSuccess(response.data));
        }).catch(error => {
            dispatch(fetchMateriasGruposFailed());
        });
    }
}

export function fetchMateriasGruposSuccess(response) {
    return {
        type: GRUPOS_MAESTRO,
        payload: response
    };
}

export function fetchMateriasGruposFailed() {
    alertFail();
    return {
        type: GRUPOS_MAESTRO,
        payload: []
    };
}

export function getTotalClasesMaestro(token, bd, id_materia) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/planes/total/clases/materia/' + id_materia, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(getTotalClasesMaestroSuccess(response.data));
        }).catch(error => {
            dispatch(getTotalClasesMaestroFailed());
        });
    }
}

export function getTotalClasesMaestroSuccess(response) {
    return {
        type: CLASES_MAESTRO,
        payload: response
    };
}

export function getTotalClasesMaestroFailed() {
    alertFail();
    return {
        type: CLASES_MAESTRO,
        payload: []
    };
}

export function createClasePlan(token, bd, fd) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.post(apiUrl + bd + '/planes/save/clases', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                alertSuccess();
                resolve(response);
            }).catch(error => {
                alertFail();
                reject(error);
            });
        });
    };
}

export function fetchFechasClasesCiclo(token, bd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/planes/data/clases/ciclo', {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchFechasClasesCicloSuccess(response.data));
        }).catch(error => {
            dispatch(fetchFechasClasesCicloFailed());
        });
    }
}

export function fetchFechasClasesCicloSuccess(response) {
    return {
        type: CLASES_CICLO,
        payload: response
    };
}

export function fetchFechasClasesCicloFailed() {
    alertFail();
    return {
        type: CLASES_CICLO,
        payload: []
    };
}

export function fetchDiasClasePorGrupo(token, bd, id_grado, id_grupo, id_materia, id_maestro) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/class/days/' + id_grado + '/' + id_grupo + '/' + id_materia + '/' + id_maestro, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                resolve(response.data);
                dispatch(fetchDiasClasePorGrupoSuccess(response.data));
            }).catch(error => {
                reject(error);
                dispatch(fetchDiasClasePorGrupoFailed());
            });
        });
    }
}

export function fetchDiasClasePorGrupoSuccess(response) {
    return {
        type: DIAS_CLASES,
        payload: response
    };
}

export function fetchDiasClasePorGrupoFailed() {
    alertFail();
    return {
        type: DIAS_CLASES,
        payload: []
    };
}

export function fetchClasesCreadas(token, bd, id_materia, id_maestro, id_grupo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/planes/clases/creadas/' + id_materia + '/' + id_maestro + '/' + id_grupo, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchClasesCreadasSuccess(response.data));
        }).catch(error => {
            dispatch(fetchClasesCreadasFailed());
        });
    }
}

export function fetchClasesCreadasSuccess(response) {
    return {
        type: CLASES_CREADAS,
        payload: response
    };
}

export function fetchClasesCreadasFailed() {
    alertFail();
    return {
        type: CLASES_CREADAS,
        payload: []
    };
}

export function updatePlanDeClase(token, bd, fd, id_maestro, id_materia, grupo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoadPost(true));
            axios.post(apiUrl + bd + '/planes/sesiones/update', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                alertSuccess('Los cambios se han guardado con éxito');
                dispatch(fetchPlanningTeacher(token, bd, id_maestro, id_materia, grupo));
                dispatch(setLoadPost(false));
                resolve(response);
            }).catch(error => {
                dispatch(fetchPlanningTeacher(token, bd, id_maestro, id_materia, grupo));
                dispatch(setLoadPost(false));
                alertFail();
                reject(error);
            });
        });
    };
}

export function createLearnActivity(token, bd, fd) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoadPost(true));
            axios.post(apiUrl + bd + '/activity_learn/create/learn/activity', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                resolve(response);
            }).catch(error => {
                dispatch(setLoadPost(false));
                reject('error_1' + error);
            });
        });
    };
}

export function fetchActivities(token, bd, id_materia, id_grupo, periodo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/activity_learn/get/activities/' + id_materia + '/' + id_grupo + '/' + periodo, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchActivitiesSuccess(response.data));
        }).catch((error) => {
            dispatch(fetchActivitiesFailed());
        });
    }
}

export function fetchLearnActivities(token, bd, id_materia, id_grupo, periodo, sub_period = '', type_period) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/activity_learn/fetch/teacher/' + id_materia + '/' + id_grupo + '/' + periodo + '/' + type_period + '/' + sub_period, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchActivitiesSuccess(response.data));
        }).catch((error) => {
            dispatch(fetchActivitiesFailed());
        });
    }
}

export function fetchActivitiesSuccess(response) {
    return {
        type: ACTIVITIES_MAESTRO,
        payload: response
    };
}

export function fetchActivitiesFailed() {
    alertFail();
    return {
        type: ACTIVITIES_MAESTRO,
        payload: []
    };
}

export function updateLearnActivity(token, bd, fd, id_materia, id_grupo, periodo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoadPost(true));
            axios.post(apiUrl + bd + '/activity_learn/update/activity/learn', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(setLoadPost(false));
                alertSuccess('Se ha editado la actividad con éxito');
                // dispatch(fetchActivities(token, bd, id_materia, id_grupo, periodo));
                resolve(response);
            }).catch(error => {
                dispatch(setLoadPost(false));
                alertFail();
                reject(error);
            });
        });
    };
}

export function fetchGroupsAndVariableExtra(token, bd, id_materia, id_maestro, periodo, sub_periodo = null, type_period) {
    return (dispatch) => {
        dispatch(setLoading(true));
        let api = '/encuadres/variable/extra/' + id_materia + '/' + id_maestro + '/' + periodo + '/' +type_period + '/';
        if (sub_periodo !== null) {
            api = '/encuadres/variable/extra/' + id_materia + '/' + id_maestro + '/' + periodo + '/' +type_period+ '/' + sub_periodo
        }
        axios.get(apiUrl + bd + api, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchGroupsAndVariableExtraSuccess(response.data));
        }).catch(error => {
            dispatch(fetchGroupsAndVariableExtraFailed());
        });
    }
}

export function fetchGroupsAndVariableExtraSuccess(response) {
    return {
        type: VARIABLE_GRUPOS,
        payload: response
    };
}

export function fetchGroupsAndVariableExtraFailed() {
    alertFail();
    return {
        type: VARIABLE_GRUPOS,
        payload: []
    };
}

export function saveVariableExtra(token, bd, fd, id_materia, id_maestro, periodo, sub_period = null, type_period) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.post(apiUrl + bd + '/encuadres/save/variable/extra', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                alertSuccess('Se ha editado el criterio extra con éxito');
                dispatch(fetchGroupsAndVariableExtra(token, bd, id_materia, id_maestro, periodo, sub_period, type_period))
                // resolve();
            }).catch(error => {
                alertFail();
                // resolve();
            });
        });
    };
}

export function updateEstatusVariableExtra(token, bd, id_encuadre, estatus, id_materia, id_maestro, periodo) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/encuadres/update/estatus/variable/extra/' + id_encuadre + '/' + estatus, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            alertSuccess('Se ha editado el criterio extra con éxito');
            dispatch(fetchGroupsAndVariableExtra(token, bd, id_materia, id_maestro, periodo))
        }).catch(error => {
            alertFail();
            dispatch(fetchGroupsAndVariableExtra(token, bd, id_materia, id_maestro, periodo))
        });
    }
}

export function fetchCriteriosExtra(token, bd, periodo, sub_period = null) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            let api = '/encuadres/rubro/extra/' + periodo + '/';
            if (sub_period !== null) {
                api = '/encuadres/rubro/extra/' + periodo + '/' + sub_period;
            }
            axios.get(apiUrl + bd + api, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchCriteriosExtraSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCriteriosExtraFailed());
                resolve();
            })
        });
    }

}

export function fetchCriteriosExtraSuccess(response) {
    return {
        type: VARIABLE_EXTRA_ALL,
        payload: response
    };
}

export function fetchCriteriosExtraFailed() {
    alertFail();
    return {
        type: VARIABLE_EXTRA_ALL,
        payload: []
    };
}

export function fetchValidationToCreateClass(token, bd) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/planes/validation/toCreate', {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchValidationToCreateClassSuccess(response.data));
        }).catch(error => {

            dispatch(fetchValidationToCreateClassFailed())
        })
    }

}

export function fetchValidationToCreateClassSuccess(response) {
    return {
        type: VALIDATION_TO_CREATE,
        validation_planes: response.validation_planes === '1',
    };
}

export function fetchValidationToCreateClassFailed() {
    alertFail();
    return {
        type: VALIDATION_TO_CREATE,
        payload: []
    };
}

export function deletePlanSinAsignar(token, bd, id_plan, user) {
    return (dispatch) => {
        return new Promise(((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/delete/unassigned/' + id_plan, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                alertSuccess('Se ha borrado el plan correctamente');
                dispatch(fetchPlanes(token, bd, user));
                resolve(response);
            }).catch(error => {
                reject(error)
                dispatch(fetchPlanes(token, bd, user))
            })
        }))
    }
}

export function fetchSuspencionesClases(token, bd, fecha) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/events/show/suspenciones/' + fecha, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchSuspencionesClasesSuccess(response.data));
        }).catch(error => {
            dispatch(fetchSuspencionesClasesFailed())
        })
    }

}

export function fetchSuspencionesClasesSuccess(response) {
    return {
        type: SUSPENCIONES_CLASES,
        payload: response.validation_planes === '1',
    };
}

export function fetchSuspencionesClasesFailed() {
    alertFail();
    return {
        type: SUSPENCIONES_CLASES,
        payload: []
    };
}

export function fetchTemasAsignadosPorMateria(token, bd, materia, ciclo, periodo, maestro, isClub) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/planes/get/temas/asignados/materia/' + materia + '/' + ciclo + '/' + periodo + '/' + maestro + '/' + isClub, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchTemasAsignadosPorMateriaSuccess(response.data));
        }).catch(error => {
            dispatch(fetchTemasAsignadosPorMateriaFailed())
        })
    }

}

export function fetchTemasAsignadosPorMateriaSuccess(response) {
    return {
        type: TEMAS_ASIGNADOS_MATERIA,
        payload: response,
    };
}

export function fetchTemasAsignadosPorMateriaFailed() {
    alertFail();
    return {
        type: TEMAS_ASIGNADOS_MATERIA,
        payload: []
    };
}

export function deleteLearnActivity(token, bd, id_actividad, id_materia, id_grupo, periodo, sub_period = '') {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/activity_learn/suppress/activity/' + id_actividad, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            alertSuccess('Se ha borrado la actividad correctamente');
            dispatch(fetchActivities(token, bd, id_materia, id_grupo, periodo,sub_period));
        }).catch(error => {
            dispatch(fetchActivities(token, bd, id_materia, id_grupo, periodo, sub_period));
        })
    }
}

export function fetchAlumnosListRevision(token, bd, grado, grupo, id_actividad, tipoAlumnos) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/activity_learn/alumnos/check/activity/' + grado + '/' + grupo + '/' + id_actividad + '/' + tipoAlumnos, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchAlumnosListRevisionSuccess(response.data));
                resolve(response.data)
            }).catch(error => {
                reject(error)
                dispatch(fetchAlumnosListRevisionFailed())
            })
        });
    }
}

export function fetchAlumnosListRevisionSuccess(response) {
    return {
        type: ALUMNOS_REVISION,
        payload: response,
    };
}

export function fetchAlumnosListRevisionFailed() {
    alertFail();
    return {
        type: ALUMNOS_REVISION,
        payload: []
    };
}

export function revisarActividad(token, bd, fd, grado, grupo, id_actividad, tipoAlumnos) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoadPost(true));
            axios.post(apiUrl + bd + '/activity_learn/revisar/actividad/alumno', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(setLoadPost(false));
                alertSuccess('Se ha realizado la acción con éxito');
                // dispatch(fetchAlumnosListRevision(token, bd, grado, grupo, id_actividad, tipoAlumnos))
                resolve();
            }).catch(error => {
                dispatch(setLoadPost(false));
                alertFail();
                reject();
            });
        });
    };
}

export function fetchActivitiesAlumno(token, bd, id_alumno, inicio, tipoActividad, materia) {
    let url = ''
    if (!materia || materia === -1) {
        url = apiUrl + bd + '/activity_learn/activities/alumno/' + id_alumno + '/' + inicio + '/' + tipoActividad
    } else {
        url = apiUrl + bd + '/activity_learn/activities/alumno/materia/' + id_alumno + '/' + inicio + '/' + tipoActividad + '/' + materia
    }
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(url, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchActivitiesAlumnoSuccess(response.data));
        }).catch(error => {
            dispatch(fetchActivitiesAlumnoFailed())
        })
    }

}

export function fetchActivitiesAlumnoSuccess(response) {
    return {
        type: ACTIVIDADES_ALUMNOS,
        payload: response,
    };
}

export function fetchActivitiesAlumnoFailed() {
    alertFail();
    return {
        type: ACTIVIDADES_ALUMNOS,
        payload: []
    };
}

export function sendRespuestaActividad(token, bd, fd, id_alumno, inicio, tipoActividad) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoadPost(true));
            axios.post(apiUrl + bd + '/activity_learn/send/respuesta/actividad', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                // alertSuccess('Se ha enviado la respuesta de la actividad con éxito');
                resolve(response);
            }).catch(error => {
                dispatch(setLoadPost(false));
                alertFail();
                resolve(error);
            });
        });
    };
}

export function comentarRespuesta(token, bd, fd, grado, grupo, id_actividad, estatus, tipoAlumnos) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            // dispatch(setLoadPost(true));
            axios.post(apiUrl + bd + '/activity_learn/comment/respuesta/actividad', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(setLoadPost(false));
                if (estatus === 'alert') {
                    alertSuccess('Se ha solicitado la corrección de la actividad con éxito');
                }
                // dispatch(fetchAlumnosListRevision(token, bd, grado, grupo, id_actividad, tipoAlumnos))
                resolve(response);
            }).catch(error => {
                // dispatch(setLoadPost(false));
                alertFail();
                reject(error);
            });
        });
    };
}

export function fetchSesionesCalendario(token, bd, id_maestro, id_materia, fecha) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/sessions/calendar/activity/' + id_maestro + '/' + id_materia + '/' + fecha, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchSesionesCalendarioSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchSesionesCalendarioFailes());
                resolve();
            })
        });
    }

}

export function fetchSesionesCalendarioSuccess(response) {
    return {
        type: PLANES_IN_CALENDARIO,
        payload: response,
    };
}

export function fetchSesionesCalendarioFailes() {
    alertFail();
    return {
        type: PLANES_IN_CALENDARIO,
        payload: []
    };
}

export function fetchSessionsBySchedule(token, bd, id_maestro, id_materia) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/schedule/sessions/' + id_maestro + '/' + id_materia, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchSessionsByScheduleSuccess(response.data));
                resolve();
            }).catch(error => {
                resolve();
                dispatch(fetchSessionsByScheduleFailed())
            })
        });
    }

}

export function fetchSessionsByScheduleSuccess(response) {
    return {
        type: SESSIONS_SCHEDULE,
        payload: response,
    };
}

export function fetchSessionsByScheduleFailed() {
    alertFail();
    return {
        type: SESSIONS_SCHEDULE,
        payload: []
    };
}

export function fetchActivitiesAdmin(token, bd, inicio, id_maestro, id_materia) {
    return (dispatch) => {
        dispatch(setLoading(true));
        axios.get(apiUrl + bd + '/activity_learn/admin/activities/' + inicio + '/' + id_maestro + '/' + id_materia, {
            method: 'GET', headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            }
        }).then((response) => {
            dispatch(fetchActivitiesAdminSuccess(response.data));
        }).catch(error => {
            dispatch(fetchActivitiesAdminFailed())
        })
    }

}

export function fetchActivitiesAdminSuccess(response) {
    return {
        type: ACTIVIDADES_ADMIN,
        payload: response,
    };
}

export function fetchActivitiesAdminFailed() {
    alertFail();
    return {
        type: ACTIVIDADES_ADMIN,
        payload: []
    };
}

export function fetchPlanesMateriasAdmin(token, bd, date_from, date_to) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/subjects/with/maestro/' + date_from + '/' + date_to, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchPlanesMateriasAdminSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchPlanesMateriasAdminFailed())
                resolve();
            })
        });
    }
}

export function fetchPlanesMateriasAdminSuccess(response) {
    return {
        type: PLANES_MATERIAS_ADMIN,
        payload: response,
    };
}

export function fetchPlanesMateriasAdminFailed() {
    alertFail();
    return {
        type: PLANES_MATERIAS_ADMIN,
        payload: []
    };
}

export function exportPdfClasesAdmin(token, bd, fd) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            // dispatch(setLoadPost(true));
            axios.post(apiUrl + bd + '/planes/create/pdf/clases', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                dispatch(setLoadPost(false));
                resolve(response);
            }).catch(error => {
                dispatch(setLoadPost(false));
                alertFail();
                resolve();
            });
        });
    };
}

export function fetchGruposByClase(token, bd, id_maestro, id_materia, clase) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/classes/sesiones/maestro/' + id_maestro + '/' + id_materia + '/' + clase, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                // dispatch(fetchAcumuladosAlumnosSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                // dispatch(fetchAcumuladosAlumnosFailed())
                reject(error);
            })
        });
    }
}

export function fetchClasesByGrupo(token, bd, id_maestro, id_materia, grupo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/grupo/clases/maestro/' + id_maestro + '/' + id_materia + '/' + grupo, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                // dispatch(fetchAcumuladosAlumnosSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                // dispatch(fetchAcumuladosAlumnosFailed())
                reject(error);
            })
        });
    }
}

export function fetchPlanningTeacher(token, bd, id_maestro, id_materia, grupo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/planeaciones/sesiones/maestro/' + id_maestro + '/' + id_materia + '/' + grupo, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchPlanningTeacherSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchPlanningTeacherFailed())
                resolve();
            })
        });
    }
}

export function fetchPlanningTeacherSuccess(response) {
    return {
        type: PLANEACIONES_MAESTRO,
        payload: response,
    };
}

export function fetchPlanningTeacherFailed() {
    alertFail();
    return {
        type: PLANEACIONES_MAESTRO,
        payload: []
    };
}

export function fetchClassesGroup(token, bd, id_maestro, id_materia, grupo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/clases/grupo/' + id_maestro + '/' + id_materia + '/' + grupo, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchClassesGroupSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchClassesGroupFailed())
                resolve();
            })
        });
    }
}

export function fetchClassesGroupSuccess(response) {
    return {
        type: CLASSES_GROUP,
        payload: response,
    };
}

export function fetchClassesGroupFailed() {
    alertFail();
    return {
        type: CLASSES_GROUP,
        payload: []
    };
}

export function saveContentOptional(token, bd, fd) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoadPost(true));
            axios.post(apiUrl + bd + '/planes/save/optional/content', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                alertSuccess('Se han definido los contenidos correctamente');
                dispatch(setLoadPost(false));
                resolve(response);
            }).catch(error => {
                dispatch(setLoadPost(false));
                alertFail();
                reject(error);
            });
        });
    };
}

export function fetchContentOptionalTeacher(token, bd, id_maestro, id_materia, grupo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/optional/teacher/content/' + id_maestro, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchContentOptionalTeacherSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchContentOptionalTeacherFailed())
                reject(error);
            })
        });
    }
}

export function deleteOptionalContentItem(token, bd, id_item) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios.get(apiUrl + bd + '/planes/delete/optional/content/' + id_item, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                resolve(response.data);
            }).catch(error => {
                resolve(error);
            })
        });
    }
}

export function fetchContentOptionalTeacherSuccess(response) {
    return {
        type: OPTIONAL_CONTENT,
        payload: response,
    };
}

export function fetchContentOptionalTeacherFailed() {
    alertFail();
    return {
        type: OPTIONAL_CONTENT,
        payload: []
    };
}

export function fetchPlanesMateriasMaestro(token, bd, id_maestro, date_from, date_to) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/subjects/planeaciones/maestro/' + id_maestro + '/' + date_from + '/' + date_to, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchPlanesMateriasMaestroSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchPlanesMateriasMaestroFailed())
                resolve();
            })
        });
    }
}

export function fetchPlanesMateriasMaestroSuccess(response) {
    return {
        type: PLANES_MATERIAS_TEACHER,
        payload: response,
    };
}

export function fetchPlanesMateriasMaestroFailed() {
    alertFail();
    return {
        type: PLANES_MATERIAS_TEACHER,
        payload: []
    };
}

export function fetchInfoActividadesAdmin(token, bd, id_alumno, periodo) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/activity_learn/info/activities/alumno/' + id_alumno + '/' + periodo, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchInfoActividadesAdminSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchInfoActividadesAdminFailed())
                resolve();
            })
        });
    }
}

export function activityReportStudents(token, bd, id_alumno, typePeriod,periodo, subPeriod) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/activity_learn/report/student/' + id_alumno +'/'+typePeriod + '/' + periodo +'/'+subPeriod, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchInfoActividadesAdminSuccess(response.data));
                resolve();
            }).catch(error => {
                dispatch(fetchInfoActividadesAdminFailed())
                resolve();
            })
        });
    }
}

export function fetchInfoActividadesAdminSuccess(response) {
    return {
        type: INFO_ACTIVITIES_ADMIN,
        payload: response,
    };
}

export function fetchInfoActividadesAdminFailed() {
    alertFail();
    return {
        type: INFO_ACTIVITIES_ADMIN,
        payload: []
    };
}

export function deleteCalificationsActivitie(token, bd, id_actividad) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/activity_learn/delete/califications/activitie/' + id_actividad, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                resolve(response);
            }).catch(error => {
                reject(error);
            })
        });
    }
}

//  <=<●>=><=<●>=><=<●>=><=<●>=> PETICIONES DE PLANEACIONES V3 <=<●>=><=<●>=><=<●>=><=<●>=>

export function fetchClassPlanning(token, bd, id_maestro, id_materia, id_grupo, fecha, hora) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/planning/schedule/' + id_maestro + '/' + id_materia + '/' + id_grupo + '/' + fecha + '/' + hora, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchClassPlanningSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchClassPlanningFailed())
                resolve(error);
            })
        });
    }
}

export function fetchClassPlanningSuccess(response) {
    return {
        type: CLASS_PLANNING,
        payload: response,
    };
}

export function fetchClassPlanningFailed() {
    alertFail();
    return {
        type: CLASS_PLANNING,
        payload: []
    };
}

export function fetchTemasPorMaestroYMateria(token, bd, id_maestro, id_materia) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoading(true));
            axios.get(apiUrl + bd + '/planes/themes/teacher/subject/' + id_maestro + '/' + id_materia, {
                method: 'GET', headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token
                }
            }).then((response) => {
                dispatch(fetchTemasPorMaestroYMateriaSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchTemasPorMaestroYMateriaFailed())
                resolve(error);
            })
        });
    }
}

export function fetchTemasPorMaestroYMateriaSuccess(response) {
    return {
        type: THEMES_SUBJECT,
        payload: response,
    };
}

export function fetchTemasPorMaestroYMateriaFailed() {
    alertFail();
    return {
        type: THEMES_SUBJECT,
        payload: []
    };
}

export function saverOrUpdatePlanningClass(token, bd, fd) {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoadPost(true));
            axios.post(apiUrl + bd + '/planes/update/save/plannings', fd, {
                    method: 'post', headers: {
                        Accept: 'application/json, text/plain, */*', 'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token
                    }
                }
            ).then((response) => {
                // alertSuccess('Los cambios se han guardado con éxito');
                dispatch(setLoadPost(false));
                resolve(response);
            }).catch(error => {
                dispatch(setLoadPost(false));
                alertFail();
                reject(error);
            });
        });
    };
}

export function showModalCriteria(data) {
    return {
        type: STATUS_MODAL_CRITERIA,
        payload: data
    }
}
