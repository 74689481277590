import React, {Suspense, useState} from 'react';
import {Col, Grid, Row} from 'react-material-responsive-grid';
import {RenderMenus} from "../../Global-W/Hooks/RenderMenus";
import {suspenseFallback} from "../../Global-W/NewFunctions";
import {KolegiaBroadcastContextProvider as BroadcastProvider} from "../../../Context/KolegiaBroadcast";
import VirtualClassAdmin from "./VirtualClassAdmin";

const MenuZoom = React.lazy(() => import( '../../Zoom-W/Admin/MenuZoom'));


export default function MenuAulaVirtual(subMenu) {
    const [menu, setMenu] = useState('');
    const subMenus = subMenu.subMenu;
    return (
        <Grid className="uk-width-1-1">
            <RenderMenus menu={subMenus} onPress={(it) => setMenu(it.web)}/>
            <Row className="uk-width-1-1@s centerAll">
                <Col className="uk-width-1-1@s centerAll">
                    <Suspense fallback={suspenseFallback()}>
                        {{
                            'Zoom': <MenuZoom/>,
                            'VirtualClassAdmin': <BroadcastProvider><VirtualClassAdmin/> </BroadcastProvider>,
                        }[menu]
                        }
                    </Suspense>
                </Col>
            </Row>
        </Grid>
    );
}
